<ng-container *ngIf="ready$ | async">
  <ng-scrollbar>
    <header class="header">
      <jit-header></jit-header>
      <ng-container *ngIf="page$ | async as page">
        <h1 *ngIf="page.title" class="title">
          <img
            *ngIf="page.icon"
            src="../assets/icons/noun-study.svg"
            alt="noun-study"
            height="48"
            width="48"
          />
          <span>{{ page.title }}</span>
        </h1>
      </ng-container>
      <jit-search-bar *ngIf="hasSearchBar$ | async"></jit-search-bar>
    </header>
    <router-outlet></router-outlet>
    <jit-footer></jit-footer>
  </ng-scrollbar>
  
  <jit-sidenav></jit-sidenav>
  <jit-notifications></jit-notifications>
  <jit-modals></jit-modals>
</ng-container>
