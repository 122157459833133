import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV, IEnvironment } from '@jit/shared';
import { CorrelationService, StorageService } from '@jit/core';

const keyAuthTransferDataResult: string = 'authData';

// DEPRECATED!!!

export const requestInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const env: IEnvironment = inject(ENV);
  const correlationService: CorrelationService = inject(CorrelationService);
  const storageService: StorageService = inject(StorageService);
  const authData: any = storageService.retrive(keyAuthTransferDataResult);
  
  let headers = request.headers;

  if (authData && authData.access_token && authData.token_type) {
    headers = headers.set('Authorization', authData.token_type + ' ' + authData.access_token);
  }

  headers = headers.set(CorrelationService.headerKey, correlationService.get());
  
  if (request.url.startsWith('/')) {
    request = request.clone({
      url: env.apiUrl + request.url,
    });
  }

  const clonedRequest = request.clone({ headers });

  return next(clonedRequest);
};
