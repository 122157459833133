import { Component, inject, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs/operators';
import { RangeFilterComponent } from '../range-filter/range-filter.component';
import { SearchService } from '../../services';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgScrollbarModule,
    TranslateModule,
    ReactiveFormsModule,
    RangeFilterComponent,
    IconSimpleComponent,
  ],
})
export class FiltersModalComponent implements OnInit {
  private dialogRef: DialogRef<unknown> = inject(DialogRef);
  private searchService: SearchService = inject(SearchService);
  private formBuilder: FormBuilder = inject(FormBuilder);

  public get form() {
    return this.searchService.form;
  };
  public readonly count$ = this.searchService.count$;

  filters$ = this.searchService.modalFilters$.pipe(
    map((filters) => {
      const query = this.searchService.getQuery();

      filters?.forEach((el) => {
        if (el.key === 'SKILL_RANGE') {
          const keyValue = query?.[el.key];

          this.form.addControl(el.key, this.formBuilder.control(keyValue));
        } else if (el.isSingle) {
          const keyValue = query?.[el.key];

          this.form.addControl(el.key, this.formBuilder.control(keyValue));
        } else {
          const group: Record<string, boolean> = {};

          el.options.forEach((item) => {
            let value: boolean = false;
            const keyValue = query?.[el.key];

            if (Array.isArray(keyValue)) {
              value = keyValue.includes(item.key);
            } else if (keyValue) {
              value = keyValue === item.key;
            }

            group[item.key] = value;
          });

          const groupControl = this.formBuilder.group(group);

          this.form.addControl(el.key, groupControl);
        }
      });

      return filters;
    })
  );

  ngOnInit(): void {
    this.form.addControl(
      'search',
      this.formBuilder.control(this.getSearchValue())
    );
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  getSearchValue() {
    return this.searchService.getQuery()?.['search'];
  }

  getFilterCount(key: string) {
    const value = this.searchService.getQuery()[key];

    if (Array.isArray(value)) {
      return value.length;
    } else {
      return value ? 1 : 0;
    }
  }

  onApply() {
    this.searchService.applyFilters(
      this.searchService.parseFilterValue(this.form.value)
    );
    this.closeDialog();
  }

  onReset(): void {
    // this.searchService.resetFilters();
    this.searchService.resetModalFilters();

    this.closeDialog();
  }
}
