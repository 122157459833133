<div class="jit-treeview-resources">
  <div class="jit-treeview-resources__header collapsible">
    <div 
      class="jit-treeview-resources__expand" 
      [class.expanded]="expanded"
      (click)="onToggle()"
    >
      <jit-icon-simple size="24" iconId="caret-down-outline"></jit-icon-simple>
    </div>
    <div 
      class="jit-treeview-resources__check" 
      [class.checked]="value" 
      [class.not-checked]="!value" 
      (click)="onChange()"
    >
      <jit-icon-simple *ngIf="value" size="24" iconId="small-check"></jit-icon-simple>
      <span *ngIf="!value">&nbsp;</span>
    </div>
    <div class="jit-treeview-resources__icon">
      <jit-icon-simple size="24" iconId="bundle-large"></jit-icon-simple>
    </div>
    <div class="jit-treeview-resources__title">
      {{ title }}
    </div>
    <div class="jit-treeview-resources__count">
      ({{ count }})
    </div>
    <div *ngIf="selected > 0" class="jit-treeview-resources__selected">
      {{ selected }} selected
    </div>
  </div>
  <div 
    class="jit-treeview-resources__content" 
    [class.expanded]="expanded"
  >
    <ng-content></ng-content>
  </div>
</div>