<mat-form-field floatLabel="always">
  <mat-label>{{ labels.title }}</mat-label>
  <mat-select
    #select
    [multiple]="false"
    [disabled]="disabled"
    (selectionChange)="onChange($event.value)"
    [hideSingleSelectionIndicator]="true"
    [placeholder]="labels.placeholder"
    [value]="current"
  >
    <mat-select-trigger *ngIf="current">
      {{ current.title }}
    </mat-select-trigger>

    <mat-option >{{ labels.clear }}</mat-option>

    <ng-container *ngFor="let option of options">
      <mat-option [value]="option">
        <span class="check">
          <jit-icon-simple size="16" iconId="check"></jit-icon-simple>
        </span>
        {{ option.title }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
