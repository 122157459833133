import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteInputComponent } from '../autocomplete-input/autocomplete-input.component';
import { RecentSearchCacheService } from '../../services';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { SubjectButtonsComponent } from '../subject-buttons/subject-buttons.component';
import { SelectFiltersComponent } from '../select-filters/select-filters.component';
import { ParamsService } from '@jit/data-layer';
import { UtilsService } from '@jit/core';

@Component({
  selector: 'jit-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    CommonModule,
    AutocompleteInputComponent,
    ReactiveFormsModule,
    IconSimpleComponent,
    SubjectButtonsComponent,
    SelectFiltersComponent,
  ],
})
export class SearchBarComponent implements OnInit, OnDestroy {

  private _cacheService = inject(RecentSearchCacheService);
  private _paramsService = inject(ParamsService);
  private _search: any = null;
  private _searchValue: any = null

  autocompleteOptions: string[] = [];

  ngOnInit(): void {
    this.autocompleteOptions = this._cacheService.getOptionsArray();
    
    this._search = UtilsService.debounce((value) => this.onSearch(value), 400);
  }

  ngOnDestroy() {
    this._search = null;
  }

  onSearch(e: any): void {
    let value: string = '';

    if (typeof e === 'string') {
      value = e;
    } else {
      value = e?.target?.value || '';
    }

    if (value !== this._searchValue) {
      if (value) {
        this._cacheService.optionsToCache(value);
        this.autocompleteOptions = this._cacheService.getOptionsArray();
      }

      this._searchValue = value;

      this._paramsService.applySearch(value);
    }
  }

  onRemove(value: string) {
    this._cacheService.removeValue(value);
    this.autocompleteOptions = this._cacheService.getOptionsArray();
  }

  onSearchChange(value: any): void {
    this._search(value);
  }

  onSearchChanged(value: any): void {
    this._search(value);
  }

}