import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { CollectionButtonComponent } from '../collection-button/collection-button.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { SortComponent } from '../sort/sort.component';
import { SeriesComponent } from '../series/series.component';

import { ISuiteEntity, IParentEntity } from '@jit/data-layer';

const suiteColorMap: Record<string, string> = {
  reading: 'pink',
  lectura: 'orange',
  math: 'blue',
  undefined: 'white',
};

@Component({
  selector: 'jit-suite',
  templateUrl: './suite.component.html',
  styleUrls: ['./suite.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    IconSimpleComponent,
    RouterLink,
    CollectionButtonComponent,
    SortComponent,
    SeriesComponent
  ],
})
class SuiteComponent implements OnInit {

  @Input() 
  suite!: ISuiteEntity;

  public isOpen: boolean = false;
  public parent!: IParentEntity;
  public colorMap: Record<string, string> = suiteColorMap;

  ngOnInit(): void {
    this.parent = this.suite.parentSubject as any;
  }

  onOpen(value: boolean): void {
    this.isOpen = value;
  }

  onSortChange(value: string | null): void {
    if (value === 'asc') {
      this.suite.series.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    } else if (value === 'desc') {
      this.suite.series.sort((a, b) => {
        return b.title.localeCompare(a.title);
      });
    }
  }

}

export {
  SuiteComponent,
  suiteColorMap,
};
