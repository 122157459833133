enum Resizes {
  MOBILE_PORTRAIT = 'mobile-portrait',
  MOBILE_LANDSCAPE = 'mobile-landscape',
  TABLET = 'tablet',
  LAPTOP = 'laptop',
  DESKTOP = 'desktop',
}

export {
  Resizes,
};
