import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export class Animations {
  public static errorAnim(): AnimationTriggerMetadata {
    return trigger('errorAnim', [
      state(
        'error-visible',
        style({
          height: '100%',
          width: '100%',
        })
      ),
      state(
        'error-hidden',
        style({
          height: '0',
          width: '0',
        })
      ),
      transition('error-visible => error-hidden', [animate('0.3s')]),
      transition('error-hidden => error-visible', [animate('0.3s')]),
    ]);
  }

  public static arrowAnim(): AnimationTriggerMetadata {
    return trigger('arrowAnim', [
      state(
        'arrow-up',
        style({
          transform: 'rotate(180deg)',
        })
      ),
      state(
        'arrow-down',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      state(
        'arrow-left',
        style({
          transform: 'rotate(90deg)',
        })
      ),
      state(
        'arrow-right',
        style({
          transform: 'rotate(270deg)',
        })
      ),
      transition('arrow-right <=> arrow-left', [animate('0.25s')]),
      transition('arrow-up <=> arrow-down', [animate('0.25s')]),
    ]);
  }
}
