import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-report-question-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ReportQuestionBooleanComponent {
  
}

export {
  ReportQuestionBooleanComponent,
};
