import { EventEmitter, IIEventEmitterSubscription } from './EventEmitter';
import { UtilsService } from '../../core/services/utils/utils.service';
import {
  ChangeDetectorRef,
  ɵComponentDef as ComponentDef,
  ɵDirectiveDef as DirectiveDef,
  ɵNG_DIR_DEF as NG_DIR_DEF,
  ɵNG_COMP_DEF as NG_COMP_DEF,
  ɵɵdirectiveInject as directiveInject
} from '@angular/core';

interface IStoreOptions {
  debug?: boolean;
}

type TSelectorResult<T> = T | Partial<T> | T[keyof T];
type TSelector<T> = ((state: T, prevState?: T) => Partial<T> | T[keyof T]) | keyof T;
type TSetState<T> = (state: Partial<T>) => void;
type TGetState<T> = () => T;
type TGetStateBySelector<T> = (selector?: TSelector<T>) => TSelectorResult<T>;
type TInitState<T> = (set: TSetState<T>, get: TGetState<T>) => T;
type TStoreDecorator = (target: any, propKey: string, descriptor?: any) => any;
type TUseStore<T> = ((selector?: TSelector<T>, options?: IStoreOptions) => TStoreDecorator) & {
  subscribe(cb: (value: T) => void): IIEventEmitterSubscription;
  subscribe(selector: TSelector<T>, cb: (value: TSelectorResult<T>) => void): IIEventEmitterSubscription;
  destroy(): void;
  getState: TGetState<T>;
  setState: TSetState<T>;
};

const NG_ONINIT = Symbol('INIT');
const NG_ONDESTROY = Symbol('DESTROY');
const CDR = Symbol('CDR');
const REFS = Symbol('REFS');
const SUBSCR = Symbol('SUBSCR');
const DEBOUNCE = Symbol('DEBOUNCE');

const STATE_CHANGED = '__STATE_CHANGED__';
const EMPTY_FN = () => {};

function createStore<T>(init: TInitState<T>): TUseStore<T> {
  // State
  let state: T;
  let prevState: T;

  const storeRefId = UtilsService.guid();
  const emitter: EventEmitter = new EventEmitter();

  (emitter as any)._storeRefId = storeRefId;

  const setState: TSetState<T> = (newState: Partial<T>): void => {
    const keys: (keyof T)[] = Object.keys(newState) as (keyof T)[];
    const changedKeys: (keyof T)[] = [];

    let _prevState = { ...state };

    for (const k of keys) {
      const s: any = state;
      const v: any = (newState as any)[k];

      if (s.hasOwnProperty(k) && s[k] !== v) {
        changedKeys.push(k);

        (state as any)[k] = v;
      }
    }

    if (changedKeys.length > 0) {
      prevState = _prevState;

      changedKeys.forEach((k: any) => emitter.emit(k));

      emitter.emit(STATE_CHANGED);
    }
  };

  const getState: TGetState<T> = (): T => ({ ...state });

  const getStateBySelector: TGetStateBySelector<T> = (selector?: TSelector<T>): TSelectorResult<T> => {
    if (typeof selector === 'function') {
      return selector({ ...state }, { ...prevState });
    } else if (typeof selector === 'string') {
      return state[selector] as Partial<T>;
    }
  
    return { ...state };
  };

  if (typeof init === 'function') {
    state = { ...init(setState, getState) } as T;
  } else {
    state = { ...(init as object) } as T;
  }

  prevState = state;

  if (Object.keys(state as object).includes(STATE_CHANGED)) {
    throw new Error(`createStore: key "${STATE_CHANGED}" is reserved name, please use another key naming`);
  }

  const destroy = () => {
    emitter.stopListening();
  }

  const subscribe: any = (selector: TSelector<T>, cb: (value: TSelectorResult<T>) => void): IIEventEmitterSubscription => {
    if (selector === STATE_CHANGED) {
      throw new Error(`store/subscribe: key "${STATE_CHANGED}" is reserved name, please use another key naming`);
    }

    if (typeof selector === 'function') {
      cb = selector as ((value: TSelectorResult<T>) => void);
    
      return emitter.on(STATE_CHANGED, () => cb(getStateBySelector(selector)));
    } else if (typeof selector === 'string') {
      return emitter.on(selector, () => cb(getStateBySelector(selector)));
    }
  
    return emitter.on(STATE_CHANGED, () => cb(getStateBySelector(selector)));
  };

  // Decorator

  function useStore(selector?: TSelector<T>, options: IStoreOptions = {}): TStoreDecorator {
    return function(target: any, propKey: string, descriptor?: any): any {
      if (options?.debug) {
        console.log('selector, target, propKey, descriptor ->', selector, target, propKey, descriptor);
      }

      if (!target[REFS]) {
        target[REFS] = {};
      }

      if (!target[REFS][storeRefId]) {
        target[REFS][storeRefId] = { 
          EMITTER: emitter,
          getStateBySelector,
        };
      }

      if (!target[DEBOUNCE]) {
        target[DEBOUNCE] = UtilsService.debounce((cdr: ChangeDetectorRef) => {
          cdr.markForCheck();
          cdr.detectChanges();
        }, 10);
      }

      if (!target[CDR]) {
        const isJIT: boolean = !target.constructor[NG_COMP_DEF];
        const inject = () => {
          const compDef: ComponentDef<any> | undefined = target.constructor[NG_COMP_DEF];
          const dirDef: DirectiveDef<any> | undefined = target.constructor[NG_DIR_DEF];
    
          if (compDef || dirDef) {
            const definition: any = compDef || dirDef;
            const factory = definition.factory || target.constructor['ɵfac'];

            if (!definition[CDR]) {
              definition[CDR] = true;

              definition.factory = function(...args: any[]) {
                const instance = factory(...args);
                
                instance[CDR] = directiveInject(ChangeDetectorRef);
    
                return instance;
              };
            }
          }
        };
    
        isJIT ? Promise.resolve().then(inject) : inject();
      }

      if (!target[REFS][storeRefId].CONTEXT) {
        target[REFS][storeRefId].CONTEXT = {};
      }

      if (!target[REFS][storeRefId].CONTEXT[propKey]) {
        target[REFS][storeRefId].CONTEXT[propKey] = {
          propKey: null,
          methodSelectors: new Set(),
          selectorKey: null,
          options: options,
        };
      }

      if (descriptor) {
        target[REFS][storeRefId].CONTEXT[propKey].methodSelectors.add(selector);
      } else {
        target[REFS][storeRefId].CONTEXT[propKey].propKey = propKey;
      }

      target[REFS][storeRefId].CONTEXT[propKey].selectorKey = selector;
      
      if (!target[REFS][storeRefId].KEYS) {
        target[REFS][storeRefId].KEYS = new Set();
      }
      
      target[REFS][storeRefId].KEYS.add(propKey);

      if (options?.debug) {
        console.log('target[REFS][storeRefId] ->', target[REFS][storeRefId]);
      }

      if (!target[NG_ONINIT]) {
        target[NG_ONINIT] = target['ngOnInit'] || EMPTY_FN;

        target.ngOnInit = function(...args: any[]) {
          const self: any = this;

          self[SUBSCR] = [];

          Object.keys(target[REFS]).forEach((refId) => {
            const ref = target[REFS][refId];

            ref.KEYS.forEach((key: string) => {
              if (ref.CONTEXT[key].options?.debug) {
                console.log('ngOnInit key, ref.CONTEXT[key] ->', key, ref.CONTEXT[key]);
              }

              if (ref.CONTEXT[key].methodSelectors && ref.CONTEXT[key].methodSelectors.size > 0) {
                for (let sel of ref.CONTEXT[key].methodSelectors) {
                  if (ref.CONTEXT[key].options?.debug) {
                    console.log(
                      'ngOnInit key, sel, ref.CONTEXT[key].methodSelectors ->', 
                      key, 
                      sel, 
                      ref.CONTEXT[key].methodSelectors
                    );
                  }
                  
                  const subscrCb = () => {
                    self[key](ref.getStateBySelector(sel));
  
                    self[CDR] && target[DEBOUNCE](self[CDR]);
                  };

                  if (typeof sel === 'function' || typeof sel === 'undefined') {
                    self[SUBSCR].push(ref.EMITTER.on(STATE_CHANGED, subscrCb));
                  } else if (typeof sel === 'string') {
                    self[SUBSCR].push(ref.EMITTER.on(sel, subscrCb));
                  }
                }
              }

              let subscr;
              
              if (ref.CONTEXT[key].propKey) {
                if (ref.CONTEXT[key].options?.debug) {
                  console.log(
                    'ngOnInit ref.CONTEXT[key].propKey, ref.CONTEXT[key].selectorKey ->', 
                    ref.CONTEXT[key].propKey,
                    ref.CONTEXT[key].selectorKey
                  );
                }
                
                const subscrCb = () => {
                  self[key] = ref.getStateBySelector(ref.CONTEXT[key].selectorKey);
                  
                  self[CDR] && target[DEBOUNCE](self[CDR]);
                };

                self[key] = ref.getStateBySelector(ref.CONTEXT[key].selectorKey);

                if (typeof ref.CONTEXT[key].selectorKey === 'function') {
                  subscr = ref.EMITTER.on(STATE_CHANGED, subscrCb);
                } else if (typeof ref.CONTEXT[key].selectorKey === 'string') {
                  subscr = ref.EMITTER.on(ref.CONTEXT[key].selectorKey, subscrCb);
                } else if (typeof ref.CONTEXT[key].selectorKey === 'undefined') {
                  subscr = ref.EMITTER.on(STATE_CHANGED, subscrCb);
                }
              }

              if (subscr) {
                self[SUBSCR].push(subscr);
              }
            });
          });

          return target[NG_ONINIT].apply(self, args);
        };
      }

      if (!target[NG_ONDESTROY]) {
        target[NG_ONDESTROY] = target['ngOnDestroy'] || EMPTY_FN;

        target.ngOnDestroy = function(...args: any[]) {
          const self: any = this;

          self[SUBSCR].forEach((s: IIEventEmitterSubscription) => s.unsubscribe());

          return target[NG_ONDESTROY].apply(self, args);
        };
      }
    };
  }

  useStore.subscribe = subscribe;
  useStore.destroy = destroy;
  useStore.getState = getState;
  useStore.setState = setState;

  return useStore as TUseStore<T>;
}

createStore.inject = (() => {}) as any;

createStore.setInjectorService = (service: any) => {
  createStore.inject = (token: any) => service.injector.get(token);
};

export {
  TSelectorResult,
  TSelector,
  TSetState,
  TGetState,
  TGetStateBySelector,
  TInitState,
  TStoreDecorator,
  TUseStore,
  createStore,
};
