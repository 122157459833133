import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchMatch',
  standalone: true,
})
export class SearchMatchPipe implements PipeTransform {
  transform(value: string, test: string | null): string {
    if (!test) {
      return value;
    }

    const regexp = new RegExp(test.toLowerCase(), 'i');

    return value.replace(
      regexp,
      '<span class="match-text">' + `$&` + '</span>'
    );
  }
}
