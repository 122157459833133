import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { NotificationCollectionComponent } from '../notification-collection/notification-collection.component';
import { IModalCollection } from '../../models';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CollectionService as CollectionServiceNew } from '../../../data-layer/services/collection.service';
import { ICollection } from '../../../data-layer/interfaces/collection.interface';
import { ISlugEntity } from '@jit/data-layer';
import { DialogStatus } from '@jit/core';
@Component({
  selector: 'jit-collection-modal',
  templateUrl: './collection-modal.component.html',
  styleUrls: ['./collection-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    IconSimpleComponent,
    NotificationCollectionComponent,
    NgScrollbarModule,
  ],
})
export class CollectionModalComponent implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();
  private _creationFormView = new BehaviorSubject<boolean>(false);
  private dialogRef: DialogRef<IModalCollection> = inject(DialogRef);
  private _collectionService = inject(CollectionServiceNew);
  private formBuilder: FormBuilder = inject(FormBuilder);

  private _collections = new BehaviorSubject<ICollection[]>([]);
  public form = this.formBuilder.group({});
  public name = new FormControl('');
  public data!: ISlugEntity;

  public collections$ = this._collections.asObservable();
  public creationFormView$ = this._creationFormView.asObservable();

  public async ngOnInit() {
    this.data = (this.dialogRef.data.item || {}) as ISlugEntity;

    const collections = this._collectionService.getCollections();

    collections.map((collection) =>
      this.form.addControl(
        collection.id,
        new FormControl(
          this.data
            ? this._collectionService.inCollection(collection.id, this.data)
            : false
        )
      )
    );

    this._collections.next(collections);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  public async action(action: string) {
    switch (action) {
      case 'save':
        await this.createNewCollection(this.name.value || '');
        break;
      case 'cancel':
        this.dialogRef.close({ dialogResult: false });
        break;
      case 'done':
        this.dialogRef.close();
    }
  }

  public async updateToCollection(collectionId: string) {
    const formElement = this.form.get(collectionId);
    const checked = formElement?.value;

    const collection = this._collectionService.getCollectionById(collectionId)!;

    if (checked) {
      await this._collectionService.addFavorite(this.data, collectionId);
      this._collectionService.showSnackbar({
        text: `Saved to ${collection.name}`,
        icon: 'check-circle',
        hasAction: false,
      });
    } else {
      if (this._collectionService.inCollectionsCount(this.data) <= 1) {
        this._collectionService.openConfirmDialog().subscribe((value) => {
          if (value === DialogStatus.Accept) {
            this._collectionService.removeFavorite(this.data, collectionId);
            this.dialogRef.close();
          } else {
            formElement?.setValue(true);
          }
        });
      } else {
        await this._collectionService.removeFavorite(this.data, collectionId);
        this._collectionService.showSnackbar({
          text: `Removed from ${collection.name}`,
          hasAction: false,
        });
      }
    }

    this._collections.next(this._collectionService.getCollections());
  }

  public changeView(): void {
    this._creationFormView.next(!this._creationFormView.value);
  }

  private async createNewCollection(name: string) {
    const collection = await this._collectionService.create(name);
    this.form.addControl(collection.id, new FormControl(false));

    this.changeView();
  }
}
