import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Tags } from './tag.enum';

@Component({
  selector: 'jit-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class TagComponent {

  @Input()
  type: Tags = Tags.DEFAULT;

  @Input()
  label: string = '';

  public Tags: typeof Tags = Tags;

}

export {
  TagComponent,
};
