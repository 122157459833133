<div class="sort">
  <span>Sort by</span>

  <button [cdkMenuTriggerFor]="menu" type="button" class="button sort-dropdown">
    <span class="sort-dropdown-value" *ngIf="currentValue$ | async as value">
      <span>{{ value.label }}</span>

      <jit-icon-simple
        size="18"
        *ngIf="value.icon"
        [iconId]="value.icon"
      ></jit-icon-simple>
    </span>

    <jit-icon-simple size="16" iconId="caret-down-outline"></jit-icon-simple>
  </button>
</div>

<ng-template #menu>
  <div class="menu" cdkMenu>
    <button
      type="button"
      class="menu-item"
      cdkMenuItem
      *ngFor="let item of items"
      (cdkMenuItemTriggered)="onSelect(item)"
    >
      {{ item.label }}
      <jit-icon-simple *ngIf="item.icon" [iconId]="item.icon"></jit-icon-simple>
    </button>
  </div>
</ng-template>
