import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NpsEmojiComponent } from '../emoji/emoji.component';

@Component({
  selector: 'jit-nps-banner-button',
  templateUrl: './banner-button.component.html',
  styleUrls: ['./banner-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NpsEmojiComponent,
  ],
})
class NpsBannerButtonComponent {

  @Input()
  value: number = 0;

  @Output()
  click: EventEmitter<number> = new EventEmitter();

  onClick(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.click.emit(this.value);
  }

}

export {
  NpsBannerButtonComponent,
};
