<button
  class="button"
  type="button"
  (click)="onClick($event)"
>
  <jit-icon-simple
    [size]="size"
    [iconId]="inCollection ? 'add-collection-filled' : 'add-collection'"
  ></jit-icon-simple>

  <ng-container *ngIf="inCollection">
    <span
      *ngIf="count > 1"
      class="count"
      [attr.data-count]="count"
      [ngStyle]="{ 'border-color': color, '--size': size + 'px' }"
    >
      <span [ngStyle]="{ color: color }">{{ count }}</span>
    </span>
  </ng-container>
</button>
