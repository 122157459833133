import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(value: string[] | null, query: string | null): string[] | null {
    if (!value || !query) {
      return value;
    }

    return value.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
  }
}
