import { Bundle } from './bundle';
import { Group, GroupResponse } from './group';
import { IResourceResponse } from './resource';
import { groupBy, prop, reduce, values } from 'ramda';
import { ELanguages } from './languages';
import { IconId } from './icon';

export class Suite extends Group {
  type = 'suite';
  bundles: Bundle[];
  typeIcon: IconId = 'suite-mono';
  typeName = 'suite';
  color: string;
  colorName: string;

  private _originBundles: Bundle[];

  static isSuiteType(payload: GroupResponse) {
    return payload.type === 'Suite';
  }

  get count() {
    return reduce((acc, item) => acc + item.resources.length, 0, this.bundles);
  }

  get standards() {
    return Array.from(
      reduce(
        (acc, item) => {
          item.standards.forEach((el) => acc.add(el));
          return acc;
        },
        new Set<string>(),
        this.bundles
      ).values()
    );
  }

  get tags() {
    return Array.from(
      reduce(
        (acc, item) => {
          item.tags.forEach((el) => acc.add(el));
          return acc;
        },
        new Set<string>(),
        this.bundles
      ).values()
    );
  }

  constructor(payload: GroupResponse) {
    super(payload);

    this.bundles = this.generateBundles(payload);
    this._originBundles = [ ...this.bundles ];
    
    this.color = this.bundles[0]?.color;
    this.colorName = this.bundles[0]?.colorName;
    this.description = this.description || this.bundles[0]?.resources[0]?.suite?.description;
  }

  clone() {
    return this._payload;
  }

  private generateBundles(payload: GroupResponse) {
    const materials = payload.materials;

    if (payload.groups) {
      return payload.groups.map((el) => new Bundle(el));
    }

    if (!materials) {
      return [];
    }

    const result: Bundle[] = [];

    const bundles = groupBy<IResourceResponse>((el) => prop('bundleId', el))(materials);

    values(bundles).forEach((el) => {
      if (el) {
        const groups = el[0].groups;

        if (groups) {
          const bundle = groups[payload.lang as ELanguages].find((el) =>
            Bundle.isBundleType(el)
          );

          if (bundle) {
            result.push(
              new Bundle({
                ...bundle,
                lang: payload.lang,
                materials: el,
              })
            );
          }
        }
      }
    });

    return result;
  }

  public sortBundles(sort: string | null): void {
    if (sort === 'asc') {
      this.bundles.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    } else if (sort === 'desc') {
      this.bundles.sort((a, b) => {
        return b.title.localeCompare(a.title);
      });
    } else {
      this.bundles = [ ...this._originBundles ];
    }

    this.bundles = [ ...this.bundles ];
  }
}
