import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { SuggestionsService } from '../services/suggestions.service';
import { ISearchResourcesState } from '../interfaces/search-resources-state.interface';
import { ISuggestionsState } from '../interfaces/suggestions-state.interface';
import { useSearchResources } from '../stores/search-resources.store';

const useSuggestions = createStore<ISuggestionsState>((set, get) => ({
  suggestions: [],
  search: '',

  setSearchId: async (search: string | void) => {
    if (search && search.length > 2) {
      const suggestionsService: SuggestionsService = createStore.inject(SuggestionsService);
      const query: any = { ...useSearchResources.getState() };

      query.search = search;

      const suggestions: string[] = await suggestionsService.getSuggestions(query);

      set({ suggestions, search });
    } else {
      set({ suggestions: [], search: '' });
    }
  },
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useSuggestions = useSuggestions;
}

export {
  ISearchResourcesState,
  useSuggestions,
};
