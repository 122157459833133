import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Bundle, Resource, Suite } from '../../models';
import { ResourceCardComponent } from '../resource-card/resource-card.component';

@Component({
  selector: 'jit-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
  standalone: true,
  imports: [CommonModule, ResourceCardComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceListComponent {
  @Input() resources!: (Resource | Suite | Bundle)[];
}
