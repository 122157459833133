interface IQueryFilters {
  subjectId?: string;
  gradeId?: string;
  domainId?: string;
  stateId?: string;
  suiteId?: string;
  seriesId?: string;
  resourceId?: string;
  searchId?: string;
  sortId?: string;
  contentType?: string;
  withSubscription?: number;
  assignable?:boolean;
}

export {
  IQueryFilters,
};
