import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

interface ITooltipPosition {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

@Component({
  selector: 'jit-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class TooltipComponent {

  @Input()
  tooltip: string = '';

  @Input()
  position: ITooltipPosition = { top: 0, right: 0, bottom: 0, left: 0, };

}

export {
  TooltipComponent
};
