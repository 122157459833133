import { Component, inject, OnInit } from '@angular/core';
import { OAuthService } from '@jit/core';

@Component({
  selector: 'jit-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
})
export class LogoutComponent implements OnInit {

  private _oauthService: OAuthService = inject(OAuthService);

  async ngOnInit(): Promise<void> {
    await this._oauthService.cleanSession();
  }
}
