<div class="header">
  <div class="header-group header-left-cell">
    <button type="button" class="button button-menu" (click)="onShowMenu()">
      <span class="button-menu-text"></span>
    </button>

    <jit-link
      *ngIf="backButton$ | async as options"
      [href]="options.href"
      [link]="options.link"
    >
      <span class="header-logo-wrapper">
        <img
          class="header-logo"
          src="assets/images/logo.svg"
          alt="Istation logo"
        />

        <span class="header-logo-text back-to-home">
          <jit-icon-simple iconId="back-arrow-outline"></jit-icon-simple>
          {{ options.title }}
        </span>
      </span>
    </jit-link>
  </div>

  <div class="header-nav header-center-cell">
    <a
      routerLink="/"
      class="nav-page"
      [class.active]="isResource"
    >
      Resources
    </a>
    @if (features.assignment && allowCreateAssignment) {
      <a
        routerLink="/assignments"
        class="nav-page desktop-gradebook"
        [class.active]="isGradebook"
      >
        Assignments
      </a>
    }
    <a
      routerLink="/my-collections"
      class="nav-page desktop-my-collections"
      [class.active]="isCollection"
    >
      My Collections
    </a>
    @if (features.sandbox) {
      <a
        routerLink="/sandbox"
        class="nav-page"
        [class.active]="isSandbox"
      >
        Dev Sandbox
      </a>
    }
  </div>

  <div class="collection-button">
    <ng-template [cdkPortalOutlet]="collectionButton$ | async"></ng-template>
  </div>

  <div class="user-info-wrap header-right-cell">
    @if (features.assignment && allowCreateAssignment) {
      <jit-new-assignment-button class="header-new-assignment" iconBg="#96D7F7"></jit-new-assignment-button>
    }
    <!-- <jit-feedback-button></jit-feedback-button> -->
    <div
      class="header-user-menu layout-row align-center"
      [matMenuTriggerFor]="menuUser"
      (click)="showHideFooter = false"
    >
      <img
        src="assets/icons/icon_profile-circled.svg"
        alt="back-arrow"
        height="48"
        width="48"
      />
      <div class="user-info layout-column">
        <span class="user-role">{{ userTitle$ | async }}</span>
        <span class="user-name">{{ userName$ | async }}</span>
      </div>
      <div class="user-menu-button">
        <jit-icon-simple
          iconId="caret-down-outline"
          class="angle-icon"
          [@arrowAnim]="showHideFooter ? 'arrow-down' : 'arrow-up'"
        ></jit-icon-simple>
      </div>
    </div>
  </div>
</div>

<mat-menu (closed)="showHideFooter = true" #menuUser="matMenu">
  <button
    type="button"
    class="action"
    mat-menu-item
    disableRipple
    (click)="onShowSelectState()"
  >
    {{ 'Standards: ' + (state ? state.title : 'Select') }}
  </button>
  <button
    type="button"
    class="action"
    mat-menu-item
    disableRipple
    (click)="onLogout()"
  >
    {{ 'app.logout' | translate }}
  </button>
</mat-menu>
