class ResponseError extends Error {

  public status: number;
  public statusText: string;
  public data: string | any;
  public headers: HeadersInit;

  constructor(status: number, statusText: string, data: string | any, headers: HeadersInit) {
    if (typeof data === 'object') {
      super(data.message || statusText);
    } else if (typeof data === 'string') {
      super(data || statusText);
    } else {
      super(statusText);
    }

    this.status = status;
    this.statusText = statusText;
    this.data = data;
    this.headers = headers;

    Object.setPrototypeOf(this, ResponseError.prototype);
  }

}

export {
  ResponseError
};
