import { CommonModule } from '@angular/common';
import { Component, AfterViewInit, Input, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '../../icon-simple/icon-simple.component';
import { ThSortComponent } from '../th-sort/th-sort.component';
import { TdCellInfoComponent } from '../td-cell-info/td-cell-info.component';
import { TrExpandComponent } from '../tr-expand/tr-expand.component';

@Component({
  selector: 'jit-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ThSortComponent,
    TdCellInfoComponent,
    TrExpandComponent,
  ],
})
class TableComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  evenBg: string = '';

  @Input()
  oddBg: string = '';

  @ViewChild('table', { static: false })
  table!: ElementRef<HTMLTableElement>;

  private _count: number = 0;
  private _isNotDestroyed = true;
  private _findTrRule: string = ':scope > tr, :scope > tbody > tr, :scope > tbody > jit-tr-expand > tr.jit-tr-content';

  ngOnInit(): void {
    this._isNotDestroyed = true;
  }

  ngOnDestroy(): void {
    this._isNotDestroyed = false;
  }

  ngAfterViewInit(): void {
    this._checkCount();
  }

  private _checkCount(): void {
    if (this.table.nativeElement && this.evenBg && this.oddBg && this._isNotDestroyed) {
      const rows: HTMLElement[] = [ ...(this.table.nativeElement.querySelectorAll(this._findTrRule) as any) ];
      const len = rows.length - 1;

      if (len > 0 && len !== this._count) {
        this._count = len;

        this._colorize(rows);
        this.refresh();
      }

      setTimeout(() => this._checkCount(), 500);
    }
  }

  private _colorize(rows: HTMLElement[]): void {
    const expanded: string = 'jit-tr-expand';

    let k = 0;

    for (let i = 0; rows[i]; i++) {
      let tr = rows[i];

      rows[i].style.backgroundColor = (k % 2 === 0) ? this.oddBg : this.evenBg;

      if (tr.classList.contains(expanded)) {
        rows[i].style.backgroundColor = ((k - 1) % 2 === 0) ? this.oddBg : this.evenBg;
      } else {
        rows[i].style.backgroundColor = (k % 2 === 0) ? this.oddBg : this.evenBg;
        k++;
      }
    }
  }

}

export {
  TableComponent,
};
