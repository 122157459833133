import { environment } from '../../../environments/environment';
import { createStore } from '../store';

interface IFeaturesState {
  assignment?: boolean;
  sandbox?: boolean;
  assignmentCsv?: boolean;
}

const useFeatures = createStore<IFeaturesState>((set, get) => {
  return { ...environment.features };
});

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useFeatures = useFeatures;
}

export {
  IFeaturesState,
  useFeatures,
};
