import { Component, inject, ChangeDetectionStrategy } from '@angular/core';
import { DialogStatus } from '@jit/core';
import { DialogRef } from '@ngneat/dialog';
import { ConfirmModalConfig } from '../../models';

@Component({
  selector: 'jit-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
  private dialogRef: DialogRef<ConfirmModalConfig> = inject(DialogRef);

  get title() {
    return this.dialogRef.data.title;
  }

  get content() {
    return this.dialogRef.data.description;
  }

  get action() {
    return this.dialogRef.data.action;
  }

  onReject() {
    this.dialogRef.close(DialogStatus.Reject);
  }

  onAccept() {
    this.dialogRef.close(DialogStatus.Accept);
  }
}
