import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { INotification, NotificationType, BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class NotificationItemComponent extends BaseComponent {

  @Input() 
  item!: INotification;

  public NotificationType: typeof NotificationType = NotificationType;

  onClose(): void {
    this.item.onClose && this.item.onClose();
  }

}

export {
  NotificationItemComponent,
};
