import { ISelectItem } from './search-resources-state.interface';
import { IGradebookKpi, TGradebook } from './gradebook.interface';
import { ThSorts } from '../../shared/components/gradebook/th-sort/th-sort.enum';
import { IStudentStats, IStudentLessonsResult } from './classroom-view.interface';
import { ILessonStats } from './lesson-view.interface';

interface IGradebookState {
  subject: ISelectItem | void;
  subjects: ISelectItem[];

  view: ISelectItem;
  views: ISelectItem[];

  period: ISelectItem | void;
  periods: ISelectItem[];

  kpi: IGradebookKpi;
  search: string | void;
  suggestions: string[];

  gradebook: TGradebook;

  sort: string | void;
  dir: string | void;

  init: () => Promise<void>;
  fetchKpi: () => Promise<void>;
  fetch: () => Promise<void>;
  reset: () => void;
  csv: () => void;
  unassignClassroom: (lesson: IStudentLessonsResult) => void;
  unassignLesson: (student: IStudentStats) => void;

  setSubject: (subject: ISelectItem | void) => void;
  setView: (view: ISelectItem) => void;
  setPeriod: (period: ISelectItem | void) => void;
  setSearch: (search: string | void) => void;

  setSortStudent: (name: string, sort: ThSorts, student: IStudentStats) => void;
  setSortLesson: (name: string, sort: ThSorts, lesson: ILessonStats) => void;
  setSortClassRoom: (direction: ThSorts) => void;
  setSortLessons: (direction: ThSorts) => void;
}

export {
  IGradebookState,
};
