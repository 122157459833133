<div class="jit-assignment-header">
  <div class="jit-assignment-header__item">
    <div class="jit-assignment-header__item-left">
      <h1>Create Assignment</h1>
    </div>
  </div>
  <div class="jit-assignment-header__item">
    <div class="jit-assignment-header__item-left">
      <h2>Assignment begins</h2>
    </div>
    <div class="jit-assignment-header__item-right">
      <jit-calendar-input [value]="startDate" (change)="onChangeStartDate($event)"></jit-calendar-input>
    </div>
  </div>
  <div class="jit-assignment-header__item">
    <div class="jit-assignment-header__item-left">
      <h2>Assignment is due <span>(optional)</span></h2>
    </div>
    <div class="jit-assignment-header__item-right">
      <jit-calendar-input [value]="dueDate" (change)="onChangeDueDate($event)"></jit-calendar-input>
    </div>
  </div>
  <div class="jit-assignment-header__item students" (click)="onClickStudents()">
    <div class="jit-assignment-header__item-left item-row">
      <h2>Assign to</h2>
      @if (hasStudentsAssigned) {
        <div class="item-warn">
          <jit-icon-simple size="24" iconId="triangle-alert-filled" [jitTooltip]="warnLabel"></jit-icon-simple>
          <div class="item-warn-bg"></div>
        </div>
      }
    </div>
    <div class="jit-assignment-header__item-right students">
      @if (totalStudents > 0 && totalStudents === selectedStudents) {
        <span>All my students</span>
      }
      @if (totalStudents !== selectedStudents) {
        <span>{{ selectedStudents }} student{{ selectedStudents > 1 ? 's': '' }}</span>
      }
      @if (totalStudents === 0 && selectedStudents === 0) {
        <span>You don't have students</span>
      }
      <jit-icon-simple size="24" iconId="caret-down-outline"></jit-icon-simple>
    </div>
  </div>
</div>