import { CommonModule } from '@angular/common';
import { 
  Component, Input, Output, EventEmitter, OnInit, inject, 
  OnChanges, SimpleChanges, AfterViewInit, OnDestroy, 
  ViewChild, ElementRef,
} from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { signal } from '@angular/core';
import { IEnvironment, ENV } from '../../models/environment';
import { allIconNames } from '../../models/icon';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { CLEAR_ID } from './simple-select.constants';
import { 
  ISelectItem, ISimpleSelectState, 
  ISimpleSelectListPositionResponse, ISimpleSelectClickOutsideResponse,
} from './simple-select.interfaces';
import { SimpleSelectService } from './simple-select.service';

@Component({
  selector: 'jit-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class SimpleSelectComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input()
  placeholder: string = 'Select value';

  @Input()
  clearable: boolean = true;

  @Input()
  clearTitle: string = 'Clear';

  @Input()
  value: ISelectItem | void = void(0);

  @Input()
  options: ISelectItem[] = [];

  @Input()
  disabled: boolean = false;

  @Input()
  minListWidth: number = 0;

  @Input()
  tabIndex: number = 1;

  @Input()
  showIcon: boolean = true;

  @Input()
  showArrow: boolean = true;

  @Input()
  dropdown: string | void = void(0);

  @Input()
  secondParamsChanged: Record<string, any> | void = void(0);

  @Output()
  changed: EventEmitter<ISelectItem | void> = new EventEmitter();

  @Output()
  click: EventEmitter<void> = new EventEmitter();

  @Output()
  open: EventEmitter<void> = new EventEmitter();

  @Output()
  close: EventEmitter<void> = new EventEmitter();

  @ViewChild('root', { static: false })
  root!: ElementRef<HTMLElement>;

  @ViewChild('list', { static: false })
  list!: ElementRef<HTMLElement>;

  private _env: IEnvironment = inject(ENV);
  private _simpleSelectService: SimpleSelectService = inject(SimpleSelectService);
  private _listPosition!: ISimpleSelectListPositionResponse;
  private _clickOutside!: ISimpleSelectClickOutsideResponse;
  private _onKeyDown!: ((e: KeyboardEvent) => void) | null;

  public state: ISimpleSelectState = {
    value: signal(void(0)),
    options: signal([]),
    isOpened: signal(false),
    disabled: signal(false),
    isFocused: signal(false),
    focusedItem: signal(-1),
    minListWidth: signal(-1),
    isDropdown: signal(false),
    isDropdownIcon: signal(false),
  };

  public CLEAR_ID: string = CLEAR_ID;

  private _prepareOptions(options: ISelectItem[] = []): ISelectItem[] {
    const newOptions = [ ...options ];

    if (this.clearable) {
      if (newOptions[0]?.id !== CLEAR_ID) {
        newOptions.unshift({ id: CLEAR_ID, title: (this.clearTitle || 'Clear') });
      }
    }

    return newOptions;
  }

  private _dropdown(dropdown: string | void): { isDropdown: boolean, isDropdownIcon: boolean } {
    const result: { isDropdown: boolean, isDropdownIcon: boolean } = { isDropdown: false, isDropdownIcon: false };

    if (dropdown) {
      result.isDropdown = true;

      if (allIconNames.includes(dropdown)) {
        result.isDropdownIcon = true;
      }
    }

    return result;
  }

  ngOnInit(): void {
    this.state.value.set(this.value);
    this.state.disabled.set(this.disabled);
    this.state.options.set(this._prepareOptions(this.options));
    this.state.minListWidth.set(this.minListWidth);

    const { isDropdown, isDropdownIcon } = this._dropdown(this.dropdown);

    this.state.isDropdown.set(isDropdown);
    this.state.isDropdownIcon.set(isDropdownIcon);
  }

  ngAfterViewInit(): void {
    this._listPosition = this._simpleSelectService.listPosition({
      parent: this.root,
      list: this.list,
      state: this.state,
    });

    this._clickOutside = this._simpleSelectService.clickOutside(this.root, () => this.handleClickOutside());
    this._onKeyDown = this._simpleSelectService.keyDown(this.state, (item) => this.onItemClick(item));
  }

  ngOnDestroy(): void {
    this._listPosition.unsubscribe();
    this._clickOutside.unsubscribe();
    this._onKeyDown = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const keys: string[] = ['value', 'options', 'disabled', 'minListWidth'];

    keys.forEach((k: string) => {
      if (changes && changes[k] && changes[k].currentValue !== changes[k].previousValue) {
        const state: any = this.state;
        const self: any = this;

        if (!changes[k].firstChange) {
          if (k === 'options') {
            state[k].set(this._prepareOptions(self[k]));
          } else if (k === 'dropdown') {
            const { isDropdown, isDropdownIcon } = this._dropdown(this.dropdown);

            state.isDropdown.set(isDropdown);
            state.isDropdownIcon.set(isDropdownIcon);
          } else {
            state[k].set(self[k]);
          }
        }
      }
    });
  }

  public reset(): void {
    this.state.value.set(void(0));
  }

  handleClickOutside(): void {
    this.state.isOpened.set(false);
  }

  onClick(): void {
    if (!this.state.disabled()) {
      const isOpened: boolean = !this.state.isOpened();

      this.state.isOpened.set(isOpened);

      if (isOpened) {
        this._listPosition.calc();
        this.open.emit();
      } else {
        this.close.emit();
      }

      this.click.emit();
    }
  }

  onItemClick(item: ISelectItem): void {
    if (!this.state.disabled()) {
      this.click.emit();

      if (item.id === CLEAR_ID) {
        this.state.value.set(void(0));
        
        this.changed.emit(void(0));
      } else {
        this.state.value.set(item);

        // it's hack to solve issue where is needed to pass additional arguments
        if (this.secondParamsChanged) {
          (item as any).secondParamsChanged = this.secondParamsChanged;
        }
        
        this.changed.emit(item);
      }

      this.state.isOpened.set(false);
      
      this.state.focusedItem.set(-1);
    }
  }

  onFocus(): void {
    this.state.isFocused.set(true);
  }

  onBlur(): void {
    this.state.isFocused.set(false);
  }

  onKeyDown(e: KeyboardEvent): void {
    this._onKeyDown && this._onKeyDown(e);
    
    this._listPosition?.calc();
  }

}

export {
  SimpleSelectComponent,
};
