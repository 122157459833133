const DEFAULT_FORMATTER: RegExp = /{{\s?(\w+)\s?}}/g;

class UtilsService {

  static guid(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  static capitalize(s: string): string {
    return s[0].toLocaleUpperCase() + s.substring(1);
  }

  static format(s: string, c: any = {}, formatter: RegExp = DEFAULT_FORMATTER): string {
    return s.replace(formatter, (m: any, p: any) => c[p]);
  }

  static formatUrl(url: string, params: Record<string, string> = {}): string {
    let result: string = url;

    Object.keys(params).forEach((key: string) => {
      result = result.replaceAll(':' + key, params[key]);
    });

    return result;
  }

  static copyDeep(obj: Object) {
    return JSON.parse(JSON.stringify(obj));
  }

  static pick<T>(data: any, allowKeys: string[]): T {
    const pickedData: any = {};

    Object.keys(data).reduce((acc: any, k: string): any => {
      if (allowKeys.includes(k)) {
        const t: any = data;

        acc[k] = t[k];
      }
      return acc;
    }, pickedData);

    return pickedData;
  }

  static omit<T>(data: any, excludeKeys: string[]): T {
    const keys: string[] = Object.keys(data).filter(k => !excludeKeys.includes(k));

    return UtilsService.pick(data, keys);
  }

  static isEqualDeep(value: any, other: any): boolean {
    let isEqual: boolean = false;

    if (value === other) {
      isEqual = true;
    } else if (value instanceof Date && other instanceof Date) {
      isEqual = value.getTime() === other.getTime();
    } else if (!value || !other || (typeof value !== 'object' && typeof other !== 'object')) {
      isEqual = value === other;
    } else if (value.prototype !== other.prototype) {
      isEqual = false;
    } else if (Array.isArray(value) && Array.isArray(other)) {
      if (value.length === other.length) {
        isEqual = value.every((i, at) => UtilsService.isEqualDeep(i, other[at]));
      }
    } else if (typeof value === 'object' && typeof other === 'object') {
      const valueKeys: string[] = Object.keys(value);
      const otherKeys: string[] = Object.keys(other);

      if (valueKeys.length === otherKeys.length) {
        isEqual = valueKeys.every(i => {
          return other.hasOwnProperty(i) ? UtilsService.isEqualDeep(value[i], other[i]) : false;
        });
      }
    }

    return isEqual;
  }

  static freeze(data: any): any {
    Object.freeze(data);

    if (Array.isArray(data)) {
      for (let i of data) {
        UtilsService.freeze(i);
      }
    } else if (data && typeof data === 'object') {
      for (let k in data) {
        UtilsService.freeze(data[k]);
      }
    }

    return data;
  }

  static getClassName(names: (string | void | boolean | null)[] = []): string {
    return names.filter((item) => !!item).join(' ');
  }

  static debounce(cb: (...args: any[]) => any, delay: number = 300) {
    let timerId: any;

    return ((...args: any[]) => {
      if (timerId) {
        clearTimeout(timerId as number);
      }

      timerId = setTimeout(() => {
        cb(...args);

        timerId = undefined;
      }, delay);
    });
  }

  static arrayIntersection(arr1: any[], arr2: any[], ): boolean {
    let result: boolean = true;
    let arrIter: any[];
    let arrSearch: any[];

    if (arr1.length < arr2.length) {
      arrIter = arr1;
      arrSearch = arr2;
    } else {
      arrIter = arr2;
      arrSearch = arr1;
    }

    for (let item of arrIter) {
      if (!arrSearch.includes(item)) {
        result = false;
        break;
      }
    }

    return result;
  }

  static arrayFirstIntersection(arr1: any[], arr2: any[]): boolean {
    let result: boolean = false;
    let arrIter: any[];
    let arrSearch: any[];

    if (arr1.length < arr2.length) {
      arrIter = arr1;
      arrSearch = arr2;
    } else {
      arrIter = arr2;
      arrSearch = arr1;
    }

    for (let item of arrIter) {
      if (arrSearch.includes(item)) {
        result = true;
        break;
      }
    }

    return result;
  }

  static arrayRemoveItem<T>(item: T, dest: (T | any)[]): (T | any)[] {
    const at: number = dest.indexOf(item);

    if (at >= 0) {
      dest.splice(at, 1);
    }

    return dest;
  }

  static isEqualDto(dto1: any, dto2: any): boolean {
    return (dto1.id && dto2.id) ? (dto1.id === dto2.id) : UtilsService.isEqualDeep(dto1, dto2);
  }

  static stopEvent(e: any): boolean | void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  static getQuerySearch(pick: string[] = []): Record<string, any> {
    const urlParams: any = new URLSearchParams(window.location.search);
    
    let query: Record<string, any> = {};

    for (let [k, v] of urlParams) {
      query[k] = v;
    }

    if (pick && pick.length) {
      query = UtilsService.pick(query, pick);
    }

    return query;
  }

  static setQueryParams(params: any): void {
    const newParams: any = {};

    Object.keys(params).forEach((key) => {
      if (params[key] !== null) {
        newParams[key] = params[key];
      }
    });

    const searchParams = new URLSearchParams(newParams);

    let search: string = searchParams.toString();

    if (search) {
      const loc = document.location;
      const newUrl = loc.origin + loc.pathname + '?' + search;
      
      (window.history as any).pushState({}, null, newUrl);
    }
  }

}

export {
  UtilsService
};
