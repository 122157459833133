import { inject, Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class CorrelationService {

  public static readonly headerKey: string = 'X-Jit-Front-Correlation-Id';
  
  private _storeKey: string = 'frontCorrelationId';
  private _storageService: StorageService = inject(StorageService);

  public get(): string {
    let frontCorrelationId: string | null = this._storageService.retrive<string>(this._storeKey);

    if (!frontCorrelationId) {
      frontCorrelationId = UtilsService.guid();

      this._storageService.store(this._storeKey, frontCorrelationId);
    }

    return frontCorrelationId;
  }

}
