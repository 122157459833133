<div class="jit-report-question-container">
  <div class="jit-report-question-container__left-sidebar">
    <span class="jit-report-question-container__idx">
      {{ idx }}
    </span>
    <span class="jit-report-question-container__code">
      <jit-tag [label]="code"></jit-tag>
    </span>
  </div>
  <div class="jit-report-question-container__body">
    <div class="jit-report-question-container__content">
      <div class="jit-report-question-container__header">
        <jit-report-icons [name]="questionType" [width]="64" [height]="56"></jit-report-icons>
        <span class="jit-report-question-container__type">
          @if (questionType === ReportQuestionTypes.BOOLEAN) {
            True / False
          } @else if (questionType === ReportQuestionTypes.FIB_DROPDOWN) {
            FIB Dropdown          
          } @else if (questionType === ReportQuestionTypes.FIB_TYLES) {
            FIB Tyles
          } @else if (questionType === ReportQuestionTypes.FIB_TIPED) {
            FIB Tiped
          } @else if (questionType === ReportQuestionTypes.MATCHING) {
            Matching (Drag & Drop)
          } @else if (questionType === ReportQuestionTypes.MULTI_SELECT) {
            Multi-Select Multiple Choice
          } @else if (questionType === ReportQuestionTypes.SEQUENCING) {
            Sequencing (Drag & Drop)
          } @else if (questionType === ReportQuestionTypes.SINGLE_SELECT) {
            Single-Select Multiple Choice
          } @else if (questionType === ReportQuestionTypes.SORTING) {
            Sorting (Drag & Drop)
          }
        </span>
      </div>
      <h2>{{ title }}</h2>
      <div class="jit-report-question-container__key">
        <h3>Answer key</h3>
        <div class="jit-report-question-container__key-content">
          Answer content - coming soon...
        </div>
      </div>
      <div class="jit-report-question-container__response">
        <h3>Response submitted</h3>
        <div class="jit-report-question-container__response-content">
          Response content - coming soon...
        </div>
      </div>
    </div>
    <div class="jit-report-question-container__right-sidebar">
      <div class="jit-report-question-container__wrap-answer-type">
        @if (answerType === ReportAnswerTypes.NOT_ATTEMPTED) {
          <div class="jit-report-question-container__answer-label">
            Not Attempted
          </div>
        } @else {
          @if (answerType === ReportAnswerTypes.CORRECT_FIRST) {
            <div class="jit-report-question-container__answer-type">
              <jit-report-icons [name]="answerType" [width]="26" [height]="28"></jit-report-icons>
            </div>
            <div class="jit-report-question-container__answer-label">
              Correct (1st try)
            </div>
          } @else if (answerType === ReportAnswerTypes.CORRECT_SECOND) {
            <div class="jit-report-question-container__answer-type">
              <jit-report-icons [name]="answerType" [width]="25" [height]="24"></jit-report-icons>
            </div>
            <div class="jit-report-question-container__answer-label">
              Correct (2nd try)
            </div>
          } @else if (answerType === ReportAnswerTypes.WRONG) {
            <div class="jit-report-question-container__answer-type">
              <jit-report-icons [name]="answerType" [width]="24" [height]="25"></jit-report-icons>
            </div>
            <div class="jit-report-question-container__answer-label">
              Incorrect
            </div>
          }
        }
      </div>
    </div>
    <div class="jit-report-question-container__footer">
      <jit-report-icons name="stopwatch" [width]="17" [height]="16"></jit-report-icons>
      <span>{{ time | stopwatch }}</span>
    </div>
  </div>
</div>