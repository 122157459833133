import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  inject,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'jit-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class LinkComponent {
  private router = inject(Router);

  @Input() link?: string;
  @Input() href?: string;

  onClick(event: MouseEvent) {
    event.preventDefault();

    this.router.navigateByUrl(this.link as string);
  }
}
