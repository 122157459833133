import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService, IResponse } from '../api';
import { LoggerService } from '../logger';
import { IUserInfo } from '../oauth/oauth.interfaces';
import { useUser } from '../../../data-layer/stores/user.store';

interface IUserData extends IUserInfo {
  firstName: string;
  lastName: string;
  state?: string;
}

@Injectable({
  providedIn: 'root',
})
class UserService {
  
  private _urlMe: string = '/v2/api/users/me';
  
  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);

  public async me(): Promise<IUserData> {
    let response: IResponse<IUserData>;

    try {
      response = await this._apiService.get<IUserData>(this._urlMe);
    } catch (err) {
      this._loggerService.error('UserService.me: Request error', err);

      throw err;
    }

    return response.data;
  }

}

export {
  IUserData,
  UserService,
};
