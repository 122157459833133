<mat-accordion>
  <mat-expansion-panel
    #panel
    hideToggle 
    (opened)="onOpen()" 
    (closed)="onClose()" 
    [class.is-open]="isOpen"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <jit-icon-simple class="arrow" [class.arrow--down]="isOpen" iconId="chevron"></jit-icon-simple>
        <jit-icon-simple [ngStyle]="{ color: color }" iconId="bundle-large"></jit-icon-simple>
        <a class="link" [routerLink]="['/', 'detail-page', series.slug, 'bundle']">
          {{ series.title }} ({{ series.resources.length }})
        </a>
      </mat-panel-title>
      <jit-collection-button [item]="$any(series)"></jit-collection-button>
    </mat-expansion-panel-header>
    
    <div *ngIf="isOpen" class="resource-list">
      <jit-resource *ngFor="let item of series.resources" [resource]="item"></jit-resource>
    </div>
  </mat-expansion-panel>
</mat-accordion>