import {IEnvironment} from '../app/shared/models/environment';

export const environment: IEnvironment = {
  // apiUrl: 'http://localhost:3002/api',
  envName: 'dev',
  apiUrl: 'https://jit-dev2.istation.com/api',
  idServerUrl: 'https://idsrv.dev2.istation.com',
  ga4Key: 'G-D9JRWGSQLB',
  gtmKey: 'GTM-W3GZFTNS',
  debugLevel: 'info',
  maxLogStash: 1,
  logToServer: true,
  sendLogsAfter: 1000,
  parentHomePage: 'https://secure.dev2.istation.com',
  showStatus: true,
  assetsDomain: 'https://assets-dev.istation.com',
  microlessonDomain: 'https://htech-develop.learning.dev.istation.com/activity/{{ activityId }}/{{ oid }}',
  features: {
    sandbox: true,
    assignment: true,
    assignmentCsv: true,
  },
};
