import { IAssignment, IStats } from './assignment-view.interface';

interface IStudentLessonsResult extends IAssignment {
  title: string;
  slug: string;
  domain: string;
}

interface IClassroomStats {
  id: string;
  name: string;
  stats: IStats;
  students: Map<string, IStudentStats>;
}

interface IStudentStats {
  id: string;
  firstName: string;
  lastName: string;
  stats: IStats;
  results: IStudentLessonsResult[];
  origin?: IStudentLessonsResult[];
  assignmentId?: string;
}

type TByClassroom = Omit<IClassroomStats, 'students'> & { students: IStudentStats[] };

export type { IClassroomStats, IStudentStats, TByClassroom, IStudentLessonsResult };
