import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-report-question-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ReportQuestionSortingComponent {
  
}

export {
  ReportQuestionSortingComponent,
};
