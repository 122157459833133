<mat-form-field floatLabel="always">
  <div (click)="onClick($event)">
    <mat-select
      #select
      [formControl]="sortValue"
      (selectionChange)="onSelectionChange()"
      [placeholder]="placeholder"
      [hideSingleSelectionIndicator]="true"
    >
      <mat-select-trigger *ngIf="displayValue">
        {{ displayValue }}
      </mat-select-trigger>

      <mat-option *ngFor="let option of options" [value]="option.value">
        <span>{{ option.text }}</span>
        <jit-icon-simple *ngIf="option.value === 'asc'" size="20" iconId="sort-asc"></jit-icon-simple>
        <jit-icon-simple *ngIf="option.value === 'desc'" size="20" iconId="sort-desc"></jit-icon-simple>
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
