<!-- Assignment -->
<jit-right-sidebar
  [isOpened]="isAssigmentOpened"
  [isInternalOpened]="isAssigmentInternalOpened"
>
  <jit-assignment content></jit-assignment>
  <ng-container internal>
    @if (assignmentInternalStep === 'related') {
      <jit-assignment-related></jit-assignment-related>
    }
    @if (assignmentInternalStep === 'students') {
      <jit-assignment-students></jit-assignment-students>
    }
    @if (assignmentInternalStep === 'search' || assignmentInternalStep === 'search-related') {
      <jit-assignment-search></jit-assignment-search>
    }
  </ng-container>
</jit-right-sidebar>

<!-- Update Assignment -->
<jit-right-sidebar [isOpened]="isUpdateOpened">
  <jit-update-assignment content></jit-update-assignment>
</jit-right-sidebar>

<!-- Select State -->
<jit-modal-dialog #dialogSelectState>
  <jit-select-state content></jit-select-state>
</jit-modal-dialog>

<!-- NPS Banner -->
<jit-bottom-sidebar [isOpened]="isNpsOpened" [showOverlay]="false">
  <jit-nps-banner content></jit-nps-banner>
</jit-bottom-sidebar>

<jit-right-sidebar [isOpened]="isQuestionsOpened">
  <jit-nps-questions content></jit-nps-questions>
</jit-right-sidebar>