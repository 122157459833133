<mat-form-field floatLabel="always">
  <div (click)="onClick($event)">
    <mat-select
      #select
      [formControl]="currentValue"
      (selectionChange)="onSelectionChange()"
      [placeholder]="placeholder"
      [hideSingleSelectionIndicator]="true"
    >
      <mat-select-trigger *ngIf="displayValue">
        {{ displayValue + (displayValue[displayValue.length - 1] === 's' ? '' : 's') }}
      </mat-select-trigger>

      <mat-option>All Resource Types</mat-option>

      <mat-option class="content-types-item" *ngFor="let option of options" [value]="option.key">
        <span class="check">
          <jit-icon-simple size="16" iconId="check"></jit-icon-simple>
        </span>
        <span class="content-types-item-label">{{ option.label + (option.label[option.label.length - 1] === 's' ? '' : 's') }}</span>
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
