import {Injectable} from '@angular/core';
import * as katex from 'katex';
import { KatexOptions } from 'katex';

const ktx: any = (katex as any).default;

@Injectable({
  providedIn: 'root'
})
class LatexService {

  renderToString(equation: any, options?: KatexOptions): string {
    return ktx.renderToString(equation, options);
  }

}

export {
  LatexService,
};
