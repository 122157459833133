interface IStudent {
  id: string;
  oId: string;
  firstName: string;
  lastName: string;
  selected: boolean;
  changed: boolean;
  disabled: boolean;
}

export {
  IStudent,
};
