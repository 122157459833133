<a
  [routerLink]="url"
  class="resource-content"
  [ngStyle]="{ '--main-color': color }"
>
  <div class="resource-header">
    <jit-icon-simple [iconId]="icon"></jit-icon-simple>
    <strong class="resource-type">{{ type }}</strong>
  </div>

  <h4 class="resource-title">
    <span *ngIf="showStatus">[{{ status }}]</span>
    {{ title }}
  </h4>

  <div class="justify-between align-center">
    <strong class="resource-category">{{ code || '' }}</strong>
  </div>
</a>

<footer class="resource-footer">
  <jit-course-duration *ngIf="resource.duration" [duration]="resource.duration"></jit-course-duration>
  <jit-collection-button [item]="$any(resource)"></jit-collection-button>
</footer>
