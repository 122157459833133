<div #input class="jit-calendar-input" (click)="onToggle($event)">
  <div class="jit-calendar-input__value">
    <ng-container *ngIf="value">
      {{ value.toFormat('LL/dd/yy') }}
    </ng-container>
    <ng-container *ngIf="!value">
      {{ placeholder }}
    </ng-container>
  </div>
  <div class="jit-calendar-input__icon">
    <jit-icon-simple iconId="calendar"></jit-icon-simple>
  </div>
  <div 
    #popup
    class="jit-calendar-input__popup"
    [class.is-open]="isOpen"
    [style]="listStyle"
  >
    <jit-calendar-list
      #list
      [value]="value"
      [month]="value ? value.month : undefined"
      [year]="value ? value.year : undefined"
      [sundayFirst]="true"
      (change)="onChange($event)"
    ></jit-calendar-list>
  </div>
</div>