import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ProgressBarComponent {

  @Input()
  percentage: number = 0;

}

export {
  ProgressBarComponent,
};
