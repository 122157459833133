import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecentSearchCacheService {
  public optionsToCache(option?: string): void {
    const values = this.getOptionsArray();

    if (option != null) {
      const checkArrayCopy: string | undefined = values.find(
        (opt: string): boolean => opt === option
      );

      if (!checkArrayCopy) {
        if (values.length > 9) {
          values.pop();
        }
        values.unshift(option);
      } else {
        values.splice(values.indexOf(option), 1);
        values.unshift(option);
      }
      localStorage.setItem('searchOptions', JSON.stringify(values));
    }
  }

  public getOptionsArray(): string[] {
    return JSON.parse(localStorage.getItem('searchOptions') as string) || [];
  }

  public removeValue(value: string) {
    const values = this.getOptionsArray();

    localStorage.setItem(
      'searchOptions',
      JSON.stringify(values.filter((el) => el !== value))
    );
  }
}
