<section class="wrapper">
  <header class="header">
    <span class="user">
      <jit-icon-simple size="32" iconId="profile-filled"></jit-icon-simple>

      <span>{{ userName$ | async }}</span>
    </span>

    <button type="button" class="button" (click)="onClose()">
      <jit-icon-simple iconId="close"></jit-icon-simple>
    </button>
  </header>

  <ul class="list">
    <li class="list-item">
      <a (click)="onShowSelectState()">
       {{ 'Standards: ' + (state ? state.title : 'Select') }}
      </a>
    </li>
    <li class="list-item">
      <a routerLink="/" [ngClass]="{ active: resourcesActive$ | async }">
        Resources
      </a>
    </li>
    @if (features.assignment && allowCreateAssignment) {
      <li class="list-item">
        <a routerLink="/assignments" [ngClass]="{ active: resourcesActive$ | async }">
          Assignments
        </a>
      </li>
      <li class="list-item">
        <a [ngClass]="{ active: resourcesActive$ | async }" (click)="onNewAssignment()">
          New Assignment
        </a>
      </li>
    }
    <li class="list-item">
      <a
        routerLink="/my-collections"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        My collections
      </a>
    </li>
    @if (features.sandbox) {
      <li class="list-item">
        <a routerLink="/sandbox" [ngClass]="{ active: resourcesActive$ | async }">
          Sandbox
        </a>
      </li>
    }

    <li class="list-item">
      <a href="#" (click)="onLogout($event)">Log out</a>
    </li>
  </ul>
</section>
