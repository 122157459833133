import { Injectable } from '@angular/core';
import { IAuthTransferData, IToken } from './oauth.interfaces';

@Injectable({
  providedIn: 'root'
})
export class OAuthUtilsService {

  public redirect(href: string): void {
    window.location.href = href;
  }

  public createRandomString(length: number): string {
    const randomValues = new Uint32Array(length);

    window.crypto.getRandomValues(randomValues);

    return Array.from(randomValues, decimal => decimal.toString(16)).join('');
  }

  public async sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);

    return await window.crypto.subtle.digest('SHA-256', data);
  }

  public async generateCodeChallengeFromVerifier(v: string): Promise<string> {
    const hashed = await this.sha256(v);

    return btoa(String.fromCharCode(...new Uint8Array(hashed)))
      .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  public async generateAuthTransferData(): Promise<IAuthTransferData> {
    const state = this.createRandomString(16).substring(0, 41);
    const codeVerifier = this.createRandomString(9).substring(0, 67);
    const nonce = this.createRandomString(32).substring(0, 41);
    const codeChallenge = await this.generateCodeChallengeFromVerifier(codeVerifier);

    return { state, codeVerifier, nonce, codeChallenge };
  }

  public objToUrl(data: any = {}): string {
    return encodeURIComponent(Object.keys(data).map((k) => (`${k}=${data[k]}`)).join('&'));
  }

  public objToParams(data: any = {}): string {
    return Object.keys(data).map((k) => (`${k}=${encodeURIComponent(data[k])}`)).join('&');
  }

  public isTokenExpired(token: string): boolean {
    const dataToken: IToken = this.decodeToken(token);

    let isExpired = true;

    if (dataToken && dataToken.exp) {
      const d = new Date(0);

      d.setUTCSeconds(dataToken.exp);

      isExpired = Date.now() > d.getTime();
    }

    return isExpired;
  }

  /**
   * parts
   *  0 - header
   *  1 - payload (by default)
   *  2 - signature
   */
  public decodeToken(token: string, part: number = 1): IToken {
    if (!token.includes('.')) {
      throw new Error('OAuthUtilsService.decodeToken: token should have dots');
    }

    const parts: string[] = token.split('.');

    if (parts.length !== 3) {
      throw new Error('OAuthUtilsService.decodeToken: token should have three parts separated by dots');
    }

    let result = parts[part].replace(/-/g, '+').replace(/_/g, '/');

    switch (result.length % 4) {
      case 0:
        break;
      case 2:
        result += '==';
        break;
      case 3:
        result += '=';
        break;
      default:
        throw Error('Illegal base64url string!');
    }

    const decoded = atob(result);

    try {
      result = decodeURIComponent(decoded
        .split('')
        .map((c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
      );
    } catch (err) {
      result = decoded;
    }

    return (result ? JSON.parse(result) : null);
  }
}
