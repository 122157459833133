<div class="jit-assignment-related">
  <div class="jit-assignment-related__header">
    <h2>Related Resources</h2>
    <a 
      class="jit-assignment-related__header-select-all" 
      (click)="onSelectAll(!unselect, $event)"
    >
      {{ unselect ? 'Unselect All' : 'Select All' }}
    </a>
  </div>
  <div class="jit-assignment-related__content">
    <div class="jit-assignment-related__list">
      @for (item of available; track $index) {
        <jit-resource-item
          [resource]="item"
          [type]="!!selected[item.id] ? ResourceItemActions.ADDED : ResourceItemActions.ADD"
          (add)="onAdd(item)"
          (added)="onAdded(item)"
          (contentClick)="onContentClick($event)"
        ></jit-resource-item>
      }
    </div>
    <div class="jit-assignment-related__related" (click)="onSearch()">
      <jit-icon-simple iconId="search-outline"></jit-icon-simple>
      Search for Additional Resources
    </div>
  </div>
  <div class="jit-assignment-related__footer">
    <span class="jit-assignment-related__footer-back" (click)="onBack()">
      Back
    </span>
    <span 
      [class.jit-assignment-related__footer-add-disabled]="!isSelected"
      [class.jit-assignment-related__footer-add]="isSelected"
      (click)="onApply()"
    >
      Add
    </span>
  </div>
</div>