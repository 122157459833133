import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, takeLast } from 'rxjs/operators';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerInterceptor implements HttpInterceptor {
  
  private _minAllowedRequestTime: number = 5000;

  constructor(private loggerService: LoggerService) {}

  private log(err: HttpErrorResponse | null, req: HttpRequest<any>, res: HttpResponse<any> | null, start: number): void {
    const requestTime: number = Date.now() - start;

    // skip logger requests and successful requests with time less than 5000ms
    if ((err && err.url === this.loggerService.api) || req.urlWithParams === this.loggerService.api || (!err && requestTime < this._minAllowedRequestTime)) {
      return;
    }

    const title: string = 'HTTP Request ' + (err ? 'is failed' : 'is successful');

    let body: Record<any, any> | null = req.body || null;

    const extra: any = {
      'request_time': requestTime,
      'method': req.method,
      'url': req.urlWithParams,
      'response_type': req.responseType,
      'user_agent': window.navigator.userAgent,
      'has_response_body': !!(res?.body),
      body,
    };

    if (err) {
      extra['error_message'] = err.message;
      extra['error_status'] = err.status;
      extra['error_status_text'] = err.statusText;

      if (err.error) {
        extra['error_response'] = err.error;
      }

      this.loggerService.error(title, extra);
    } else {
      this.loggerService.log(title, extra);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const start: number = Date.now();

    return next.handle(req).pipe(
      takeLast(1),
      tap({
        next: (res) => this.log(null, req, (res as HttpResponse<any>), start),
        error: (err) => this.log(err, req, null, start),
      }),
    );
  }
}
