import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, inject, SimpleChanges } from '@angular/core';
import { IResourceEntity, BaseComponent, ResourceService, IDetails, useStates, IEntityFilters } from '@jit/data-layer';
import { IEnvironment, ENV } from '../../models/environment';
import { CourseDurationComponent } from '../course-duration/course-duration.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ResourceTranslatePipe } from '../../pipes';

enum ResourceItemActions {
  NONE = 'none',
  ADD = 'add',
  ADDED = 'added',
  REMOVE = 'remove',
}

@Component({
  selector: 'jit-resource-item',
  templateUrl: './resource-item.component.html',
  styleUrls: ['./resource-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    CourseDurationComponent,
    ResourceTranslatePipe,
  ],
})
class ResourceItemComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() 
  resource!: IResourceEntity;

  @Input() 
  type: ResourceItemActions = ResourceItemActions.NONE;

  @Output()
  add: EventEmitter<IResourceEntity> = new EventEmitter<IResourceEntity>();

  @Output()
  added: EventEmitter<IResourceEntity> = new EventEmitter<IResourceEntity>();

  @Output()
  remove: EventEmitter<IResourceEntity> = new EventEmitter<IResourceEntity>();

  @Output()
  contentClick: EventEmitter<IResourceEntity> = new EventEmitter<IResourceEntity>();

  @useStates('state')
  state: IEntityFilters | void = void(0);

  private _env: IEnvironment = inject(ENV);
  private _resourceService: ResourceService = inject(ResourceService);
  private _loaded: boolean = false;

  public thumb: string = '';
  public expanded: boolean = false;
  public ResourceItemActions: typeof ResourceItemActions = ResourceItemActions;
  public description: string = '';
  public leartingTarget: string = '';
  public lang!: string;
  public code: string | null = null;

  ngOnInit(): void {
    this.init(this.resource);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['resource'] && changes['resource'].currentValue !== changes['resource'].previousValue) {
      this.init(changes['resource'].currentValue);
    }
  }

  init(resource: IResourceEntity): void {
    this._loaded = false;
    this.expanded = false;
    this.thumb = resource?.thumbnail?.path || '';

    if (!this.thumb.includes('https://') && !this.thumb.includes('http://')) {
      this.thumb = this._env.assetsDomain + this.thumb;
    }

    this.mapCode(resource);
  }

  mapCode(res: any): void {
    this.code = null;

    if (this.state) {
      if (res && res.codes) {
        const stateId: string = this.state.id;
        const code = res.codes.find((c: any) => (c.stateId === stateId));

        this.code = (code && code.stateCode) ? code.stateCode : null;

        this.refresh();
      }
    }

    this.refresh();
  }

  onAdd(): void {
    this.add.emit(this.resource);
  }

  onAdded(): void {
    this.added.emit(this.resource);
  }

  onRemove(): void {
    this.remove.emit(this.resource);
  }

  onContentClick(): void {
    this.contentClick.emit(this.resource);
  }

  onToggle(): void {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.fetchDetails();
    }
  }

  async fetchDetails(): Promise<void> {
    if (!this._loaded) {
      this._loaded = true;

      const resource = await this._resourceService.getResource(this.resource.slug);
  
      this.lang = resource.language;
      this.description = resource.description || '';
      this.leartingTarget = resource.learningTarget?.description || '';
  
      this.refresh();
    }
  }

}

export {
  ResourceItemComponent,
  ResourceItemActions,
};
