import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { IconId } from '../../models';

@Component({
  selector: 'jit-icon-simple',
  templateUrl: './icon-simple.component.html',
  styleUrls: ['./icon-simple.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class IconSimpleComponent {
  @Input() size: string = '24';
  @Input() iconId?: IconId;
  @Input() title: string = '';
}
