import { IAssignment, IStats } from './assignment-view.interface';

interface IResult extends IAssignment {
  studentId: string;
  firstName: string;
  lastName: string;
}

interface IStandardStats {
  id: string;
  name: string;
  stats: IStats;
  resources: Map<string, IResourceStats>;
}

interface IResourceStats {
  id: string;
  title: string;
  slug: string;
  stats: IStats;
  results: IResult[];
}

type TByStandard = Omit<IStandardStats, 'resources'> & { resources: IResourceStats[] };

export type { IStandardStats, IResourceStats, TByStandard };
