<div class="jit-assignment-students" [class.has-assignment]="hasStudentsAssigned">
  <div class="jit-assignment-students__header">
    <h2>Edit Students</h2>
    @if (selectedStudents < totalStudents) {
      <a class="jit-assignment-students__header-select-all" (click)="onSelectAll(true, $event)">
        Select All
      </a>
    } @else if (selectedStudents === totalStudents) {
      <a class="jit-assignment-students__header-select-all" (click)="onSelectAll(false, $event)">
        Unselect All
      </a>
    }
    @if (hasStudentsAssigned) {
      <div class="jit-assignment-students__header-has-assignment">
        <jit-report-icons name="yellow-triangle" [height]="11" [width]="14"></jit-report-icons>
        <span>Already assigned</span>
      </div>
    }
  </div>
  <div class="jit-assignment-students__content">
    @for (classRoom of classRooms; track $index) {
      <jit-treeview-item
        [title]="classRoom.name"
        [count]="this.countStudents[classRoom.id]"
        [value]="classRoom.selected"
        [isCollapsible]="true"
        [expanded]="false"
        (change)="onSelectClassRoom(classRoom, $event)"
      >
        @for (student of classRoom.students; track $index) {
          <jit-treeview-item
            [title]="student.lastName + ', ' + student.firstName"
            [value]="student.selected"
            [disabled]="student.disabled"
            [isCollapsible]="false"
            [expanded]="false"
            [showWarn]="!!studentsAssigned[student.id]"
            [warnLabel]="!!studentsAssigned[student.id] ? getWarnTitle(student.id) : ''"
            [warnResources]="studentsAssigned[student.id] || []"
            (change)="onSelectedStudent(student, $event)"
          ></jit-treeview-item>
        }
      </jit-treeview-item>
    }
  </div>
  <div class="jit-assignment-students__footer">
    <span class="jit-assignment-students__footer-back" (click)="onBack()">
      Back
    </span>
    @if (selectedStudents === 0) {
      <span class="jit-assignment-students__footer-add-disabled">
        Update
      </span>
    } @else if (selectedStudents > 0) {
      <span class="jit-assignment-students__footer-add" (click)="onApply()">
        Update
      </span>
    }
  </div>
</div>