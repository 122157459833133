import { DateTime } from 'luxon';
import { TAssignmentStatus } from './assignment.interface';

interface IStats {
  total: number;
  completed: number;
  inProgress: number;
  notStarted: number;
  selfSelected: number;
  pastDue: boolean;
}

interface IAssignment {
  assignmentId: string;
  status: string;
  statusTitle: string;
  selfSelected: boolean;
  startDate: string;
  startDateDT: DateTime;
  formatedStartDate: string;
  dueDate: string | null;
  dueDateDT: DateTime | null;
  formatedDueDate: string;
  duration: number;
  score: number;
  pastDue: boolean;
  skill: string;
}

interface IListResult {
  id: string;
  resource_id: string;
  resource: string;
  resource_slug: string;
  student_id: number;
  classroom: string;
  status: TAssignmentStatus;
  self_selected: boolean;
  duration: number;
  score: number;
  start_date: Date;
  due_date: Date;
  domain: string;
  suite?: string;
  series?: string[];
  standard_id?: string;
  standard?: string;
  standard_description?: string;
  learning_target?: string;
  success_criteria?: string[];
  episode?: number;
}

export type { IStats, IListResult, IAssignment };
