import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  inject,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil, filter } from 'rxjs';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { NavigationEnd, Router } from '@angular/router';
import { FilterOption } from '@jit/shared';
import { IContentTypeItem } from '@jit/data-layer';

@Component({
  selector: 'jit-content-types',
  templateUrl: './content-types.component.html',
  styleUrls: ['./content-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ContentTypesComponent,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    IconSimpleComponent,
  ],
})
export class ContentTypesComponent implements ControlValueAccessor, OnDestroy, OnInit, OnChanges {
  
  private _destroy = new Subject<void>();
  private _onChange?: (_: any) => void;
  private _onTouched?: any;
  private router = inject(Router);

  @Input() options: IContentTypeItem[] = [];
  @Input() hideDisabled = false;
  @Input() defaultValue?: string;
  @Input() placeholder: string = 'Standards by State';
  @Output() changed = new EventEmitter<string | void>();

  @ViewChild('select')
  select?: MatSelect;

  public currentValue = new FormControl<string | void>('');

  get displayValue() {
    const value = this.currentValue.value;

    return this.options.find((item) => (item.key === value))?.label || '';
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.select?.close();
      });

    if (this.defaultValue) {
      const option = this.options.find((option) => (option.key === this.defaultValue));

      if (option) {
        setTimeout(() => {
          this.currentValue.patchValue(option.key);
        }, 20);
      }
    }
    
    this.currentValue.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        this._onTouched && this._onTouched();
        this._onChange?.(value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultValue']) {
      const value = changes['defaultValue'].currentValue;

      if (value) {
        const option = this.options.find((option) => (option.key === value));

        if (option) {
          this.currentValue.patchValue(option.key);
        }
      }
    } 
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(obj?: string): void {
    if (obj) {
      this.currentValue.patchValue(obj);
    } else {
      this.currentValue.patchValue(null);
    }
  }

  onSelectionChange() {
    this.changed.emit(this.currentValue.value as string | void);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onClick(e: Event) {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
