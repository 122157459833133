import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'jit-tr-expand',
  templateUrl: './tr-expand.component.html',
  styleUrls: ['./tr-expand.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class TrExpandComponent {

  @Input()
  colspan: number = 1;

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter();

  public expanded: boolean = false;

  public expand(): void {
    this.expanded = true;
  }

  public collapse(): void {
    this.expanded = false;
  }

  onToggle(): void {
    this.expanded = !this.expanded;

    this.toggle.emit(this.expanded);
  }

}

export {
  TrExpandComponent,
};
