import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IEntityFilters } from '../interfaces/entity-filters.interface';
import { useResourse } from '../stores';

@Injectable({
  providedIn: 'root',
})
class ParamsService {

  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _router: Router = inject(Router);

  public clear(): void {
    this._router.navigate(['/results'], { queryParams: {} });

    useResourse.getState().clear();
  }

  public getStateParam(): string | null {
    const { state } = this._route.snapshot.queryParams;

    return state || null;
  }

  public applySubject(subject: IEntityFilters | null): void {
    const queryParams = { ...this._route.snapshot.queryParams };

    if (subject) {
      queryParams['subject'] = subject.slug;
    } else {
      delete queryParams['subject'];
    }
    
    delete queryParams['grade'];
    delete queryParams['domain'];
    delete queryParams['state'];
    delete queryParams['contentType'];

    this._router.navigate(['/results'], { queryParams });
  }

  public applyGrade(entity: IEntityFilters | null): void {
    const queryParams = { ...this._route.snapshot.queryParams };

    if (entity) {
      queryParams['grade'] = entity.value;
    } else {
      delete queryParams['grade'];
    }

    delete queryParams['domain'];
    delete queryParams['state'];
    delete queryParams['contentType'];

    this._router.navigate(['/results'], { queryParams });
  }

  public applyDomain(entity: IEntityFilters | null): void {
    const queryParams = { ...this._route.snapshot.queryParams };

    if (entity) {
      queryParams['domain'] = entity.value;
    } else {
      delete queryParams['domain'];
    }
    
    delete queryParams['state'];
    delete queryParams['contentType'];

    this._router.navigate(['/results'], { queryParams });
  }

  public applyState(entity: IEntityFilters | null): void {
    const queryParams = { ...this._route.snapshot.queryParams };

    if (entity) {
      queryParams['state'] = entity.value;
    } else {
      delete queryParams['state'];
    }

    delete queryParams['contentType'];

    this._router.navigate(['/results'], { queryParams });
  }

  public applySelectFilters(type: string, entity: IEntityFilters | null): void {
    if (type === 'grade') {
      this.applyGrade(entity);
    } else if (type === 'domain') {
      this.applyDomain(entity);
    } if (type === 'state') {
      this.applyState(entity);
    }
  }

  public applySearch(value: string): void {
    const queryParams = { ...this._route.snapshot.queryParams };

    if (value) {
      queryParams['search'] = value;
    } else {
      delete queryParams['search'];
    }

    this._router.navigate(['/results'], { queryParams });
  }

  public applySort(value: string): void {
    const queryParams = { ...this._route.snapshot.queryParams };

    if (value) {
      queryParams['sort'] = value;
    } else {
      delete queryParams['sort'];
    }

    this._router.navigate(['/results'], { queryParams });
  }

  public applyContentType(value: string): void {
    const queryParams = { ...this._route.snapshot.queryParams };

    if (value) {
      queryParams['contentType'] = value;
    } else {
      delete queryParams['contentType'];
    }

    this._router.navigate(['/results'], { queryParams });
  }

}

export {
  ParamsService,
};
