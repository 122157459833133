<div #dialog class="jit-modal-dialog" [class.is-opened]="isOpened">
  <div class="jit-modal-dialog__window">
    <div class="jit-modal-dialog__header">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="jit-modal-dialog__content">
      <ng-content select="[content]"></ng-content>
    </div>
    <div class="jit-modal-dialog__footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
<div #overlay class="jit-modal-dialog__overlay" [class.is-opened]="isOpened"></div>