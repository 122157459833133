@if (resource) {
  <div class="jit-resource-item" tabindex="1">
    <div class="jit-resource-item__header">
      <div class="jit-resource-item__toggle" tabindex="1" [class.expanded]="expanded" (click)="onToggle()">
        <jit-icon-simple size="24" iconId="caret-down-outline"></jit-icon-simple>
      </div>
      <div 
        class="jit-resource-item__thumb desktop" 
        style="background-image: url('{{ this.thumb }}');"
        (click)="onContentClick()"
      >
        &nbsp;
      </div>
      <div 
        class="jit-resource-item__thumb mobile" 
        style="background-image: url('{{ this.thumb }}');"
      >
        &nbsp;
      </div>
      <div class="jit-resource-item__content desktop" (click)="onContentClick()">
        <div class="jit-resource-item__title">
          {{ resource.title }}
        </div>
        <div class="jit-resource-item__subtitle">
          @if (resource.duration) {
            <jit-course-duration [duration]="resource.duration"></jit-course-duration>
          }
          @if (code) {
            <span class="jit-resource-item__div"> | </span>
            <div class="jit-resource-item__state">
              {{ code || '' }}
            </div>
          }
        </div>
      </div>
      <div class="jit-resource-item__content mobile">
        <div class="jit-resource-item__title">
          {{ resource.title }}
        </div>
        <div class="jit-resource-item__subtitle">
          @if (resource.duration) {
            <jit-course-duration [duration]="resource.duration"></jit-course-duration>
          }
          @if (code) {
            <span class="jit-resource-item__div"> | </span>
            <div class="jit-resource-item__state">
              {{ code || '' }}
            </div>
          }
        </div>
      </div>
      @if (type !== ResourceItemActions.NONE) {
        <div class="jit-resource-item__action {{ type }}">
          @if (type === ResourceItemActions.ADD) {
            <span tabindex="1" (click)="onAdd()">
              <jit-icon-simple iconId="add" size="32"></jit-icon-simple>
            </span>
          }
          @if (type === ResourceItemActions.ADDED) {
            <span tabindex="1" (click)="onAdded()">
              <jit-icon-simple size="24" iconId="check"></jit-icon-simple>
            </span>
          }
          @if (type === ResourceItemActions.REMOVE) {
            <span tabindex="1" (click)="onRemove()">
              <jit-icon-simple size="32" iconId="trash"></jit-icon-simple>
            </span>
          }
        </div>
      }
    </div>
    <div class="jit-resource-item__body" [class.expanded]="expanded">
      <div class="jit-resource-item__body-descr">
        <h4>Description</h4>
        <p>{{ description || '...' }}</p>
      </div>
      <div class="jit-resource-item__body-learn">
        <h4>Learning Target</h4>
        <p>
          {{ 'learningTarget' | resTranslate : lang }}
          {{
            leartingTarget
              ? leartingTarget + 
                (leartingTarget[leartingTarget.length - 1] !== '.' ? '.' : '') 
              : '...'
          }}
        </p>
      </div>
      <div class="jit-resource-item__body-actions">
        <div class="jit-def-button" (click)="onContentClick()">Preview</div>
      </div>
    </div>
  </div>
}