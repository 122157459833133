import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '@jit/shared';

import { AssignmentUpdateHeaderComponent } from '../update-header/update-header.component';
import { AssignmentUpdateFooterComponent } from '../update-footer/update-footer.component';

@Component({
  selector: 'jit-update-assignment',
  templateUrl: './update-content.component.html',
  styleUrls: ['./update-content.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    AssignmentUpdateHeaderComponent,
    AssignmentUpdateFooterComponent,
  ],
})
class AssignmentUpdateContentComponent extends BaseComponent {}

export {
  AssignmentUpdateContentComponent,
};
