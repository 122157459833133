import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { CollectionService } from '../../services';
import { Bundle, IconId, Resource, Suite } from '../../models';

@Component({
  selector: 'jit-notification-collection',
  templateUrl: './notification-collection.component.html',
  styleUrls: ['./notification-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconSimpleComponent],
})
export class NotificationCollectionComponent {
  constructor(
    private collectionService: CollectionService,
    @Inject(MAT_SNACK_BAR_DATA)
    public params: {
      text: string;
      icon: IconId;
      hasAction: boolean;
      item: Suite | Bundle | Resource;
    }
  ) {}

  public action(): void {
    this.collectionService.openCreationDialog(this.params.item);
  }
}
