import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { UtilsService } from '@jit/core';
import { 
  BaseComponent, useAssignment, IClassRoom, AssignmentService, 
  IStudent, IAssignedStudentsResponse, IResourceEntity
} from '@jit/data-layer';
import { 
  IconSimpleComponent, ResourceItemComponent, TreeviewItemComponent, ReportIconsComponent,
  TooltipDirective, ResourcesTooltipDirective
} from '@jit/shared';

@Component({
  selector: 'jit-assignment-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ResourceItemComponent,
    TreeviewItemComponent,
    ReportIconsComponent,
    TooltipDirective, 
    ResourcesTooltipDirective,
  ],
})
class AssignmentStudentsComponent extends BaseComponent implements OnInit {

  @useAssignment('resources')
  resources: IResourceEntity[] = [];

  @useAssignment('classRooms')
  _classRooms: IClassRoom[] = [];

  @useAssignment('totalStudents')
  totalStudents: number = 0;

  @useAssignment('studentsAssigned')
  studentsAssigned: IAssignedStudentsResponse = {};

  @useAssignment('hasStudentsAssigned')
  hasStudentsAssigned: boolean = false;

  private _assignmentService: AssignmentService = inject(AssignmentService);

  public classRooms: IClassRoom[] = [];
  public selectedStudents: number = 0;
  public countStudents: Record<string, number> = {};

  ngOnInit(): void {
    this.classRooms = UtilsService.copyDeep(this._classRooms);

    this.calculate();
  }

  @useAssignment('classRooms')
  @useAssignment('totalStudents')
  calculate(): void {
    this.selectedStudents = this._assignmentService.getSelectedStudents(this.classRooms);

    this.classRooms.forEach((classRoom: IClassRoom) => {
      classRoom.selected = this._assignmentService.isClassRoomSelected(classRoom);

      this.countStudents[classRoom.id] = this._assignmentService.getSelectedStudentsByClassRoom(classRoom);
    });
  }

  getWarnTitle(studentId: string): string {
    if (this.studentsAssigned[studentId]) {
      const suf: string = (this.studentsAssigned[studentId].length === 1) ? ' is ' : ' are ';

      return this.studentsAssigned[studentId].map(i => i.title).join(', ') + suf + 'already assigned';
    } else {
      return '';
    }
  }

  onSelectAll(selectAll: boolean, e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.classRooms.forEach((classRoom: IClassRoom) => {
      classRoom.selected = selectAll;
      classRoom.changed = true;

      classRoom.students.forEach((student: IStudent) => {
        if (!student.disabled) {
          student.selected = selectAll;
          student.changed = true;
        }
      });
    });

    this.calculate();
  }

  onSelectClassRoom(classRoom: IClassRoom, selected: boolean): void {
    classRoom.selected = selected;
    classRoom.changed = true;

    classRoom.students.forEach((student: IStudent) => {
      if (!student.disabled) {
        student.selected = selected;
        student.changed = true;
      }
    });
    
    this.calculate();
  }

  onSelectedStudent(student: IStudent, selected: boolean): void {
    if (!student.disabled) {
      student.selected = selected;
      student.changed = true;
    }

    this.calculate();
  }

  onBack(): void {
    useAssignment.getState().backRelated();
  }

  onApply(): void {
    useAssignment.getState().setStudents(this.classRooms);
  }

}

export {
  AssignmentStudentsComponent,
};
