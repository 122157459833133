import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReportIconsComponent } from '../icons/icons.component';
import { IconSimpleComponent } from '../../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-rating-feedback',
  templateUrl: './rating-feedback.component.html',
  styleUrls: ['./rating-feedback.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReportIconsComponent,
    IconSimpleComponent,
  ],
})
class RatingFeedbackComponent {

  @Input()
  title: string = '';

  @Input()
  code: string = '';

  @Input()
  time: number = 0;

  @Input()
  positive: boolean = false;

}

export {
  RatingFeedbackComponent,
};
