import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-report-question-fib-tiles',
  templateUrl: './fib-tiles.component.html',
  styleUrls: ['./fib-tiles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ReportQuestionFibTilesComponent {
  
}

export {
  ReportQuestionFibTilesComponent,
};
