import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  inject,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DialogStatus } from '@jit/core';
import { DialogRef } from '@ngneat/dialog';
import { Collection } from '../../models';
import { CollectionService } from '@jit/data-layer';

@Component({
  selector: 'jit-edit-collection-modal',
  templateUrl: './edit-collection-modal.component.html',
  styleUrls: ['./edit-collection-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule],
})
export class EditCollectionModalComponent implements OnInit {

  private dialogRef: DialogRef<Collection> = inject(DialogRef);
  private collectionService = inject(CollectionService);

  public nameControl = new FormControl('');

  ngOnInit(): void {
    this.nameControl.patchValue(this.dialogRef.data.name);
  }

  public async onSave(): Promise<void> {
    if (this.nameControl.value) {
      await this.collectionService.rename(this.dialogRef.data.id, this.nameControl.value);

      this.dialogRef.close(DialogStatus.Accept);
    }
  }

  public onCancel() {
    this.dialogRef.close(DialogStatus.Reject);
  }

  public async onDelete(): Promise<void> {
    await this.collectionService.delete(this.dialogRef.data.id);

    this.dialogRef.close(DialogStatus.Accept);
  }

}
