interface IFavorite extends IBasicFavorite {
  id: string;
  cnt: number;
  contentType: string;
  originalContentType: string;
  duration?: string | null;
  slug: string;
  subjectSlug: string;
  title: string;
  type: string;
}

interface IBasicFavorite {
  type: string;
  slug: string;
}

interface IBasicFavoriteWithCount extends IBasicFavorite {
  cnt: number;
}

interface IFavoriteMap extends IBasicFavoriteWithCount {
  collectionIds: Set<string>;
}

interface ICreateFavorite extends IBasicFavorite {}

export {
  IFavorite,
  ICreateFavorite,
  IBasicFavorite,
  IFavoriteMap,
  IBasicFavoriteWithCount,
};
