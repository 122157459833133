<div class="jit-report-kpi">
  <div class="jit-report-kpi__button">
    <h3>{{ kpi.total }}</h3>
    <span>Assigned Lessons</span>
  </div>
  <div class="jit-report-kpi__button">
    <h3>{{ kpi.completed }}</h3>
    <span>Completed Assignments</span>
  </div>
  <div class="jit-report-kpi__button">
    <h3>{{ kpi.inProgress }}</h3>
    <span>In-Progress Assignments</span>
  </div>
  <div class="jit-report-kpi__button">
    <h3>{{ kpi.notStarted }}</h3>
    <span>Not Started Assignments</span>
  </div>
  <div class="jit-report-kpi__button">
    <h3 [ngClass]="{ past: kpi.pastDue > 0 }">
      {{ kpi.pastDue }}
    </h3>
    <span>Past Due Assignments</span>
  </div>
  <div class="jit-report-kpi__button">
    <h3>{{ kpi.selfSelected }}</h3>
    <span>Self-Selected Lessons</span>
  </div>
</div>