<div class="jit-nps-answer">
  <jit-checkbox [value]="value" [title]="title" (changed)="onValue($event)"></jit-checkbox>
  @if (value || showText) {
    <div class="jit-nps-answer__wrap-text">
      <textarea 
        class="jit-nps-answer__text" 
        [placeholder]="placeholder" 
        (keyup)="onText($event)"
      >{{ text }}</textarea>
    </div>
  }
</div>