import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-td-cell-info',
  templateUrl: './td-cell-info.component.html',
  styleUrls: ['./td-cell-info.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class TdCellInfoComponent {

  @Input()
  title: string | number = '';

  @Input()
  descr: string = '';

}

export {
  TdCellInfoComponent,
};
