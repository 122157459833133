import { MapLoglevels } from './logger.enums';

interface ILogItem {
  level: MapLoglevels;
  fatal?: any;
  error?: any;
  warn?: any;
  data?: any;
}

export {
  ILogItem,
};
