class LogError extends Error {

  public data: string | any;

  constructor(message: string, data: any = null) {
    super(message);

    this.data = data;

    Object.setPrototypeOf(this, LogError.prototype);
  }

}

export {
  LogError
};
