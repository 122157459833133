<div class="jit-paginator">
  <div class="jit-paginator__paginator">
    @for (item of pagins; track $index) {
      <div
        tabindex="1"
        class="jit-paginator__paginator-item" 
        [class.active]="item.active"
        [class.is-arrow]="item.isArrow"
        (click)="onClick(item)"
      >
        <span
          class="jit-paginator__paginator-label" 
          [class.active]="item.active"
          [class.is-arrow]="item.isArrow"
        >
          {{ item.name }}
        </span>
      </div>
    }
  </div>
  @if (showLimit && pagins.length) {
    <div class="jit-paginator__limit">
      <span class="jit-paginator__limit-title">Show</span>
      <jit-simple-select
        [clearable]="false"
        [value]="currentLimit"
        [options]="limitOptions"
        [showIcon]="false"
        (changed)="onLimitChange($event)"
      ></jit-simple-select>
    </div>
  }
</div>