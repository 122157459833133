<a [href]="'/detail-page/' + resource.resource_slug + '/resource'" class="jit-resource-small-item" target="_blank">
  <div 
    class="jit-resource-small-item__thumb" 
    style="background-image: url('{{ this.thumb }}');"
  >
    &nbsp;
  </div>
  <div class="jit-resource-small-item__title">
    {{ resource.title }}
  </div>
</a>