import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ELanguages } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private translateService: TranslateService = inject(TranslateService);

  public get language(): ELanguages {
    return (this.translateService.currentLang ||
      this.translateService.defaultLang) as ELanguages;
  }
}
