import { IUserInfo } from '../../core/services/oauth/oauth.interfaces';

interface IUserState {
  user: IUserInfo | void;
  initialized: boolean;
  
  allowCreateAssignment: boolean;
  allowLectura: boolean;
  allowMath: boolean;
  allowReading: boolean;
  allowMatematicas: boolean;

  setUser: (user: IUserInfo | void, isInit?: boolean) => Promise<void>;
  init: () => Promise<void>;
}

export {
  IUserState,
};
