import {
  Component,
  ChangeDetectionStrategy,
  inject,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';
import { RadioControlComponent } from '../radio-control/radio-control.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { SelectComponent } from '../select/select.component';
import { SearchService } from '../../services';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  map,
  takeUntil,
  startWith,
} from 'rxjs';
import { CheckboxControlComponent } from '../checkbox-control/checkbox-control.component';

type RadioControlValue = 'one' | 'all';

@Component({
  selector: 'jit-onboarding-modal',
  standalone: true,
  imports: [
    CommonModule,
    RadioControlComponent,
    ReactiveFormsModule,
    SelectComponent,
    CheckboxControlComponent,
  ],
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingModalComponent implements OnInit, OnDestroy {
  private dialogRef: DialogRef<unknown> = inject(DialogRef);
  private _destroy = new Subject<void>();
  private _step = new BehaviorSubject<number>(0);
  private _continueDisabled = new BehaviorSubject<boolean>(true);
  private _saveDisabled = new BehaviorSubject<boolean>(true);

  private searchService = inject(SearchService);
  private formBuilder = inject(FormBuilder);

  public radioControl = new FormControl<RadioControlValue>('one');
  public stateControl = new FormControl<string[]>([]);
  public gradesForm!: FormGroup;

  public step$ = this._step.asObservable();
  public continueDisabled$ = this._continueDisabled.asObservable();
  public saveDisabled$ = this._saveDisabled.asObservable();
  public stateFilterOptions$ = this.searchService.searchBarFilters$.pipe(
    map((filters) => {
      const filter = filters?.find((el) => el.key === 'states');

      return filter
        ? filter.options.map((el) => ({ text: el.label, value: el.key }))
        : null;
    })
  );
  public gradesFilter$ = this.searchService.searchBarFilters$.pipe(
    map((filters) => {
      const filter = filters?.find((el) => el.key === 'grade');
      const options = filter?.options.map((el) => ({
        text: el.label,
        key: el.key,
      }));
      const form: Record<string, boolean> = {
        all: false,
      };

      options?.forEach((el) => (form[el.key] = false));
      const formGroup = this.formBuilder.group(form);
      this.gradesForm = formGroup;

      return {
        formGroup,
        options,
      };
    })
  );

  ngOnInit(): void {
    this.subscribeOnChanges();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  public onSetAllGrades(value: boolean) {
    if (value) {
      this.gradesForm.reset({ all: true });
    }
    this.updateSavePosibility();
  }

  public onUnsetAllGrades(value: boolean) {
    if (value) {
      this.gradesForm.patchValue({ all: false });
    }
    this.updateSavePosibility();
  }

  public onContinue() {
    this._step.next(1);
  }

  public onSave() {
    const option = this.radioControl.value;
    const states = this.stateControl.value;
    const grades: string[] = [];
    Object.keys(this.gradesForm.value).forEach((key) => {
      if (key !== 'all' && this.gradesForm.value[key]) {
        grades.push(key);
      }
    });

    const params = this.searchService.parseFilterValue({
      grade: grades,
      states: option === 'all' ? [] : states,
    });

    this.searchService.applyFilters(params);
    this.dialogRef.close(params);
  }

  private updateSavePosibility() {
    setTimeout(() => {
      const formValue = this.gradesForm.value;

      this._saveDisabled.next(
        !Object.keys(formValue).some((key) => this.gradesForm?.value[key])
      );
    });
  }

  private subscribeOnChanges() {
    combineLatest({
      option: this.radioControl.valueChanges.pipe(startWith('one')),
      states: this.stateControl.valueChanges.pipe(startWith([])),
    })
      .pipe(takeUntil(this._destroy))
      .subscribe(({ option, states }) => {
        if (option === 'all') {
          this._continueDisabled.next(false);
        } else if ((states?.length || 0) > 0) {
          this._continueDisabled.next(false);
        } else {
          {
            this._continueDisabled.next(true);
          }
        }
      });
  }
}
