import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ISelectItem, useGradebook, IEntityFilters, GradebookService } from '@jit/data-layer';
import { IconSimpleComponent } from '../../icon-simple/icon-simple.component';
import { ReportIconsComponent } from '../icons/icons.component';
import { SimpleSelectComponent } from '../../simple-select/simple-select.component';

@Component({
  selector: 'jit-gradebook-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ReportIconsComponent,
    SimpleSelectComponent,
  ],
})
class GradebookHeaderComponent {

  @useGradebook('subject')
  subject: ISelectItem | void = void(0);

  @useGradebook('view')
  view: ISelectItem = GradebookService.getDefaultView();

  @useGradebook('period')
  period: ISelectItem | void = void(0);

  @useGradebook('subjects')
  subjects: ISelectItem[] = [];
  
  @useGradebook('views')
  views: ISelectItem[] = [];
  
  @useGradebook('periods')
  periods: ISelectItem[] = [];

  onSubjectChanged(value: ISelectItem | void): void {
    useGradebook.getState().setSubject(value as IEntityFilters)
  }

  onViewChanged(value: ISelectItem): void {
    useGradebook.getState().setView(value);
  }

  onPeriodChanged(value: ISelectItem | void): void {
    useGradebook.getState().setPeriod(value);
  }

}

export {
  GradebookHeaderComponent,
};
