import { inject, Injectable } from '@angular/core';
import { ENV } from '../../models/environment';

interface IDataLayer extends Window {
  dataLayer: any[];
  gtag: (...args: any[]) => any;
}

@Injectable({
  providedIn: 'root',
})
export class GA4Service {

  private _initialized: boolean = false;
  private _env = inject(ENV);
  private _ga4Key: string = '';
  private _gtmKey: string = '';
  private _ga4Url: string = 'https://www.googletagmanager.com/gtag/js?id=';
  private _gtmUrl: string = 'https://www.googletagmanager.com/gtm.js?id=';

  constructor() {
    this._ga4Key = this._env.ga4Key;
    this._gtmKey = this._env.gtmKey;
  }

  private _injectScript(url: string) {
    const firstEl = document.body.children[0];
    const dataLayerScript: HTMLScriptElement = document.createElement('script');

    dataLayerScript.async = true;
    dataLayerScript.src = url;

    document.body.insertBefore(dataLayerScript, firstEl);
  }

  private _initGA4(): GA4Service {
    this._injectScript(this._ga4Url + this._ga4Key);

    const win: IDataLayer = window as any;

    function gtag(...args: any[]) {
      win.dataLayer.push(arguments);
    }

    win.dataLayer = win.dataLayer || [];
    win.gtag = gtag;

    gtag('js', new Date());
    gtag('config', this._ga4Key);

    return this;
  }

  private _initGTM(): GA4Service {
    const win: IDataLayer = window as any;

    win.dataLayer = win.dataLayer || [];

    win.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    this._injectScript(this._gtmUrl + this._gtmKey);

    return this;
  }
  
  public init(): void {
    if (!this._initialized) {
      this._initialized = true;

      this._initGA4()._initGTM();
    }
  }

}
