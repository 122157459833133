import { DateTime } from 'luxon';
import { IResourceEntity } from './resource.interface';
import { IClassRoom } from './class-room.interface';
import { IAssignedStudentsResponse } from './assignment.interface';

type TInternalSteps = 'students' | 'related' | 'search' | 'search-related' | null | void;

interface IAssignmentState {
  isOpened: boolean;
  isUpdateOpened: boolean;
  isInternalOpened: boolean;
  startDate: DateTime;
  dueDate: DateTime | void | null;
  updateEntityType: string;
  updateAssignmentId: string | null;
  updateStartDate: DateTime | void | null;
  updateDueDate: DateTime | void | null;
  resources: IResourceEntity[];
  classRooms: any[];
  internalStep: TInternalSteps;
  related: IResourceEntity[];
  totalStudents: number;
  selectedStudents: number;
  isSearchMode: boolean;
  contentType: string;
  studentsAssigned: IAssignedStudentsResponse;
  hasStudentsAssigned: boolean;
  
  setStartDate: (startDate: DateTime) => void;
  setDueDate: (dueDate: DateTime | void | null) => void;

  setUpdateStartDate: (startDate: DateTime) => void;
  setUpdateDueDate: (dueDate: DateTime | void | null) => void;
  
  openStudents: () => void;
  openRelated: () => void;
  openSearch: (params?: any) => void;
  
  backRelated: () => void;
  addRelated: (related: IResourceEntity[]) => void;
  removeRelated: (resource: IResourceEntity) => void;
  
  setStudents: (classRooms: IClassRoom[]) => void;

  start: (resource?: IResourceEntity) => void;
  reset: () => void;
  apply: () => void;
  startUpdate: (
    updateAssignmentId: string, 
    updateStartDate: DateTime | string, 
    updateDueDate: DateTime | string | void | null,
    updateEntityType: string
  ) => void;
  applyUpdate: () => Promise<void>;
  closeUpdate: () => void;
}

export {
  TInternalSteps,
  IAssignmentState,
};
