import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject, OnChanges, SimpleChanges } from '@angular/core';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { IEnvironment, ENV } from '../../models/environment';
import { IResourceEntity, BaseComponent } from '@jit/data-layer';
import { UtilsService } from '@jit/core';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';

@Component({
  selector: 'jit-microlesson-preview',
  templateUrl: './microlesson-preview.component.html',
  styleUrls: ['./microlesson-preview.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    SafeUrlPipe,
  ],
})
class MicrolessonPreviewComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() 
  resource!: IResourceEntity;

  @Input()
  showThumb: boolean = false;
  
  private _env: IEnvironment = inject(ENV);

  public path: string = '';
  public url: string = '';

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['resource'] && changes['resource'].currentValue !== changes['resource'].previousValue) {
      this.init();
    }
  }

  init() {
    if (this.showThumb) {
      if (this.resource?.thumbnail?.path) {
        this.path = this.resource?.thumbnail?.path;

        if (!this.path.includes('https://') && !this.path.includes('http://')) {
          this.path = this._env.assetsDomain + this.path;
        }
      }

      this.url = UtilsService.format(this._env.microlessonDomain, this.resource);
    } else {
      this.path = UtilsService.format(this._env.microlessonDomain, this.resource);
    }
  }

}

export {
  MicrolessonPreviewComponent,
};
