import { Injectable, ElementRef } from '@angular/core';
import { UtilsService } from '@jit/core';
import { 
  ISelectItem,
  ISimpleSelectState,
  ISimpleSelectListPositionParams,
  ISimpleSelectListPositionResponse,
  ISimpleSelectClickOutsideResponse,
  ISimpleSelectListPositionStyle,
} from './simple-select.interfaces';
import { MIN_WIDTH } from './simple-select.constants';

enum SelectKeyCode {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ESC = 'Escape',
  ENTER = 'Enter',
}

const ALLOWED_CODES: SelectKeyCode[] = Object.values(SelectKeyCode);

@Injectable({
  providedIn: 'root',
})
export class SimpleSelectService {

  public setStyle(list: HTMLElement, style: ISimpleSelectListPositionStyle): void {
    if (list) {
      list.style.top = style.top + 'px';
      list.style.left = style.left + 'px';
      list.style.width = style.width + 'px';
    }
  }

  public listPosition({ parent, list, state }: ISimpleSelectListPositionParams): ISimpleSelectListPositionResponse {
    const setListPosition = () => {
      if (state.isOpened() && parent?.nativeElement && list?.nativeElement) {
        const mw = (state.minListWidth() > 0) ? state.minListWidth() : MIN_WIDTH;        
        const innerWidth: number = window.innerWidth;
        const parentRect: DOMRect = parent.nativeElement.getBoundingClientRect();
        const width: number = (parentRect.width < mw) ? mw : parentRect.width;

        let left: number = parentRect.x;
        let top: number = parent.nativeElement.offsetTop + parentRect.height + 4;
  
        if (left + width > innerWidth) {
          left = left - ((left + width) - (innerWidth - 16));
        }

        this.setStyle(list.nativeElement, { top, left, width });
      }
    };

    const calc = () => setTimeout(() => setListPosition(), 10);
  
    setListPosition();
  
    const handlePosition = UtilsService.debounce(() => (setListPosition()), 10);
  
    window.addEventListener('resize', handlePosition, true);
    window.addEventListener('scroll', handlePosition, true);
  
    const unsubscribe = () => {
      window.removeEventListener('resize', handlePosition);
      window.removeEventListener('scroll', handlePosition);
    };
    
    return { unsubscribe, calc };
  }

  scrollList(focusedItem: number, el: HTMLElement, css: string = 'jit-simple-select__item'): void {
    if (focusedItem >= 0 && el) {
      const item: any = el.querySelector(`.${css}[data-at="${focusedItem}"]`);

      if (item) {
        const itemRect: DOMRect = item.getBoundingClientRect();
        const parentEl: any = item.offsetParent;
    
        if (parentEl) {
          const parentRect = parentEl.getBoundingClientRect();
          const cs: CSSStyleDeclaration = getComputedStyle(parentEl);
          const padding: number = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);
          const parentHeight: number = parentRect.height - padding;
    
          if (itemRect.top > (parentRect.top + parentHeight)) {
            parentEl.scrollTop += itemRect.height;
          } else if (parentRect.top > (itemRect.top + itemRect.height - padding)) {
            parentEl.scrollTop -= itemRect.height;
          }
        }
      }
    }
  }

  clickOutside(el: ElementRef<HTMLElement>, handler: () => void): ISimpleSelectClickOutsideResponse {
    const handlerClickOutside = (e: Event) => {
      if (el?.nativeElement?.contains(e.target as HTMLElement)) {
        return;
      }

      handler();
    };

    document.addEventListener('click', handlerClickOutside, false);

    const unsubscribe = () => {
      document.removeEventListener('click', handlerClickOutside, false);
    };

    return { unsubscribe };
  }

  keyDown(state: ISimpleSelectState, clickHandler: (item: ISelectItem) => void, css?: string): (e: KeyboardEvent) => void {
    return (e: KeyboardEvent) => {
      if (ALLOWED_CODES.includes(e.code as SelectKeyCode)) {
        UtilsService.stopEvent(e);

        if (state.disabled()) {
          return;
        }

        if (e.code === SelectKeyCode.ARROW_DOWN) {
          let nextFocusedItem: number = 0;

          if (!state.isOpened()) {
            state.isOpened.set(true);
          } else {
            nextFocusedItem = state.focusedItem() + 1;

            if (nextFocusedItem >= state.options().length) {
              nextFocusedItem = state.options().length - 1;
            }
          }

          state.focusedItem.set(nextFocusedItem);

          this.scrollList(nextFocusedItem, e.target as HTMLElement, css);
        } else if (e.code === SelectKeyCode.ARROW_UP) {
          let nextFocusedItem: number = state.focusedItem() - 1;

          if (nextFocusedItem < 0) {
            nextFocusedItem = 0;
          }

          state.focusedItem.set(nextFocusedItem);

          this.scrollList(nextFocusedItem, e.target as HTMLElement, css);
        } else if (e.code === SelectKeyCode.ENTER) {
          if (state.isOpened() && state.focusedItem() >= 0) {
            clickHandler(state.options()[state.focusedItem()]);
          }
        } else if (e.code === SelectKeyCode.ESC) {
          state.isOpened.set(false);
        }
      }
    };
  }

}
