<div class="jit-report-header">
  <div class="jit-report-header__header">
    <jit-report-icons name="report-logo" [width]="41" [height]="41"></jit-report-icons>
    <h1>Assignments</h1>
  </div>
  <div class="jit-report-header__content">
    <jit-simple-select 
      placeholder="All Subjects"
      [value]="subject"
      [minListWidth]="200"
      [options]="subjects" 
      (changed)="onSubjectChanged($event)"
    ></jit-simple-select>
    <jit-simple-select 
      placeholder="Select"
      [value]="view"
      [clearable]="false"
      [minListWidth]="250"
      [options]="views" 
      (changed)="onViewChanged($any($event))"
    ></jit-simple-select>
    <jit-simple-select 
      placeholder="All lessons"
      [value]="period"
      [minListWidth]="200"
      [options]="periods" 
      (changed)="onPeriodChanged($event)"
    ></jit-simple-select>
  </div>
</div>