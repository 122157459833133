import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '@jit/shared';

import { AssignmentHeaderComponent } from '../header/header.component';
import { AssignmentFooterComponent } from '../footer/footer.component';
import { AssignmentResourcesComponent } from '../resources/resources.component';
import { AssignmentRelatedComponent } from '../related/related.component';

@Component({
  selector: 'jit-assignment',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    AssignmentHeaderComponent,
    AssignmentFooterComponent,
    AssignmentResourcesComponent,
    AssignmentRelatedComponent,
  ],
})
class AssignmentContentComponent extends BaseComponent {}

export {
  AssignmentContentComponent,
};
