import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ApiService, IResponse, LoggerService, UtilsService } from '@jit/core';
import { 
  IStudent, IClassRoom, IAssignmentState, IAssignedStudentsResponse, 
  IStudendsAssignedRequest, IClassRoomResponse, IResourceEntity
} from '../interfaces';

const BODY_KEYS = [
  'startDate',
  'dueDate',
  'resources',
];

const WARN: string = 'warn'

interface ICreateBody {
  startDate: DateTime;
  dueDate: DateTime | void | null;
  resources: string[];
  students: string[];
}

interface IUpdateBody {
  startDate: DateTime | string;
  dueDate?: DateTime | string | void | null;
}

interface IAssignment {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
class AssignmentService {
  
  private _urlStudents: string = '/v2/api/teachers/classrooms';
  private _urlCreateAssignment: string = '/v2/api/assignments';
  private _urlUpdateAssignment: string = '/v2/api/assignments/{{ assignmentId }}';
  private _urlStudentsAssigned: string = '/v2/api/students/assigned';

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);

  public async create(data: Partial<IAssignmentState>): Promise<IAssignment> {
    let response: IResponse<IAssignment>;

    const body: ICreateBody = UtilsService.pick(data, BODY_KEYS);

    body.resources = body.resources.map((res: any) => res.id);

    const students = data.classRooms?.reduce((acc: Set<string>, classRoom: IClassRoom) => {
      classRoom.students.forEach((student: IStudent) => {
        student.selected && acc.add(student.id)
      });

      return acc;
    }, new Set());

    body.students = [ ...students ];

    try {
      response = await this._apiService.post<IAssignment, ICreateBody>(this._urlCreateAssignment, body);
    } catch (err) {
      this._loggerService.error('AssignmentService.create: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async update(
    assignmentId: string, 
    startDate: DateTime | string, 
    dueDate: DateTime | string | null | void
  ): Promise<any> {
    const body: IUpdateBody = { startDate };
    const url: string = UtilsService.format(this._urlUpdateAssignment, { assignmentId })

    if (dueDate || dueDate === null) {
      body.dueDate = dueDate;
    }

    if (body.startDate && typeof body.startDate !== 'string') {
      body.startDate = body.startDate.toFormat('yyyy-LL-dd') + 'T00:00:00.000Z';
    }

    if (body.dueDate && typeof body.dueDate !== 'string') {
      body.dueDate = body.dueDate.toFormat('yyyy-LL-dd') + 'T00:00:00.000Z';
    }

    let response;

    try {
      response = await this._apiService.patch<IAssignment, IUpdateBody>(url, body);
    } catch (err) {
      this._loggerService.error('AssignmentService.update: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getClassRooms(): Promise<IClassRoom[]> {
    let response: IResponse<IClassRoom[]>;

    try {
      response = await this._apiService.get<IClassRoom[]>(this._urlStudents);
    } catch (err) {
      this._loggerService.error('AssignmentService.getClassRooms: Request error', err);

      throw err;
    }

    response.data.forEach((classRoom: IClassRoom) => {
      classRoom.selected = true;

      classRoom.students.forEach((student: IStudent) => {
        student.selected = true;
        student.changed = false;
        student.disabled = false;
      });

      return classRoom;
    });

    return response.data;
  }

  public calculateSelected(classRooms: IClassRoom[]): IClassRoomResponse {
    let totalStudents: number = 0;
    let selectedStudents: number = 0;

    classRooms.forEach((classRoom: IClassRoom) => {
      classRoom.students.forEach((student: IStudent) => {
        if (student.selected) {
          selectedStudents++;
        }

        totalStudents++;
      });
    });

    return { classRooms, totalStudents, selectedStudents };
  }

  public async getStudentAssigned(resources: string[] = []): Promise<IAssignedStudentsResponse> {
    let response: IResponse<IAssignedStudentsResponse>;

    const body: IStudendsAssignedRequest = { resources };

    try {
      response = await this._apiService.post<IAssignedStudentsResponse, IStudendsAssignedRequest>(this._urlStudentsAssigned, body);
    } catch (err) {
      this._loggerService.error('AssignmentService.getStudentAssigned: Request error', err);

      throw err;
    }

    return response.data;
  }

  public getSelectedStudents(classRooms: IClassRoom[]): number {
    let selectedStudents: number = 0;

    classRooms.forEach((classRoom: IClassRoom) => {
      selectedStudents = selectedStudents + this.getSelectedStudentsByClassRoom(classRoom);
    });

    return selectedStudents;
  }

  public getSelectedStudentsByClassRoom(classRoom: IClassRoom): number {
    let selectedStudents: number = 0;

    classRoom.students.forEach((student: IStudent) => {
      if (student.selected) {
        selectedStudents++;
      }
    });

    return selectedStudents;
  }

  public isClassRoomSelected(classRoom: IClassRoom): boolean {
    return (this.getSelectedStudentsByClassRoom(classRoom) === classRoom.students.length);
  }

  public changeSelectedByAssigned(
    classRooms: IClassRoom[], 
    studentAssigned: IAssignedStudentsResponse,
    resources: IResourceEntity[]
  ): IClassRoom[] {
    const len: number = resources.length;

    classRooms.forEach((classRoom: IClassRoom) => {
      classRoom.students.forEach((student: IStudent) => {
        if (studentAssigned[student.id]) {
          if (studentAssigned[student.id].length === len) {
            student.selected = false;
            student.disabled = true;
          } else if (studentAssigned[student.id].length < len) {
            student.disabled = false;
            
            if (!student.changed) {
              student.selected = true;
            }
          }
        } else {
          if (!student.changed) {
            student.selected = true;
          }
        }
      });
    });

    return classRooms;
  } 

}

export {
  AssignmentService,
};
