import { DateTime } from 'luxon';
import { UtilsService } from '@jit/core';
import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import {
  IResourceEntity, ResourceService, AssignmentService, IClassRoom, IAssignmentState,
  TInternalSteps, IAssignedStudentsResponse, IStudent, GradebookService,
} from '@jit/data-layer';
import { useNotifications } from './notification.store';
import { useGradebook } from './gradebook.store';

function getInitValuesState(): Partial<IAssignmentState> {
  return {
    isOpened: false,
    isUpdateOpened: false,
    isInternalOpened: false,
    startDate: DateTime.now(),
    dueDate: null,
    updateEntityType: '',
    updateAssignmentId: null,
    updateStartDate: DateTime.now(),
    updateDueDate: null,
    resources: [],
    classRooms: [],
    internalStep: null,
    related: [],
    totalStudents: 0,
    selectedStudents: 0,
    isSearchMode: false,
    contentType: 'Microlesson',
    studentsAssigned: {},
    hasStudentsAssigned: false,
  };
}

const useAssignment = createStore<IAssignmentState>((set, get) => ({
  ...getInitValuesState() as IAssignmentState,

  setStartDate: (startDate: DateTime) => {
    const dueDate = get().dueDate;
    const partState: Partial<IAssignmentState> = { startDate };

    if (!startDate || (dueDate && dueDate <= startDate)) {
      partState.dueDate = null;
    }

    set(partState);
  },
  setDueDate: (dueDate: DateTime | void | null) => {
    if (!dueDate || (dueDate <= get().startDate)) {
      set({ dueDate: null });
    } else {
      set({ dueDate });
    }
  },
  setUpdateStartDate: (updateStartDate: DateTime | string) => {
    if (typeof updateStartDate === 'string') {
      updateStartDate = DateTime.fromISO(updateStartDate, { zone: 'utc' });
    }

    const updateDueDate = get().updateDueDate;
    const partState: Partial<IAssignmentState> = { updateStartDate };

    if (!updateStartDate || (updateDueDate && updateDueDate <= updateStartDate)) {
      partState.updateDueDate = null;
    }

    set(partState);
  },
  setUpdateDueDate: (updateDueDate: DateTime | void | null) => {
    if (typeof updateDueDate === 'string') {
      updateDueDate = DateTime.fromISO(updateDueDate, { zone: 'utc' });
    }

    const updateStartDate = get().updateStartDate;

    if (!updateDueDate || (updateStartDate && updateDueDate <= updateStartDate)) {
      set({ updateDueDate: null });
    } else {
      set({ updateDueDate });
    }
  },
  openStudents: () => {
    set({ isOpened: true, isInternalOpened: true, internalStep: 'students' });
  },
  openRelated: () => {
    set({ isOpened: true, isInternalOpened: true, internalStep: 'related' });
  },
  openSearch: (params?: any) => {
    const internalStep: TInternalSteps = params?.internalStep || 'search';

    set({ isOpened: true, isInternalOpened: true, internalStep });
  },
  backRelated: () => {
    if (get().internalStep === 'search-related') {
      get().openRelated();
    } else {
      set({ isInternalOpened: false });

      // Hide content after 750ms because of HTML moving transition
      setTimeout(() => set({ internalStep: null }), 750);
    }
  },
  setStudents: (classRooms: IClassRoom[]) => {
    const assignmentService: AssignmentService = createStore.inject(AssignmentService);

    set({
      classRooms,
      selectedStudents: assignmentService.getSelectedStudents(classRooms),
    });

    get().backRelated();
  },
  addRelated: async (related: IResourceEntity[]) => {
    const assignmentService: AssignmentService = createStore.inject(AssignmentService);
    const resources = [ ...get().resources, ...related ];

    let classRooms: IClassRoom[] = UtilsService.copyDeep(get().classRooms);

    const studentsAssigned: IAssignedStudentsResponse = await assignmentService.getStudentAssigned(resources.map((s) => s.slug));

    const hasStudentsAssigned = !!Object.keys(studentsAssigned).length;

    classRooms = assignmentService.changeSelectedByAssigned(classRooms, studentsAssigned, resources);

    const { totalStudents, selectedStudents } = assignmentService.calculateSelected(classRooms);

    set({
      resources,
      studentsAssigned,
      classRooms,
      totalStudents,
      selectedStudents,
      hasStudentsAssigned,
      internalStep: null,
    });

    get().backRelated();
  },
  removeRelated: async (resource: IResourceEntity) => {
    const assignmentService: AssignmentService = createStore.inject(AssignmentService);
    const resources = [ ...get().resources ];

    let classRooms: IClassRoom[] = UtilsService.copyDeep(get().classRooms);
    let at = resources.findIndex((r) => (r.id === resource.id));

    if (at >= 0) {
      resources.splice(at, 1);

      const studentsAssigned: IAssignedStudentsResponse = await assignmentService.getStudentAssigned(resources.map((s) => s.slug));

      const hasStudentsAssigned = !!Object.keys(studentsAssigned).length;

      classRooms = assignmentService.changeSelectedByAssigned(classRooms, studentsAssigned, resources);

      const { totalStudents, selectedStudents } = assignmentService.calculateSelected(classRooms);

      set({
        resources,
        studentsAssigned,
        classRooms,
        totalStudents,
        selectedStudents,
        hasStudentsAssigned,
      });
    }
  },

  start: async (resource?: IResourceEntity | void) => {
    const assignmentService: AssignmentService = createStore.inject(AssignmentService);
    const resourceService: ResourceService = createStore.inject(ResourceService);
    const valuesState = getInitValuesState();

    let studentsAssigned: IAssignedStudentsResponse = {};

    valuesState.isOpened = true;

    if (resource) {
      valuesState.resources = [resource];

      set(valuesState);

      // Get related resources in background
      const slug = resource.parentSeries?.slug;

      if (slug) {
        const query = { contentType: get().contentType };
        const related: IResourceEntity[] = await resourceService.getRelated(slug, void(0), query);

        studentsAssigned = await assignmentService.getStudentAssigned([resource.slug]);

        const hasStudentsAssigned = !!Object.keys(studentsAssigned).length;

        set({ related, studentsAssigned, hasStudentsAssigned });
      }
    } else {
      valuesState.isSearchMode = true;

      set(valuesState);
    }

    const classRooms: IClassRoom[] = await assignmentService.getClassRooms();

    const { totalStudents, selectedStudents } = assignmentService.calculateSelected(classRooms);

    set({ classRooms, totalStudents, selectedStudents });
  },
  reset: () => set(getInitValuesState()),
  apply: async () => {
    if (get().resources.length > 0) {
      const assignmentService: AssignmentService = createStore.inject(AssignmentService);

      try {
        await assignmentService.create(get());
      } catch (error) {
        useNotifications.getState().showError({ label: 'Assignments have not been created' });

        return;
      }

      let showWarn: boolean = false;

      const { classRooms, studentsAssigned } = get();

      classRooms.forEach((classRoom: IClassRoom) => {
        classRoom.students.forEach((student: IStudent) => {
          if (student.selected && studentsAssigned[student.id] && studentsAssigned[student.id].length > 0) {
            showWarn = true;
          }
        });
      });

      if (showWarn) {
        useNotifications.getState().showWarn({ label: 'Some assignments were not created because they are already assigned.' });
      } else {
        useNotifications.getState().showSuccess({ label: 'Assignment created successfully!' });
      }

      get().reset();
    }
  },

  startUpdate: (
    updateAssignmentId: string,
    updateStartDate: DateTime | string,
    updateDueDate: DateTime | string | void | null,
    updateEntityType: string
  ) => {
    console.log('Original Start Date -> ', updateStartDate);

    if (typeof updateStartDate === 'string') {
      updateStartDate = DateTime.fromISO(updateStartDate, { zone: 'utc' });
      console.log('Updated start date -> ', updateStartDate.toFormat('yyyy-MM-dd'), updateStartDate)
    }

    if (typeof updateDueDate === 'string') {
      updateDueDate = DateTime.fromISO(updateDueDate, { zone: 'utc' });
    }

    set({ isUpdateOpened: true, updateAssignmentId, updateStartDate, updateDueDate, updateEntityType })
  },

  applyUpdate: async () => {
    const assignmentService: AssignmentService = createStore.inject(AssignmentService);
    const gradebookService: GradebookService = createStore.inject(GradebookService);
    const { updateAssignmentId, updateStartDate, updateDueDate, closeUpdate, updateEntityType } = get();

    if (updateAssignmentId && updateStartDate) {
      let result: any;

      try {
        result = await assignmentService.update(updateAssignmentId, updateStartDate, updateDueDate);

        useNotifications.getState().showSuccess({ label: 'Assignment update successfully!' });
      } catch (err: any) {
        if (err?.status === 400) {
          useNotifications.getState().showWarn({ label: 'Unable to update assignment. Please ensure that the new "Assignment begins" and/or "Assignment is due" date are not in the past.' });
        } else {
          useNotifications.getState().showWarn({ label: 'Unable to update assignment. Please try again in a few minutes.' });
        }

        return;
      }

      if (updateEntityType === 'classroom') {
        gradebookService.eachClassroom(useGradebook.getState().gradebook, (res, student, classroom) => {
          if (res.assignmentId === updateAssignmentId) {
            const pastDue = !!result.pastDue;

            gradebookService.formatDueDate(res, updateStartDate, updateDueDate);

            res.pastDue = pastDue;

            if (pastDue) {
              student.stats.pastDue = true;
              classroom.stats.pastDue = true;
            } else {
              // check if other studens have pastDue
              const resPastDue = student.results.filter((r: any) => r.pastDue);

              if (resPastDue.length === 0) {
                student.stats.pastDue = false;
              }

              const studPastDue = classroom.students.filter((s: any) => s.stats.pastDue);

              if (studPastDue.length === 0) {
                classroom.stats.pastDue = false;
              }
            }
          }
        });
      } else if (updateEntityType === 'lesson') {
        gradebookService.eachLesson(useGradebook.getState().gradebook, (student, lesson) => {
          if (student.assignmentId === updateAssignmentId) {
            const pastDue = !!result.pastDue;

            gradebookService.formatDueDate(student, updateStartDate, updateDueDate);

            student.pastDue = pastDue;

            if (pastDue) {
              lesson.stats.pastDue = true;
            } else {
              // check if other studens have pastDue
              const studPastDue = lesson.results.filter((i: any) => i.pastDue);

              if (studPastDue.length === 0) {
                lesson.stats.pastDue = false;
              }
            }
          }
        });
      }

      useGradebook.getState().fetchKpi();

      closeUpdate();
    }
  },

  closeUpdate: () => {
    set({
      isUpdateOpened: false,
      updateAssignmentId: null,
      updateStartDate: null,
      updateDueDate: null,
    });
  }
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useAssignment = useAssignment;
}

export {
  useAssignment,
};
