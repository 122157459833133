import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'jit-nps-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class NpsLogoComponent {}

export {
  NpsLogoComponent,
};
