import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { FiltersService } from '../services/filters.service';
import { ResourceService } from '../services/resource.service';
import { IResources, IResourceEntity } from '../interfaces/resource.interface';
import { IQueryFilters } from '../interfaces/query-filters.interface';
import { useNotifications } from './notification.store';
import { ISearchResourcesState } from '../interfaces/search-resources-state.interface';

const useSearchResources = createStore<ISearchResourcesState>((set, get) => ({
  subjectId: void(0),
  gradeId: void(0),
  domainId: void(0),
  stateId: void(0),
  searchId: void(0),
  sortId: void(0),
  contentType: 'Microlesson',
  assignable: true,

  subjects: [],
  grades: [],
  domains: [],
  states: [],

  resources: { suites: [], count: 0, contentTypes: [] },
  additionalResources: [],

  loadResources: async () => {
    const resourceService: ResourceService = createStore.inject(ResourceService);
    const query: IQueryFilters = get() as IQueryFilters;

    let resources: IResources = { suites: [], count: 0, contentTypes: [] };
    let additionalResources: IResourceEntity[] = [];

    try {
      resources = await resourceService.getResources(query);
      additionalResources = await resourceService.getAdditionalResources(query);
    } catch (err) {
      useNotifications.getState().showError({ label: (err as any).message });
    }

    set({ resources, additionalResources });
  },
  loadSubjects: async () => {
    const filtersService: FiltersService = createStore.inject(FiltersService);
    const { contentType } = get();

    const subjects = await filtersService.getSubjects({ contentType, withSubscription: 1 });

    set({ subjects });
  },

  setSubjectId: async (subjectId: string | void) => {
    const filtersService: FiltersService = createStore.inject(FiltersService);
    const { contentType } = get();

    if (subjectId) {
      set({
        subjectId, gradeId: void(0), domainId: void(0), stateId: void(0),
        domains: [], states: [],
      });

      const grades = await filtersService.getGrades({ subjectId, contentType });

      set({ grades, domains: [], states: [] });
    } else {
      set({
        subjectId: void(0), gradeId: void(0), domainId: void(0), stateId: void(0),
        grades: [], domains: [], states: [],
      });
    }

    get().loadResources();
  },
  setGradeId: async (gradeId: string | void) => {
    const filtersService: FiltersService = createStore.inject(FiltersService);
    const { subjectId, contentType } = get();

    if (subjectId && gradeId) {
      const domains = await filtersService.getDomains({ subjectId, gradeId, contentType });

      set({ gradeId, domains });
    } else {
      set({
        gradeId: void(0), domainId: void(0), stateId: void(0),
        domains: [], states: [],
      });
    }

    get().loadResources();
  },
  setDomainId: async (domainId: string | void) => {
    const filtersService: FiltersService = createStore.inject(FiltersService);
    const { subjectId, gradeId, contentType } = get();

    if (subjectId && gradeId && domainId) {
      const states = await filtersService.getStates({ subjectId, gradeId, domainId, contentType });

      set({ domainId, states });
    } else {
      set({
        domainId: void(0), stateId: void(0),
        states: [],
      });
    }

    get().loadResources();
  },
  setStateId: (stateId: string | void) => {
    set({ stateId });

    get().loadResources();
  },
  setSearchId: (searchId: string | number | void) => {
    set({ searchId });

    get().loadResources();
  },
  setSortId: (sortId: string | void) => {
    set({ sortId });

    get().loadResources();
  },

  reset: () => {
    set({
      subjectId: void(0),
      gradeId: void(0),
      domainId: void(0),
      stateId: void(0),
      searchId: void(0),
      sortId: void(0),
      grades: [],
      domains: [],
      states: [],
      resources: { suites: [], count: 0, contentTypes: [] },
    });
  },

  init: () => {
    const state = get();

    state.reset();

    if (state.subjects.length === 0) {
      state.loadSubjects();
    }

    state.loadResources();
  },
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useSearchResources = useSearchResources;
}

export {
  ISearchResourcesState,
  useSearchResources,
};
