import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnChanges, inject, SimpleChanges } from '@angular/core';
import { BaseComponent, IAssignedStudentsItemResponse } from '@jit/data-layer';
import { IEnvironment, ENV } from '../../models/environment';

@Component({
  selector: 'jit-resource-small-item',
  templateUrl: './resource-small-item.component.html',
  styleUrls: ['./resource-small-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ResourceSmallItemComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() 
  resource!: IAssignedStudentsItemResponse;

  private _env: IEnvironment = inject(ENV);

  public thumb: string = '';

  ngOnInit(): void {
    this.init(this.resource);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['resource'] && changes['resource'].currentValue !== changes['resource'].previousValue) {
      this.init(changes['resource'].currentValue);
    }
  }

  init(resource: IAssignedStudentsItemResponse): void {
    this.thumb = resource.thumbnail_path || '';

    if (!this.thumb.includes('https://') && !this.thumb.includes('http://')) {
      this.thumb = this._env.assetsDomain + this.thumb;
    }
  }

}

export {
  ResourceSmallItemComponent,
};
