import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { useNps } from '@jit/data-layer';
import { NpsBannerButtonComponent } from '../banner-button/banner-button.component';
import { NpsCloseButtonComponent } from '../close-button/close-button.component';
import { NpsLogoComponent } from '../logo/logo.component';

@Component({
  selector: 'jit-nps-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NpsBannerButtonComponent,
    NpsCloseButtonComponent,
    NpsLogoComponent,
  ],
})
class NpsBannerComponent {

  @useNps('buttons')
  buttons: number[] = [];

  @useNps('descr')
  descr: string = '';

  @useNps('question')
  question: string = '';

  onButton(at: number): void {
    useNps.getState().setRating(at);
  }

  onClose(): void {
    useNps.getState().dismiss();
  }

}

export {
  NpsBannerComponent,
};
