<div 
  #root
  class="jit-simple-select"
  [class.is-opened]="state.isOpened()"
  [class.is-disabled]="state.disabled()"
  [class.is-focused]="state.isFocused()"
  [tabIndex]="tabIndex ? tabIndex : 1"
  (keydown)="onKeyDown($event)"
>
  <div class="jit-simple-select__container" [class.dropdown]="state.isDropdown()" (click)="onClick()">
    <div class="jit-simple-select__content">
      @if (state.isDropdown()) {
        <span class="jit-simple-select__value dropdown">
          @if (state.isDropdownIcon()) {
            <jit-icon-simple [iconId]="$any(dropdown)"></jit-icon-simple>
          } @else {
            {{ dropdown }}
          }
        </span>
      } @else {
        @if (!state.value() && placeholder) {
          <span class="jit-simple-select__placeholder">
            {{ placeholder }}
          </span>
        }
        @if (state.value()) {
          <span class="jit-simple-select__value">
            {{ state.value()?.title }}
          </span>
        }
      }
    </div>
    @if (showArrow) {
      <div class="jit-simple-select__arrow">
        <jit-icon-simple iconId="caret-down-outline" size="18"></jit-icon-simple>
      </div>
    }
  </div>
  <ul #list class="jit-simple-select__list">
    @for (item of state.options(); track i; let i = $index) {
      <li 
        class="jit-simple-select__item"
        [class.is-active]="state.value()?.id === item.id"
        [class.is-kb-focused]="state.focusedItem() === i"
        [attr.data-id]="item.id ? item.id : i"
        [attr.data-at]="i"
        (click)="onItemClick(item)"
      >
        @if (clearable && item.id === CLEAR_ID) {
          <span class="jit-simple-select__label">
            {{ item.title }}
          </span>
        }
        @if (clearable && item.id !== CLEAR_ID || !clearable) {
          @if (showIcon) {
            <span class="jit-simple-select__icon">
              <jit-icon-simple size="16" iconId="check"></jit-icon-simple>
            </span>
          }
          <span class="jit-simple-select__label">
            {{ item.title }}
          </span>
        }
      </li>
    }
  </ul>
</div>