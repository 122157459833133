import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  AfterContentInit,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlDirective } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'jit-radio-control',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-control.component.html',
  styleUrls: ['./radio-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioControlComponent implements AfterContentInit, OnDestroy {
  private _destroy = new Subject<void>();

  @Input() value!: string;
  @HostBinding('class.checked') checked = false;
  @ContentChild(FormControlDirective) control?: FormControlDirective;
  @ContentChild(FormControlDirective) controlName?: FormControlDirective;

  ngAfterContentInit(): void {
    const control = this.control || this.controlName;
    const currentValue = this.control?.value || this.controlName?.value;
    this.checked = currentValue === this.value;

    this.control?.valueChanges
      ?.pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        this.checked = this.value === value;
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
