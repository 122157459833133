<section class="wrapper">
  <header>
    <h3 class="title">{{ title }}</h3>
  </header>

  <p class="content">{{ content }}</p>

  <footer class="actions">
    <button
      class="button jit-primary-btn-outline"
      type="button"
      (click)="onReject()"
    >
      {{ action.reject }}
    </button>
    <button class="button danger" type="button" (click)="onAccept()">
      {{ action.accept }}
    </button>
  </footer>
</section>
