import {
  Component, ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RightSidebarComponent,
  ModalDialogComponent,
  BottomSidebarComponent,
  NpsBannerComponent,
  NpsQuestionsComponent,
} from '@jit/shared';
import { useAssignment, useStates, useNps } from '@jit/data-layer';
import { AssignmentContentComponent } from '../assignment/content/content.component';
import { AssignmentRelatedComponent } from '../assignment/related/related.component';
import { AssignmentStudentsComponent } from '../assignment/students/students.component';
import { AssignmentSearchComponent } from '../assignment/search/search.component';
import { AssignmentUpdateContentComponent } from '../assignment/update-content/update-content.component';
import { SelectStateComponent } from '../select-state/select-state.component';

@Component({
  selector: 'jit-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RightSidebarComponent,
    BottomSidebarComponent,
    AssignmentContentComponent,
    AssignmentRelatedComponent,
    AssignmentStudentsComponent,
    AssignmentSearchComponent,
    AssignmentUpdateContentComponent,
    ModalDialogComponent,
    SelectStateComponent,
    NpsBannerComponent,
    NpsQuestionsComponent,
  ],
})
class ModalsComponent {

  @ViewChild('dialogSelectState', { static: false })
  dialogSelectState!: ModalDialogComponent;

  @useAssignment('isOpened')
  isAssigmentOpened: boolean = false;

  @useAssignment('isInternalOpened')
  isAssigmentInternalOpened: boolean = false;

  @useAssignment('internalStep')
  assignmentInternalStep: string | null = null;

  @useAssignment('isUpdateOpened')
  isUpdateOpened: boolean = false;

  @useNps('isOpened')
  isNpsOpened: boolean = false;

  @useNps('isQuestionsOpened')
  isQuestionsOpened: boolean = false;

  @useStates('isOpened')
  onOpenSelectStateDialog(isOpened: boolean): void {
    if (isOpened) {
      this.dialogSelectState.open();
    }
  }

  @useStates('isClosed')
  onCloseSelectStateDialog(isClosed: boolean): void {
    if (isClosed) {
      this.dialogSelectState.close();
    }
  }

}

export {
  ModalsComponent,
};
