import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { IconId } from '../../models';

@Component({
  selector: 'jit-course-duration',
  templateUrl: './course-duration.component.html',
  styleUrls: ['./course-duration.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconSimpleComponent],
})
export class CourseDurationComponent implements OnInit {
  @Input() duration: string | null = '';

  icon?: IconId;

  ngOnInit(): void {
    this.icon = this.getIconId();
  }

  parse(duration: string) {
    return duration.replace('<', '< ');
  }

  private getIconId(): IconId {
    if (this.duration?.includes('<')) {
      return 'clock-min';
    }

    if (this.duration?.includes('-')) {
      return 'clock-15';
    }

    if (this.duration?.includes('+')) {
      return 'clock';
    }

    return 'clock';
  }
}
