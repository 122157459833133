<div 
  #root 
  class="jit-search-input"
  [class.is-opened]="state.isOpened()"
  (keydown)="onKeyDown($event)"
>
  <div class="jit-search-input__wrap-input">
    <input 
      class="jit-search-input__input"
      [placeholder]="placeholder || ''"
      [name]="name || ''"
      [tabIndex]="tabIndex"
      [value]="state.value()"
      (input)="onChange($event)"
      (keypress)="onKeyPress($event)"
      (click)="onClick()"
    />
  </div>
  <div *ngIf="showButton" class="jit-search-input__wrap-button">
    <button class="jit-search-input__button" (click)="onSubmit()">
      <jit-icon-simple size="32" iconId="search-outline"></jit-icon-simple>
    </button>
  </div>
  <ul #list class="jit-search-input__list">
    <li 
      *ngFor="let item of options; let i = index"
      class="jit-search-input__item"
      [class.is-active]="state.value() === item"
      [class.is-kb-focused]="state.focusedItem() === i"
      [attr.data-at]="i"
      (click)="onItemClick(item)"
    >
      <ng-container>
        <div class="jit-search-input__title">
          <span class="jit-search-input__icon">
            <jit-icon-simple size="24" iconId="clock-filled"></jit-icon-simple>
          </span>
          <span 
            class="jit-search-input__label"
            [innerHTML]="item | searchMatch : state.value() | safeHtml"
          ></span>
        </div>
      </ng-container>
    </li>
  </ul>
</div>