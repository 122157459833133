import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StopwatchPipe } from '../../../../pipes';
import { ReportIconsComponent } from '../../icons/icons.component';
import { TagComponent } from '../../tag/tag.component';
import { ReportQuestionBooleanComponent } from '../boolean/boolean.component';
import { ReportQuestionFibDropdownComponent } from '../fib-dropdown/fib-dropdown.component';
import { ReportQuestionFibTilesComponent } from '../fib-tiles/fib-tiles.component';
import { ReportQuestionFibTypedComponent } from '../fib-typed/fib-typed.component';
import { ReportQuestionMatchingComponent } from '../matching/matching.component';
import { ReportQuestionMultiSelectComponent } from '../multi-select/multi-select.component';
import { ReportQuestionSequencingComponent } from '../sequencing/sequencing.component';
import { ReportQuestionSingleSelectComponent } from '../single-select/single-select.component';
import { ReportQuestionSortingComponent } from '../sorting/sorting.component';
import { ReportAnswerTypes, ReportQuestionTypes } from '../question.enum';

@Component({
  selector: 'jit-report-question-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    StopwatchPipe,
    ReportIconsComponent,
    TagComponent,
    ReportQuestionBooleanComponent,
    ReportQuestionFibDropdownComponent,
    ReportQuestionFibTilesComponent,
    ReportQuestionFibTypedComponent,
    ReportQuestionMatchingComponent,
    ReportQuestionMultiSelectComponent,
    ReportQuestionSequencingComponent,
    ReportQuestionSingleSelectComponent,
    ReportQuestionSortingComponent,
  ],
})
class ReportQuestionContainerComponent {
  
  // TODO Inputs can be replaced by Store data

  @Input()
  idx: number = 0;

  @Input()
  code: string = '';

  @Input()
  questionType!: ReportQuestionTypes;
  
  @Input()
  answerType!: ReportAnswerTypes;

  @Input()
  title: string = '';

  @Input()
  time: number = 0;

  ReportAnswerTypes: typeof ReportAnswerTypes = ReportAnswerTypes;
  ReportQuestionTypes: typeof ReportQuestionTypes = ReportQuestionTypes;

}

export {
  ReportQuestionContainerComponent,
};
