import { InjectionToken } from '@angular/core';

export interface IEnvironment {
  envName: string;
  idServerUrl: string;
  apiUrl: string;
  ga4Key: string;
  gtmKey: string;
  debugLevel: string;
  maxLogStash: number;
  logToServer: boolean;
  sendLogsAfter: number;
  parentHomePage: string;
  showStatus: boolean;
  assetsDomain: string;
  microlessonDomain: string;
  features: {
    sandbox: boolean;
    assignment: boolean;
    assignmentCsv: boolean;
  };
}

export const ENV = new InjectionToken<IEnvironment>('env');
