import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { useUser } from '../../data-layer/stores/user.store';

type TResolve = (init: boolean) => void;
type TReject = (e: Error) => void;
type TTimeHasLeft = () => boolean;

@Injectable({
  providedIn: 'root' 
})
export class AssignmentGuard implements CanActivate {

  private _timeHasLeft(): () => boolean {
    const past = new Date().getTime();
    const oneMin = 1000 * 60 * 1; // one minute for checking 
    
    return () => ((new Date().getTime() - past) > oneMin);
  }

  private _isUserInitialized(resolve: TResolve, reject: TReject, timeHasLeft: TTimeHasLeft): void {
    const state = useUser.getState();

    if (state.initialized) {
      resolve(state.allowCreateAssignment);
    } else {
      if (timeHasLeft()) {
        reject(new Error('AssignmentGuard: user has not initialized more that one minute'));
      } else {
        setTimeout(() => this._isUserInitialized(resolve, reject, timeHasLeft), 200);
      }
    }
  }

  async canActivate(): Promise<boolean> {
    return new Promise((resolve: TResolve, reject: TReject) => {
      this._isUserInitialized(resolve, reject, this._timeHasLeft());
    });
  }

}
