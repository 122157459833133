<div class="search-container">
  <div class="search-field-wrapper">
    <mat-form-field class="search-field-form" floatLabel="always">
      <mat-label>Optionally search keywords, standards and more...</mat-label>
      <input
        class="search-input"
        type="text"
        matInput
        [formControl]="control"
        placeholder="Ex. “Fluency” or “main idea” or “CCSS.ELA-LITERACY.RI.1.1”"
        [matAutocomplete]="auto"
      />
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete">
      <ng-scrollbar [autoHeightDisabled]="false">
        <mat-option
          *ngFor="let option of filterOptions$ | async | filter : control.value"
          [value]="option"
        >
          <jit-icon-simple
            class="clock"
            iconId="clock-filled"
          ></jit-icon-simple>
          <span
            [innerHTML]="option | searchMatch : control.value | safeHtml"
          ></span>

          <button
            type="button"
            class="button remove"
            (click)="onRemove(option, $event)"
          >
            <jit-icon-simple iconId="close"></jit-icon-simple>
          </button>
        </mat-option>

        <mat-option [disabled]="true" class="empty-item">
          Empty list
        </mat-option>
      </ng-scrollbar>
    </mat-autocomplete>
  </div>
  <button type="button" class="button search-button" (click)="onSubmit()">
    <img
      ngSrc="/assets/icons/button_search.svg"
      alt="button-search"
      height="48"
      width="48"
    />
  </button>
</div>
