@if (resources && resources.length > 0) {
  <div 
    class="jit-resource-small-item-list"
    [style.top]="position.top + 'px'"
    [style.right]="position.right + 'px'"
  >
    <div class="jit-resource-small-item-list__wrap">
      @for (resource of resources; track $index) {
        <span>
          @if (resources.length > 1) {
            <span>{{ $index + 1 }}. </span>
          }
          <span>{{ resource.title }}</span>
        </span>
      }
    </div>
  </div>
}