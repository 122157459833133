import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { IGradebookKpi, TGradebook } from '../interfaces/gradebook.interface';
import { IEntityFilters } from '../interfaces/entity-filters.interface';
import { IGradebookState } from '../interfaces/gradebook-state.interface';
import { ISelectItem } from '../interfaces/search-resources-state.interface';
import { FiltersService } from '../services/filters.service';
import { GradebookService } from '../services/gradebook.service';
import { ThSorts } from '../../shared/components/gradebook/th-sort/th-sort.enum';
import { IStudentStats, TByClassroom, IStudentLessonsResult } from '../interfaces/classroom-view.interface';
import { ILessonStats } from '../interfaces/lesson-view.interface';
import { useNotifications } from './notification.store';

const useGradebook = createStore<IGradebookState>((set, get) => ({
  subject: void(0),
  subjects: [],

  view: GradebookService.getDefaultView(),
  views: GradebookService.views,

  period: void(0),
  periods: GradebookService.periods,

  kpi: GradebookService.getEmptyGradebookKpi(),
  search: void(0),
  suggestions: [],

  sort: void(0),
  dir: void(0),

  gradebook: [],

  init: async () => {
    const filtersService: FiltersService = createStore.inject(FiltersService);
    const gradebookService: GradebookService = createStore.inject(GradebookService);
    const queryState: Partial<IGradebookState> = gradebookService.getStateFromQuery();

    const _subjects: IEntityFilters[] = await filtersService.getSubjects();

    const subjects: ISelectItem[] = _subjects.map((i): ISelectItem => ({ id: i.slug || '', title: i.title }));

    const state: Partial<IGradebookState> = gradebookService.mapQueryToStateIds(queryState, [
      subjects,
      GradebookService.views,
      GradebookService.periods,
    ]);

    get().reset();
    set({ ...state, subjects });
    get().fetch();
  },

  fetchKpi: async () => {
    const gradebookService: GradebookService = createStore.inject(GradebookService);
    const state: Partial<IGradebookState> = get();

    gradebookService.stateToUrl(state);
    
    const kpi: IGradebookKpi = await gradebookService.getKpi(state);

    set({ kpi });
  },

  fetch: async () => {
    const gradebookService: GradebookService = createStore.inject(GradebookService);
    const state: Partial<IGradebookState> = get();

    gradebookService.stateToUrl(state);
    
    const [ gradebook, kpi ]: [TGradebook, IGradebookKpi] = await Promise.all([
      gradebookService.getGradebook(state),
      gradebookService.getKpi(state)
    ]);

    set({ gradebook, kpi });
  },

  reset: () => {
    set({
      subject: void(0),
      view: GradebookService.views[0],
      period: void(0),
      kpi: GradebookService.getEmptyGradebookKpi(),
      search: void(0),
      suggestions: [],
      gradebook: [],
    });
  },

  csv: () => {
    const gradebookService: GradebookService = createStore.inject(GradebookService);
    const state: Partial<IGradebookState> = get();

    gradebookService.stateToUrl(state);
    gradebookService.exportToCsv(state);
  },

  /**
   * Run flow to unassign by Classroom and student
   */
  unassignClassroom: async (lesson: IStudentLessonsResult) => {
    if (lesson.assignmentId) {
      const gradebookService: GradebookService = createStore.inject(GradebookService);
      const gradebook: TByClassroom[] = get().gradebook as TByClassroom[];
      const fetchKpi = get().fetchKpi;
  
      try {
        await gradebookService.unassign(lesson.assignmentId);

        useNotifications.getState().showSuccess({ label: 'Assignment removed' });
      } catch (err) {
        useNotifications.getState().showError({ label: 'Action failed. Please try again later.' });

        return;
      }
  
      for (let classRoom of gradebook) {
        let isBreak: boolean = false;

        for (let i = 0; classRoom.students[i]; i++) {
          const st = classRoom.students[i];

          let isBreakSt = false;

          for (let k = 0; st.results[k]; k++) {
            const res = st.results[k];

            if (res.assignmentId === lesson.assignmentId) {
              st.results.splice(k, 1);

              isBreakSt = true;
              
              break;
            }
          }

          if (isBreakSt) {
            isBreak = true;

            break;
          }
        }

        if (isBreak) {
          break;
        }
      }

      fetchKpi();
    }
  },

  /**
   * Run flow to unassign by Lesson and student
   */
  unassignLesson: async (student: IStudentStats) => {
    if (student.assignmentId) {
      const gradebookService: GradebookService = createStore.inject(GradebookService);
      const gradebook: ILessonStats[] = get().gradebook as ILessonStats[];
      const fetchKpi = get().fetchKpi;

      try {
        await gradebookService.unassign(student.assignmentId);

        useNotifications.getState().showSuccess({ label: 'Assignment removed' });
      } catch (err) {
        useNotifications.getState().showError({ label: 'Action failed. Please try again later.' });

        return;
      }

      for (let res of gradebook) {
        let isBreak: boolean = false;

        for (let i = 0; res.results[i]; i++) {
          const st = res.results[i];

          if (st.assignmentId === student.assignmentId) {
            res.results.splice(i, 1);
            
            isBreak = true;
            
            break;
          }
        }

        if (isBreak) {
          break;
        }
      }

      fetchKpi();
    }
  },

  setSubject: (subject: ISelectItem | void) => {
    set({ subject });

    get().fetch();
  },

  setView: (view: ISelectItem) => {
    set({ view });

    get().fetch();
  },

  setPeriod: (period: ISelectItem | void) => {
    set({ period });

    get().fetch();
  },

  setSearch: async (search: string | void) => {
    const gradebookService: GradebookService = createStore.inject(GradebookService);

    let suggestions: string[] = [];

    set({ search });
    
    if (search) {
      suggestions = await gradebookService.getSuggestions(get());
    }
  
    set({ suggestions });
    get().fetch();
  },

  setSortStudent: (name: string, direction: ThSorts, student: IStudentStats) => {
    const gradebookService: GradebookService = createStore.inject(GradebookService);

    gradebookService.sortAssignmentBy(name, direction, student);
  },

  setSortLesson: (name: string, direction: ThSorts, lesson: ILessonStats) => {
    const gradebookService: GradebookService = createStore.inject(GradebookService);

    gradebookService.sortLessonBy(name, direction, lesson);
  },

  setSortClassRoom: (direction: ThSorts) => {
    if (direction === ThSorts.NONE) {
      set({ sort: void(0), dir: void(0) });
    } else {
      set({ sort: 'classroom', dir: direction.toUpperCase() });
    }

    get().fetch();
  },

  setSortLessons: (direction: ThSorts) => {
    if (direction === ThSorts.NONE) {
      set({ sort: void(0), dir: void(0) });
    } else {
      set({ sort: 'lesson', dir: direction.toUpperCase() });
    }

    get().fetch();
  },

}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useGradebook = useGradebook;
}

export {
  useGradebook,
};
