import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { 
  BaseComponent, useAssignment, useSearchResources, useSuggestions,
  IResourceEntity, IResources, ResourceService,
  ISeriesEntity,
} from '@jit/data-layer';
import { 
  IconSimpleComponent, ResourceItemComponent, SimpleSelectComponent, 
  SearchInputComponent, TreeviewResourcesComponent, ResourceItemActions,
} from '@jit/shared';
import { ISelectItem } from '../../../shared/components/simple-select/simple-select.interfaces';

@Component({
  selector: 'jit-assignment-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ResourceItemComponent,
    SimpleSelectComponent,
    SearchInputComponent,
    TreeviewResourcesComponent,
  ],
})
class AssignmentSearchComponent extends BaseComponent implements OnInit {

  @useAssignment('resources')
  resources: IResourceEntity[] = [];

  @useSearchResources('subjects')
  subjects: ISelectItem[] = [];

  @useSearchResources('grades')
  grades: ISelectItem[] = [];

  @useSearchResources('domains')
  domains: ISelectItem[] = [];

  @useSearchResources('states')
  states: ISelectItem[] = [];

  @useSearchResources('subjectId')
  subjectId: string | void = void(0);

  @useSearchResources('gradeId')
  gradeId: string | void = void(0);

  @useSearchResources('domainId')
  domainId: string | void = void(0);

  @useSearchResources('resources')
  searchResources!: IResources;

  @useSuggestions('suggestions')
  suggestions: string[] = [];

  @ViewChild('refGrade', { static: false })
  refGrade!: SimpleSelectComponent;

  @ViewChild('refDomain', { static: false })
  refDomain!: SimpleSelectComponent;

  private _resourceService: ResourceService = inject(ResourceService);

  public isSelected: boolean = false;
  public selected: Record<string, IResourceEntity> = {};
  public ResourceItemActions: typeof ResourceItemActions = ResourceItemActions;
  public allResources!: IResources;
  public allAdditionalResources!: IResourceEntity[];
  public allAdditionalCount: number = 0;

  @useAssignment('resources')
  onSelectedResources(selectedResources: IResourceEntity[]): void {
    this._filterSelected(this.searchResources, selectedResources);
  }

  @useSearchResources('resources')
  onResources(resources: IResources): void {
    this.isSelected = false;
    this.selected = {};

    this._filterSelected(resources, this.resources);
  }

  @useSearchResources('subjectId')
  onSubjectId(id: string | void): void {
    if (!id) {
      this.refGrade.reset();
      this.refDomain.reset();
    }
  }

  @useSearchResources('gradeId')
  onGradeId(id: string | void): void {
    if (!id) {
      this.refDomain.reset();
    }
  }

  ngOnInit(): void {
    useSearchResources.getState().init();
  }

  private _filterSelected(resources: IResources, selectedResources: IResourceEntity[] = []): void {
    if (resources && selectedResources) {
      for (let s = resources.suites.length - 1; s >= 0; s--) {
        const suite = resources.suites[s];

        for (let b = suite.series.length - 1; b >= 0; b--) {
          const bundle = suite.series[b];

          for (let r = bundle.resources.length - 1; r >= 0; r--) {
            const resource = bundle.resources[r];
            const at = selectedResources.findIndex((res) => (res.id === resource.id));

            if (at >= 0) {
              bundle.resources.splice(r, 1);
            }
          }

          if (bundle.resources.length === 0) {
            suite.series.splice(b, 1);
          }
        }

        if (suite.series.length === 0) {
          resources.suites.splice(s, 1);
        }
      }
    }

    const additionalResources: IResourceEntity[] = [ ...(useSearchResources.getState().additionalResources || []) ];

    for (let i = additionalResources.length - 1; i >= 0; i--) {
      const resource: IResourceEntity = additionalResources[i];
      const at = selectedResources.findIndex((res) => (res.id === resource.id));

      if (at >= 0) {
        additionalResources.splice(i, 1);
      }
    }

    this.allResources = resources;
    this.allAdditionalResources = additionalResources;
  }

  onBack(): void {
    useAssignment.getState().backRelated();
    useSearchResources.getState().reset();
  }

  onApply(): void {
    if (this.isSelected) {
      useAssignment.getState().addRelated(Object.values(this.selected));
      useSearchResources.getState().reset();
    }
  }

  onFilterChanged(name: string, value: void | ISelectItem): void {
    (useSearchResources.getState() as any)[name](value ? (value['slug'] || value['value'] || value.id) : void(0));

    if (name === 'setSubjectId') {
      this.refGrade.reset();
      this.refDomain.reset();
    } else if (name === 'setGradeId') {
      this.refDomain.reset();
    }
  }

  private _updateIsSelected(): void {
    const len: number = Object.keys(this.selected).length;

    this.isSelected = (len > 0);
  }

  onSearchChanged(value: string): void {
    useSuggestions.getState().setSearchId(value || '');
    useSearchResources.getState().setSearchId(value || void(0));
  }

  onAdd(resource: IResourceEntity, bundle: ISeriesEntity): void {
    this.selected[resource.id] = resource;

    bundle.selected = (bundle.selected || 0) + 1;

    this._updateIsSelected();
  }

  onAddAdditional(resource: IResourceEntity): void {
    this.selected[resource.id] = resource;

    this.allAdditionalCount = this.allAdditionalCount + 1;

    this._updateIsSelected();
  }

  onAdded(resource: IResourceEntity, bundle: ISeriesEntity): void {
    delete this.selected[resource.id];

    bundle.selected = (bundle.selected || 1) - 1;

    this._updateIsSelected();
  }

  onAddedAdditional(resource: IResourceEntity): void {
    delete this.selected[resource.id];

    this.allAdditionalCount = this.allAdditionalCount - 1;

    this._updateIsSelected();
  }

  onBundleSelectAll(bundle: ISeriesEntity, value: boolean): void {
    bundle.selected = value ? bundle.resources.length : 0;
    
    bundle.resources.forEach((resource) => {
      if (value) {
        this.selected[resource.id] = resource;
      } else {
        delete this.selected[resource.id];
      }
    });

    this._updateIsSelected();
  }

  onAdditionalSelectAll(value: boolean): void {
    this.allAdditionalCount = value ? this.allAdditionalResources.length : 0;

    this.allAdditionalResources.forEach((resource) => {
      if (value) {
        this.selected[resource.id] = resource;
      } else {
        delete this.selected[resource.id];
      }
    });

    this._updateIsSelected();
  }

  onContentClick(item: IResourceEntity): void {
    this._resourceService.openMicrolesson(item);
  }

}

export {
  AssignmentSearchComponent,
};
