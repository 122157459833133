import { IStudent } from './student.interface';

interface IClassRoom {
  id: string;
  name: string;
  students: IStudent[];
  selected: boolean;
  changed: boolean;
}

export {
  IClassRoom,
};
