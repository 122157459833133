import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from './logger.service';

const skipVendorMessages: string[][] = [];

export class LoggerErrorHandler implements ErrorHandler {
  
  constructor(private loggerService: LoggerService) {}

  private _hasVendorError(err: Error | HttpErrorResponse | string): boolean {
    let stack: string = '';
    let hasError: boolean = false;

    if (typeof err === 'string') {
      stack = err;
    } else if (err && err instanceof Error && err.stack) {
      stack = err.stack;
    }

    if (stack) {
      for (const keys of skipVendorMessages) {
        for (const key of keys) {
          if (stack.includes(key)) {
            hasError = true;
            break;
          }
        }

        if (hasError) {
          break;
        }
      }
    }

    return hasError;
  }

  handleError(err: Error | HttpErrorResponse | string): void {
    // Skip http errors. They are caught by LoggerInterceptor
    if (!(err instanceof HttpErrorResponse) && !this._hasVendorError(err)) {
      this.loggerService.error(err);
    }

    console.error(err);
  }
}
