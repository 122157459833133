<li class="jit-notification-item {{ item.type }}">
  <div class="jit-notification-item__content">
    @if (item.type === NotificationType.INFO) {
      <jit-icon-simple iconId="info" size="24"></jit-icon-simple>
    } @else {
      <span class="icon-check">
        @if (item.type === NotificationType.WARN || item.type === NotificationType.ERROR) {
          !
        } @else {
          <jit-icon-simple iconId="check" size="16"></jit-icon-simple>
        }
      </span>
    }
    <span class="label">
      {{ item.label }}
    </span>
  </div>
  <div class="jit-notification-item__actions">
    <span class="label" (click)="onClose()">dismiss</span>
  </div>
</li>