import { environment } from '../../../environments/environment';
import { createStore } from '../store';

interface IResourceState {
  sortDirection: string | void | null;
  isClear: number;

  setSort: (sortDirection: string | void | null) => void;
  clear: () => void;
}

const useResourse = createStore<IResourceState>((set, get) => ({
  sortDirection: void(0),
  isClear: 0,

  setSort: (sortDirection: string | void | null) => set({ sortDirection }),
  clear: () => set({ isClear: get().isClear + 1 }),
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useResourse = useResourse;
}

export {
  IResourceState,
  useResourse,
};
