<div 
  class="jit-checkbox" 
  [class.checked]="value" 
  [class.not-checked]="!value" 
  [class.disabled]="disabled"
  (click)="onChange()"
>
  <div 
    class="jit-checkbox__wrap"
    [class.checked]="value" 
    [class.not-checked]="!value" 
    [class.disabled]="disabled"
  >
    @if (value) {
      <jit-icon-simple size="24" iconId="small-check" [class.disabled]="disabled"></jit-icon-simple>
    } @else {
      <span class="jit-checkbox__empty" [class.disabled]="disabled">&nbsp;</span>
    }
  </div>
  @if (title) {
    <label class="jit-checkbox__title" [class.disabled]="disabled">{{ title }}</label>
  }
</div>