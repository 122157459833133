import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { useFeatures } from '../../data-layer/stores/features.store';

@Injectable({
  providedIn: 'root' 
})
export class SandboxGuard implements CanActivate {

  async canActivate(): Promise<boolean> {
    return useFeatures.getState().sandbox || false;
  }

}
