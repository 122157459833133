import { Attribute } from './resource';

export type FilterResponse = Attribute;

export type FilterOption = {
  label: string;
  key: string;
  available?: boolean;
};

export type Filter = {
  name: string;
  key: string;
  id: string;
  isRange: boolean;
  isSingle: boolean;
  options: FilterOption[];
};

export type Pill = {
  key: string;
  label: string;
  filterKey: string;
};
