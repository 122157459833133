<mat-accordion multi>
  <ng-container *ngFor="let row of view">
    <mat-expansion-panel
      hideToggle
      *ngIf="row.suite.id"
      (opened)="row.opened = true"
      (closed)="row.opened = false"
      [ngClass]="getPanelClasses(row.suite)"
    >
      <mat-expansion-panel-header
        [ngClass]="getHeaderClasses(row.suite, row.opened)"
      >
        <mat-panel-title>
          <jit-icon-simple
            class="arrow"
            [class.arrow--down]="row.opened"
            iconId="chevron"
          ></jit-icon-simple>

          <jit-icon-simple iconId="suite"></jit-icon-simple>

          <a
            [routerLink]="['/', 'detail-page', row.suite.id, 'suite']"
            class="link name"
          >
            {{ row.suite.title }}
          </a>
        </mat-panel-title>

        <div *ngIf="row.opened" class="sort-wrap">
          <span class="sort-wrap-title">Sort by</span>
          <jit-sort (changed)="onSortChange($event, row)"></jit-sort>
        </div>

        <jit-collection-button
          *ngIf="row.suite.id"
          [item]="$any(row.suite)"
        ></jit-collection-button>
      </mat-expansion-panel-header>

      <div class="content">
        <h3 class="title">{{ row.suite.title }}</h3>
        <p class="description">{{ row.suite.description }}</p>
        <!-- <ul class="list list--horizontal">
          <li
            *ngFor="let tag of row.suite.tags"
            class="badge badge--primary"
            [ngClass]="'badge--' + row.suite.colorName"
          >
            {{ tag }}
          </li>
        </ul> -->
      </div>

      <jit-bundle-list
        #bundleList
        [hideType]="true"
        [bundles]="row.suite.bundles"
        [id]="row.suite.id"
      ></jit-bundle-list>
    </mat-expansion-panel>

    <ng-container *ngIf="!row.suite.id">
      <jit-bundle-list
        #bundleList
        [hideType]="true"
        [bundles]="row.suite.bundles"
        [id]="row.suite.id"
      ></jit-bundle-list>
    </ng-container>
  </ng-container>
</mat-accordion>
