import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DateTime } from 'luxon';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent, CalendarInputComponent, TooltipDirective } from '@jit/shared';
import { useAssignment } from '@jit/data-layer';

@Component({
  selector: 'jit-assignment-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    CalendarInputComponent,
    TooltipDirective,
  ],
})
class AssignmentHeaderComponent extends BaseComponent {

  @useAssignment('startDate')
  startDate!: DateTime;

  @useAssignment('dueDate')
  dueDate!: DateTime | void | null;

  @useAssignment('classRooms')
  classRooms: any[] = [];
  
  @useAssignment('totalStudents')
  totalStudents: number = 0;

  @useAssignment('selectedStudents')
  selectedStudents: number = 0;

  @useAssignment('hasStudentsAssigned')
  hasStudentsAssigned: boolean = false;

  warnLabel: string = 'Some lessons are already assigned';

  onChangeStartDate(startDate: DateTime): void {
    useAssignment.getState().setStartDate(startDate);
  }

  onChangeDueDate(dueDate: DateTime): void {
    useAssignment.getState().setDueDate(dueDate);
  }

  onClickStudents(): void {
    useAssignment.getState().openStudents();
  }

}

export {
  AssignmentHeaderComponent,
};
