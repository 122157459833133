<div class="jit-assignment-header">
  <div class="jit-assignment-header__item">
    <div class="jit-assignment-header__item-left">
      <h1>Edit Details</h1>
    </div>
  </div>
  <div class="jit-assignment-header__item">
    <div class="jit-assignment-header__item-left">
      <h2>Assignment begins</h2>
    </div>
    <div class="jit-assignment-header__item-right">
      <jit-calendar-input [value]="updateStartDate" (change)="onChangeStartDate($event)"></jit-calendar-input>
    </div>
  </div>
  <div class="jit-assignment-header__item">
    <div class="jit-assignment-header__item-left">
      <h2>Assignment is due <span>(optional)</span></h2>
    </div>
    <div class="jit-assignment-header__item-right">
      <jit-calendar-input [value]="updateDueDate" (change)="onChangeDueDate($event)"></jit-calendar-input>
    </div>
  </div>
</div>