import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseComponent, IAssignedStudentsItemResponse } from '@jit/data-layer';
import { CourseDurationComponent } from '../course-duration/course-duration.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { TooltipDirective, ResourcesTooltipDirective } from '../../directives';

@Component({
  selector: 'jit-treeview-item',
  templateUrl: './treeview-item.component.html',
  styleUrls: ['./treeview-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    CourseDurationComponent,
    CheckboxComponent,
    TooltipDirective, 
    ResourcesTooltipDirective,
  ],
})
class TreeviewItemComponent extends BaseComponent implements OnInit {

  @Input()
  title: string = '';

  @Input()
  count: number = 0;

  @Input()
  value: boolean = false;

  @Input()
  isCollapsible: boolean = false;

  @Input()
  expanded: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  showWarn: boolean = false;

  @Input()
  warnLabel: string = '';

  @Input()
  warnResources: IAssignedStudentsItemResponse[] = [];

  @Output()
  change: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    
  }

  onChange(): void {
    this.value = !this.value;

    this.change.emit(this.value);
  }

  onToggle(): void {
    this.expanded = !this.expanded;

    this.toggle.emit(this.expanded);
  }

}

export {
  TreeviewItemComponent,
};
