<ng-container *ngIf="(step$ | async) === 0">
  <h3 class="title">Welcome to Teacher Resources!</h3>

  <p class="description">
    Begin by telling us if you want to view your state’s specific resources or
    resources across all states and standards. You can change your selection at
    any time.
  </p>

  <div class="options" *ngIf="stateFilterOptions$ | async as options">
    <div class="option">
      <jit-radio-control value="one">
        <input type="radio" [formControl]="radioControl" value="one" />
      </jit-radio-control>

      <jit-select
        placeholder="Select my state"
        [options]="options"
        [formControl]="stateControl"
      ></jit-select>
    </div>

    <span>or</span>

    <div class="option">
      <jit-radio-control value="all">
        <input type="radio" [formControl]="radioControl" value="all" />

        <span label>View all States/Standards</span>
      </jit-radio-control>
    </div>
  </div>

  <div class="footer">
    <button
      type="button"
      class="btn jit-primary-btn"
      (click)="onContinue()"
      [disabled]="continueDisabled$ | async"
    >
      Continue
    </button>
  </div>
</ng-container>

<ng-container *ngIf="(step$ | async) === 1">
  <h3 class="title">What grades are you interested in?</h3>

  <ng-container *ngIf="gradesFilter$ | async as grades">
    <div class="grades" [formGroup]="gradesForm">
      <jit-checkbox-control
        *ngFor="let grade of grades.options"
        (changed)="onUnsetAllGrades($event)"
      >
        <input type="checkbox" [formControlName]="grade.key" />

        <span label>{{ grade.text }}</span>
      </jit-checkbox-control>

      <jit-checkbox-control (changed)="onSetAllGrades($event)">
        <input type="checkbox" formControlName="all" />

        <span label>All grades</span>
      </jit-checkbox-control>
    </div>
  </ng-container>

  <div class="footer">
    <button
      type="button"
      class="btn jit-primary-btn"
      (click)="onSave()"
      [disabled]="saveDisabled$ | async"
    >
      Let's go!
    </button>
  </div>
</ng-container>
