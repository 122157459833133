import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  inject,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil, filter } from 'rxjs';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { NavigationEnd, Router } from '@angular/router';

interface ISortSelect {
  text: string;
  value: string;
}

const OPTIONS: ISortSelect[] = [
  {text: 'A to Z', value: 'asc'},
  {text: 'Z to A', value: 'desc'},
];

@Component({
  selector: 'jit-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SortComponent,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    IconSimpleComponent,
  ],
})
export class SortComponent implements ControlValueAccessor, OnDestroy, OnInit, OnChanges {
  
  private _destroy = new Subject<void>();
  private _onChange?: (_: any) => void;
  private _onTouched?: any;
  private router = inject(Router);

  @Input() hideDisabled = false;
  @Input() defaultValue?: string;
  @Input() placeholder: string = 'None';
  @Output() changed = new EventEmitter<string | null>();

  @ViewChild('select')
  select?: MatSelect;

  public options: ISortSelect[] = OPTIONS;
  public sortValue = new FormControl<string>('');

  get displayValue() {
    const value = this.sortValue.value;

    return OPTIONS.find((item) => (item.value === value))?.text || '';
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.select?.close();
      });

    if (this.defaultValue) {
      const option = OPTIONS.find((option) => (option.value === this.defaultValue));

      if (option) {
        setTimeout(() => {
          this.sortValue.patchValue(option.value);
        }, 20);
      }
    }
    
    this.sortValue.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        this._onTouched && this._onTouched();
        this._onChange?.(value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultValue']) {
      const value = changes['defaultValue'].currentValue;

      if (value) {
        const option = OPTIONS.find((option) => (option === value));

        if (option) {
          this.sortValue.patchValue(option.value);
        }
      }
    } 
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(obj?: string): void {
    if (obj) {
      this.sortValue.patchValue(obj);
    } else {
      this.sortValue.patchValue(null);
    }
  }

  onSelectionChange() {
    this.changed.emit(this.sortValue.value);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onClick(e: Event) {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
