import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { BaseComponent, useAssignment, IResourceEntity } from '@jit/data-layer';
import { IconSimpleComponent, ModalDialogComponent } from '@jit/shared';

@Component({
  selector: 'jit-assignment-update-footer',
  templateUrl: './update-footer.component.html',
  styleUrls: ['./update-footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ModalDialogComponent,
  ],
})
class AssignmentUpdateFooterComponent extends BaseComponent {

  onApply(): void {
    useAssignment.getState().applyUpdate();
  }

  onCancel(): void {
    useAssignment.getState().closeUpdate();
  }

}

export {
  AssignmentUpdateFooterComponent,
};
