enum ReportAnswerTypes {
  CORRECT_FIRST = 'correct-1',
  CORRECT_SECOND = 'correct-2',
  WRONG = 'wrong',
  NOT_ATTEMPTED = 'not-attempted',
}

enum ReportQuestionTypes {
  BOOLEAN = 'boolean',
  FIB_DROPDOWN = 'fib-dropdown',
  FIB_TYLES = 'fib-tiles',
  FIB_TIPED = 'fib-typed',
  MATCHING = 'matching',
  MULTI_SELECT = 'multi-select',
  SEQUENCING = 'sequencing',
  SINGLE_SELECT = 'single-select',
  SORTING = 'sorting',
}

export {
  ReportAnswerTypes,
  ReportQuestionTypes,
};
