type TFn = (...args: any[]) => any;

interface IIEventEmitterSubscription {
  unsubscribe(): void;
}

const listenersKey = Symbol('listeners');
const onceListenersKey = Symbol('onceListeners');

class EventEmitter {

  private [listenersKey]: Map<string, Set<TFn>> = new Map();
  private [onceListenersKey]: Map<string, Set<TFn>> = new Map();

  on(name: string, fn: TFn): IIEventEmitterSubscription {
    const self = this;
    const listeners = this[listenersKey].get(name) || new Set();

    if (typeof fn !== 'function') {
      throw new Error('Second argument must be a function');
    }

    listeners.add(fn);

    this[listenersKey].set(name, listeners);

    const unsubscribe = () => self.off(name, fn);

    return { unsubscribe };
  }

  once(name: string, fn: TFn): IIEventEmitterSubscription {
    const self = this;
    const listeners = this[onceListenersKey].get(name) || new Set();

    if (typeof fn !== 'function') {
      throw new Error('Second argument must be a function');
    }

    listeners.add(fn);

    this[onceListenersKey].set(name, listeners);

    const unsubscribe = () => self.off(name, fn);

    return { unsubscribe };
  }

  off(name: string, fn: TFn): void {
    const listeners = this[listenersKey].get(name);
    const onceListeners = this[onceListenersKey].get(name);

    if (typeof fn !== 'function') {
      throw new Error('Second argument must be a function');
    }

    if (listeners) {
      listeners.delete(fn);
    }

    if (onceListeners) {
      onceListeners.delete(fn);
    }
  }

  emit(name: string, ...args: any[]): void {
    setTimeout(() => {
      const onceListeners = this[onceListenersKey].get(name);
      const listeners = this[listenersKey].get(name);
      const allListeners = this[listenersKey].get('*');

      if (onceListeners) {
        onceListeners.forEach((fn: TFn) => fn(...args));

        this[onceListenersKey].delete(name);
      }

      if (listeners) {
        listeners.forEach((fn: TFn) => fn(...args));
      }

      if (allListeners) {
        allListeners.forEach((fn: TFn) => fn(name, ...args));
      }
    });
  }

  stopListening(): void {
    this[listenersKey].clear();
    this[onceListenersKey].clear();
  }

}

export {
  TFn,
  EventEmitter,
  IIEventEmitterSubscription,
};
