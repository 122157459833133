import { CommonModule } from '@angular/common';
import { 
  Component, Input, Output, EventEmitter, OnInit, inject, 
  OnChanges, SimpleChanges, AfterViewInit, OnDestroy, 
  ViewChild, ElementRef,
} from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { signal } from '@angular/core';
import { IEnvironment, ENV } from '../../models/environment';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ISearchInputState } from './search-input.interfaces';
import { UtilsService } from '@jit/core';
import { SimpleSelectService } from '../simple-select/simple-select.service';
import { 
  ISimpleSelectListPositionResponse,
  ISimpleSelectClickOutsideResponse,
} from '../simple-select/simple-select.interfaces';
import { FilterPipe, SafeHtmlPipe, SearchMatchPipe } from '../../pipes';

@Component({
  selector: 'jit-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    FilterPipe, 
    SafeHtmlPipe, 
    SearchMatchPipe,
  ],
})
class SearchInputComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input()
  showButton: boolean = true;

  @Input()
  name!: string;
  
  @Input()
  placeholder!: string;

  @Input()
  value: string = '';

  @Input()
  tabIndex: number = 1;

  @Input()
  options: string[] = [];

  @Output()
  changed: EventEmitter<string> = new EventEmitter();

  @Output()
  submit: EventEmitter<string> = new EventEmitter();

  @ViewChild('root', { static: false })
  root!: ElementRef<HTMLElement>;

  @ViewChild('list', { static: false })
  list!: ElementRef<HTMLElement>;

  private _env: IEnvironment = inject(ENV);
  private _simpleSelectService: SimpleSelectService = inject(SimpleSelectService);
  private _handleChangeValue: any;
  private _listPosition!: ISimpleSelectListPositionResponse;
  private _clickOutside!: ISimpleSelectClickOutsideResponse;
  private _onKeyDown!: ((e: KeyboardEvent) => void) | null;

  public state: ISearchInputState = {
    value: signal(''),
    disabled: signal(false),
    isOpened: signal(false),
    focusedItem: signal(-1),
    minListWidth: signal(-1),
  };

  ngOnInit(): void {
    this.state.value.set(this.value);

    this._handleChangeValue = UtilsService.debounce((e: Event) => this.handleChangeValue(e), 450);
  }

  ngAfterViewInit(): void {
    this._listPosition = this._simpleSelectService.listPosition({
      parent: this.root,
      list: this.list,
      state: this.state as any,
    });

    this._clickOutside = this._simpleSelectService.clickOutside(this.root, () => this.handleClickOutside());
    this._onKeyDown = this._simpleSelectService.keyDown(
      this.state as any, 
      (item: any) => this.onItemClick(item),
      'jit-search-input__item'
    );
  }

  ngOnDestroy(): void {
    this._handleChangeValue = null;
    this._onKeyDown = null;
    this._listPosition.unsubscribe();
    this._clickOutside.unsubscribe();
  }

  handleClickOutside(): void {
    this.state.isOpened.set(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const keys: string[] = ['value'];

    keys.forEach((k: string) => {
      if (changes && changes[k] && changes[k].currentValue !== changes[k].previousValue) {
        const state: any = this.state;
        const self: any = this;

        if (!changes[k].firstChange) {
          state[k].set(self[k]);
        }
      }
    });
  }

  handleChangeValue(e: Event): void {
    if (e?.target) {
      const el: any = e?.target;
  
      this.state.value.set(el.value || '');
      this.changed.emit(el.value || '');
    }
  }

  onClick(): void {
    const isOpened: boolean = !this.state.isOpened();

    this.state.isOpened.set(isOpened);

    if (isOpened) {
      this._listPosition.calc();
    }
  }

  onItemClick(item: string): void {
    this.state.value.set(item);
    this.changed.emit(item);
    this.state.isOpened.set(false);
    this.state.focusedItem.set(-1);
  }

  onChange(e: Event): void {
    this._handleChangeValue(e);
  }

  onKeyPress(e: KeyboardEvent): void {
    if (e && e.code === 'Enter') {
      this.onSubmit();
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    const customEv: any = Object.assign({}, e);

    customEv.target = this.root.nativeElement;
    customEv.code = e.code;

    this._onKeyDown && this._onKeyDown(customEv);
    
    this._listPosition?.calc();
  }

  onSubmit(): void {
    this.submit.emit(this.state.value());
  }

}

export {
  SearchInputComponent,
};
