import { Bundle } from "./bundle";
import { Resource } from "./resource";
import { Suite } from "./suites";

export type ContentType = Resource | Suite | Bundle;

export abstract class Content {
  public isResource(content: ContentType): content is Resource {
    return content instanceof Resource;
  }

  public isSuite(content: ContentType): content is Suite {
    return content instanceof Suite;
  }

  public isBundle(content: ContentType): content is Bundle {
    return content instanceof Bundle;
  }
}
