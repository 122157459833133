import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-report-question-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ReportQuestionSingleSelectComponent {
  
}

export {
  ReportQuestionSingleSelectComponent,
};
