import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, lastValueFrom, from } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Filter, FilterOption, FilterResponse, Pill, QueryParams } from '../../models';
import { gradeMap } from '@jit/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DialogService } from '../dialog/dialog.service';
import { FiltersModalComponent } from '../../components';
import * as R from 'ramda';
import { isNotNil, flatten } from 'ramda';
import { IDynamicFilters } from '../../models/content-response';
import { mapResourceType } from '../../models/resource';
import { LoggerService, ApiService, IResponse, UtilsService } from '@jit/core';
import { ENV, IEnvironment } from '../../models/environment';

export enum EFilterType {
  SUBJECT = 'subject',
  GRADE = 'grade',
  STATE = 'states',
  DOMAIN = 'sub-domain',
  MODAL_FILTERS = 'modal-filters',
};

/**
 * DEPRECATED
 * 
 * Service is not used any more
 */

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  private _filtersV2: Filter[] = [];

  private _filters = new BehaviorSubject<null | Filter[]>(null);
  private _params = new BehaviorSubject<Record<string, unknown>>({});
  private _searchBarFilers = ['grade', 'subject', 'sub-domain', 'states'];
  private _hasSearchBar = new BehaviorSubject<boolean>(false);
  private _count = new BehaviorSubject<number>(0);
  private _availablity = new BehaviorSubject<void>(void(0));

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);
  private _env: IEnvironment = inject(ENV);
  
  private http = inject(HttpClient);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private breakpointObserver = inject(BreakpointObserver);
  private dialogService = inject(DialogService);

  public hasSearchBar$ = this._hasSearchBar.asObservable();
  public count$ = this._count.asObservable();
  public availability$ = this._availablity.asObservable();

  public readonly filters$ = this._filters.asObservable();
  public readonly searchBarFilters$ = combineLatest({
    isMobile: this.breakpointObserver.observe('(max-width: 767px)'),
    filters: this.filters$,
  }).pipe(
    map(({ filters }) =>
      filters?.filter((filter) => this._searchBarFilers.includes(filter.key))
    )
  );

  get modalFilters$() {
    return combineLatest({
      isMobile: this.breakpointObserver.observe('(max-width: 767px)'),
      filters: this.filters$,
    }).pipe(
      map(({ filters, isMobile }) => {
        return (
          filters &&
          filters.filter((filter) =>
            !isMobile.matches
              ? !this._searchBarFilers.includes(filter.key)
              : true
          )
        );
      })
    );
  }

  get pills$(): Observable<Pill[] | null> {
    return combineLatest({
      filters: this.modalFilters$,
      route: this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(true)
      ),
    }).pipe(
      map(({ filters }) => {
        if (!filters) {
          return null;
        }

        const pills = R.map((filter) => {
          const value: string | string[] = this.getQuery()[filter.key];

          if (!value) {
            return null;
          }

          let options: ({ label: string; key: string } | undefined)[] = [];

          if (Array.isArray(value)) {
            options = value.map((v) =>
              filter.options.find((option) => option.key === v)
            );
          }

          if (typeof value === 'string') {
            const option = filter.options.find(
              (option) => option.key === value
            );

            options = option ? [option] : [];
          }

          return R.filter(isNotNil, options).map((option) => ({
            key: option.key,
            label: option.label,
            filterKey: filter.key,
          }));
        }, filters);

        return R.filter(isNotNil, flatten(pills));
      })
    );
  }

  form = new FormGroup<Record<string, AbstractControl | FormGroup>>({
    search: new FormControl<string>(''),
    subject: new FormGroup<Record<string, FormControl<boolean>>>({}),
    grade: new FormControl<string[]>([]),
    'sub-domain': new FormControl<string[]>([]),
    states: new FormControl<string[]>([]),
  });

  constructor() {
    // this.router.events.subscribe((e: any) => this._handleRouterChanges(e));
  }

  private _handleRouterChanges(e: NavigationEnd) {
    if (e instanceof NavigationEnd) {
      if (e.url === '/' || e.url === '/my-collections') {
        this._resetFilters()._resetModalFilters().enableFilterFields();
      }
    }
  }

  public getModalFilters(): Filter[] {
    const filters = this._filters.value || [];
    const modalFilters: Filter[] = filters.reduce((acc: Filter[], filter: Filter): Filter[]  => {
      (!this._searchBarFilers.includes(filter.key)) && acc.push(filter);

      return acc;
    }, []);

    return modalFilters;
  }

  public getFilters(): Observable<Filter[] | null> {
    return from(this.getFiltersV2()).pipe(tap(filters => this._filters.next(filters)));
  }

  public applyFilters(data: Record<string, unknown>) {
    const params = { ...data };

    this._params.next(params);

    return this.router.navigate(['/results'], { queryParams: params });
  }

  public getQuery() {
    return this.route.snapshot.queryParams;
  }

  public getApiQuery(): Observable<QueryParams> {
    return this.filters$.pipe(
      filter((value) => value !== null),
      map((filters) => {
        const query = this.getQuery();
        
        const newQuery: QueryParams = {
          search: query['search'] || '',
        };

        if (query['sort']) {
          newQuery['sort'] = query['sort'];
        }

        filters?.forEach(({ key, id }) => {
          if (key in query) {
            newQuery[id] = query[key];
          }
        });

        return newQuery;
      })
    );
  }

  public init() {
    // this.route.queryParams.subscribe(() => {
    //   const query = this.getQuery();
    //   if (Object.keys(query).length === 0) {
    //     this.form.reset();
    //   } else {
    //     this.form.patchValue(query);
    //   }

    //   this._params.next(query);
    // });

    // this.getFilters().subscribe(() => this.enableFilterFields());
  }

  enableFilterFields(): SearchService {
    setTimeout(() => {
      const subjectValue: any = this.form.get(EFilterType.SUBJECT)?.value || {};
      const isSubjectSelected: boolean = Object.keys(subjectValue || {}).some((key) => (!!subjectValue[key]));
      
      const gradeField = this.form.get(EFilterType.GRADE);
      const domainField = this.form.get(EFilterType.DOMAIN);
      const stateField = this.form.get(EFilterType.STATE);
      
      let gradeValue = gradeField?.value || [];
      let domainValue = domainField?.value || [];
      let stateValue = stateField?.value || '';
  
      if (Array.isArray(gradeValue) && gradeValue.length === 1 && gradeValue[0] === 'all') {
        gradeValue = [];
      }

      if (Array.isArray(domainValue) && domainValue.length === 1 && domainValue[0] === 'all') {
        domainValue = [];
      }

      if (Array.isArray(stateValue)) {
        if (stateValue.length) {
          stateValue = '1';
        } else {
          stateValue = '';
        }
      }

      isSubjectSelected ? gradeField?.enable() : gradeField?.disable();
      !!gradeValue.length ? domainField?.enable() : domainField?.disable();
      !!domainValue.length ? stateField?.enable() : stateField?.disable();
    });

    return this;
  }

  public parseFilterValue(
    value: Record<
      string,
      Partial<{ [key: string]: boolean }> | string | string[] | null
    >,
    filterType: EFilterType | null = null, 
    entity: any = null
  ) {
    const result: Record<string, string[] | string> = {};
    
    if (filterType && filterType === EFilterType.SUBJECT) {
      if (value[filterType]) {
        const subject: Record<string, boolean> = value[filterType] as any;

        Object.keys(subject).forEach((id: string) => {
          if (id !== entity.key) {
            subject[id] = false;
          }
        });

        this.form.get(filterType)?.setValue(subject);

        // When Subject is changes there is a need to reset values for Select fields
        value[EFilterType.GRADE] = null;
        value[EFilterType.DOMAIN] = null;
        value[EFilterType.STATE] = null;

        setTimeout(() => {
          this.form.get(EFilterType.GRADE)?.setValue(null);
          this.form.get(EFilterType.DOMAIN)?.setValue(null);
          this.form.get(EFilterType.STATE)?.setValue(null);
        });
      }
    } else if (filterType && filterType === EFilterType.GRADE) {
      value[EFilterType.DOMAIN] = null;
      value[EFilterType.STATE] = null;

      setTimeout(() => {
        this.form.get(EFilterType.DOMAIN)?.setValue(null);
        this.form.get(EFilterType.STATE)?.setValue(null);
      });
    } else if (filterType && filterType === EFilterType.DOMAIN) {
      value[EFilterType.STATE] = null;

      setTimeout(() => {
        this.form.get(EFilterType.STATE)?.setValue(null);
      });
    }
    
    this.enableFilterFields();

    Object.keys(value).forEach((key) => {
      const keyValue = value[key];

      if (Array.isArray(keyValue)) {
        result[key] = keyValue.filter((el) => el !== 'all');
      } else if (typeof keyValue === 'string') {
        result[key] = keyValue;
      } else if (keyValue) {
        const newValue: string[] = [];

        Object.keys(keyValue).forEach((el) => {
          if (keyValue[el]) {
            newValue.push(el);
          }
        });
        
        result[key] = newValue;
      }
    });

    return result;
  }

  public showSearchBar() {
    this._hasSearchBar.next(true);
  }

  public hideSearchBar() {
    this._hasSearchBar.next(false);
  }

  public openFilters() {
    this.dialogService.open(FiltersModalComponent, {
      windowClass: 'filters-dialog',
      width: '100%',
      height: '100%',
    });
  }

  public updateCount(count: number) {
    this._count.next(count);
  }

  public applyDynamicFilters(dynamicFilters: IDynamicFilters | null) {
    if (dynamicFilters && !Object.keys(dynamicFilters).length) {
      return;
    }

    const filters = this._filters.value || [];

    if (dynamicFilters) {
      const ids: string[][] = Object.keys(dynamicFilters).map((s) => s.split(','));
      const subject: Filter | void = filters.find((f: Filter) => (f.key === EFilterType.SUBJECT));
      const grade: Filter | void = filters.find((f: Filter) => (f.key === EFilterType.GRADE));
      const domain: Filter | void = filters.find((f: Filter) => (f.key === EFilterType.DOMAIN));
      const state: Filter | void = filters.find((f: Filter) => (f.key === EFilterType.STATE));
      const modalFilters: Filter[] = filters.filter((f: Filter) => !(
        f.id === subject?.id || f.id === grade?.id ||
        f.id === domain?.id || f.id === state?.id
      ));

      const idx: Record<any, any> = {
        [EFilterType.GRADE]: [],
        [EFilterType.DOMAIN]: [],
        [EFilterType.STATE]: [],
        [EFilterType.MODAL_FILTERS]: [],
      };

      for (let item of ids) {
        if (subject && grade && item.length === 1 && item[0] === subject.id) {
          // Get ids for grade
          idx[EFilterType.GRADE] = dynamicFilters[item[0]].reduce((acc, df) => {
            if (grade.id === df._id) {
              df.attributeValues.forEach((i) => acc.push(i._id as never));
            }

            return acc;
          }, []);
        } else if (
          subject && grade && domain && item.length === 2 && 
          (item.includes(subject.id) && item.includes(grade.id))
        ) {
          // Get ids for domain
          idx[EFilterType.DOMAIN] = dynamicFilters[item.join(',')].reduce((acc, df) => {
            if (domain.id === df._id) {
              df.attributeValues.forEach((i) => acc.push(i._id as never));
            }

            return acc;
          }, []);
        } else if (
          // Get ids for state
          subject && grade && domain && state && item.length === 3 && 
          (item.includes(subject.id) && item.includes(grade.id) && item.includes(domain.id))
        ) {
          // Domain
          idx[EFilterType.STATE] = dynamicFilters[item.join(',')].reduce((acc, df) => {
            if (state.id === df._id) {
              df.attributeValues.forEach((i) => acc.push(i._id as never));
            }

            return acc;
          }, []);
        }

        idx[EFilterType.MODAL_FILTERS] = dynamicFilters[item.join(',')].reduce((acc, df) => {
          if (subject && subject.id === df._id) {
            return acc;
          }

          if (grade && grade.id === df._id) {
            return acc;
          }

          if (domain && domain.id === df._id) {
            return acc;
          }

          if (state && state.id === df._id) {
            return acc;
          }

          df.attributeValues.forEach((i) => acc.push(i._id as never));

          return acc;
        }, []);
      }

      // Turn on/off filters by dunamic filters
      grade?.options.forEach((option: FilterOption) => {
        option.available = idx[EFilterType.GRADE].includes(option.key);
      });

      domain?.options.forEach((option: FilterOption) => {
        option.available = idx[EFilterType.DOMAIN].includes(option.key);
      });

      state?.options.forEach((option: FilterOption) => {
        option.available = idx[EFilterType.STATE].includes(option.key);
      });

      modalFilters.forEach((filter: Filter) => {
        filter.options.forEach((option: FilterOption) => {
          option.available = idx[EFilterType.MODAL_FILTERS].includes(option.key);
        });
      });
    } else {
      // Reset availability
      filters.forEach((filter: Filter) => {
        filter?.options.forEach((option: FilterOption) => (option.available = true));
      });
    }

    this._availablity.next();
  }

  private _resetFilters(): SearchService {
    const subject: any = this.form.get(EFilterType.SUBJECT);

    Object.keys(subject.controls).forEach((key) => (subject.controls[key].setValue(false)));

    this.form.get(EFilterType.GRADE)?.setValue(null);
    this.form.get(EFilterType.DOMAIN)?.setValue(null);
    this.form.get(EFilterType.STATE)?.setValue(null);

    return this;
  }

  private _resetModalFilters(): SearchService {
    const modalFilters: Filter[] = this.getModalFilters();

    modalFilters.forEach((filter: Filter) => {
      const formField: any = this.form.get(filter.key);

      if (formField) {
        Object.keys(formField.controls || {}).forEach((key: string) => {
          formField.get(key)?.setValue(false);
        });
      }
    });

    return this;
  }

  resetFilters(): void {
    this._resetFilters().enableFilterFields().applyFilters({});
  }

  resetModalFilters(): void {
    this._resetModalFilters().applyFilters(this.parseFilterValue(this.form.value));
  }

  /////////
  // migration from Observable to Promise to improve performance
  /////////

  async getFiltersV2(): Promise<Filter[]> {
    if (this._filtersV2 && this._filtersV2.length > 0) {
      return this._filtersV2;
    }

    const lang = this.translateService.currentLang || this.translateService.defaultLang;
    const url: string = this._env.apiUrl + '/filters?lang=' + lang;

    let response: IResponse<FilterResponse[]>;

    try {
      response = await this._apiService.get<FilterResponse[]>(url);
    } catch (err) {
      this._filtersV2 = [];
      this._loggerService.error('SearchService.getFiltersV2: Request error', err);

      return this._filtersV2;
    }

    this._filtersV2 = response.data.reduce((acc: Filter[], item: FilterResponse) => {
      if (item.attributeValues.length > 0) {
        const attributeMap = (item.name === 'Grade') ? gradeMap : null;
        const newName = (item.name === 'State') ? 'states' : item.name;

        acc.push({
          name: newName,
          id: item._id,
          isRange: item.name === 'SKILL_RANGE',
          isSingle: newName === 'states',
          key: newName.replace(' ', '_').toLowerCase(),
          options: item.attributeValues.map((attr) => {
            let label = attr.name;

            if (attributeMap) {
              label = attributeMap[attr.name];
            } else if (mapResourceType[label]) {
              label = mapResourceType[label];
            }
          
            return { label, key: attr._id, available: true };
          }),
        });
      }

      return acc;
    }, []);

    return this._filtersV2;
  }

  async getFilterByKey(key: string): Promise<Filter | null> {
    const filters: Filter[] = await this.getFiltersV2();

    let filter: Filter | null = null;

    if (filters && filters.length) {
      filter = filters.find((f: Filter) => (f.key === key)) || null;
    }

    return filter;
  }

}
