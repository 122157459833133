import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { CourseDurationComponent } from '../course-duration/course-duration.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-treeview-resources',
  templateUrl: './treeview-resources.component.html',
  styleUrls: ['./treeview-resources.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    CourseDurationComponent,
  ],
})
class TreeviewResourcesComponent extends BaseComponent implements OnInit {

  @Input()
  title: string = '';

  @Input()
  count: number = 0;

  @Input()
  selected: number = 0;

  @Input()
  value: boolean = false;

  @Input()
  expanded: boolean = false;

  @Output()
  changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    
  }

  onChange(): void {
    this.value = !this.value;

    this.changed.emit(this.value);
  }

  onToggle(): void {
    this.expanded = !this.expanded;

    this.toggle.emit(this.expanded);
  }

}

export {
  TreeviewResourcesComponent,
};
