import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { Suite } from '../../models';
import { BundleListComponent } from '../bundle-list/bundle-list.component';
import { CollectionButtonComponent } from '../collection-button/collection-button.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { SortComponent } from '../../../shared/components/sort/sort.component';

interface IRowSuit {
  opened: boolean;
  suite: Suite;
}

@Component({
  selector: 'jit-suite-list',
  templateUrl: './suite-list.component.html',
  styleUrls: ['./suite-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatExpansionModule,
    IconSimpleComponent,
    BundleListComponent,
    RouterLink,
    CollectionButtonComponent,
    SortComponent,
  ],
})
export class SuiteListComponent {

  @Input() suites!: Suite[];

  public view: IRowSuit[] = [];

  @ViewChildren('bundleList')
  bundleList!: QueryList<BundleListComponent>;

  getPanelClasses(suite: Suite) {
    return {
      ['bg--' + suite.colorName + '-10']: true,
    };
  }

  getHeaderClasses(suite: Suite, opened: boolean) {
    return {
      ['bg--' + suite.colorName + '-30']: opened,
      opened,
    };
  }

  ngOnInit(): void {
    this.view = this.suites.map((el) => ({ opened: false, suite: el }));
  }

  onSortChange(value: string | null, row: IRowSuit): void {
    const el = this.bundleList.find((item) => (item.id === row.suite.id));
    
    if (el) {
      row.suite.sortBundles(value);
      el.updateBundles();
    }
  }
}
