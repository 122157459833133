import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { useNotifications, INotification } from '@jit/data-layer';

@Component({
  selector: 'jit-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NotificationItemComponent,
  ],
})
class NotificationsComponent {

  @useNotifications((state) => state.notifications)
  notifications: INotification[] = [];

}

export {
  NotificationsComponent,
};
