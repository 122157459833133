import { CommonModule } from '@angular/common';
import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class ModalDialogComponent extends BaseComponent implements AfterViewInit, OnDestroy {

  @ViewChild('dialog', { static: false })
  dialog!: ElementRef;

  @ViewChild('overlay', { static: false })
  overlay!: ElementRef;

  public isOpened = false;

  ngAfterViewInit(): void {
    document.body.appendChild(this.dialog.nativeElement);
    document.body.appendChild(this.overlay.nativeElement);
  }

  ngOnDestroy(): void {
    document.body.removeChild(this.dialog.nativeElement);
    document.body.removeChild(this.overlay.nativeElement);
  }

  public open(): void {
    this.isOpened = true;
  }

  public close(): void {
    this.isOpened = false;
  }

}

export {
  ModalDialogComponent,
};
