import { Injectable, inject } from '@angular/core';
import { IResponse, IRequestInit } from './api.interfaces';
import { ResponseError } from './ResponseError';
import { Interceptor } from './Interceptor';
import { StorageService } from '../storage/storage.service';
import { LoggerService } from '../logger';
import { CorrelationService } from '../correlation/correlation.service';

@Injectable({
  providedIn: 'root',
})
class ApiService extends Interceptor {

  public defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json'
  };

  private _storageService: StorageService = inject(StorageService);
  private _loggerService: LoggerService = inject(LoggerService);
  private _correlationService: CorrelationService = inject(CorrelationService);
  private _keyAuthTransferDataResult: string = 'authData';

  private async _prepareResponse<T>(response: Response): Promise<IResponse<T>> {
    const status: number = response.status;
    const statusText: string = response.statusText;
    const headers: HeadersInit = {};

    response.headers.forEach((val: string, key: string) => (headers[key] = val));

    if (status >= 200 && status < 300) {
      let data: T | any;

      try {
        data = await response.json();
      } catch (err) {
        this._loggerService.error('Api: response parse error', { error: err, status });
      }

      if (typeof data === 'undefined') {
        try {
          if (typeof response.text === 'function') {
            data = await response.text();
          } else if (typeof response.text === 'string') {
            data = await response.text;
          }
        } catch (error) {
          data = void(0);
        }
      }

      const result: IResponse<T> = { status, statusText, data, headers };

      await this.execAfterRequest<T>(result);

      return result;
    } else if (status === 400) {
      let data: T | any;

      try {
        data = await response.json();
      } catch (err) {
        this._loggerService.error('Api: response parse error', { error: err, status });
      }

      if (typeof data === 'undefined') {
        try {
          if (typeof response.text === 'function') {
            data = await response.text();
          } else if (typeof response.text === 'string') {
            data = await response.text;
          }
        } catch (error) {
          data = void(0);
        }
      }

      if (Array.isArray(data.message)) {
        data = data.message.reduce((acc: any, errItem: any) => {
          acc[errItem.property] = Object.keys(errItem.constraints).map((k) => {
            return errItem.constraints[k];
          });

          return acc;
        }, {});
      }

      await this.execErrorRequest({ status, statusText, data, headers });

      throw new ResponseError(status, statusText, data, headers);
    } else {
      let text: string | any = await response.text();

      try {
        text = JSON.parse(text);
      } catch (err) {
        //
      }

      await this.execErrorRequest({ status, statusText, text, headers });

      throw new ResponseError(status, statusText, text, headers);
    }
  }

  logging(url: string, data?: Error | string | unknown): void {
    this._loggerService.error('Api: response error', { url, data });
  }

  async headers(customHeaders: HeadersInit = {}): Promise<Headers> {
    let headersInit: HeadersInit = Object.assign({}, this.defaultHeaders);

    headersInit = Object.assign(headersInit, customHeaders);

    const authData: any = this._storageService.retrive(this._keyAuthTransferDataResult);

    if (authData && authData.access_token && authData.token_type) {
      (headersInit as any)['Authorization'] = authData.token_type + ' ' + authData.access_token;
    }

    (headersInit as any)[CorrelationService.headerKey] = this._correlationService.get();

    const headers: Headers = new Headers(headersInit);

    await this.execHeadersRequest(headers);

    return new Headers(headers);
  }

  async get<T>(url: string, options: RequestInit = {}): Promise<IResponse<T>> {
    const controller: AbortController = new AbortController();
  
    options.signal = controller.signal;

    let response: Response;

    await this.execBeforeRequest({ url, options, data: null, abort: () => controller.abort() });

    options.headers = await this.headers(options.headers);
    options.method = 'GET';

    try {
      response = await fetch(url, options);
    } catch (err) {
      this.logging(url, err);

      throw err;
    }

    return await this._prepareResponse<T>(response);
  }

  async post<T, P>(url: string, data: P, options: RequestInit = {}): Promise<IResponse<T>> {
    const controller: AbortController = new AbortController();

    options.signal = controller.signal;

    let response: Response;

    await this.execBeforeRequest<P>({ url, options, data, abort: () => controller.abort() });

    options.headers = await this.headers(options.headers);
    options.method = 'POST';
    
    if (data instanceof URLSearchParams) {
      options.body = data.toString();
    } else if (typeof data === 'function') {
      options.body = await data();
    } else if (data && typeof data === 'object') {
      options.body = JSON.stringify(data);
    } else {
      options.body = data as any;
    }

    try {
      response = await fetch(url, options);
    } catch (err) {
      this.logging(url, err);

      throw err;
    }

    return await this._prepareResponse<T>(response);
  }

  async put<T, P>(url: string, data: P, options: RequestInit = {}): Promise<IResponse<T>> {
    const controller: AbortController = new AbortController();

    options.signal = controller.signal;

    let response: Response;

    await this.execBeforeRequest<P>({ url, options, data, abort: () => controller.abort() });

    options.headers = await this.headers(options.headers);
    options.method = 'PUT';

    if (data instanceof URLSearchParams) {
      options.body = data.toString();
    } else if (typeof data === 'function') {
      options.body = await data();
    } else if (data && typeof data === 'object') {
      options.body = JSON.stringify(data);
    } else {
      options.body = data as any;
    }

    try {
      response = await fetch(url, options);
    } catch (err) {
      this.logging(url, err);

      throw err;
    }

    return await this._prepareResponse<T>(response);
  }

  async patch<T, P>(url: string, data: P, options: RequestInit = {}): Promise<IResponse<T>> {
    const controller: AbortController = new AbortController();

    options.signal = controller.signal;

    let response: Response;

    await this.execBeforeRequest<P>({ url, options, data, abort: () => controller.abort() });

    options.headers = await this.headers(options.headers);
    options.method = 'PATCH';

    if (data instanceof URLSearchParams) {
      options.body = data.toString();
    } else if (typeof data === 'function') {
      options.body = await data();
    } else if (data && typeof data === 'object') {
      options.body = JSON.stringify(data);
    } else {
      options.body = data as any;
    }

    try {
      response = await fetch(url, options);
    } catch (err) {
      this.logging(url, err);

      throw err;
    }

    return await this._prepareResponse<T>(response);
  }

  async delete<T>(url: string, options: RequestInit = {}): Promise<IResponse<T>> {
    const controller: AbortController = new AbortController();

    options.signal = controller.signal;

    let response: Response;

    await this.execBeforeRequest({ url, options, data: null, abort: () => controller.abort() });

    options.headers = await this.headers(options.headers);
    options.method = 'DELETE';

    try {
      response = await fetch(url, options);
    } catch (err) {
      this.logging(url, err);

      throw err;
    }

    return await this._prepareResponse<T>(response);
  }

  async download(url: string, options: IRequestInit = {}): Promise<IResponse<ReadableStreamDefaultReader<Uint8Array>>> {
    const controller: AbortController = new AbortController();

    options.signal = controller.signal;

    let response: Response;

    await this.execBeforeRequest({ url, options, data: null, abort: () => controller.abort() });

    try {
      response = await fetch(url, options);
    } catch (err) {
      this.logging(url, err);

      throw err;
    }

    const status: number = response.status;
    const statusText: string = response.statusText;
    const headers: HeadersInit = {};

    response.headers.forEach((val: string, key: string) => (headers[key] = val));

    if (response.status === 200 || response.status === 206) {
      const data: ReadableStreamDefaultReader<Uint8Array> = await response.body!.getReader();

      const result: IResponse<ReadableStreamDefaultReader<Uint8Array>> = { status, statusText, data, headers };

      await this.execAfterRequest<ReadableStreamDefaultReader<Uint8Array>>(result);

      return result;
    } else {
      let text: string | any = await response.text();

      try {
        text = JSON.parse(text);
      } catch (err) {
        //
      }

      await this.execErrorRequest({ status, statusText, text, headers });

      throw new ResponseError(status, statusText, text, headers);
    }
  }

}

export {
  ApiService
};
