import {
  Component,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  inject,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ENV, IEnvironment } from '../../models/environment';
import Hls from 'hls.js';
import { IResourceEntity } from '@jit/data-layer';

@Component({
  selector: 'jit-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  standalone: true,
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input()
  content!: IResourceEntity;
  
  @ViewChild('video')
  videoElement?: ElementRef<HTMLVideoElement>;

  private hls?: Hls;
  private _env:IEnvironment = inject(ENV);

  public thumb!: string;
  public path!: string;

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.afterInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['content'] && changes['content'].currentValue !== changes['content'].previousValue) {
      this.init();

      setTimeout(() => this.afterInit(), 300);
    }
  }

  init() {
    let path = this.content.file?.path || '';
    let thumb = this.content.thumbnail?.path || '';
    
    if (!path.includes('https://') && !path.includes('http://')) {
      path = this._env.assetsDomain + path;
    }
  
    if (!thumb.includes('https://') && !thumb.includes('http://')) {
      thumb = this._env.assetsDomain + thumb;
    }

    this.thumb = thumb;
    this.path = path;
  }

  afterInit() {
    const video = this.videoElement?.nativeElement;

    if (video) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(this.path);
        hls.attachMedia(video);
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.path;
      }
    }
  }

  ngOnDestroy(): void {
    this.hls?.destroy();
  }
}
