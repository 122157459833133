import { Injectable, inject } from '@angular/core';
import { DialogService as LibDialogService } from '@ngneat/dialog';
import {
  ConfirmModalComponent,
  OnboardingModalComponent,
} from '../../components';
import { ConfirmModalConfig } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialog = inject(LibDialogService);

  public open = this.dialog.open.bind(this.dialog);

  public openConfirmDialog(config: ConfirmModalConfig) {
    return this.open(ConfirmModalComponent, {
      windowClass: 'confirm-dialog',
      width: '500px',
      data: config,
    });
  }

  public openOnboardingDialog() {
    return this.open(OnboardingModalComponent, {
      windowClass: 'onboarding-dialog',
      width: '500px',
    });
  }
}
