import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { UserService, IUserInfo, UtilsService } from '@jit/core';
import { IUserState } from '../interfaces/user-state.interface';
import { FiltersService } from '../services/filters.service';

function getInitState(): Partial<IUserState> {
  return {
    user: void(0),
    initialized: false,
    allowCreateAssignment: false,
    allowLectura: false,
    allowMath: false,
    allowReading: false,
    allowMatematicas: false,
  };
}

const useUser = createStore<IUserState>((set, get) => ({
  ...getInitState() as IUserState,

  setUser: async (user: IUserInfo | void, isInit: boolean = false): Promise<void> => {
    const newState: any = getInitState();

    newState.user = user || void(0);

    if (Array.isArray(user?.contentSubscriptions)) {
      const filtersService: FiltersService = createStore.inject(FiltersService);

      const subjects = await filtersService.getSubjects();

      const slugs: string[] = subjects.map((s) => s.slug || '');

      for (let slug of slugs) {
        const subscr = user.contentSubscriptions.find((subscr) => subscr.subject.toLowerCase() === slug);

        if (subscr && subscr.hasSubscription) {
          newState['allow' + UtilsService.capitalize(slug)] = true;
        }
      }

      newState.allowCreateAssignment = !!user.contentSubscriptions.find((subscr) => subscr.hasSubscription);
    }

    if (isInit) {
      newState.initialized = true;
    }

    set(newState);
  },

  init: async (): Promise<void> => {
    const userService: UserService = createStore.inject(UserService);

    const user: IUserInfo = await userService.me();

    get().setUser(user, true);
  },
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useUser = useUser;
}

export {
  useUser,
};
