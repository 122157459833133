<a
  [routerLink]="['/', 'detail-page', resource.id, type]"
  class="resource-content"
  [ngStyle]="cardStyles"
>
  <div class="resource-header">
    <jit-icon-simple [iconId]="resource.typeIcon"></jit-icon-simple>
    <strong class="resource-type">{{ resource.typeName }}</strong>
  </div>

  <h4 class="resource-title">{{ resource.title }}</h4>

  <div *ngIf="isResource(resource)" class="justify-between align-center">
    <!-- <h5 class="resource-author">{{ resource.learningTarget }}</h5> -->

    <strong class="resource-category">
      {{ resource.standardsValue }}
    </strong>
  </div>

  <div *ngIf="!isResource(resource)">&nbsp;</div>
</a>

<footer class="resource-footer">
  <jit-course-duration
    *ngIf="isResource(resource)"
    [duration]="resource.duration"
  ></jit-course-duration>

<!--  <jit-collection-button [item]="resource"></jit-collection-button>-->
</footer>
