import { Injectable, inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { OAuthService } from '../services/oauth/oauth.service';

@Injectable({
  providedIn: 'root' 
})
export class OAuthGuard implements CanActivate {

  private _oauthService: OAuthService = inject(OAuthService);
  
  async canActivate(): Promise<boolean> {
    return await this._oauthService.isTokenValid();
  }

}
