import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, takeLast } from 'rxjs/operators';
import { OAuthService } from '../services/oauth/oauth.service';

// DEPRECATED!!!

@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptor implements HttpInterceptor {

  private _oauthService: OAuthService = inject(OAuthService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      takeLast(1),
      tap({
        error: (response) => {
          // if BE returns Auth error then initialize Auth flow
          if (response.status === 401) {
            this._oauthService.init();
          }

          return response;
        },
      }),
    );
  }
}
