import { ChangeDetectionStrategy, Component, inject, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SelectComponent } from '../select/select.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ParamsService, FiltersService, IEntityFilters, BaseComponent } from '@jit/data-layer';

@Component({
  selector: 'jit-subject-buttons',
  templateUrl: './subject-buttons.component.html',
  styleUrls: ['./subject-buttons.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    CommonModule,
    SelectComponent,
    IconSimpleComponent,
  ],
})
class SubjectButtonsComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output()
  changed: EventEmitter<IEntityFilters | null> = new EventEmitter();
  
  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _paramsService: ParamsService = inject(ParamsService);
  private _filtersService: FiltersService = inject(FiltersService);
  private _subscription: any;

  public prevValue: string | null = null;
  public currentValue: string | null = null;
  public current: IEntityFilters | null = null;
  public subjects: IEntityFilters[] = [];

  private _handleQueryParams(): void {
    this.currentValue = this._route.snapshot.queryParamMap.get('subject') || null;
  }

  ngOnInit(): void {
    this._subscription = this._route.queryParams.subscribe(() => this._handleQueryParams());

    this.fetch();
  }

  ngOnDestroy(): void {
    this._subscription && this._subscription.unsubscribe();
  }

  async fetch(): Promise<void> {
    try {
      this.subjects = await this._filtersService.getSubjects();
    } catch (err) {
      // TODO: Show Notification error
      throw err;
    }

    this.refresh();
  }

  onChange(subject: IEntityFilters): void {
    if (subject === this.current) {
      this.currentValue = null;
      this.current = null;

      this._paramsService.applySubject(null);
    } else {
      this.currentValue = subject.slug || null;
      this.current = subject;

      this._paramsService.applySubject(subject);
    }

    this.changed.emit(this.current);
  }

}

export {
  SubjectButtonsComponent,
};
