
import { Component, Input, Output, inject, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { PaginatorService, IPaginatorItem, IPaginatorProps, IPaginatorChanges } from '@jit/data-layer';
import { SimpleSelectComponent } from '../simple-select/simple-select.component';
import { ISelectItem } from '../simple-select/simple-select.interfaces';

const KEYS: (keyof IPaginatorProps)[] = ['page', 'limit', 'total', 'count'];

const LIMIT_OPTIONS: ISelectItem[] = [
  { id: '5', title: '5' },
  { id: '10', title: '10' },
  { id: '20', title: '20' },
  { id: '50', title: '50' },
  { id: '100', title: '100' },
];

@Component({
  selector: 'jit-paginator',
  standalone: true,
  imports: [
    SimpleSelectComponent,
  ],
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
class PaginatorComponent implements OnInit, OnChanges {

  @Input() 
  page: number = 1;

  @Input() 
  limit: number = 0;

  @Input() 
  total: number = 0;

  @Input() 
  count: number = 5;

  @Input() 
  showLimit: boolean = true;

  @Output()
  changed: EventEmitter<IPaginatorChanges> = new EventEmitter();

  private _paginatorService: PaginatorService = inject(PaginatorService);

  public pagins: IPaginatorItem[] = [];
  public limitOptions: ISelectItem[] = LIMIT_OPTIONS;
  public currentLimit!: ISelectItem | void;

  ngOnInit() {
    this._init();
  }

  ngOnChanges(changes: SimpleChanges | void): void {
    this._init(changes);
  }

  private _init(changes: SimpleChanges | void): void {
    const props: IPaginatorProps = {
      page: this.page, 
      limit: this.limit, 
      total: this.total, 
      count: this.count,
    };

    if (changes) {
      for (const name of KEYS) {
        if (changes[name] && changes[name].currentValue !== changes[name].previousValue) {
          props[name] = changes[name].currentValue;
        }
      }
    }

    this.pagins = this._paginatorService.get(props);
    this.currentLimit = LIMIT_OPTIONS.find((item) => (item.id === props?.limit?.toString()));
  }

  getCurrentLimit(): number {
    return this.currentLimit ? parseInt(this.currentLimit.id) : 0;
  }

  getCurrent(item?: IPaginatorItem, _limit?: ISelectItem | void): IPaginatorChanges {
    if (!item) {
      item = {
        name: this.page.toString(),
        page: this.page,
        active: true,
        isArrow: false,
      };
    }

    const limit: number = _limit ? parseInt(_limit.id) : this.getCurrentLimit();
    const from: number = (item.page - 1) * limit;
    const to: number = from + limit - 1;
  
    return { item, limit, from, to };
  }

  onClick(item: IPaginatorItem): void {
    if (!item.active) {
      this.changed.emit(this.getCurrent(item));
    }
  }

  onLimitChange(item: ISelectItem | void): void {
    if (item) {
      this.changed.emit(this.getCurrent(void(0), item));
    }
  }

}

export {
  PaginatorComponent,
};
