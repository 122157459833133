<div class="jit-subject-buttons">
  <div *ngIf="subjects" class="filter-subjects-wrapper">
    <strong>Select a subject</strong>

    <div class="filter-subjects">
      <ng-container *ngFor="let subject of subjects">
        <label>
          <input type="checkbox" (change)="onChange(subject)" hidden />
          <span 
            class="button subject-button {{ $any(subject.slug || '') }}"
            [class.active]="currentValue === subject.slug"
          >
            {{ subject.title }}
          </span>
        </label>
      </ng-container>
    </div>
  </div>
</div>