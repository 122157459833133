<div class="jit-assignment-footer">
  <span class="jit-assignment-footer__cancel" (click)="onCancel()">
    Cancel
  </span>
  <span 
    [class.jit-assignment-footer__apply]="resources.length > 0" 
    [class.jit-assignment-footer__apply-disabled]="resources.length === 0" 
    (click)="onApply()"
  >
    Create Assignment
  </span>
</div>

<jit-modal-dialog #dialogCancel>
  <ng-container header>
    Cancel this assignment?
  </ng-container>
  <ng-container content>
    This action will delete this assignment and cannot be undone. Are you sure you want to cancel?
  </ng-container>
  <ng-container footer>
    <span class="button-default" (click)="onCloseDialog()">No, go back</span>
    <span class="button-active" (click)="confirmCancel()">Yes, cancel</span>
  </ng-container>
</jit-modal-dialog>