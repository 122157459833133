import { inject, Injectable } from '@angular/core';
import { ApiService, IResponse, LoggerService, UtilsService } from '@jit/core';
import { IQueryFilters } from '../interfaces/query-filters.interface';

const ALLOWED_KEYS = ['subjectId', 'gradeId', 'domainId', 'stateId', 'contentType', 'search'];

@Injectable({
  providedIn: 'root',
})
class SuggestionsService {
  
  private _urlSuggestions: string = '/v2/api/search/autocomplete';

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);

  private _getQueryStringFromQuery(_query: IQueryFilters = {}): string {
    const query: any = UtilsService.pick(_query, ALLOWED_KEYS);

    Object.keys(query).forEach((k) => {
      if (!query[k]) {
        delete query[k];
      }
    });

    let queryParams: string = new URLSearchParams(query).toString();

    return queryParams;
  }

  public async getSuggestions(query: IQueryFilters = {}): Promise<string[]> {
    const queryParams: string = this._getQueryStringFromQuery(query);

    const url = this._urlSuggestions + (queryParams ? '?' + queryParams : '');

    let response: IResponse<string[]>;

    try {
      response = await this._apiService.get<string[]>(url);
    } catch (err) {
      this._loggerService.error('SuggestionsService.getSuggestions: Request error', err);

      throw err;
    }

    return response.data;
  }

}

export {
  SuggestionsService,
};
