import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnInit, inject, Output, EventEmitter } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { CollectionButtonComponent } from '../collection-button/collection-button.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { IconId } from '../../models/icon';
import { IEnvironment, ENV } from '../../models/environment';
import { CourseDurationComponent } from '../course-duration/course-duration.component';
import { IResourceEntity, IFavorite, IEntityFilters, BaseComponent, useStates } from '@jit/data-layer';

const mapperResourceType: {[key: string]: string} = {
  'Lesson Slides': 'Slides',
  'Google Slide': 'Slides',
  'Slide Deck': 'Slides',
  'Slides': 'Slides',
  'Backward Design': 'Backward Design',
  'Video': 'Video',
  'Picture Book': 'Book',
  'Passage': 'Passage',
  'Close Reading': 'Close Reading',
  'PDF File': 'Book',
  'Book': 'Book',
  'Micro-Lesson': 'Microlesson',
  'series': 'Bundle',
  'suite': 'Suite',
};

const mapperResourceTypeIcon: {[key: string]: IconId} = {
  'Lesson Slides': 'slide',
  'Google Slide': 'slide',
  'Slide Deck': 'slide',
  'Slides': 'slide',
  'Video': 'video',
  'Backward Design': 'backward-design',
  'Close Reading': 'pdf',
  'Passage': 'pdf',
  'PDF File': 'pdf',
  'Picture Book': 'book',
  'Book': 'book',
  'Micro-Lesson': 'microlesson',
  'suite': 'suite-mono',
  'series': 'bundle-mono',
};

const resourceColorMap: any = {
  math: '#354add',
  lectura: '#ff5e03',
  reading: '#aa3c9a',
  undefined: '#fff',
};

@Component({
  selector: 'jit-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatExpansionModule,
    IconSimpleComponent,
    RouterLink,
    CollectionButtonComponent,
    IconSimpleComponent,
    CourseDurationComponent,
    CollectionButtonComponent,
  ],
})
class ResourceComponent extends BaseComponent implements OnInit {

  @Input() 
  resource!: IResourceEntity | IFavorite;

  @Output()
  click: EventEmitter<IResourceEntity | IFavorite> = new EventEmitter();

  @useStates('state')
  state: IEntityFilters | void = void(0);
  
  private _env: IEnvironment = inject(ENV);

  public type!: string;
  public icon!: IconId;
  public showStatus!: boolean;
  public color!: string;
  public status!: string | null;
  public title!: string;
  public url: string[] = [];
  public code: string | null = null;

  ngOnInit(): void {
    const res: any = this.resource;

    let parentSlug: string = '';

    if (res.parentSubject) {
      parentSlug = res.parentSubject.slug;
    } else {
      parentSlug = res.subjectSlug;
    }

    if (res.type === 'resource') {
      this.url = ['/', 'detail-page', res.slug, 'resource'];

      this.mapCode(res);
    } else if (res.type === 'series') {
      this.url = ['/', 'detail-page', res.slug, 'bundle'];
    } else if (res.type === 'suite') {
      this.url = ['/', 'detail-page', res.slug, 'suite'];
    }

    this.status = res.status || null;
    this.title = res.title;
    this.showStatus = !!(res.status && this._env.showStatus);
    this.color = resourceColorMap[parentSlug];
    this.type = mapperResourceType[this.resource.originalContentType];
    this.icon = mapperResourceTypeIcon[this.resource.originalContentType];
  }

  mapCode(res: any): void {
    this.code = null;

    if (this.state) {
      if (res && res.codes) {
        const stateId: string = this.state.id;
        const code = res.codes.find((c: any) => (c.stateId === stateId));

        this.code = (code && code.stateCode) ? code.stateCode : null;

        this.refresh();
      }
    }

    this.refresh();
  }

  onClick(): void {
    this.click.emit(this.resource);
  }

  @useStates('state')
  onState(): void {
    this.mapCode(this.resource);
  }

}

export {
  ResourceComponent,
  resourceColorMap,
  mapperResourceType,
  mapperResourceTypeIcon,
};
