import { Injectable } from '@angular/core';

const NOT_SEC_LABEL: string = 'Multiple Grade Levels';
const NOT_DEFINED_LABEL: string = 'Grades are not defined';

@Injectable({
  providedIn: 'root',
})
class GradeService {
  
  public mathGrade(grade: number, many: boolean = false, isFirst: boolean = false): string {
    let result: string = '';
  
    if (grade === -1) {
      result = many ? 'PK' : 'Pre-K';
    } else if (grade === 0) {
      result = 'Kinder';
    } else if (grade === 1) {
      result = '1st' + (isFirst ? '' : ' Grade');
    } else if (grade === 2) {
      result = '2nd' + (isFirst ? '' : ' Grade');
    } else if (grade === 3) {
      result = '3rd' + (isFirst ? '' : ' Grade');
    } else {
      result = `${grade}th` + (isFirst ? '' : ' Grade');
    }

    return result;
  }

  public mathGradeInFilter(grade: number): string {
    let result: string = '';
  
    if (grade === -1) {
      result = 'Pre-K';
    } else if (grade === 0) {
      result = 'Kindergarten';
    } else if (grade === 1) {
      result = '1st';
    } else if (grade === 2) {
      result = '2nd';
    } else if (grade === 3) {
      result = '3rd';
    } else {
      result = `${grade}th`;
    }

    return result;
  }

  public gradesToStr(_grades: number[]): string {
    const grades: number[] = [ ...(new Set(_grades.sort())) ];
    const len = grades.length;

    let label = NOT_DEFINED_LABEL;

    if (len > 0) {
      if (len === 1) {
        label = this.mathGrade(grades[0], false);
      } else {
        const lastAt = len - 1;
        const lastGrade = grades[0] + lastAt;

        if (lastGrade === grades[lastAt]) {
          label = `${this.mathGrade(grades[0], true, true)} - ${this.mathGrade(grades[lastAt], true)}`;
        } else {
          label = NOT_SEC_LABEL;
        }
      }
    }

    return label;
  }

}

export {
  GradeService,
};
