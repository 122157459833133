import { IResourceResponse } from './resource';

export interface IDynamicFilterValue {
  _id: string;
  count: number;
}
export interface IDynamicFilter {
  _id: string;
  attributeValues: IDynamicFilterValue[];
}

export interface IDynamicFilters {
  [key: string]: IDynamicFilter[];
}

export interface IGetContentItemsResponse {
  materials: IResourceResponse[];
  groupedMaterials: IContentBundle[];
  totalGroupedCount: ICountTotal;
  totalIndividualCount: ICountTotal;
  totalCount: {
    value: number;
  };
  dynamicFilters?: IDynamicFilters;
}

export interface ICountTotal {
  value: number;
  relation: string;
}

export interface IGetContentItem {
  _source: IResourceResponse;
}

export type QueryParams = {
  [param: string]:
    | string
    | number
    | boolean
    | ReadonlyArray<string | number | boolean>;
};

export interface IContentBundle {
  bundleId: string;
  bundleName: string;
  materials: IResourceResponse[];
  suiteId: string;
  suiteName: string;
  suiteDescription?: string;
}
