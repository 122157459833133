import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stopwatch',
  standalone: true,
})
class StopwatchPipe implements PipeTransform {
  
  transform(sec: number): string {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const remainingSeconds = sec % 60;

    const hourString = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : ''
    const minuteString = minutes > 0 ? `${minutes} min` : ''
    const secondString = remainingSeconds > 0 ? `${remainingSeconds} sec` : ''
    
    if (hours > 0) {
      return `${hourString} ${minuteString || ''} ${secondString && ` ${secondString}`}`;
    } else if (!hours && minutes > 0) {
      return `${minuteString} ${secondString && ` ${secondString}`}`;
    }

    return secondString;
  }

}

export {
  StopwatchPipe,
};
