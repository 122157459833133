import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class RightSidebarComponent extends BaseComponent {

  @Input() 
  isOpened: boolean = false;

  @Input() 
  isInternalOpened: boolean = false;

  toggle(isOpened: boolean) {
    this.isOpened = isOpened;

    if (!isOpened) {
      this.isInternalOpened = false;
    }
  }

  toggleInternal(isOpened: boolean) {
    this.isInternalOpened = isOpened;
  }

}

export {
  RightSidebarComponent,
};
