import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DateTime } from 'luxon';
import { UtilsService } from '@jit/core';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '../../icon-simple/icon-simple.component';
import { CalendarListComponent } from '../calendar-list/calendar-list.component';

@Component({
  selector: 'jit-calendar-input',
  templateUrl: './calendar-input.component.html',
  styleUrls: ['./calendar-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    CalendarListComponent,
  ],
})
class CalendarInputComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input()
  value!: DateTime | void | null;

  @Input()
  sundayFirst: boolean = false;

  @Input()
  placeholder: string = 'Choose date';

  @Output()
  change: EventEmitter<DateTime> = new EventEmitter<DateTime>();

  @ViewChild('input', { static: false })
  input!: ElementRef<HTMLDivElement>;

  @ViewChild('popup', { static: false })
  popup!: ElementRef<HTMLDivElement>;

  @ViewChild('list', { static: false })
  list!: CalendarListComponent;

  private _handlerClickOutside!: any;
  private _handlerListPosition!: any;
  private _viewPort!: any;

  public isOpen: boolean = false;
  public listStyle: Record<string, string | number> = {};

  ngOnInit(): void {
    this._handlerClickOutside = (e: Event) => this.handlerClickOutside(e);
    this._handlerListPosition = UtilsService.debounce(() => this.handlerListPosition(), 100);

    document.addEventListener('click', this._handlerClickOutside, false);
    window.addEventListener('resize', this._handlerListPosition, true);

    this._viewPort = document.getElementsByClassName('ng-native-scrollbar-hider')[0];

    if (this._viewPort) {
      this._viewPort.addEventListener('scroll', this._handlerListPosition, true);
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this._handlerClickOutside, false);
    window.removeEventListener('resize', this._handlerListPosition);

    if (this._viewPort) {
      this._viewPort.removeEventListener('scroll', this._handlerListPosition);
    }

    this._handlerClickOutside = null;
    this._handlerListPosition = null;
    this._viewPort = null;
  }

  onChange(date: DateTime): void {
    this.change.emit(date);

    this.isOpen = false;
  }

  onToggle(e: Event): void {
    this.isOpen = !this.isOpen;

    this.isOpen && this.handlerListPosition();

    if (this.isOpen && this.list) {
      this.list.init();
    }
  }

  handlerClickOutside(e: Event): void {
    if (this.input && this.input.nativeElement && this.input.nativeElement.contains(e.target as HTMLElement)) {
      return;
    }

    this.isOpen = false;

    this.refresh()
  }

  handlerListPosition(): void {
    const parent = this.input.nativeElement;
    const popup = this.popup.nativeElement;

    if (parent && popup) {
      const parentRect: DOMRect = parent.getBoundingClientRect();
      const rect: DOMRect = popup.getBoundingClientRect();

      let left: number = parentRect.x + parentRect.width - rect.width;
      let top: number = parent.offsetTop + parentRect.height + 4;

      this.listStyle = {
        top: top + 'px',
        left: left + 'px',
      };

      this.refresh();
    }
  }

}

export {
  CalendarInputComponent,
};
