import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { BaseComponent, IEntityFilters, useStates } from '@jit/data-layer';
import { IconSimpleComponent, SimpleSelectComponent } from '@jit/shared';

@Component({
  selector: 'jit-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    SimpleSelectComponent,
  ],
})
class SelectStateComponent extends BaseComponent {

  @useStates('states')
  states: IEntityFilters[] = [];

  @useStates('state')
  state: IEntityFilters | void = void(0);

  @useStates('isFirstLoaded')
  isFirstLoaded: boolean = true;

  @useStates('isSkipped')
  isSkipped: boolean = false;

  @ViewChild('dropdown', {static: false})
  dropdown!: SimpleSelectComponent;

  selectedState: IEntityFilters | void = void(0);
  isChanged: boolean = false;

  onChanged(state: any | void): void {
    this.selectedState = state;

    this.isChanged = true;
  }

  private _reset(): void {
    this.isChanged = false;

    if (this.state) {
      this.selectedState = this.state;
      this.state = { ...this.state };
    } else {
      this.selectedState = void(0);
      
      this?.dropdown?.reset();
    }
  }

  onSkip(): void {
    this._reset();

    useStates.getState().skip();
  }

  onClose(): void {
    this._reset();

    useStates.getState().close();
  }

  onApply(): void {
    if (this.isChanged) {
      useStates.getState().setState(this.selectedState);

      this.onClose();
    }
  }

}

export {
  SelectStateComponent,
};
