import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UtilsService } from '@jit/core';
import { CheckboxComponent } from '../../checkbox/checkbox.component';

@Component({
  selector: 'jit-nps-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CheckboxComponent,
  ],
})
class NpsQuestionComponent implements OnInit, OnDestroy {

  @Input()
  title: string = '';

  @Input()
  value: boolean = false;

  @Input()
  text: string = '';

  @Input()
  placeholder: string = 'Can you tell us more?';

  @Input()
  showText: boolean = false;

  @Output()
  valueChanged: EventEmitter<boolean> = new EventEmitter();

  @Output()
  textChanged: EventEmitter<string> = new EventEmitter();

  private _debounceText: any;

  ngOnInit(): void {
    this._debounceText = UtilsService.debounce((text: string) => this.handleText(text));
  }

  ngOnDestroy(): void {
    this._debounceText = null;
  }

  onValue(value: boolean): void {
    this.value = value;

    this.valueChanged.emit(value);
  }

  handleText(text: string): void {
    this.text = text;

    this.textChanged.emit(text);
  }

  onText(e: KeyboardEvent): void {
    const el: HTMLTextAreaElement = e.target as HTMLTextAreaElement;
    
    if (el) {
      this._debounceText(el.value);
    }
  }

}

export {
  NpsQuestionComponent,
};
