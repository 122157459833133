interface IAuthTransferData {
  codeVerifier: string;
  state: string;
  nonce: string;
  codeChallenge: string;
}

interface IDataToken {
  access_token: string;
  expires_in: number;
  id_token: string;
  scope: string;
  token_type: string;
  [key: string]: string | number;
}

interface IUserContentSubscription {
  subject: string;
  hasSubscription: string;
}

interface IUserInfo {
  name: string;
  given_name: string;
  family_name: string;
  role: string[];
  oid: number;
  domainId: string;
  multi_acct: boolean;
  sub: number;
  state?: string;
  allowCreateAssignment?: boolean;
  contentSubscriptions: IUserContentSubscription[];
}

interface IToken {
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
  client_id: string;
  sub: string;
  auth_time: number,
  idp: string;
  domainOid: string;
  jti: string;
  sid: string;
  iat: number;
  scope: string[],
  amr: string[],
}

interface IOAuthConfig {
  clientId: string;
  redirectUri: string;
  logoutRedirectUri: string;
  authEndpoint: string;
  tokenEndpoint: string;
  userInfo: string;
  scope: string;
  endSession: string;
  authorize: string;
  revocationEndpoint: string;
}

export {
  IAuthTransferData,
  IDataToken,
  IUserInfo,
  IToken,
  IOAuthConfig,
  IUserContentSubscription,
};
