import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { NpsService } from '../services/nps.service';
import { INpsState, INpsAvailable, INpsSurvey, INpsAnswer } from '../interfaces';

const useNps = createStore<INpsState>((set, get) => ({
  buttons: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  descr: `We'd love to hear about your experience with Istation. 
          Help us improve by filling out this short 2 question survey. 
          We read every single response.`,
  question: 'How likely are you to recommend Istation to a colleague?',
  rating: -1,
  isOpened: false,
  isQuestionsOpened: false,
  questions: [],
  survey: null,
  questionOnPositive: '',
  questionOnNegative: '',

  setRating: async (rating: number) => {
    set({ rating, isQuestionsOpened: true });
  },

  dismiss: async () => {
    const npsService: NpsService = createStore.inject(NpsService);
    
    set({ isOpened: false });

    const survey: INpsSurvey | null = get().survey;

    if (survey) {
      await npsService.dismiss(survey.id);
    }
  },

  done: async (answers: INpsAnswer[]) => {
    const npsService: NpsService = createStore.inject(NpsService);
    const { survey, rating } = get();

    set({ isOpened: false, isQuestionsOpened: false, rating: -1 });

    if (survey && rating >= 0) {
      await npsService.create({ surveyId: survey.id, rating, answers });
    }
  },

  init: async () => {
    const npsService: NpsService = createStore.inject(NpsService);

    const available: INpsAvailable = await npsService.getAvailable();

    if (available && available.available) {
      set({
        survey: available.survey,
        question: available.survey?.title,
        descr: available.survey?.description,
        questions: available.survey?.questions,
        questionOnPositive: available.survey?.questionOnPositive,
        questionOnNegative: available.survey?.questionOnNegative,
      });

      setTimeout(() => get().start(), 2000);
    }
  },

  start: async () => {
    set({ isOpened: true });
  },
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useNps = useNps;
}

export {
  useNps,
};
