<div class="jit-assignment-resources">
  @if (resources.length > 0) {
    <h2>Resource(s) to be assigned</h2>
    <div class="jit-assignment-resources__list">
      @for (item of resources; track $index) {
        <jit-resource-item
          [resource]="item"
          [type]="(resources.length > 1) ? ResourceItemActions.REMOVE : ResourceItemActions.NONE"
          (remove)="onRemove($event)"
          (contentClick)="onContentClick($event)"
        ></jit-resource-item>
      }
    </div>
  }
  
  @if (related && related.length > 0 && resources.length < related.length) {
    <div class="jit-assignment-resources__related" (click)="onRelated()">
      + Add Resource(s)
    </div>
  } @else if (isSearchMode) {
    <div class="jit-assignment-resources__related" (click)="onSearch()">
      + Add Resource(s)
    </div>
  } @else {
    <div class="jit-assignment-resources__related" (click)="onSearch()">
      <jit-icon-simple iconId="search-outline"></jit-icon-simple>
      Search for Additional Resources
    </div>
  }
</div>

<jit-modal-dialog #dialogRemove>
  <ng-container header>
    Remove resource?
  </ng-container>
  <ng-container content>
    This action will remove the resource from your assignment. Are you sure you want to remove?
  </ng-container>
  <ng-container footer>
    <span class="button-default" (click)="onRemoveClose()">Cancel</span>
    <span class="button-active" (click)="confirmRemove()">Yes, remove</span>
  </ng-container>
</jit-modal-dialog>