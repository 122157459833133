import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jit-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {}
