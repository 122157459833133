import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class ExpandService {

  private _expandSeries = new BehaviorSubject<string>('');
  public expandSeries$ = this._expandSeries.asObservable();

  set(id: string): void {
    this._expandSeries.next(id);
  }

}

export {
  ExpandService,
};
