import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

interface ICalendarDay {
  date: DateTime;
  day: number;
  weekDay: number;
  weekdayShort: string;
  isAvailable: boolean;
  isPrevMonth: boolean;
  isNextMonth: boolean;
  isToday: boolean;
}

interface ICalendar {
  month: number;
  year: number;
  days: ICalendarDay[][];
  monthName: string;
  today: DateTime;
}

interface ICalendarParams {
  month?: number | void;
  year?: number | void;
  sundayFirst?: boolean | void;
  canSelectPrev?: boolean | void;
}

const EMPTY_HOURS = { hour: 0, minute: 0, second: 0, millisecond: 0 };

@Injectable({
  providedIn: 'root',
})
class CalendarService {

  private _getCurrent({ month, year }: ICalendarParams): { month: number, year: number, current: any } {
    const current = DateTime.fromObject(EMPTY_HOURS);

    if (!month || (month < 1 || month > 12)) {
      month = current.month;
    }

    if (!year) {
      year = current.year;
    }

    return { month, year, current };
  }

  public getPrevDaysOfMonth(params: ICalendarParams): ICalendarDay[][] {
    let { month, year } = this._getCurrent(params);

    if (month === 1) {
      month = 12;
      year = year - 1;
    } else {
      month = month - 1;
    }

    return this.getDaysOfMonth({ ...params, month, year });
  }

  public getNextDaysOfMonth(params: ICalendarParams): ICalendarDay[][] {
    let { month, year } = this._getCurrent(params);

    if (month === 12) {
      month = 1;
      year = year + 1;
    } else {
      month = month + 1;
    }

    return this.getDaysOfMonth({ ...params, month, year });
  }

  public getDaysOfMonth(params: ICalendarParams): ICalendarDay[][] {
    const { month, year, current } = this._getCurrent(params);
    const result: ICalendarDay[][] = [];
    const firstDay = DateTime.fromObject({ year, month, day: 1, ...EMPTY_HOURS });
    const first = firstDay.day;
    const last = firstDay.endOf('month').day;
    const days: ICalendarDay[] = [];

    for (let day = first; day <= last; day++) {
      const date = DateTime.fromObject({ year, month, day, ...EMPTY_HOURS });
      const weekdayShort = date.weekdayShort || '';
      const isAvailable = (date >= current);
      const isPrevMonth = false;
      const isNextMonth = false;
      const isToday = (date.valueOf() === current.valueOf());

      let weekDay = date.weekday;

      if (params.sundayFirst) {
        if (weekDay === 7) {
          weekDay = 1;
        } else {
          weekDay = weekDay + 1;
        }
      }

      days.push({ date, day, weekDay, weekdayShort, isAvailable, isPrevMonth, isNextMonth, isToday });
    }

    let week: ICalendarDay[] | null = null;

    for (let i = 0; i < days.length; i++) {
      if (week === null) {
        week = Array(7).fill(null);
        result.push(week);
      }

      week[days[i].weekDay - 1] = days[i];

      if (days[i].weekDay === 7) {
        week = null;
      }
    }

    return result;
  }

  public getMonth(params: ICalendarParams): ICalendar {
    const { month, year, current } = this._getCurrent(params);
    const today = current;
    const newParams: ICalendarParams = { ...params, month, year };
    const days = this.getDaysOfMonth(newParams);
    const prevMonth = this.getPrevDaysOfMonth(newParams);
    const nextMonth = this.getNextDaysOfMonth(newParams);
    const firstWeek = days[0];
    const lastWeek = days[days.length - 1];
    const prevLastWeek = prevMonth[prevMonth.length - 1];
    const nextFirstWeek = nextMonth[0];
    const monthName = DateTime.fromObject({ day: 1, month, year}).monthLong as string;

    for (let i = 0; i < firstWeek.length; i++) {
      if (!firstWeek[i]) {
        prevLastWeek[i].isPrevMonth = true;
        firstWeek[i] = prevLastWeek[i];
      }
    }

    for (let i = 0; i < lastWeek.length; i++) {
      if (!lastWeek[i]) {
        nextFirstWeek[i].isNextMonth = true;
        lastWeek[i] = nextFirstWeek[i];
      }
    }

    return { month, year, days, monthName, today };
  }

}

export {
  CalendarService,
  ICalendarDay,
  ICalendar,
  ICalendarParams,
};
