import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { Filter } from '../../models';

@Component({
  selector: 'jit-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgFor, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RangeFilterComponent,
      multi: true,
    },
  ],
})
export class RangeFilterComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  private _destroy = new Subject<void>();
  private _onChange?: (_: any) => void;
  private _onTouched?: any;

  @Input() filter!: Filter;

  fromControl = new FormControl('');
  toControl = new FormControl('');
  withinControl = new FormControl(false);

  ngOnInit(): void {
    combineLatest({
      from: this.fromControl.valueChanges,
      to: this.toControl.valueChanges,
      withinRange: this.withinControl.valueChanges,
    })
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        const { from, to, withinRange } = value;

        if (withinRange && to) {
          this._onChange?.([from, to]);
        } else if (from) {
          this._onChange?.([from]);
        } else {
          this._onChange?.([]);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  writeValue(obj: any): void {
    if (typeof obj === 'string') {
      this.fromControl.patchValue(obj);
    } else if (Array.isArray(obj)) {
      this.fromControl.patchValue(obj[0] || '');
      this.toControl.patchValue(obj[1] || '');

      if (obj[1]) {
        this.withinControl.patchValue(true);
      }
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.fromControl.disable();
      this.toControl.disable();
      this.withinControl.disable();
    } else {
      this.fromControl.enable();
      this.toControl.enable();
      this.withinControl.enable();
    }
  }
}
