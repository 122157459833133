interface IPaginatorItem {
  name: string;
  page: number;
  active: boolean;
  isArrow: boolean;
}

interface IPaginatorChanges {
  item: IPaginatorItem;
  limit: number;
  from: number;
  to: number;
}

interface IPaginatorProps {
  page?: number;
  limit?: number;
  total?: number;
  count?: number;
}

export {
  IPaginatorItem,
  IPaginatorProps,
  IPaginatorChanges,
};
