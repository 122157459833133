<mat-form-field floatLabel="always">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    #select
    [formControl]="toppings"
    [multiple]="multiple"
    (selectionChange)="onSelectionChange()"
    [placeholder]="placeholder"
    [hideSingleSelectionIndicator]="true"
  >
    <mat-select-trigger *ngIf="displayValue">
      {{ displayValue[0] }}
      <span
        *ngIf="(displayValue.length || 0) > 1"
        class="example-additional-selection"
      >
        (+{{ (displayValue.length || 0) - 1 }}
        {{ displayValue.length === 2 ? 'other' : 'others' }})
      </span>
    </mat-select-trigger>

    <mat-option *ngIf="!multiple">Clear</mat-option>

    <ng-container *ngFor="let option of options">
      <ng-container *ngIf="hideDisabled">
        <ng-container *ngIf="option.value && option.available">
          <mat-option [value]="option.value">
            <span *ngIf="!multiple" class="check">
              <jit-icon-simple size="16" iconId="check"></jit-icon-simple>
            </span>
            {{ option.text }}
          </mat-option>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!hideDisabled">
        <mat-option
          [disabled]="!option.value || !option.available"
          [value]="option.value"
        >
          <span *ngIf="!multiple" class="check">
            <jit-icon-simple size="16" iconId="check"></jit-icon-simple>
          </span>
          {{ option.text }}
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
</mat-form-field>
