import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ApiService, IResponse, LoggerService, UtilsService } from '@jit/core';
import { IGradebookKpi, IGradebookCsv, TGradebook } from '../interfaces/gradebook.interface';
import { IGradebookState } from '../interfaces/gradebook-state.interface';
import { ISelectItem } from '../interfaces/search-resources-state.interface';
import { ThSorts } from '../../shared/components/gradebook/th-sort/th-sort.enum';
import { IStudentStats, TByClassroom } from '../interfaces/classroom-view.interface';
import { ILessonStats } from '../interfaces/lesson-view.interface';
import { ResourceTranslatePipe } from '../../shared/pipes/resource-translate.pipe';
import { StopwatchPipe } from '../../shared/pipes/stopwatch.pipe';
import { CsvService } from './csv.service';
import {useUser} from "../stores";

@Injectable({
  providedIn: 'root',
})
class GradebookService {

  private _urlKpi: string = '/v2/api/gradebook/kpi';
  private _urlAutocomplete: string = '/v2/api/gradebook/autocomplete';
  private _urlExport: string = '/v2/api/gradebook/export';
  private _urlGradebook: string = '/v2/api/gradebook';
  private _urlUnassign: string = '/v2/api/assignments/{{ assignmentId }}';
  private _dateFormat: string = 'LL/dd/yy';

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);
  private _csvService: CsvService = inject(CsvService);
  private _resourceTranslatePipe: ResourceTranslatePipe = inject(ResourceTranslatePipe);
  private _stopwatchPipe: StopwatchPipe = inject(StopwatchPipe);

  public static readonly stateKeys: (keyof Partial<IGradebookState>)[] = ['subject', 'view', 'period'];
  public static readonly queryKeys: string[] = [ 'as_subject', 'as_view', 'as_period', 'as_search' ];

  public static readonly views: ISelectItem[] = [
    {
      id: 'classroom',
      title: 'View by Classroom',
    },
    {
      id: 'lesson',
      title: 'View by Lesson',
    },
    // {
    //   id: 'standard',
    //   title: 'View by Standard',
    // },
  ];

  public static readonly periods: ISelectItem[] = [
    {
      id: '7d',
      title: 'Past 7 days',
    },
    {
      id: '30d',
      title: 'Past 30 days',
    },
    {
      id: '60d',
      title: 'Past 60 days',
    },
    {
      id: '90d',
      title: 'Past 90 days',
    },
  ];

  public static readonly statuses: ISelectItem[] = [
    {
      id: 'assigned',
      title: 'Not started',
    },
    {
      id: 'in_progress',
      title: 'In progress',
    },
    {
      id: 'completed',
      title: 'Completed',
    },
  ];

  public static readonly dropboxActions: ISelectItem[] = [
    {
      id: 'edit',
      title: 'Edit details',
    },
    {
      id: 'unassign',
      title: 'Unassign',
      isRed: true,
    },
  ];

  public static readonly viewPlaceholders: Record<string, string> = {
    classroom: 'Search by lesson, standard or student name',
    lesson: 'Search by lesson, standard or student name',
    standard: 'Search by standard, skill or student name',
  };

  public static getDefaultView(): ISelectItem {
    // return GradebookService.views[0];
    return GradebookService.views[1];
  }

  public static getEmptyGradebookKpi(): IGradebookKpi {
    return {
      completed: 0,
      inProgress: 0,
      pastDue: 0,
      selfSelected: 0,
      total: 0,
      notStarted: 0,
    };
  }

  public getPlaceholderByView(view: ISelectItem): string {
    return GradebookService.viewPlaceholders[view.id];
  }

  public stateToUrl(state: Partial<IGradebookState> = {}): void {
    const query: Record<string, string> = this.stateToQuery(state);

    Object.keys(query).forEach((k: string) => {
      query['ac_' + k] = query[k];

      delete query[k];
    });

    UtilsService.setQueryParams(query);
  }

  public stateToQuery(state: Partial<IGradebookState> = {}): Record<string, string> {
    const query: Record<string, string> = GradebookService.stateKeys
      .reduce((acc:  Record<string, string>, key: keyof Partial<IGradebookState>) => {
        if (state[key]) {
          acc[key] = (state[key] as ISelectItem).id;
        }

        return acc;
      }, {});

    if (state.search) {
      query['search'] = state.search;
    }

    if (state.sort) {
      query['sort'] = state.sort;
    }

    if (state.dir) {
      query['dir'] = state.dir;
    }

    return query;
  }

  public getStateFromQuery(): Partial<IGradebookState> {
    const initQuery: any = UtilsService.getQuerySearch(GradebookService.queryKeys);
    const query: any = {};

    Object.keys(initQuery).forEach((k) => {
      if (initQuery[k]) {
        query[k.replace('as_', '')] = initQuery[k];
      }
    });

    return query;
  }

  /**
   * @param resource
   * 0 - subjects
   * 1 - views
   * 2 - periods
   */
  public mapQueryToStateIds(query: any, resources: ISelectItem[][]): Partial<IGradebookState> {
    const newState: Partial<IGradebookState> = GradebookService.stateKeys
      .reduce((acc: Partial<IGradebookState>, key: string, at: number) => {
        const resource: ISelectItem[] = resources[at];

        if (query[key]) {
          const item: ISelectItem | void = resource.find((r) => (r.id === query[key]));

          if (item) {
            (acc as any)[key] = item;
          }
        }

        return acc;
      }, {});

    if (!newState.view) {
      newState.view = GradebookService.getDefaultView();
    }

    if (query.search) {
      newState.search = query.search;
    }

    return newState;
  }

  public async unassign(assignmentId: string): Promise<void> {
    const url: string = UtilsService.format(this._urlUnassign, { assignmentId });

    try {
      await this._apiService.delete<IGradebookKpi>(url);
    } catch (err) {
      this._loggerService.error('GradebookService.unassign: Request error', err);

      throw err;
    }
  }

  public async getKpi(state: Partial<IGradebookState> = {}): Promise<IGradebookKpi> {
    const queryStr = new URLSearchParams(this.stateToQuery(state)).toString();
    const url = this._urlKpi + (queryStr ? '?' + queryStr : '');

    let response: IResponse<IGradebookKpi>;

    try {
      response = await this._apiService.get<IGradebookKpi>(url);
    } catch (err) {
      this._loggerService.error('GradebookService.getKpi: Request error', err);

      throw err;
    }

    if (typeof (response.data as any).assigned === 'number' && typeof (response.data as any).notStarted === 'undefined') {
      (response.data as any).notStarted = (response.data as any).assigned;
    }

    return response.data;
  }

  public async getSuggestions(state: Partial<IGradebookState> = {}): Promise<string[]> {
    const queryStr = new URLSearchParams(this.stateToQuery(state)).toString();
    const url = this._urlAutocomplete + (queryStr ? '?' + queryStr : '');

    let response: IResponse<string[]>;

    try {
      response = await this._apiService.get<string[]>(url);
    } catch (err) {
      this._loggerService.error('GradebookService.getSuggestions: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getGradebook(state: Partial<IGradebookState> = {}): Promise<TGradebook> {
    const queryStr = new URLSearchParams(this.stateToQuery(state)).toString();
    const url = this._urlGradebook + (queryStr ? '?' + queryStr : '');

    let response: IResponse<TGradebook>;

    try {
      response = await this._apiService.get<TGradebook>(url);
    } catch (err) {
      this._loggerService.error('GradebookService.getSuggestions: Request error', err);

      throw err;
    }

    if (state.view?.id === 'classroom') {
      response.data.forEach((classRoom: any) => {
        classRoom.students.forEach((student: any) => {
          student.results.forEach((assignent: any) => {
            assignent.startDateDT = assignent.startedAt ? DateTime.fromISO(assignent.startedAt, {zone: 'utc'}) : '--';
            assignent.formatedStartDate = assignent.startedAt  ? assignent.startDateDT.toFormat(this._dateFormat) : '--';

            if (assignent.dueDate) {
              assignent.dueDateDT = assignent.dueDate ? DateTime.fromISO(assignent.dueDate, {zone: 'utc'}) : '--';
              assignent.formatedDueDate = assignent.dueDateDT.toFormat(this._dateFormat);
            } else {
              assignent.dueDateDT = null;
              assignent.formatedDueDate = '--';
            }

            const statusTitle = GradebookService.statuses.find((i) => (i.id === assignent.status));
            assignent.statusTitle = statusTitle ? statusTitle.title : 'unknown';
          });

          student.origin = [ ...student.results ];
        });
      });
    } else if (state.view?.id === 'lesson') {
      response.data.forEach((lesson: any) => {
        lesson.results.forEach((student: any) => {
          student.startDateDT = student.startedAt ? DateTime.fromISO(student.startedAt, {zone: 'utc'}) : '--';
          student.formatedStartDate = student.startedAt ? student.startDateDT.toFormat(this._dateFormat) : '--';

          if (student.dueDate) {
            student.dueDateDT = student.dueDate ? DateTime.fromISO(student.dueDate, {zone: 'utc'}) : '--';
            student.formatedDueDate = student.dueDateDT.toFormat(this._dateFormat);
          } else {
            student.dueDateDT = null;
            student.formatedDueDate = '--';
          }

          const statusTitle = GradebookService.statuses.find((i) => (i.id === student.status));
          student.statusTitle = statusTitle ? statusTitle.title : 'unknown';
        });

        lesson.origin = [ ...lesson.results ];
      });
    }

    return response.data;
  }

  sortAssignmentBy(name: string, direction: ThSorts, student: IStudentStats): void {
    student.results = [ ...(student.origin || []) ] as any[];

    if (name === 'lesson') {
      if (direction === ThSorts.ASC) {
        student.results.sort((a: any, b: any) => {
          return a.title.localeCompare(b.title);
        });
      } else if (direction === ThSorts.DESC) {
        student.results.sort((a: any, b: any) => {
          return b.title.localeCompare(a.title);
        });
      }
    } else if (name === 'skill') {

    } else if (name === 'score') {
      if (direction === ThSorts.ASC) {
        student.results.sort((a: any, b: any) => {
          return a.score < b.score ? -1 : 1;
        });
      } else if (direction === ThSorts.DESC) {
        student.results.sort((a: any, b: any) => {
          return a.score < b.score ? 1 : -1;
        });
      }
    } else if (name === 'time') {
      if (direction === ThSorts.ASC) {
        student.results.sort((a: any, b: any) => {
          return a.duration < b.duration ? -1 : 1;
        });
      } else if (direction === ThSorts.DESC) {
        student.results.sort((a: any, b: any) => {
          return a.duration < b.duration ? 1 : -1;
        });
      }
    } else if (name === 'started') {
      if (direction === ThSorts.ASC) {
        student.results.sort((a: any, b: any) => {
          return a.startDateDT < b.startDateDT ? -1 : 1;
        });
      } else if (direction === ThSorts.DESC) {
        student.results.sort((a: any, b: any) => {
          return a.startDateDT < b.startDateDT ? 1 : -1;
        });
      }
    } else if (name === 'due') {
      if (direction === ThSorts.ASC) {
        student.results.sort((a: any, b: any) => {
          if (a.dueDateDT && b.dueDateDT) {
            return a.dueDateDT < b.dueDateDT ? -1 : 1;
          } else if (!a.dueDateDT && b.dueDateDT) {
            return -1;
          } else if (a.dueDateDT && !b.dueDateDT) {
            return 1;
          } else {
            return 0;
          }
        });
      } else if (direction === ThSorts.DESC) {
        student.results.sort((a: any, b: any) => {
          if (a.dueDateDT && b.dueDateDT) {
            return a.dueDateDT < b.dueDateDT ? 1 : -1;
          } else if (!a.dueDateDT && b.dueDateDT) {
            return 1;
          } else if (a.dueDateDT && !b.dueDateDT) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
  }

  sortLessonBy(name: string, direction: ThSorts, lesson: ILessonStats): void {
    lesson.results = [ ...(lesson.origin || []) ] as any[];

    if (name === 'student') {
      if (direction === ThSorts.ASC) {
        lesson.results.sort((a: any, b: any) => {
          const tA = a.lastName + ', ' + a.firstName;
          const tB = b.lastName + ', ' + b.firstName;

          return tA.localeCompare(tB);
        });
      } else if (direction === ThSorts.DESC) {
        lesson.results.sort((a: any, b: any) => {
          const tA = a.lastName + ', ' + a.firstName;
          const tB = b.lastName + ', ' + b.firstName;

          return tB.localeCompare(tA);
        });
      }
    } else if (name === 'score') {
      if (direction === ThSorts.ASC) {
        lesson.results.sort((a: any, b: any) => {
          return a.score < b.score ? -1 : 1;
        });
      } else if (direction === ThSorts.DESC) {
        lesson.results.sort((a: any, b: any) => {
          return a.score < b.score ? 1 : -1;
        });
      }
    } else if (name === 'time') {
      if (direction === ThSorts.ASC) {
        lesson.results.sort((a: any, b: any) => {
          return a.duration < b.duration ? -1 : 1;
        });
      } else if (direction === ThSorts.DESC) {
        lesson.results.sort((a: any, b: any) => {
          return a.duration < b.duration ? 1 : -1;
        });
      }
    } else if (name === 'started') {
      if (direction === ThSorts.ASC) {
        lesson.results.sort((a: any, b: any) => {
          return a.startDateDT < b.startDateDT ? -1 : 1;
        });
      } else if (direction === ThSorts.DESC) {
        lesson.results.sort((a: any, b: any) => {
          return a.startDateDT < b.startDateDT ? 1 : -1;
        });
      }
    } else if (name === 'due') {
      if (direction === ThSorts.ASC) {
        lesson.results.sort((a: any, b: any) => {
          if (a.dueDateDT && b.dueDateDT) {
            return a.dueDateDT < b.dueDateDT ? -1 : 1;
          } else if (!a.dueDateDT && b.dueDateDT) {
            return -1;
          } else if (a.dueDateDT && !b.dueDateDT) {
            return 1;
          } else {
            return 0;
          }
        });
      } else if (direction === ThSorts.DESC) {
        lesson.results.sort((a: any, b: any) => {
          if (a.dueDateDT && b.dueDateDT) {
            return a.dueDateDT < b.dueDateDT ? 1 : -1;
          } else if (!a.dueDateDT && b.dueDateDT) {
            return 1;
          } else if (a.dueDateDT && !b.dueDateDT) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
  }

  getCsvHeader(): string[] {
    return [
      'Student',
      'Classroom',
      'Lesson Name',
      'Suite',
      'Series',
      'Skill',
      'Standard Code',
      'Standard Description',
      'Learning Target Description',
      'Success Criteria Description',
      'Status',
      'Score',
      'Time',
      'Start Date',
      'Due Date',
      'Completion Date',
      'Assigned or Self-Selected',
    ];
  }

  public async exportToCsv(state: Partial<IGradebookState> = {}): Promise<void> {
    const csvList: (string | number)[][] = [this.getCsvHeader()];
    const queryStr = new URLSearchParams(this.stateToQuery(state)).toString();
    const url = this._urlExport + (queryStr ? '?' + queryStr : '');

    let response: IResponse<IGradebookCsv[]>;

    try {
      response = await this._apiService.get<IGradebookCsv[]>(url);
    } catch (err) {
      this._loggerService.error('GradebookService.getSuggestions: Request error', err);

      throw err;
    }

    for (let i of response.data) {
      const row: (string | number)[] = [];

      let startDateDT;
      let formatedStartDate;

      if (i.startedAt) {
        startDateDT = DateTime.fromISO(i.startDate, {zone: 'utc'});
        formatedStartDate = startDateDT.toFormat(this._dateFormat);
      } else {
        startDateDT = null;
        formatedStartDate = '--';
      }

      let dueDateDT;
      let formatedDueDate;

      if (i.dueDate) {
        dueDateDT = DateTime.fromISO(i.dueDate, {zone: 'utc'});
        formatedDueDate = dueDateDT.toFormat(this._dateFormat);
      } else {
        dueDateDT = null;
        formatedDueDate = '--';
      }

      let completedDateDT;
      let formatedCompletedDate;

      if (i.completedDate) {
        completedDateDT = DateTime.fromISO(i.completedDate, {zone: 'utc'});
        formatedCompletedDate = completedDateDT.toFormat(this._dateFormat);
      } else {
        completedDateDT = null;
        formatedCompletedDate = '--';
      }

      const statusTitleItem = GradebookService.statuses.find((s) => (s.id === i.status));

      const statusTitle = statusTitleItem ? statusTitleItem.title : 'unknown';

      row.push(i.student);
      row.push(i.classroom);
      row.push(i.lesson);
      row.push(i.suite || '--');
      row.push((i.series || [])[0] || '--');
      row.push(i.skill);
      row.push(i.academicStandard);
      row.push(i.standardDescription);
      row.push(
        this._resourceTranslatePipe.transform('learningTarget', i.language) +
        ' ' +
        i.learningTarget +
        (i.learningTarget[i.learningTarget.length - 1] !== '.' ? '.' : '')
      );
      row.push(
        this._resourceTranslatePipe.transform('successCriteria', i.language) +
        ': ' +
        i.successCriteria.join(', ')
      );
      row.push(statusTitle);
      row.push(i.score ? i.score + '%' : '--');
      row.push(i.duration ? this._stopwatchPipe.transform(i.duration) : '--');
      row.push(formatedStartDate);
      row.push(formatedDueDate);
      row.push(formatedCompletedDate);
      row.push(i.selfSelected ? 'self-selected' : 'assigned');

      csvList.push(row);
    }

    const userState: any = useUser.getState();
    this._csvService.download(csvList, userState.user.displayName);
  }

  each(type: string, gradebook: TGradebook, cb: (...args: any[]) => void): void {
    if (type === 'classroom') {
      gradebook.forEach((classRoom: any) => {
        classRoom.students.forEach((student: any) => {
          student.results.forEach((assignent: any) => {
            cb(assignent, student, classRoom);
          });
        });
      });
    } else if (type === 'lesson') {
      gradebook.forEach((lesson: any) => {
        lesson.results.forEach((student: any) => {
          cb(student, lesson);
        });
      });
    }
  }

  eachClassroom(gradebook: TGradebook, cb: (...args: any[]) => void): void {
    this.each('classroom', gradebook, cb);
  }

  eachLesson(gradebook: TGradebook, cb: (...args: any[]) => void): void {
    this.each('lesson', gradebook, cb);
  }

  formatDueDate(entity: any, startDate: DateTime, dueDate: DateTime | null | void): void {
    entity.startDate = startDate.toFormat('yyyy-LL-dd') + 'T00:00:00.000Z';

    if (dueDate) {
      entity.dueDate = dueDate.toFormat('yyyy-LL-dd') + 'T00:00:00.000Z';
      entity.dueDateDT = dueDate ? dueDate : '--';
      entity.formatedDueDate = dueDate.toFormat(this._dateFormat);
    } else {
      entity.dueDate = null;
      entity.dueDateDT = null;
      entity.formatedDueDate = '--';
    }
  }

}

export {
  GradebookService,
  TGradebook,
};
