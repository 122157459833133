const app: Record<string, string> = {
  name: 'Istation - Just In Time',
  commit: 'aa8724e',
  buildDate: '2024-09-09T15:45:22.851Z',
  branch: 'dev',
};

export {
  app,
};
