import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { useAssignment, useUser } from '@jit/data-layer';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ReportIconsComponent } from '../gradebook/icons/icons.component';

@Component({
  selector: 'jit-new-assignment-button',
  templateUrl: './new-assignment-button.component.html',
  styleUrls: ['./new-assignment-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ReportIconsComponent,
  ],
})
export class NewAssignmentButtonComponent {

  @Input()
  iconBg: string = '';

  @useUser('allowCreateAssignment')
  allowCreateAssignment: boolean = false;

  onNewAssignment(): void {
    if (this.allowCreateAssignment) {
      useAssignment.getState().start();
    }
  }

}
