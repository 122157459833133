import { reduce, sort } from 'ramda';
import { Group, GroupResponse } from './group';
import { IconId } from './icon';
import { Resource } from './resource';
import { Suite } from './suites';

export class Bundle extends Group {
  type = 'bundle';
  suite: Suite | null;
  resources: Resource[];
  name: string;
  color: string;
  colorName: string;
  typeIcon: IconId = 'bundle-mono';
  typeName = 'bundle';

  static isBundleType(payload: GroupResponse) {
    return payload.type === 'ParentSerie';
  }

  get standards() {
    return Array.from(
      reduce(
        (acc, item) => {
          item.standards.forEach((el) => acc.add(el));
          return acc;
        },
        new Set<string>(),
        this.resources
      ).values()
    );
  }

  get tags() {
    return Array.from(
      reduce(
        (acc, item) => {
          item.tags.forEach((el) => acc.add(el));
          return acc;
        },
        new Set<string>(),
        this.resources
      ).values()
    );
  }

  constructor(payload: GroupResponse) {
    super(payload);

    this.name = payload.name;
    this.suite = this.parseSuite(payload.parentGroupId);
    this.resources = this.parseResources(payload);
    this.color = this.resources[0]?.color;
    this.colorName = this.resources[0]?.colorName;
  }

  clone() {
    return this._payload;
  }

  private parseSuite(value?: GroupResponse | null | string): Suite | null {
    if (typeof value === 'string' || value === null || value === undefined) {
      return null;
    }

    return new Suite(value);
  }

  private parseResources(payload: GroupResponse) {
    const resources =
      payload.materials?.map((el) => new Resource(el, payload.lang)) || [];

    return sort((a, b) => a.episode - b.episode, resources);
  }
}
