import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-report-question-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ReportQuestionMultiSelectComponent {
  
}

export {
  ReportQuestionMultiSelectComponent,
};
