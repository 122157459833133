import {
  Component,
  inject,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import {
  HeaderComponent,
  FooterComponent,
  Page,
  pages,
  SidenavComponent,
  InitService,
  ApiInterceptor,
} from '@jit/core';
import {
  SearchBarComponent,
  SearchService,
  FeedbackButtonComponent,
  NotificationsComponent,
} from '@jit/shared';
import { StoreModule } from './data-layer/store';
import { 
  CollectionService, 
  useNotifications, useStates, useNps, useUser, useResize,
} from '@jit/data-layer';
import { ModalsComponent } from './features/modals/modals.component';

@Component({
  selector: 'jit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    StoreModule,
    NgScrollbarModule,
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    SearchBarComponent,
    SidenavComponent,
    FeedbackButtonComponent,
    NotificationsComponent,
    ModalsComponent,
  ],
})
export class AppComponent implements OnInit {

  @ViewChild(NgScrollbar)
  scrollbarRef!: NgScrollbar;

  private _initService: InitService = inject(InitService);
  private _apiInterceptor: ApiInterceptor = inject(ApiInterceptor);
  private _collectionService: CollectionService = inject(CollectionService);
  
  private searchService = inject(SearchService);
  private router = inject(Router);
  private _page = new BehaviorSubject<Page | null>(null);

  public page$ = this._page.asObservable();
  public hasSearchBar$ = this.searchService.hasSearchBar$;

  public ready$!: any;

  public async ngOnInit(): Promise<void> {
    this.ready$ = this._initService.ready$;

    this.ready$.subscribe((ready: boolean) => {
      if (ready) {
        [useStates, useNotifications, useNps, useUser, useResize].forEach((s) => s.getState().init());
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const page = pages.find((el) => {
          if (el.route === '/') {
            return (event.url === el.route);
          } else {
            return event.url.includes(el.route);
          }
        });

        page ? this._page.next(page) : this._page.next(null);
        
        page?.hasSearchBar ? this.searchService.showSearchBar() : this.searchService.hideSearchBar();
      }
    });
  }

}
