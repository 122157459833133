import { importProvidersFrom, ENVIRONMENT_INITIALIZER, inject, ErrorHandler } from '@angular/core';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient,
  withInterceptors,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { environment } from './environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app/app.routes';
import { requestInterceptor, ResponseInterceptor } from './app/core/interceptors';
import { ENV } from './app/shared/models/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { 
  GA4Service, 
  SimpleSelectService, 
  SearchInputService, 
  ResourceTranslatePipe, 
  StopwatchPipe,
} from '@jit/shared';
import {
  ApiService,
  LoggerErrorHandler,
  LoggerService,
  LoggerInterceptor,
  CorrelationService,
  OAuthService,
  OAuthUtilsService,
  OAuthStorageService,
  OAuthConfigService,
  InitService,
  OAuthGuard,
  ApiInterceptor,
  UserService,
  LatexService,
} from '@jit/core';

import {
  ParamsService,
  FiltersService,
  ResourceService,
  ExpandService,
  CollectionService,
  DateService,
  CalendarService,
  AssignmentService,
  SuggestionsService,
  GradeService,
  NpsService,
  PaginatorService,
  GradebookService,
  CsvService,
  useFeatures,
} from '@jit/data-layer';

import { app } from './environments/version';

(window as any).jitApp = app;

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Provide global sandbox for testing and debugging
if (useFeatures.getState().sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => inject(GA4Service).init(),
    },
    provideHttpClient(
      withInterceptors([requestInterceptor]),
      withInterceptorsFromDi()
    ),
    provideAnimations(),
    provideRouter(
      APP_ROUTES
      // withDebugTracing()
    ),
    OAuthGuard,
    ResourceTranslatePipe, 
    StopwatchPipe,
    ApiService,
    OAuthUtilsService,
    OAuthStorageService,
    OAuthConfigService,
    OAuthService,
    CorrelationService,
    LoggerService,
    InitService,
    ParamsService,
    FiltersService,
    ResourceService,
    CollectionService,
    ExpandService,
    DateService,
    CalendarService,
    AssignmentService,
    ApiInterceptor,
    SimpleSelectService,
    SearchInputService,
    SuggestionsService,
    GradeService,
    NpsService,
    UserService,
    LatexService,
    PaginatorService,
    GradebookService,
    CsvService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerInterceptor,
      deps: [LoggerService],
      multi: true ,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      deps: [OAuthService],
      multi: true ,
    },
    {
      provide: ErrorHandler,
      useClass: LoggerErrorHandler,
      deps: [LoggerService]
    },
    importProvidersFrom(
      MatSnackBarModule,
      LayoutModule,
      PortalModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: ENV,
      useValue: environment,
    },
  ],
}).catch((err) => console.error(err));
