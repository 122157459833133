<div class="jit-nps-answers">
  <div class="jit-nps-answers__header">
    <h2>Quick question...</h2>
  </div>
  <div class="jit-nps-answers__content">
    <div class="jit-nps-answers__title">
      @if (rating <= 8) {
        <h3>{{ questionOnNegative }}</h3>
      } @else {
        <h3>{{ questionOnPositive }}</h3>
      }
      <p>Check all that apply.</p>
    </div>
    @if (isQuestionsOpened) {
      <div class="jit-nps-answers__answers">
        @for (question of questions; track $index) {
          <jit-nps-question
            [title]="question.title"
            [value]="selectedAnswers[question.id] ? selectedAnswers[question.id].selected : false"
            [showText]="question.expanded"
            [placeholder]="question.placeholder || ''"
            (valueChanged)="onSelect(question, $event)"
            (textChanged)="onText(question, $event)"
          ></jit-nps-question>
        }
      </div>
    }
  </div>
  <div class="jit-nps-answers__footer">
    <h3>
      We appreciate your feedback and your desire to help make our products better!
    </h3>
    <p>
      We're working on a lot of exciting things and look forward to
      providing you with better tools you can use in your classroom.
    </p>
    <div class="jit-nps-answers__done" (click)="onDone()">
      Done
    </div>
  </div>
</div>