import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-report-question-fib-typed',
  templateUrl: './fib-typed.component.html',
  styleUrls: ['./fib-typed.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ReportQuestionFibTypedComponent {
  
}

export {
  ReportQuestionFibTypedComponent,
};
