<div class="jit-assignment-search">
  <div class="jit-assignment-search__header">
    <div class="jit-assignment-search__header-title">
      <h2>Search Resources</h2>
    </div>
    <div class="jit-assignment-search__header-item">
      <div class="jit-assignment-search__header-item-cell">
        <label>Subjects</label>
        <jit-simple-select
          placeholder="Select"
          [minListWidth]="200"
          [options]="subjects"
          (changed)="onFilterChanged('setSubjectId', $event)"
        ></jit-simple-select>
      </div>
      <div class="jit-assignment-search__header-item-cell">
        <label>Grade</label>
        <jit-simple-select
          #refGrade
          placeholder="Select"
          [disabled]="!subjectId"
          [minListWidth]="200"
          [options]="grades"
          (changed)="onFilterChanged('setGradeId', $event)"
        ></jit-simple-select>
      </div>
      <div class="jit-assignment-search__header-item-cell">
        <label>Domain</label>
        <jit-simple-select
          #refDomain
          placeholder="Select"
          [disabled]="(!subjectId || !gradeId)"
          [minListWidth]="200"
          [options]="domains"
          (changed)="onFilterChanged('setDomainId', $event)"
        ></jit-simple-select>
      </div>
      <!-- <div class="jit-assignment-search__header-item-cell">
        <label>State</label>
        <jit-simple-select
          #refState
          placeholder="Select"
          [disabled]="(!subjectId || !gradeId || !domainId)"
          [minListWidth]="200"
          [options]="states"
          (changed)="onFilterChanged('setStateId', $event)"
        ></jit-simple-select>
      </div> -->
    </div>
    <div class="jit-assignment-search__header-item search">
      <jit-search-input
        [options]="suggestions"
        (changed)="onSearchChanged($event)"
      ></jit-search-input>
    </div>
  </div>
  <div class="jit-assignment-search__content">
    @if (allResources && allResources.suites && allResources.suites.length === 0 && allAdditionalResources.length === 0) {
      <h2>0 available resources found</h2>
    }
    @if (allResources) {
      @for (suite of allResources.suites; track suite.id; let suiteIdx = $index) {
        @for (bundle of suite.series; track bundle.id; let bundleIdx = $index) {
          <jit-treeview-resources
            [title]="bundle.title"
            [count]="bundle.resources.length || 0"
            [selected]="bundle.selected || 0"
            [value]="bundle.resources.length === bundle.selected"
            [expanded]="true"
            (changed)="onBundleSelectAll(bundle, $event)"
          >
            @for (resource of bundle.resources; track $index) {
              <jit-resource-item
                [resource]="resource"
                [type]="!!selected[resource.id] ? ResourceItemActions.ADDED : ResourceItemActions.ADD"
                (add)="onAdd(resource, bundle)"
                (added)="onAdded(resource, bundle)"
                (contentClick)="onContentClick($event)"
              ></jit-resource-item>
            }
          </jit-treeview-resources>
        }
      }
    }

    @if (allAdditionalResources && allAdditionalResources.length > 0) {
      <jit-treeview-resources
        title="Standalone Lessons"
        [count]="allAdditionalResources.length || 0"
        [selected]="allAdditionalCount || 0"
        [value]="allAdditionalResources.length === allAdditionalCount"
        [expanded]="true"
        (changed)="onAdditionalSelectAll($event)"
      >
        @for (resource of allAdditionalResources; track $index) {
          <jit-resource-item
            [resource]="resource"
            [type]="!!selected[resource.id] ? ResourceItemActions.ADDED : ResourceItemActions.ADD"
            (add)="onAddAdditional(resource)"
            (added)="onAddedAdditional(resource)"
            (contentClick)="onContentClick($event)"
          ></jit-resource-item>
        }
      </jit-treeview-resources>
    }
  </div>
  <div class="jit-assignment-search__footer">
    <span class="jit-assignment-search__footer-back" (click)="onBack()">
      Back
    </span>
    <span
      [class.jit-assignment-search__footer-add-disabled]="!isSelected"
      [class.jit-assignment-search__footer-add]="isSelected"
      (click)="onApply()"
    >
      Add
    </span>
  </div>
</div>
