import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  
  public store(key: string, value: unknown) {
    const storedValue = JSON.stringify(value);

    localStorage.setItem(key, storedValue);
  }

  public retrive<T>(key: string): T | null {
    const storedValue = localStorage.getItem(key);

    if (!storedValue) {
      return null;
    }

    return JSON.parse(storedValue);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

}
