import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { useNps, INpsQuestion, INpsAnswer } from '@jit/data-layer';
import { NpsQuestionComponent } from '../question/question.component';

@Component({
  selector: 'jit-nps-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NpsQuestionComponent,
  ],
})
class NpsQuestionsComponent {

  @useNps('questions')
  questions: INpsQuestion[] = [];

  @useNps('rating')
  rating: number = -1;

  @useNps('isQuestionsOpened')
  isQuestionsOpened: boolean = false;

  @useNps('questionOnPositive')
  questionOnPositive: string = '';

  @useNps('questionOnNegative')
  questionOnNegative: string = '';

  public selectedAnswers: Record<string, INpsAnswer> = {};

  private _createIfNotExist(question: INpsQuestion): void {
    if (!this.selectedAnswers[question.id]) {
      this.selectedAnswers[question.id] = {
        id: question.id,
        text: '',
        selected: true,
      };
    }
  }

  onSelect(question: INpsQuestion, selected: boolean): void {
    this._createIfNotExist(question);

    this.selectedAnswers[question.id].selected = selected;
  }

  onText(question: INpsQuestion, text: string): void {
    this._createIfNotExist(question);

    this.selectedAnswers[question.id].text = text;
  }

  onDone(): void {
    const answers: INpsAnswer[] = this.questions.map((question: INpsQuestion) => {
      if (this.selectedAnswers[question.id] && this.selectedAnswers[question.id].selected) {
        return this.selectedAnswers[question.id];
      } else {
        return {
          id: question.id,
          text: '',
          selected: false,
        };
      }
    });

    this.selectedAnswers = {};

    useNps.getState().done(answers);
  }

}

export {
  NpsQuestionsComponent,
};
