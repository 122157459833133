import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DateTime } from 'luxon';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent, CalendarInputComponent, TooltipDirective } from '@jit/shared';
import { useAssignment } from '@jit/data-layer';

@Component({
  selector: 'jit-assignment-update-header',
  templateUrl: './update-header.component.html',
  styleUrls: ['./update-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    CalendarInputComponent,
    TooltipDirective,
  ],
})
class AssignmentUpdateHeaderComponent extends BaseComponent {

  @useAssignment('updateStartDate')
  updateStartDate!: DateTime;

  @useAssignment('updateDueDate')
  updateDueDate!: DateTime | void | null;

  onChangeStartDate(updateStartDate: DateTime): void {
    useAssignment.getState().setUpdateStartDate(updateStartDate);
  }

  onChangeDueDate(updateDueDate: DateTime): void {
    useAssignment.getState().setUpdateDueDate(updateDueDate);
  }

}

export {
  AssignmentUpdateHeaderComponent,
};
