import { IResourceResponse } from './resource';

export type GroupResponse = {
  analyzer?: string;
  id?: string;
  _id?: string;
  name: string;
  parentGroupId: null | string | GroupResponse;
  tenantId: number;
  type: string; // 'Suite' | 'ParentSerie';
  materials?: IResourceResponse[];
  lang: string;
  description?: string;
  groups?: GroupResponse[];
  materialsCount?: number;
};

export abstract class Group {
  protected _payload: GroupResponse;
  title: string;
  id: string;
  description?: string;
  materialsCount?: number;

  constructor(payload: GroupResponse) {
    this.id = payload.id || payload._id || '';
    this.title = payload.name;
    this.description = payload.description;
    this._payload = payload;
    this.materialsCount = payload.materialsCount;
  }
}
