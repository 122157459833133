import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormsModule,
} from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'jit-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormsModule],
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() placeholder?: string;

  public disabled?: boolean;
  private onChange: (val: string) => void = noop;
  private onTouch: () => void = noop;
  private innerValue: string = '';

  public get value(): string {
    return this.innerValue;
  }

  public set value(val: string) {
    this.innerValue = val;
    this.onChange(val);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: string): void {
    this.value = value;
  }
}
