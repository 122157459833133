import { Injectable } from '@angular/core';
import { IResponse, IBeforeRequest, IErrorRequest } from './api.interfaces';

type TFn = (...args: any[]) => any;

const beforeRequestInterceptors: Set<TFn> = new Set();
const afterRequestInterceptors: Set<TFn> = new Set();
const errorRequestInterceptors: Set<TFn> = new Set();
const headersRequestInterceptors: Set<TFn> = new Set();

@Injectable({
  providedIn: 'root',
})
class Interceptor {

  // Define local interceptors
  private _beforeRequestInterceptors: Set<TFn> = new Set();
  private _afterRequestInterceptors: Set<TFn> = new Set();
  private _errorRequestInterceptors: Set<TFn> = new Set();
  private _headersRequestInterceptors: Set<TFn> = new Set();

  // Register global interceptors

  public static beforeRequest(fn: TFn): void {
    beforeRequestInterceptors.add(fn);
  }

  public static afterRequest(fn: TFn): void {
    afterRequestInterceptors.add(fn);
  }

  public static errorRequest(fn: TFn): void {
    errorRequestInterceptors.add(fn);
  }

  public static headersRequest(fn: TFn): void {
    headersRequestInterceptors.add(fn);
  }

  // unregister global interceptors

  public static removeBeforeRequest(fn: TFn): void {
    beforeRequestInterceptors.delete(fn);
  }

  public static removeAfterRequest(fn: TFn): void {
    afterRequestInterceptors.delete(fn);
  }

  public static removeErrorRequest(fn: TFn): void {
    errorRequestInterceptors.delete(fn);
  }

  public static removeHeadersRequest(fn: TFn): void {
    headersRequestInterceptors.delete(fn);
  }

  // register local interceptors

  public beforeRequest(fn: TFn): void {
    this._beforeRequestInterceptors.add(fn);
  }

  public afterRequest(fn: TFn): void {
    this._afterRequestInterceptors.add(fn);
  }

  public errorRequest(fn: TFn): void {
    this._errorRequestInterceptors.add(fn);
  }

  public headersRequest(fn: TFn): void {
    this._headersRequestInterceptors.add(fn);
  }

  // unregister local interceptors

  public removeBeforeRequest(fn: TFn): void {
    this._beforeRequestInterceptors.delete(fn);
  }

  public removeAfterRequest(fn: TFn): void {
    this._afterRequestInterceptors.delete(fn);
  }

  public removeErrorRequest(fn: TFn): void {
    this._errorRequestInterceptors.delete(fn);
  }

  public removeHeadersRequest(fn: TFn): void {
    this._headersRequestInterceptors.delete(fn);
  }

  async execBeforeRequest<T>(data: IBeforeRequest<T>): Promise<void> {
    for (const fn of beforeRequestInterceptors) {
      await fn(data);
    }

    for (const fn of this._beforeRequestInterceptors) {
      await fn(data);
    }
  }

  async execAfterRequest<T>(result: IResponse<T>): Promise<void> {
    for (const fn of afterRequestInterceptors) {
      await fn(result);
    }

    for (const fn of this._afterRequestInterceptors) {
      await fn(result);
    }
  }

  async execErrorRequest(data: IErrorRequest): Promise<void> {
    for (const fn of errorRequestInterceptors) {
      await fn(data);
    }

    for (const fn of this._errorRequestInterceptors) {
      await fn(data);
    }
  }

  async execHeadersRequest(headers: Headers): Promise<void> {
    for (const fn of headersRequestInterceptors) {
      await fn(headers);
    }

    for (const fn of this._headersRequestInterceptors) {
      await fn(headers);
    }
  }

}

export {
  Interceptor
};
