import { Injector, NgModule, Injectable, inject } from '@angular/core';
import { createStore } from './create-store';

@Injectable({ providedIn: 'root' })
class StoreService {
  constructor(public injector: Injector) {
    createStore.setInjectorService(this);
  }
}

@NgModule({ providers: [StoreService] })
class StoreModule {
  private _storeService: StoreService = inject(StoreService);
}

export {
  StoreService,
  StoreModule,
};
