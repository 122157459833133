interface IResponse<T> {
  status: number;
  statusText: string;
  data: T;
  headers: HeadersInit;
}

interface IBeforeRequest<T> {
  url: string;
  options: RequestInit;
  data: T | null;
  abort: () => void;
}

interface IErrorRequest {
  status: number;
  statusText: string;
  text?: string | any;
  headers: HeadersInit;
  data?: any;
}

interface IRequestInit extends RequestInit {
  withCredentials?: boolean;
}

export {
  IResponse,
  IRequestInit,
  IBeforeRequest,
  IErrorRequest
};
