import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnDestroy, inject, ViewChild } from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { CollectionButtonComponent } from '../collection-button/collection-button.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ResourceComponent } from '../resource/resource.component';
import { ISeriesEntity, IParentEntity, ExpandService } from '@jit/data-layer';

const seriesColorMap: Record<string, string> = {
  reading: '#aa3c9a',
  lectura: '#ff5e03',
  math: '#354add',
  undefined: '#fff',
};

@Component({
  selector: 'jit-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    IconSimpleComponent,
    RouterLink,
    CollectionButtonComponent,
    ResourceComponent,
  ],
})
class SeriesComponent implements OnInit, OnDestroy {

  @Input() 
  series!: ISeriesEntity;

  @ViewChild('panel', { static: true })
  panel!: MatExpansionPanel;

  private _expandService: ExpandService = inject(ExpandService);
  private _subsription!: any;

  public isOpen: boolean = false;
  public color: string = '';
  public parent!: IParentEntity;

  ngOnInit(): void {
    this.parent = this.series.parentSubject as any;
    this.color = seriesColorMap[this.parent.slug];

    this._subsription = this._expandService.expandSeries$.subscribe((id) => this.handleExpandSeries(id));
  }

  ngOnDestroy(): void {
    this._subsription && this._subsription.unsubscribe();
  }

  handleExpandSeries(id: string): void {
    if (id && this.isOpen && id !== this.series.id) {
      this.panel.close();
    }
  }

  onOpen(): void {
    this.isOpen = true;

    this._expandService.set(this.series.id);
  }

  onClose(): void {
    this.isOpen = false;
  }

}

export {
  SeriesComponent,
  seriesColorMap,
};
