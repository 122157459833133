<section class="filters">
  <header class="header">
    <div class="d-flex items-center">
      <button type="button" class="button close" (click)="closeDialog()">
        <jit-icon-simple iconId="close"></jit-icon-simple>
      </button>

      <h4 class="filters-heading">
        {{ 'search.filters' | translate }}
      </h4>

      <button
        type="button"
        class="button button--primary ms-auto"
        (click)="onApply()"
      >
        {{ 'Apply' | translate }}
      </button>
    </div>

    <p class="filters-subheading">
      <strong *ngIf="getSearchValue()">"{{ getSearchValue() }}"</strong>
      {{ count$ | async }} Results
    </p>
  </header>

  <ng-scrollbar class="filters-scrollbar" [autoHeightDisabled]="false">
    <form [formGroup]="form" class="accordion accordion-flush">
      <div class="filters-group d-md-none">
        <h5
          id="filters-heading-keyword"
          data-bs-toggle="collapse"
          class="filters-group-header accordion-header accordion-button collapsed"
          data-bs-target="#filters-items-keyword"
        >
          {{ 'keyword' }}
        </h5>

        <div
          class="accordion-collapse collapse"
          id="filters-items-keyword"
          aria-labelledby="filters-heading-keyword"
        >
          <div class="filters-group-items">
            <input type="text" name="" formControlName="search" />
          </div>
        </div>
      </div>

      <ng-container *ngFor="let group of filters$ | async; let index = index">
        <div
          *ngIf="!group.isRange && !group.isSingle"
          [formGroupName]="group.key"
          class="filters-group"
        >
          <h5
            [id]="'filters-heading' + index"
            data-bs-toggle="collapse"
            class="filters-group-header accordion-header accordion-button collapsed"
            [attr.data-bs-target]="'#filter-items' + index"
          >
            {{ group.name }}
            <span class="filters-counter" *ngIf="getFilterCount(group.key)">
              {{ getFilterCount(group.key) }}
            </span>
          </h5>

          <div
            class="accordion-collapse collapse"
            [id]="'filter-items' + index"
            [attr.aria-labelledby]="'filters-heading' + index"
          >
            <div class="filters-group-items">
              <ng-container *ngFor="let item of group.options">
                <label
                  *ngIf="form.get(group.key)?.get(item.key)"
                  class="filters-item"
                  [class.disabled]="!item.available"
                >
                  <input 
                    *ngIf="item.available"
                    type="checkbox" 
                    hidden 
                    [formControlName]="item.key" 
                    [disabled]="!item.available"
                  />
                  {{ item.label }}
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="group.isSingle" class="filters-group">
          <h5
            [id]="'filters-heading' + index"
            data-bs-toggle="collapse"
            class="filters-group-header accordion-header accordion-button collapsed"
            [attr.data-bs-target]="'#filter-items' + index"
          >
            {{ group.name }}
            <span class="filters-counter" *ngIf="getFilterCount(group.key)">
              {{ getFilterCount(group.key) }}
            </span>
          </h5>

          <div
            class="accordion-collapse collapse"
            [id]="'filter-items' + index"
            [attr.aria-labelledby]="'filters-heading' + index"
          >
            <div class="filters-group-items">
              <ng-container *ngFor="let item of group.options">
                <label 
                  class="filters-item"
                  [class.disabled]="!item.available"
                >
                  <input
                    *ngIf="item.available"
                    type="radio"
                    hidden
                    [disabled]="!item.available"
                    [formControlName]="group.key"
                    [value]="item.key"
                  />
                  {{ item.label }}
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div
          *ngIf="group.isRange"
          class="filters-group"
          [formGroupName]="group.key"
        >
          <h5
            [id]="'filters-heading' + index"
            data-bs-toggle="collapse"
            class="filters-group-header accordion-header accordion-button collapsed"
            [attr.data-bs-target]="'#filter-items' + index"
          >
            {{ group.name }}
            <span class="filters-counter" *ngIf="getFilterCount(group.key)">
              {{ getFilterCount(group.key) }}
            </span>
          </h5>

          <div
            class="accordion-collapse collapse"
            [id]="'filter-items' + index"
            [attr.aria-labelledby]="'filters-heading' + index"
          >
            <div class="filters-group-items">
              <jit-range-filter
                [formControlName]="group.key"
                [filter]="group"
              ></jit-range-filter>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-scrollbar>

  <div class="filters-footer">
    <button
      type="button"
      class="button button--outline"
      (click)="closeDialog()"
    >
      {{ 'app.close' | translate }}
    </button>
    <button
      type="button"
      class="button button--outline"
      (click)="onReset()"
    >
      {{ 'Clear all' | translate }}
    </button>
    <button 
      type="button" 
      class="button button--primary" 
      (click)="onApply()"
    >
      {{ 'Apply' | translate }}
    </button>
  </div>
</section>
