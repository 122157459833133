import { Injectable, inject } from '@angular/core';
import { OAuthService } from '../services/oauth/oauth.service';
import { ApiService, IBeforeRequest, IErrorRequest } from '../services/api';
import { useNotifications } from '@jit/data-layer';
import { ENV, IEnvironment } from '@jit/shared';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
class ApiInterceptor {

  private readonly _env: IEnvironment = inject(ENV);
  private readonly _oauthService: OAuthService = inject(OAuthService);
  private readonly _storageService: StorageService = inject(StorageService);

  constructor(private readonly _apiService: ApiService) {
    this._apiService.beforeRequest(async (data: IBeforeRequest<unknown>) => await this.handleBeforeRequest(data));
    this._apiService.errorRequest(async (data: IErrorRequest) => await this.handleErrorRequest(data));
  }

  async handleBeforeRequest({ url, abort }: IBeforeRequest<unknown>): Promise<void> {
    if (window?.navigator?.onLine === false) {
      abort();

      useNotifications.getState().showError({ label: 'Network connection error' });
    }

    // skip auth requests
    if (url.includes(this._env.idServerUrl)) {
      return;
    }

    const user = this._oauthService.userInfo;

    if (!user) {
      abort();

      const redirected: boolean = this._storageService.retrive('redirected') || false;

      if (!redirected) {
        this._storageService.store('redirected', true);
  
        setTimeout(() => (window.location.href = '/'), 300);
      }
    }
  }

  async handleErrorRequest({ status, statusText, text }: IErrorRequest): Promise<void> {
    if (status === 401 || status === 403) {
      const redirected: boolean = this._storageService.retrive('redirected') || false;

      if (!redirected) {
        useNotifications.getState().showWarn({ 
          label: 'Your session has expired. You are being redirected to the login page.',
          autoClose: 3000,
        });

        this._storageService.store('redirected', true);
  
        setTimeout(() => (window.location.href = '/'), 1000);
      }
    } else if (status >= 500) {
      useNotifications.getState().showError({ 
        label: statusText + (text ? (': ' + text) : '')
      });
    }
  }
}

export {
  ApiInterceptor,
};
