import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackButtonComponent } from '../../../shared/components/feedback-button/feedback-button.component';

@Component({
  selector: 'jit-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FeedbackButtonComponent,
  ]
})
export class FooterComponent {}
