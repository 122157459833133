import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { BaseComponent, useAssignment, IResourceEntity, ResourceService } from '@jit/data-layer';
import { IconSimpleComponent, ResourceItemComponent, ResourceItemActions } from '@jit/shared';

@Component({
  selector: 'jit-assignment-related',
  templateUrl: './related.component.html',
  styleUrls: ['./related.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ResourceItemComponent,
  ],
})
class AssignmentRelatedComponent extends BaseComponent implements OnInit {

  @useAssignment('related')
  related: IResourceEntity[] = [];

  @useAssignment('resources')
  resources: IResourceEntity[] = [];

  private _resourceService: ResourceService = inject(ResourceService);

  public ResourceItemActions: typeof ResourceItemActions = ResourceItemActions;
  public available: IResourceEntity[] = [];
  public selected: Record<string, boolean> = {};
  public unselect: boolean = false;
  public isSelected: boolean = false;

  ngOnInit(): void {
    this.available = this.related.filter((rel) => {
      const at = this.resources.findIndex((res) => (rel.id === res.id));

      return (at < 0);
    });
  }

  private _updateIsSelected(): void {
    const len: number = Object.keys(this.selected).length;

    this.isSelected = (len > 0);

    this.unselect = (len === this.available.length);
  }

  onSelectAll(selectAll: boolean, e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (selectAll) {
      this.available.forEach((res) => (this.selected[res.id] = true));
    } else {
      this.selected = {};
    }

    this.unselect = selectAll;

    this._updateIsSelected();
  }

  onAdd(resource: IResourceEntity): void {
    this.selected[resource.id] = true;

    this._updateIsSelected();
  }

  onAdded(resource: IResourceEntity): void {
    delete this.selected[resource.id];

    this._updateIsSelected();
  }

  onBack(): void {
    useAssignment.getState().backRelated();
  }

  onApply(): void {
    if (this.isSelected) {
      const selected = this.related.filter((rel) => (this.selected[rel.id]));
  
      useAssignment.getState().addRelated(selected);
    }
  }

  onContentClick(item: IResourceEntity): void {
    this._resourceService.openMicrolesson(item);
  }

  onSearch(): void {
    useAssignment.getState().openSearch({ internalStep: 'search-related' });
  }

}

export {
  AssignmentRelatedComponent,
};
