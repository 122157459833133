import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { IconId } from '../../models';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

type Item = {
  key: string;
  label: string;
  icon?: IconId;
};

@Component({
  selector: 'jit-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CdkMenu,
    CdkMenuTrigger,
    CdkMenuItem,
    IconSimpleComponent,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SortFilterComponent,
      multi: true,
    },
  ],
})
export class SortFilterComponent implements OnChanges, ControlValueAccessor {
  private _onChange?: (_: any) => void;
  private _onTouched?: any;

  @Input() value?: string;

  readonly items: Item[] = [
    // { key: 'relevance', label: 'Relevance' },
    // { key: 'popularity', label: 'Popularity' },
    { key: 'asc', label: 'A to Z', icon: 'asc' },
    { key: 'desc', label: 'Z to A', icon: 'desc' },
  ];

  private _currentValue = new BehaviorSubject<Item>(this.items[0]);

  currentValue$ = this._currentValue.asObservable();

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      const item = this.items.find(
        (el) => el.key === changes['value'].currentValue
      );

      item && this._currentValue.next(item);
    }
  }

  onSelect(item: Item) {
    this._currentValue.next(item);
    this._onChange?.(item.key);
  }

  writeValue(value: string): void {
    const item = this.items.find((el) => el.key === value);

    item && this._currentValue.next(item);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}
