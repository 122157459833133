import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ContentChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, delay, takeUntil } from 'rxjs';
import { FormControlDirective, FormControlName } from '@angular/forms';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-checkbox-control',
  standalone: true,
  imports: [CommonModule, IconSimpleComponent],
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxControlComponent {
  private _destroy = new Subject<void>();

  @Input() value!: string;
  @Output() changed = new EventEmitter<boolean>();
  @HostBinding('class.checked') checked = false;
  @ContentChild(FormControlName) control?: FormControlName;
  @ContentChild(FormControlDirective) controlName?: FormControlDirective;

  ngAfterContentInit(): void {
    const control = this.control || this.controlName;
    const currentValue = this.control?.value || this.controlName?.value;
    this.checked = currentValue;

    control?.valueChanges
      ?.pipe(delay(10), takeUntil(this._destroy))
      .subscribe((value) => {
        if (this.checked !== !!value) {
          this.changed.emit(value);
        }

        this.checked = !!value;
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
