import { StorageService, UserService, IUserData } from '@jit/core';
import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { IStatesState } from '../interfaces/states.interface';
import { IEntityFilters } from '../interfaces/entity-filters.interface';
import { FiltersService } from '../services/filters.service';

const SELECTED_STATE_KEY: string = 'selected_state';
const FIRST_LOADED_KEY: string = 'selected_state_loaded';
const IS_SKIPPED_KEY: string = 'selected_state_skipped';

const useStates = createStore<IStatesState>((set, get) => ({
  states: [],
  state: void(0),
  isStateSelected: false,
  isOpened: false,
  isClosed: false,
  isFirstLoaded: false,
  isSkipped: false,

  init: () => {
    const storageService: StorageService = createStore.inject(StorageService);
    const isFirstLoaded: boolean = !!storageService.retrive<boolean | void>(FIRST_LOADED_KEY);
    const isSkipped: boolean = !!storageService.retrive<boolean | void>(IS_SKIPPED_KEY) || false;

    set({ isFirstLoaded, isSkipped });

    get().loadStates();
  },
  
  open: () => {
    const storageService: StorageService = createStore.inject(StorageService);
    const isFirstLoaded: boolean = !!storageService.retrive<boolean | void>(FIRST_LOADED_KEY);
    const isSkipped: boolean = !!storageService.retrive<boolean | void>(IS_SKIPPED_KEY) || false;

    set({ isOpened: true, isFirstLoaded, isSkipped });

    setTimeout(() => set({ isOpened: false }), 1000);
  },

  close: () => {
    set({ isClosed: true });

    get().setFirstLoaded();

    setTimeout(() => set({ isClosed: false }), 1000);
  },

  skip: () => {
    const storageService: StorageService = createStore.inject(StorageService);

    storageService.store(IS_SKIPPED_KEY, true);

    set({ isSkipped: true });

    get().close();
  },

  setFirstLoaded: () => {
    const storageService: StorageService = createStore.inject(StorageService);

    set({ isFirstLoaded: true });

    storageService.store(FIRST_LOADED_KEY, true);
  },

  loadStates: async () => {
    const userService: UserService = createStore.inject(UserService);
    const storageService: StorageService = createStore.inject(StorageService);
    const filtersService: FiltersService = createStore.inject(FiltersService);
    const slug = storageService.retrive<string | void>(SELECTED_STATE_KEY);

    let isStateSelected: boolean = false;
    let state: IEntityFilters | void = void(0);

    const states: IEntityFilters[] = await filtersService.getAllStates();

    if (slug) {
      state = states.find((s) => (s.value === slug));
    } else {
      const me: IUserData = await userService.me();

      if (me?.state) {
        state = states.find((item: IEntityFilters) => (item.title === me.state))

        if (state) {
          get().setState(state);
        }
      }
    }

    if (state) {
      isStateSelected = true;
    }

    set({ states, state, isStateSelected });

    if (!state && !get().isSkipped) {
      setTimeout(() => get().open(), 1000);
    }
  },
  
  setState: (state: IEntityFilters | void) => {
    const storageService: StorageService = createStore.inject(StorageService);

    set({ state });
    
    if (state) {
      storageService.store(SELECTED_STATE_KEY, state.value);
    } else {
      storageService.remove(SELECTED_STATE_KEY);
    }
  },
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useStates = useStates;
}

export {
  useStates,
};
