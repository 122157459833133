<div class="content">
  <jit-icon-simple *ngIf="params.icon" [iconId]="params.icon"></jit-icon-simple>

  <div class="notification-text">{{ params.text }}</div>
</div>

<button
  type="button"
  class="button action"
  (click)="action()"
  *ngIf="params.hasAction"
>
  Add to Collection
</button>
