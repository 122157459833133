interface ISuggestionsState {
  suggestions: string[];
  search: string;

  setSearchId: (search: string | void) => void;
}

export {
  ISuggestionsState,
};
