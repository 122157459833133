import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@jit/data-layer';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-bottom-sidebar',
  templateUrl: './bottom-sidebar.component.html',
  styleUrls: ['./bottom-sidebar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class BottomSidebarComponent extends BaseComponent {

  @Input()
  isOpened: boolean = false;

  @Input()
  showOverlay: boolean = true;

  toggle(isOpened: boolean) {
    this.isOpened = isOpened;
  }

}

export {
  BottomSidebarComponent,
};
