import { environment } from '../../../environments/environment';
import { UtilsService } from '../../core/services/utils/utils.service';
import { createStore } from '../store';
import { IResizeState } from '../interfaces/resize-state.interface';
import { Resizes } from '../enums/resize.enum';

function resize(): void {
  const w: number = window.innerWidth;

  if (w <= 576) {
    useResize.setState({ resolution: Resizes.MOBILE_PORTRAIT });
  } else if (w > 576 && w <= 768) {
    useResize.setState({ resolution: Resizes.MOBILE_LANDSCAPE });
  } else if (w > 768 && w <= 1024) {
    useResize.setState({ resolution: Resizes.TABLET });
  } else if (w > 1024 && w <= 1200) {
    useResize.setState({ resolution: Resizes.LAPTOP });
  } else if (w > 1200) {
    useResize.setState({ resolution: Resizes.DESKTOP });
  }
}

const handleResize = UtilsService.debounce(() => resize(), 10);

window.addEventListener('resize', handleResize, true);

const useResize = createStore<IResizeState>((set, get) => ({
  resolution: Resizes.DESKTOP,
  init: () => resize(),
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useResize = useResize;
}

export {
  useResize,
};
