<div *ngIf="days && days.length" class="jit-calendar-list" (click)="onClick($event)">
  <div class="jit-calendar-list__header">
    <div class="jit-calendar-list__header-prev">
      <span class="jit-calendar-list__header-prev-year" (click)="onPrevYear($event)">
        <jit-icon-simple iconId="double-lt"></jit-icon-simple>
      </span>
      <span class="jit-calendar-list__header-prev-month" (click)="onPrevMonth($event)">
        <jit-icon-simple iconId="lt"></jit-icon-simple>
      </span>
    </div>
    <div class="jit-calendar-list__header-title">
      {{ monthName }} {{ currentYear }}
    </div>
    <div class="jit-calendar-list__header-next">
      <span class="jit-calendar-list__header-next-month" (click)="onNextMonth($event)">
        <jit-icon-simple iconId="lt"></jit-icon-simple>
      </span>
      <span class="jit-calendar-list__header-next-year" (click)="onNextYear($event)">
        <jit-icon-simple iconId="double-lt"></jit-icon-simple>
      </span>
    </div>
  </div>
  <div class="jit-calendar-list__content">
    <div class="jit-calendar-list__content-legend">
      <ng-container *ngIf="sundayFirst">
        <span class="jit-calendar-list__content-legend-cell">Sun</span>
      </ng-container>
      <span class="jit-calendar-list__content-legend-cell">Mon</span>
      <span class="jit-calendar-list__content-legend-cell">Tue</span>
      <span class="jit-calendar-list__content-legend-cell">Wed</span>
      <span class="jit-calendar-list__content-legend-cell">Thu</span>
      <span class="jit-calendar-list__content-legend-cell">Fri</span>
      <span class="jit-calendar-list__content-legend-cell">Sat</span>
      <ng-container *ngIf="!sundayFirst">
        <span class="jit-calendar-list__content-legend-cell">Sun</span>
      </ng-container>
    </div>
    <div class="jit-calendar-list__content-days">
      <ng-container *ngFor="let week of days">
        <div class="jit-calendar-list__content-days-week">
          <ng-container *ngFor="let day of week">
            <ng-container *ngTemplateOutlet="cell; context:{ $implicit: day }"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="jit-calendar-list__footer" (click)="onToday($event)">Today</div>
</div>

<ng-template #cell let-day>
  <span 
    class="jit-calendar-list__content-days-cell" 
    [class.not-available]="!day.isAvailable"
    [class.prev-month]="day.isPrevMonth"
    [class.next-month]="day.isNextMonth"
    (click)="onDay(day, $event)"
  >
    <span 
      class="jit-calendar-list__content-days-cell-inner" 
      [class.today]="day.isToday"
      [class.selected]="value && value.valueOf() === day.date.valueOf()"
    >
      {{ day.day }}
    </span>
  </span>
</ng-template>