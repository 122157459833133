import { ISelectItem } from './search-resources-state.interface';
import { TByClassroom } from './classroom-view.interface';
import { TByStandard } from './standard-view.interface';
import { ILessonStats } from './lesson-view.interface';

type TGradebook = TByClassroom[] | TByStandard[] | ILessonStats[];

interface IGradebookQuery extends Record<string, any> {
  subject?: ISelectItem;
  view?: ISelectItem;
  period?: ISelectItem;
  search?: string;
}

interface IGradebookKpi {
  completed: number;
  inProgress: number;
  pastDue: number;
  selfSelected: number;
  total: number;
  notStarted: number;
}

interface IGradebookCsv {
  student: string;
  classroom: string;
  lesson: string;
  suite: string;
  series: string[];
  skill: string;
  academicStandard:  string;
  standardDescription:  string;
  stateStandards: string;
  learningTarget: string;
  successCriteria: string[];
  status: string;
  score: number;
  time: number;
  duration: number;
  startDate: string;
  startedAt: string;
  dueDate: string | null;
  completedDate: string | null;
  selfSelected: boolean;
  language: string;
}

export {
  IGradebookKpi,
  IGradebookQuery,
  IGradebookCsv,
  TGradebook,
};
