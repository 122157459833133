import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  HostBinding,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { IconSimpleComponent } from '@jit/shared';
import {
  BehaviorSubject,
  filter,
  map,
  ReplaySubject,
  Subject,
  takeUntil,
} from 'rxjs';
import { Event } from '../../models';
import { EventService, OAuthService, IUserInfo } from '../../services';
import { useStates, IEntityFilters, useFeatures, IFeaturesState, useUser, useAssignment } from '@jit/data-layer';

@Component({
  selector: 'jit-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconSimpleComponent, RouterModule],
})
export class SidenavComponent implements OnInit {

  @useStates('state')
  state: IEntityFilters | void = void(0);

  @useUser('allowCreateAssignment')
  allowCreateAssignment: boolean = false;

  @useFeatures()
  features: IFeaturesState = {};

  private _destroy = new Subject<void>();
  private _resourcesActive = new BehaviorSubject<boolean>(true);
  private _opened = false;

  private _oauthService: OAuthService = inject(OAuthService);
  private _router = inject(Router);
  private _eventService = inject(EventService);
  private _breakpointObserver = inject(BreakpointObserver);

  public resourcesActive$ = this._resourcesActive.asObservable();
  public user!: ReplaySubject<IUserInfo | null>;

  get userName$() {
    return this.user.pipe(
      map((user) => {
        if (user) {
          return `${user.given_name} ${user.family_name[0] || ''}.`
        } else {
          return 'Guest';
        }
      })
    );
  }

  @HostBinding('class.opened') 
  get opened() {
    return this._opened;
  }

  ngOnInit(): void {
    this.user = this._oauthService.userInfo$;

    this._eventService.event$
      .pipe(
        filter((event) => event === Event.OpenNavigation),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this._opened = true;
      });

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.onClose();
          if (
            event.urlAfterRedirects === '/' ||
            event.urlAfterRedirects.includes('/results')
          ) {
            this._resourcesActive.next(true);
          } else {
            this._resourcesActive.next(false);
          }
        }
      });

    this._breakpointObserver
      .observe('(max-width: 767px)')
      .pipe(takeUntil(this._destroy))
      .subscribe((isMobile) => {
        if (!isMobile.matches) {
          this.onClose();
        }
      });
  }

  onClose() {
    this._opened = false;
  }

  onLogout(event: MouseEvent) {
    event.preventDefault();

    this._oauthService.logout();
  }

  public onShowSelectState(): void {
    this.onClose();

    useStates.getState().open();
  }

  onNewAssignment(): void {
    this.onClose();
    useAssignment.getState().start();
  }

}
