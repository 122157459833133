
import { Component, Input, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { extractMath } from 'extract-math'
import { LatexService } from '@jit/core';

@Component({
  selector: 'jit-latex',
  standalone: true,
  imports: [],
  templateUrl: './latex.component.html',
  styleUrls: ['./latex.component.scss'],
})
class LatexComponent {

  @Input({ required: true }) 
  value!: string;

  private _html: any = [];
  
  private _domSanitizer: DomSanitizer = inject(DomSanitizer);
  private _latexService: LatexService = inject(LatexService);
  
  public safeHtml: SafeHtml | undefined;

  ngOnInit() {
    const segments = extractMath(this.value, {
      delimiters: {
        inline: ['$', '$'],
        display: ['$$', '$$']
      }
    })

    for (let i = 0; i < segments.length; i++) {
      if (segments[i]['type'] == 'text') {
        this._html.push(segments[i]['value'])
      } else if (segments[i]['type'] == 'inline') {
        this._html.push(this._latexService.renderToString(segments[i]['value'], { output: 'mathml', throwOnError: false, displayMode: false }))
      } else if (segments[i]['type'] == 'display') {
        this._html.push(this._latexService.renderToString(segments[i]['value'], { output: 'mathml', throwOnError: false, displayMode: true }))
      } else {
        console.warn('An error occurred when parsing the LaTex input. The type of the segment is not recognized.');
      }
    }

    this.safeHtml = this._domSanitizer.bypassSecurityTrustHtml(this._html.join(''));
  }

}

export {
  LatexComponent,
};
