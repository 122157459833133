import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { CourseDurationComponent } from '../course-duration/course-duration.component';
import { CollectionButtonComponent } from '../collection-button/collection-button.component';
import { Bundle, Content, Resource, Suite } from '../../models';

@Component({
  selector: 'jit-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    CourseDurationComponent,
    CollectionButtonComponent,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceCardComponent extends Content {
  @Input() resource!: Resource | Bundle | Suite;
  @Output() public collectionCreate = new EventEmitter();
  @Output() public openDetails = new EventEmitter();

  get cardStyles() {
    return {
      '--main-color': this.resource.color,
    };
  }

  get type() {
    if (this.isBundle(this.resource)) {
      return 'bundle';
    }

    if (this.isSuite(this.resource)) {
      return 'suite';
    }

    return 'resource';
  }

  public createCollection(): void {
    this.collectionCreate.emit(this.resource);
  }
}
