interface IListResponse<T> {
  page: number;
  limit: number;
  total: number;
  rows: T[];
}

export {
  IListResponse
};
