<header>
  <h2 class="header-name">Rename collection</h2>
</header>

<div class="control-wrapper">
  <label class="control-label">Collection title</label>

  <input type="text" class="control" [formControl]="nameControl" />
</div>
<div class="buttons-wrapper">
  <button type="button" class="button button-delete" (click)="onDelete()">
    Remove
  </button>

  <button
    class="button button-action jit-primary-btn-outline"
    (click)="onCancel()"
    type="button"
  >
    Cancel
  </button>
  <button
    class="button button-action btn jit-primary-btn"
    (click)="onSave()"
    type="button"
  >
    Save
  </button>
</div>
