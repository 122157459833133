<ng-container *ngIf="asLink">
  <a href="{{ link }}" class="jit-feedback-link" target="_blank">
    Feedback
  </a>
</ng-container>

<ng-container *ngIf="!asLink">
  <a href="{{ link }}" class="jit-feedback-button" target="_blank">
    <div class="jit-feedback-button__wrap">
      <span class="jit-feedback-button__title">Feedback?</span>
      <span class="jit-feedback-button__descr">Help us make this better.</span>
    </div>
    <div class="jit-feedback-button__icon">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.58 122.88">
        <path d="M25.8,111.27,44.08,94.69a3.46,3.46,0,0,1,2.41-1h66.18a2,2,0,0,0,2-1.95V8.9a2,2,0,0,0-2-1.95H8.9A1.95,1.95,0,0,0,7,8.9V91.76a1.95,1.95,0,0,0,2,1.95H22.33a3.48,3.48,0,0,1,3.47,3.48v14.08Zm1.17-45a3.48,3.48,0,0,0,0,7H68a3.48,3.48,0,0,0,0-7Zm0-39.86a3.48,3.48,0,0,0,0,7H94.69a3.48,3.48,0,1,0,0-6.95Zm0,19.93a3.48,3.48,0,0,0,0,6.95H87.66a3.48,3.48,0,0,0,0-6.95Zm20.9,54.32-23,21.07a3.48,3.48,0,0,1-6.06-2.32V100.66H8.9A8.91,8.91,0,0,1,0,91.76V8.9A8.91,8.91,0,0,1,8.9,0H112.67a8.93,8.93,0,0,1,8.91,8.9V91.76a8.93,8.93,0,0,1-8.91,8.9Z"/>
      </svg>
    </div>
  </a>
</ng-container>