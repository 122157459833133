import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-nps-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class NpsEmojiComponent {

  @Input()
  value: number = 0;

}

export {
  NpsEmojiComponent,
};
