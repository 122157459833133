import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { BaseComponent, CalendarService, ICalendarDay } from '@jit/data-layer';
import { IconSimpleComponent } from '../../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class CalendarListComponent extends BaseComponent implements OnInit, OnChanges {

  @Input()
  month!: number | void;

  @Input()
  year!: number | void;

  @Input()
  value!: DateTime | void | null;

  @Input()
  sundayFirst: boolean = false;

  @Output()
  change: EventEmitter<DateTime> = new EventEmitter<DateTime>();

  private _calendarService: CalendarService = inject(CalendarService);

  public days: ICalendarDay[][] = [];
  public currentMonth!: number;
  public currentYear!: number;
  public monthName!: string;
  public today!: DateTime;

  ngOnInit(): void {
    this.fetch(this.month, this.year);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isChanged = false;
    let month = void(0);
    let year = void(0);

    if (changes && changes['month'] && changes['month'].currentValue !== changes['month'].previousValue) {
      isChanged = true;
      month = changes['month'].currentValue;
    }

    if (changes && changes['year'] && changes['year'].currentValue !== changes['year'].previousValue) {
      isChanged = true;
      year = changes['year'].currentValue;
    }

    if (isChanged) {
      this.fetch(month, year);
    }
  }

  public fetch(_month?: number | void, _year?: number | void): void {
    const { month, year, days, monthName, today } = this._calendarService.getMonth({
      month: _month,
      year: _year,
      sundayFirst: this.sundayFirst,
    });

    this.currentMonth = month;
    this.currentYear = year;
    this.monthName = monthName;
    this.days = days;
    this.today = today;
  }

  public init(): void {
    if (this.value) {
      this.fetch(this.value.month, this.value.year);
    } else {
      this.fetch(this.month, this.year);
    }
  }

  onPrevMonth(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    let month = this.currentMonth;
    let year = this.currentYear;
  
    if (month === 1) {
      month = 12;
      year = year - 1;
    } else {
      month = month - 1;
    }

    this.fetch(month, year);
  }

  onNextMonth(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    let month = this.currentMonth;
    let year = this.currentYear;
  
    if (month === 12) {
      month = 1;
      year = year + 1;
    } else {
      month = month + 1;
    }

    this.fetch(month, year);
  }

  onNextYear(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    let month = this.currentMonth;
    let year = this.currentYear;
  
    year = year + 1;

    this.fetch(month, year);
  }

  onPrevYear(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    let month = this.currentMonth;
    let year = this.currentYear;
  
    year = year - 1;

    this.fetch(month, year);
  }

  onDay(day: ICalendarDay, e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (day.isAvailable) {
      this.change.emit(day.date);
    }
  }

  onToday(e: Event): void {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.change.emit(this.today);
  }

  onClick(e: Event) {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

}

export {
  CalendarListComponent,
};
