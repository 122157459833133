<div class="jit-rating-feedback">
  <div class="jit-rating-feedback__content">
    <div class="jit-rating-feedback__title">{{ title }}</div>
    <div class="jit-rating-feedback__subtitle">
      <span class="jit-rating-feedback__code">{{ code }}</span>
      <span class="jit-rating-feedback__div"> | </span>
      <span class="jit-rating-feedback__time">
        <jit-icon-simple iconId="small-clock" size="16"></jit-icon-simple>
        {{ time }} sec
      </span>
    </div>
  </div>
  <div 
    class="jit-rating-feedback__actions"
    [ngClass]="{ 
      positive: positive, 
      negative: !positive,
    }"
  >
    <jit-report-icons [name]="positive ? 'positive' : 'negative'"></jit-report-icons>
    <span>{{ positive ? 'Yes' : 'Not yet' }}</span>
  </div>
</div>