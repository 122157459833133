<label class="label">
  <span class="control">
    <ng-content></ng-content>
  </span>

  <span class="checkbox">
    <jit-icon-simple size="16" iconId="check"></jit-icon-simple>
  </span>

  <ng-content select="[label]"></ng-content>
</label>
