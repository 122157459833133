import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IGradebookKpi, useGradebook, GradebookService } from '@jit/data-layer';

@Component({
  selector: 'jit-gradebook-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class GradebookKpiComponent {

  @useGradebook('kpi')
  kpi: IGradebookKpi = GradebookService.getEmptyGradebookKpi();

}

export {
  GradebookKpiComponent,
};
