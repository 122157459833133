import { 
  Directive, Input, ElementRef, HostListener, EmbeddedViewRef,
  ComponentRef, ApplicationRef, ComponentFactoryResolver, Injector,
  OnInit, OnDestroy
} from '@angular/core';
import { UtilsService } from '@jit/core';
import { IAssignedStudentsItemResponse } from '@jit/data-layer';
import { ResourceSmallItemListComponent } from '../components/resource-small-item-list/resource-small-item-list.component';

@Directive({
  selector: '[jitResourcesTooltip]',
  standalone: true,
})
class ResourcesTooltipDirective implements OnInit, OnDestroy {
  
  @Input('jitResourcesTooltip')
  resources: IAssignedStudentsItemResponse[] = [];
  
  private _componentRef: ComponentRef<any> | null = null;
  private _listener: any = null;

  constructor(
    private _elementRef: ElementRef,
  	private _appRef: ApplicationRef, 
	  private _componentFactoryResolver: ComponentFactoryResolver,
	  private _injector: Injector
  ) {}

  ngOnInit(): void {
    this._listener = UtilsService.debounce(() => (this.destroy()), 10);
  
    window.addEventListener('resize', this._listener, true);
    window.addEventListener('scroll', this._listener, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this._listener);
    window.removeEventListener('scroll', this._listener);
    
    this.destroy();

    this._listener = null;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this._componentRef === null) {
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(ResourceSmallItemListComponent);

      this._componentRef = componentFactory.create(this._injector);

      this._appRef.attachView(this._componentRef.hostView);
      
      const domElem = (this._componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;       
      
      document.body.appendChild(domElem);
      
      this.setProps();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  setProps() {
    if (this._componentRef !== null) {
      this._componentRef.instance.resources = this.resources;

      const parentRect = this._elementRef.nativeElement.getBoundingClientRect();
      const innerWidth: number = window.innerWidth;
      const right: number = Math.round(innerWidth - parentRect.right) - 3;

      this._componentRef.instance.position = { 
        right,
        top: Math.round(parentRect.top),
      };
    }
  }

  destroy(): void {
    if (this._componentRef !== null) {
      this._appRef.detachView(this._componentRef.hostView);
      this._componentRef.destroy();
      
      this._componentRef = null;
    }
  }

}

export {
  ResourcesTooltipDirective,
};
