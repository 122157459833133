<mat-expansion-panel
  hideToggle
  (opened)="onOpen(true)"
  (closed)="onOpen(false)"
  class="{{ 'bg--' + colorMap[parent.slug] + '-10' }}"
>
  <mat-expansion-panel-header 
    class="{{ isOpen ? 'bg--' + colorMap[parent.slug] + '-30' : '' }}"
    [class.opened]="isOpen"
  >
    <mat-panel-title>
      <jit-icon-simple class="arrow" [class.arrow--down]="isOpen" iconId="chevron"></jit-icon-simple>
      <jit-icon-simple iconId="suite"></jit-icon-simple>

      <a
        [routerLink]="['/', 'detail-page', suite.slug, 'suite']"
        class="link name"
      >
        {{ suite.title }}
      </a>
    </mat-panel-title>

    <div *ngIf="isOpen" class="sort-wrap">
      <span class="sort-wrap-title">Sort by</span>
      <jit-sort (changed)="onSortChange($event)"></jit-sort>
    </div>

    <!-- TODO: Collection fix -->
    <jit-collection-button [item]="$any(suite)"></jit-collection-button>
  </mat-expansion-panel-header>

  <div *ngIf="isOpen" class="content">
    <h3 class="title">{{ suite.title }}</h3>
    <p class="description">{{ suite.description }}</p>
  </div>

  <mat-accordion *ngIf="isOpen">
    <jit-series *ngFor="let item of suite.series" [series]="item"></jit-series>
  </mat-accordion>
</mat-expansion-panel>
