import { Injectable } from '@angular/core';
import { IAuthTransferData, IDataToken } from './oauth.interfaces';

@Injectable({
  providedIn: 'root'
})
export class OAuthStorageService {

  private _storage: Storage = window.localStorage;
  private _keyTransferData: string = 'authTransfer';
  private _keyTokens: string = 'authData';
  private _keyMaxRedirect: string = 'authMaxRedirect';

  private _set(key: string, data: any): void {
    this._storage.setItem(key, JSON.stringify(data));
  }

  private _get<T>(key: string): T | null {
    const data = this._storage.getItem(key);

    return data ? JSON.parse(data) : null;
  }

  private _del(key: string): void {
    this._storage.removeItem(key);
  }

  public setTransferData(transferData: IAuthTransferData): void {
    this._set(this._keyTransferData, transferData);
  }

  public getTransferData(): IAuthTransferData | null {
    return this._get<IAuthTransferData>(this._keyTransferData);
  }
  
  public setTokens(dataToken: IDataToken): void {
    this._set(this._keyTokens, dataToken);
  }

  public getTokens(): IDataToken | null {
    return this._get<IDataToken>(this._keyTokens);
  }

  public addMaxRedirect(): void {
    let maxRedirect: number = this.getMaxRedirect() + 1;

    this._set(this._keyMaxRedirect, maxRedirect);
  }

  public getMaxRedirect(): number {
    return this._get<number>(this._keyMaxRedirect) || 0;
  }

  public removeMaxRedirect(): void {
    this._del(this._keyMaxRedirect);
  }

  public clean(): void {
    this._del(this._keyTransferData);
    this._del(this._keyTokens);
    this._del(this._keyMaxRedirect);
  }

}
