import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class CheckboxComponent implements OnInit {

  @Input()
  title: string = '';

  @Input()
  value: boolean = false;

  @Input()
  disabled: boolean = false;

  @Output()
  changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    
  }

  onChange(): void {
    if (!this.disabled) {
      this.value = !this.value;
  
      this.changed.emit(this.value);
    }
  }

}

export {
  CheckboxComponent,
};
