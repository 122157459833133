import { concat, reduce } from 'ramda';
import { Bundle } from './bundle';
import { Color, colorMap } from './color';
import { GroupResponse } from './group';
import { IconId } from './icon';
import { ELanguages } from './languages';
import { Suite } from './suites';
import { gradeMap, UtilsService } from '@jit/core';

export type Attribute = {
  name: string;
  lang: string;
  _id: string;
  attributeValues: AttributeValue[];
  isActive: boolean;
  sortIndex: number;
};

export type AttributeValue = {
  name: string;
  lang: string;
  description: string;
  attributeItemId: string;
  _id: string;
};

export type StandardAttributeValue = {
  id: string;
  code: string;
  name: string;
  state: string;
  sortIndex: number;
  isActive: boolean;
}

export type Image = {
  height: number;
  type: string;
  url: string; // image/png
  width: number;
};

export type File = {
  image: Image;
  location: string;
  name: string;
  type: ResourceType;
  googleSlidesLocation?: string;
};

export type Meta = {
  description: string;
  name: string;
};

export type IResourceResponse = {
  file: File;
  images: {
    thumbnail: Image;
  };
  episodeNumber: number;
  nextActivityEpisodeNumber: string | null;
  nextActivityId: string | null;
  nexButtonLabel: string;
  meta: Record<ELanguages, Meta>;
  attributes: Record<ELanguages, Attribute[]>;
  id: string;
  groups: Record<ELanguages, GroupResponse[]> | null;
  bundleId: string;
};

export enum ResourceType {
  Video = 'Video',
  Slides = 'Slides',
  BackwardDesign = 'Backward Design',
  PDF = 'Book',
}

export const mapResourceType: {[key: string]: string} = {
  'Lesson Slides': 'Slides',
  'Google Slide': 'Slides',
  'Slide Deck': 'Slides',
  'Slides': 'Slides',
  'Backward Design': 'Backward Design',
  'Video': 'Video',
  'Picture Book': 'Book',
  'Close Reading': 'Close Reading',
  'PDF File': 'Book',
  'Book': 'Book',
};

export const mapResourceTypeIcon: {[key: string]: IconId} = {
  'Lesson Slides': 'slide',
  'Google Slide': 'slide',
  'Slide Deck': 'slide',
  'Slides': 'slide',
  'Video': 'video',
  'Backward Design': 'backward-design',
  'Close Reading': 'pdf',
  'PDF File': 'pdf',
  'Picture Book': 'book',
  'Book': 'book',
};

export class Resource {
  private _payload: IResourceResponse;
  private _originStandardList: any[] = [];
  private _filterStandardStateId: string | void = void(0);

  static contentTypeName: string = 'Content Type';
  static standardName: string = 'Standard';

  type: ResourceType;
  title: string;
  description: string;
  learningTarget?: string;
  skill?: string;
  duration: string;
  file: File;
  image?: Image;
  attributes: Attribute[];
  attributesValues: string[] = [];
  isCollected?: boolean;
  subject: string;
  lang: string;
  id: string;
  suite?: Suite;
  bundle?: Bundle;
  standards: string[];
  criteria: string[];
  learningTargetDescription: string;
  tags: string[];
  episode: number;
  standardsValue: string = '';
  standardList: string[] = [];
  standardIds: string[] = [];

  static colorMap = {
    [Color.Blue]: '#354add',
    [Color.Orange]: '#ff5e03',
    [Color.Pink]: '#aa3c9a',
    [Color.White]: '#fff',
  };

  static getColor(subject?: string): string {
    if (!subject) {
      return '#dde1e9';
    }

    return Resource.colorMap[colorMap[subject.toLowerCase()]];
  }

  get imagePreview() {
    return this.image?.url;
  }

  get color() {
    return Resource.getColor(this.subject);
  }

  get colorName() {
    return colorMap[this.subject.toLowerCase()];
  }

  get typeIcon(): IconId {
    return this.getResourceTypeIconValue(this.type);
  }

  get typeName(): string {
    return this.getResourceTypeValue(this.type);
  }

  constructor(payload: IResourceResponse, lang: string) {
    this._payload = payload;
    this.attributes = payload.attributes[lang as ELanguages];
    this.type = payload.file.type;
    this.title = payload.meta[lang as ELanguages].name;
    this.description = payload.meta[lang as ELanguages].description;
    this.duration = this.parseAttribute('Estimated Duration')[0];
    this.lang = lang;
    this.id = payload.id;
    this.skill = this.parseAttribute('SkillGroup')[0];
    this.learningTarget = this.parseAttribute('Learning Target')[0];
    this.subject = this.parseAttribute('Subject')[0];
    this.attributes.forEach((attribute) => {
      const values = attribute.attributeValues.map((el) => el.name);
      this.attributesValues.push(...values);
    });
    this.file = payload.file;
    this.image = payload.file.image;
    this.standards = this.parseAttribute('Standard');
    this.criteria = this.parseAttribute('Success Criteria', 'description');
    this.learningTargetDescription = this.parseAttribute(
      'Learning Target',
      'description'
    )[0];
    const skipAttributeList = [
      'Grade',
      'Estimated Duration',
      'Standard',
      'Success Criteria',
      'Learning Target',
    ];
    this.tags = reduce(
      (acc, item) =>
        concat(
          acc,
          item.attributeValues.map((el) => el.name)
        ),
      [] as string[],
      this.attributes.filter((el) => !skipAttributeList.includes(el.name))
    );
    this.episode = payload.episodeNumber;

    this.parseGroups(payload.groups, lang as ELanguages);

    this._getContentType(this.attributes);

    this.type = this._getContentType(this.attributes) as ResourceType;

    if (this.type) {
      this.type = this.getResourceTypeValue(this.type) as any;
    } else {
      if (this.file.googleSlidesLocation) {
        this.type = this.getResourceTypeValue('Google Slide') as any;
      } else if (this.file.location.includes('pdf')) {
        this.type = this.getResourceTypeValue('PDF File') as any;
      }
    }

    this._applyStandard();
  }

  private _applyStandard(): void {
    const { states } = UtilsService.getQuerySearch();
    const standart: Attribute | void = this.attributes.find((attr: Attribute) => (attr.name === Resource.standardName));

    if (standart) {
      for (let standardAttr of standart.attributeValues as any[]) {
        this._originStandardList.push(standardAttr);

        for (let related of standardAttr.relatedAttributes) {
          if (related.attributeValueId === states) {
            this.standardsValue = standardAttr.code;
            this._originStandardList = [standardAttr.code];
          }

          if (!this.standardIds.includes(related.attributeValueId)) {
            this.standardIds.push(related.attributeValueId);
          }
        }
      }

      this.filterStandardsById();
    }
  }

  private _getContentType(attributes: Attribute[]): string {
    let typeName: string = 'unknown-type';

    const contentType: Attribute | void = attributes.find((attribute: Attribute) => {
      return (attribute.name === Resource.contentTypeName);
    });

    if (contentType && contentType.attributeValues && contentType.attributeValues[0]) {
      typeName = contentType.attributeValues[0].name;
    } else {
      console.warn('Resource.getContentType: unknown content type for resource.id ->', this.id, this.title);
    }

    return typeName;
  }

  filterStandardsById(id: string | void): void {
    this._filterStandardStateId = id;

    let list = this._originStandardList;

    if (this._filterStandardStateId) {
      list = list.filter((s) => {
        if (Array.isArray(s.relatedAttributes)) {
          for (let attr of s.relatedAttributes) {
            if (attr.attributeValueId === this._filterStandardStateId) {
              return true;
            }
          }
        }

        return false;
      });
    }

    this.standardList = list.map((s) => s.code);
  }

  getResourceTypeValue(key: string): string {
    let name: string = mapResourceType[key];

    if (!name) {
      name = key;

      console.warn('Resource.getResourceTypeValue: unknown resource type ->', key);
    }

    return name;
  }

  getResourceTypeIconValue(key: string): IconId {
    let name: IconId = mapResourceTypeIcon[key];

    if (!name) {
      name = 'minus-circle';

      console.warn('Resource.getResourceTypeIconValue: unknown resource type icon ->', key);
    }

    return name;
  }

  getAttribute(name: string) {
    if (name === 'Grade') {
      const valueList = this.parseAttribute(name);
      const value = gradeMap[valueList[0]];

      if (parseInt(valueList[0]) > 0) {
        return value + ' Grade';
      } else {
        return value;
      }
    } else {
      return this.parseAttribute(name);
    }
  }

  clone() {
    return this._payload;
  }

  private parseAttribute(
    name: string,
    param: keyof AttributeValue = 'name'
  ): string[] {
    const attribute = this.attributes.find((el) => el.name === name);

    if (attribute) {
      const values = attribute.attributeValues;

      if (values.length > 1) {
        return values.map((el) => el[param]);
      }

      return [values[0][param]];
    }

    return [];
  }

  private parseGroups(
    payload: Record<ELanguages, GroupResponse[]> | null,
    lang: ELanguages
  ) {
    if (payload === null || !payload[lang]) {
      return;
    }

    payload[lang].forEach((el) => {
      if (Bundle.isBundleType(el)) {
        this.bundle = new Bundle(el);
      }

      if (Suite.isSuiteType(el)) {
        this.suite = new Suite(el);
      }
    });
  }
}
