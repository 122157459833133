import { ApiService, LoggerService, SnackbarService } from '@jit/core';
import { inject, Injectable } from '@angular/core';
import {
  ICollection,
  ICreateCollection,
  IFavorite,
  IModifyCollection,
} from '../interfaces/collection.interface';
import {
  IBasicFavorite,
  IBasicFavoriteWithCount,
  ICreateFavorite,
  IFavoriteMap,
} from '../interfaces/favorite.interface';
import { ISlugEntity } from '../interfaces/resource.interface';
import { useCollection } from '../stores';
import {
  Bundle,
  CollectionModalComponent,
  DialogService,
  EditCollectionModalComponent,
  IconId,
  NotificationCollectionComponent,
  Resource,
  Suite,
} from '@jit/shared';

const baseUrl = '/v2/api/collections';

@Injectable({
  providedIn: 'root',
})
class CollectionService {

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);
  private _dialogService: DialogService = inject(DialogService);
  private _snackbarService: SnackbarService = inject(SnackbarService);

  private mainCollection: ICollection;
  private readonly favorites = new Map<string, IFavoriteMap>();
  private collections: ICollection[];

  constructor() {
    this.mainCollection = {} as ICollection;
    this.collections = [];
  }

  public getCollections() {
    return this.collections;
  }

  public async init() {
    this.collections = await this.list();

    this.mainCollection = this.collections.find(
      (collection) => collection.main
    )!;

    this.collections.map((collection) => {
      collection.favorites.map((fav) =>
        this._handleFavoriteMapping(fav, collection.id)
      );
    });

    useCollection.getState().setCollections(this.collections);
  }

  private _handleFavoriteMapping(
    fav: IBasicFavoriteWithCount,
    collectionId: string
  ) {
    const key = this._favToString(fav);
    if (this.favorites.has(key)) {
      const f = this.favorites.get(key)!;
      f.collectionIds.add(collectionId);
    } else {
      this.favorites.set(this._favToString(fav), {
        ...fav,
        collectionIds: new Set<string>([collectionId]),
      });
    }
  }

  private _addToFavorites(fav: IBasicFavoriteWithCount, collectionId: string) {
    this._handleFavoriteMapping(fav, collectionId);

    const collection = this.getCollectionById(collectionId);
    collection.favorites.push(fav as IFavorite);

    useCollection.getState().setCollection(collection);
  }

  public getMainCollection() {
    return this.mainCollection;
  }

  public getCollectionById(collectionId: string): ICollection {
    return this.collections.find(
      (collection) => collection.id === collectionId
    )!;
  }

  private _removeFromFavorites(fav: IBasicFavorite, collectionId: string) {
    const key = this._favToString(fav);

    const favorite = this.favorites.get(key);
    if (!favorite) {
      return;
    }

    favorite.collectionIds.delete(collectionId);
    if (favorite.collectionIds.size < 1) {
      this.favorites.delete(key);
    }

    const collection = this.getCollectionById(collectionId);
    collection.favorites = collection.favorites.filter(
      (f) => !(f.type === favorite.type && f.slug === favorite.slug)
    );
  }

  private _favToString(fav: ISlugEntity) {
    return fav.type + '_' + fav.slug;
  }

  public isFavorite(item: ISlugEntity) {
    return this.favorites.get(this._favToString(item));
  }

  public inCollection(collectionId: string, item: ISlugEntity): boolean {
    const favorite = this.favorites.get(this._favToString(item));

    if (!favorite) {
      return false;
    }

    return favorite.collectionIds.has(collectionId);
  }

  public inCollectionsCount(item: ISlugEntity): number {
    const favorite = this.favorites.get(this._favToString(item));

    if (!favorite) {
      return 0;
    }

    return favorite.collectionIds.size;
  }

  public async list(): Promise<ICollection[]> {
    try {
      return (await this._apiService.get<ICollection[]>(baseUrl)).data;
    } catch (err) {
      this._loggerService.error('CollectionService.list: Request error', err);

      throw err;
    }
  }

  public async show(id: string): Promise<ICollection> {
    try {
      return (await this._apiService.get<ICollection>(`${baseUrl}/${id}`)).data;
    } catch (err) {
      this._loggerService.error('CollectionService.show: Request error', err);

      throw err;
    }
  }

  public async rename(id: string, name: string) {
    try {
      const result = // TODO: I assume there is should be PUT
        (
          await this._apiService.patch<ICollection, IModifyCollection>(
            `${baseUrl}/${id}`,
            { name }
          )
        ).data;

      useCollection.getState().setCollection(result);

      return result;
    } catch (err) {
      this._loggerService.error('CollectionService.rename: Request error', err);

      throw err;
    }
  }

  public async addFavorite(favorite: ICreateFavorite, id: string) {
    try {
      const res = (
        await this._apiService.post<IFavorite, ICreateFavorite>(
          `${baseUrl}/${id}/favorites`,
          {
            type: favorite.type,
            slug: favorite.slug,
          }
        )
      ).data;

      this._addToFavorites(
        {
          cnt: res.cnt,
          slug: favorite.slug,
          type: favorite.type,
        },
        id
      );

      return res;
    } catch (err) {
      this._loggerService.error(
        'CollectionService.addFavorite: Request error',
        err
      );

      throw err;
    } finally {
      useCollection.getState().setCollections([ ...useCollection.getState().collections ]);
    }
  }

  public async removeFavorite(favorite: IBasicFavorite, collectionId: string) {
    try {
      await this._apiService.delete(`${baseUrl}/${collectionId}/favorites`, {
        body: JSON.stringify({
          slug: favorite.slug,
          type: favorite.type,
        }),
      });

      this._removeFromFavorites(favorite, collectionId);
    } catch (err) {
      this._loggerService.error(
        'CollectionService.removeFavorite: Request error',
        err
      );

      throw err;
    } finally {
      useCollection.getState().setCollections([ ...useCollection.getState().collections ]);
    }
  }

  public async create(name: string) {
    try {
      const result = (
        await this._apiService.post<ICollection, ICreateCollection>(
          `${baseUrl}`,
          { name }
        )
      ).data;

      this.collections.push(result);

      useCollection.getState().setCollection(result);

      return result;
    } catch (err) {
      this._loggerService.error('CollectionService.create: Request error', err);

      throw err;
    }
  }

  public async delete(id: string) {
    try {
      await this._apiService.delete(`${baseUrl}/${id}`);
    } catch (err) {
      this._loggerService.error('CollectionService.delete: Request error', err);

      throw err;
    }
  }

  public openCreationDialog(item?: ISlugEntity) {
    const dialogRef = this._dialogService.open(CollectionModalComponent, {
      windowClass: 'item-dialog',
      width: '500px',
    });
    dialogRef.data = { item };
  }

  public openEditDialog(collection: ICollection) {
    const dialogRef = this._dialogService.open(EditCollectionModalComponent, {
      windowClass: 'item-dialog',
      width: '500px',
      data: collection,
    });

    return dialogRef.afterClosed$;
  }

  public openConfirmDialog() {
    const dialogRef = this._dialogService.openConfirmDialog({
      title: `Remove favorite?`,
      description:
        'This action will remove the resource from your collections. Are you sure you want to do this?',
      action: {
        reject: 'Cancel',
        accept: 'Yes, remove',
      },
    });

    return dialogRef.afterClosed$;
  }

  public showSnackbar(payload: {
    text: string;
    hasAction: boolean;
    item?: Bundle | Suite | Resource | ISlugEntity;
    icon?: IconId;
  }) {
    this._snackbarService.openFromComponent(
      NotificationCollectionComponent,
      payload
    );
  }
}

export { CollectionService };
