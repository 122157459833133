import { inject, Injectable } from '@angular/core';
import { UtilsService, ApiService, IResponse, LoggerService } from '@jit/core';
import { GradeService } from './grade.service';
import { IEntityFilters } from '../interfaces/entity-filters.interface';
import { IQueryFilters } from '../interfaces/query-filters.interface';

@Injectable({
  providedIn: 'root',
})
class FiltersService {

  private _urlSubjects: string = '/v2/api/filters/subjects';
  private _urlGrades: string = '/v2/api/filters/grades?subjectId={{ subjectId }}';
  private _urlDomains: string = '/v2/api/filters/domains?subjectId={{ subjectId }}&gradeId={{ gradeId }}';
  private _urlStates: string = '/v2/api/filters/states?subjectId={{ subjectId }}&gradeId={{ gradeId }}&domainId={{ domainId }}';
  private _urlAllStates: string = '/v2/api/filters/states/all';
  private _urlState: string = '/v2/api/filters/state/{{ slug }}';

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);
  private _gradeService: GradeService = inject(GradeService);

  private _cacheStateSlugs: Map<string, IEntityFilters> = new Map<string, IEntityFilters>();

  constructor() {}

  private _prepareUrl(url: string, query: IQueryFilters): string {
    let div = '?';

    if (url.includes(div)) {
      div = '&';
    }

    const params: any = {};

    if (query.contentType) {
      params.contentType = query.contentType;
    }

    if (query.searchId) {
      params.searchId = query.searchId;
    }

    if (query.withSubscription) {
      params.withSubscription = query.withSubscription;
    }

    const strParams = new URLSearchParams(params).toString();
    
    return url + (strParams ? (div + strParams) : '');
  }

  public async getSubjects(query?: IQueryFilters): Promise<IEntityFilters[]> {
    if (!query) {
      query = {};
    }

    const url = this._prepareUrl(this._urlSubjects, query);

    let response: IResponse<IEntityFilters[]>;

    try {
      response = await this._apiService.get<IEntityFilters[]>(url);
    } catch (err) {
      this._loggerService.error('FiltersService.getSubjects: Request error', err);

      return [];
    }

    return response.data;
  }

  public async getGrades(query: IQueryFilters): Promise<IEntityFilters[]> {
    const url = this._prepareUrl(UtilsService.format(this._urlGrades, query), query);

    let response: IResponse<IEntityFilters[]>;

    try {
      response = await this._apiService.get<IEntityFilters[]>(url);
    } catch (err) {
      this._loggerService.error('FiltersService.getGrades: Request error', err);

      return [];
    }

    return response.data.map((grade) => {
      grade.title = this._gradeService.mathGradeInFilter(grade.value as any);
      
      return grade;
    });
  }

  public async getDomains(query: IQueryFilters): Promise<IEntityFilters[]> {
    const url = this._prepareUrl(UtilsService.format(this._urlDomains, query), query);

    let response: IResponse<IEntityFilters[]>;

    try {
      response = await this._apiService.get<IEntityFilters[]>(url);
    } catch (err) {
      this._loggerService.error('FiltersService.getDomains: Request error', err);

      return [];
    }

    return response.data;
  }

  public async getStates(query: IQueryFilters): Promise<IEntityFilters[]> {
    const url = this._prepareUrl(UtilsService.format(this._urlStates, query), query);

    let response: IResponse<IEntityFilters[]>;

    try {
      response = await this._apiService.get<IEntityFilters[]>(url);
    } catch (err) {
      this._loggerService.error('FiltersService.getStates: Request error', err);

      return [];
    }

    return response.data;
  }

  public async getStateBySlug(slug: string): Promise<IEntityFilters | null> {
    if (this._cacheStateSlugs.has(slug)) {
      return this._cacheStateSlugs.get(slug) as IEntityFilters;
    } else {
      const url = UtilsService.format(this._urlState, { slug });
    
      let response: IResponse<IEntityFilters>;

      try {
        response = await this._apiService.get<IEntityFilters>(url);
      } catch (err) {
        this._loggerService.error('FiltersService.getState: Request error', err);

        return null;
      }

      this._cacheStateSlugs.set(slug, response.data);

      return response.data;
    }
  }

  public async getAllStates(): Promise<IEntityFilters[]> {
    let response: IResponse<IEntityFilters[]>;

    try {
      response = await this._apiService.get<IEntityFilters[]>(this._urlAllStates);
    } catch (err) {
      this._loggerService.error('FiltersService.getAllStates: Request error', err);

      return [];
    }

    return response.data;
  }

}

export {
  FiltersService,
};
