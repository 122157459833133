import { CommonModule } from '@angular/common';
import { Component, inject, ChangeDetectorRef } from '@angular/core';
import { UtilsService } from '@jit/core';

@Component({
  selector: 'jit-base-component',
  template: '',
  standalone: true,
  imports: [ CommonModule ],
})
class BaseComponent {

  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  private _debounceRefresh: any = UtilsService.debounce(() => {
    this._cdr.markForCheck();
    this._cdr.detectChanges();
  }, 100);

  public refresh(): void {
    this._debounceRefresh();
  }
}

export {
  BaseComponent,
};
