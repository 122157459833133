import { IFavorite } from './favorite.interface';

interface ICollection {
  id: string;
  name: string;
  main: boolean;
  favorites: IFavorite[];
}

interface ICreateCollection {
  name: string;
}

interface IModifyCollection {
  name: string;
}

export { ICollection, ICreateCollection, IModifyCollection, IFavorite };
