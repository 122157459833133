enum MapLoglevelsSort {
  none = 7,
  error = 6,
  warn = 5,
  info = 4,
  http = 3,
  verbose = 2,
  debug = 1,
  silly = 0,
};

enum MapLoglevels {
  NONE = 'none',
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  HTTP = 'http',
  VERBOSE = 'verbose',
  DEBUG = 'debug',
  SILLY = 'silly',
};

const Loglevels = ['none', 'error', 'warn', 'info', 'http', 'verbose', 'debug', 'silly'];

export {
  MapLoglevelsSort,
  MapLoglevels,
  Loglevels
};

