import { Injectable } from '@angular/core';
import { IPaginatorItem, IPaginatorProps } from '../interfaces/paginator.interface';

@Injectable({
  providedIn: 'root',
})
class PaginatorService {

  get({ page, limit, total, count }: IPaginatorProps): IPaginatorItem[] {
    page = page || 1;
    total = total || 0;
    limit = limit || 0;
    count = count || 5;

    const pages: number = Math.ceil(total / limit);
    const pagins: IPaginatorItem[] = [];

    if (pages <= count) {
      count = pages;
    }

    if (total > 0) {
      if (count < pages) {
        pagins.push({
          name: '«',
          page: 1,
          active: false,
          isArrow: true,
        });
      }

      pagins.push({
        name: '‹',
        page: (page === 1) ? 1 : page - 1,
        active: false,
        isArrow: true,
      });

      if (page < count) {
        for (let i = 1; i <= count; i++) {
          pagins.push({
            name: i.toString(),
            page: i,
            active: (page === i),
            isArrow: false,
          });
        }
      } else {
        let j: number;

        if (page > (pages - count + 1) && page > count) {
          j = pages - count;
        } else if (pages <= count) {
          j = pages - count;
        } else {
          j = page - Math.ceil(count / 2);
        }

        for (let i = 1; i <= count; i++) {
          j++;

          pagins.push({
            name: j.toString(),
            page: j,
            active: (page === j),
            isArrow: false,
          });
        }
      }

      pagins.push({
        name: '›',
        page: (page === pages) ? pages : (page + 1),
        active: false,
        isArrow: true,
      });
      
      if (count < pages) {
        pagins.push({
          name: '»',
          page: pages,
          active: false,
          isArrow: true,
        });
      }
    }

    return pagins;
  }

}

export {
  PaginatorService,
};
