<span 
  class="jit-th-sort"
  [class.none]="state.sort() === ThSorts.NONE"
  [class.asc]="state.sort() === ThSorts.ASC"
  [class.desc]="state.sort() === ThSorts.DESC"
  (click)="onClick()"
>
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
    <path class="jit-th-sort__top" d="M0.120055 4.32539C0.160236 4.37792 0.208561 4.42035 0.26227 4.45024C0.315979 4.48013 0.37402 4.49691 0.433078 4.49961C0.492137 4.50231 0.551056 4.49089 0.606471 4.46599C0.661886 4.44108 0.712711 4.40319 0.756044 4.35448L3.00001 1.78948L5.24397 4.35448C5.28731 4.40319 5.33813 4.44107 5.39355 4.46597C5.44896 4.49087 5.50788 4.50229 5.56694 4.49959C5.62599 4.49689 5.68403 4.48012 5.73774 4.45023C5.79145 4.42034 5.83978 4.37792 5.87996 4.32539C5.92015 4.27287 5.9514 4.21126 5.97194 4.14409C5.99248 4.07692 6.00191 4.00551 5.99968 3.93393C5.99745 3.86234 5.98361 3.79199 5.95895 3.72689C5.93429 3.66179 5.8993 3.60322 5.85596 3.55451L3.306 0.645514C3.22279 0.551974 3.1135 0.499999 3.00001 0.499999C2.88652 0.499999 2.77722 0.551974 2.69401 0.645514L0.144054 3.55451C0.100714 3.60321 0.0657136 3.66179 0.0410515 3.72689C0.0163893 3.79199 0.00254878 3.86234 0.000320141 3.93392C-0.00190849 4.00551 0.0075185 4.07693 0.0280627 4.14409C0.0486069 4.21126 0.079866 4.27287 0.120055 4.32539Z" fill="currentColor"/>
    <path class="jit-th-sort__bottom" d="M5.87995 7.67461C5.83976 7.62208 5.79144 7.57965 5.73773 7.54976C5.68402 7.51987 5.62598 7.50309 5.56692 7.50039C5.50786 7.49769 5.44894 7.50911 5.39353 7.53401C5.33811 7.55892 5.28729 7.59681 5.24396 7.64552L2.99999 10.2105L0.756028 7.64552C0.712693 7.59681 0.661867 7.55893 0.606452 7.53403C0.551038 7.50913 0.49212 7.49771 0.433063 7.50041C0.374006 7.50311 0.315966 7.51988 0.262257 7.54977C0.208548 7.57966 0.160222 7.62208 0.120038 7.67461C0.0798545 7.72714 0.0485997 7.78874 0.0280586 7.85591C0.00751737 7.92308 -0.00190826 7.99449 0.00032 8.06607C0.00254874 8.13766 0.0163875 8.20801 0.0410467 8.27311C0.0657058 8.33821 0.100702 8.39679 0.144038 8.44549L2.694 11.3545C2.77721 11.448 2.8865 11.5 2.99999 11.5C3.11348 11.5 3.22278 11.448 3.30599 11.3545L5.85595 8.44549C5.89929 8.39679 5.93429 8.33821 5.95895 8.27311C5.98361 8.20801 5.99745 8.13766 5.99968 8.06608C6.00191 7.99449 5.99248 7.92307 5.97194 7.85591C5.95139 7.78874 5.92013 7.72713 5.87995 7.67461Z" fill="currentColor"/>
  </svg>
</span>