import { Injectable, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { LoggerService, LogError } from '../logger';
import { ApiService, IResponse } from '../api';
import { OAuthStorageService } from './oaut-storage.service';
import { OAuthUtilsService } from './oauth-utils.service';
import { OAuthConfigService } from './oauth-config.service';
import { IAuthTransferData, IUserInfo, IOAuthConfig, IDataToken } from './oauth.interfaces';
import { useUser } from '../../../data-layer/stores/user.store';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);
  private _oauthStorageService: OAuthStorageService = inject(OAuthStorageService);
  private _oauthUtilsService: OAuthUtilsService = inject(OAuthUtilsService);
  private _oaAuthConfigService: OAuthConfigService = inject(OAuthConfigService);

  private _userInfo: IUserInfo | null = null;
  private _maxRetry: number = 10;
  private _waitBeforeRetry: number = 3000;

  public readonly authorized: ReplaySubject<void> = new ReplaySubject();
  public readonly authError: ReplaySubject<any> = new ReplaySubject();
  public readonly userInfo$: ReplaySubject<IUserInfo | null> = new ReplaySubject();

  get userInfo(): IUserInfo | null {
    return this._userInfo;
  }

  async getUserInfo(): Promise<IUserInfo> {
    const config: IOAuthConfig = await this._oaAuthConfigService.getConfig();
    const response: IResponse<IUserInfo> = await this._apiService.get<IUserInfo>(config.userInfo);

    return response.data;
  }

  async isTokenValid(): Promise<boolean> {
    const dataToken: IDataToken | null = this._oauthStorageService.getTokens();

    let isValid = false;

    if (dataToken && dataToken.access_token && dataToken.id_token) {
      if (!this._oauthUtilsService.isTokenExpired(dataToken.id_token)) {
        isValid = true;
      }
    }

    return isValid;
  }

  private async _authorize(): Promise<void> {
    const config: IOAuthConfig = await this._oaAuthConfigService.getConfig();
    const transferData: IAuthTransferData = await this._oauthUtilsService.generateAuthTransferData();

    const searchParams: string = this._oauthUtilsService.objToParams({
      client_id: config.clientId,
      response_type: 'code',
      redirect_uri: config.redirectUri,
      scope: config.scope,
      state: transferData.state,
      nonce: transferData.nonce,
      code_challenge: transferData.codeChallenge,
      code_challenge_method: 'S256',
    });

    this._oauthStorageService.setTransferData(transferData);

    this._oauthUtilsService.redirect(config.authorize + '?' + searchParams);
  }

  async getTokens(code: string): Promise<IDataToken> {
    const config: IOAuthConfig = await this._oaAuthConfigService.getConfig();
    const transferData: IAuthTransferData | null = this._oauthStorageService.getTransferData();

    let response: IResponse<IDataToken>;
    let result: IDataToken;

    if (transferData) {
      const searchParams = new URLSearchParams({
        code,
        grant_type: 'authorization_code',
        client_id: config.clientId,
        code_verifier: transferData.codeVerifier,
        redirect_uri: config.redirectUri
      });

      response = await this._apiService.post<IDataToken, URLSearchParams>(
        config.tokenEndpoint,
        searchParams,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
      );

      if (response.data.access_token && response.data.id_token) {
        result = response.data;
      } else {
        throw new LogError('OAuthService.getTokens - get token request is failed', response.data);
      }
    } else {
      throw new LogError('OAuthService.getTokens - transferData is not defined');
    }

    return result;
  }

  async logout(): Promise<void> {
    const config: IOAuthConfig = await this._oaAuthConfigService.getConfig();
    const dataToken: IDataToken | null = this._oauthStorageService.getTokens();

    if (dataToken) {
      const url = config.endSession +
        '?id_token_hint=' + dataToken.id_token +
        '&post_logout_redirect_uri=' + encodeURIComponent(config.logoutRedirectUri);

      const revokeUrl: string = config.revocationEndpoint;

      const revokeData = {
        client_id: config.clientId,
        token: dataToken.access_token,
        token_type_hint: 'access_token',
      };

      const searchParams = new URLSearchParams(revokeData);

      try {
        await this._apiService.post(
          revokeUrl,
          searchParams,
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
        );

        this._oauthUtilsService.redirect(url);
      } catch (err) {
        // ignore failed logout
      }
    }

    // Initialize login flow
    this.init();
  }

  public async cleanSession() {
    this._oauthStorageService.clean();
    
    await this._apiService.post('/v2/api/logout',{});

    setTimeout(() => this._oauthUtilsService.redirect('/'), 300);
  }

  async init(): Promise<void> {
    const dataToken: IDataToken | null = this._oauthStorageService.getTokens();

    // Handle callback event
    if (window.location.pathname === '/auth/login') {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      const transferData: IAuthTransferData | null = this._oauthStorageService.getTransferData();

      if (code && state && transferData && transferData.state === state) {
        let tokens: IDataToken;

        try {
          tokens = await this.getTokens(code);
        } catch (err) {
          this._loggerService.error(err as Error);
          this.authError.next(err);

          this._oauthStorageService.addMaxRedirect();
          this._oauthUtilsService.redirect('/');

          return;
        }

        this._oauthStorageService.setTokens(tokens)
        this._oauthUtilsService.redirect('/');
      } else {
        // incorrect incoming data or data is not equal
        // redirect to auth page plus increase max count redirection
        this._loggerService.error('OAuthService.init: Incorrect incoming data or data is not equal', { code, state });

        this._oauthStorageService.addMaxRedirect();

        if (this._oauthStorageService.getMaxRedirect() <= this._maxRetry) {
          this._oauthUtilsService.redirect('/');

          return;
        } else {
          this._loggerService.error('OAuthService.init: Max redirect count in callback validation');
        }
      }
    } else {
      // Check if token not expired
      if (dataToken && dataToken.access_token && dataToken.id_token) {
        if (this._oauthUtilsService.isTokenExpired(dataToken.id_token)) {
          this._authorize();
        } else {
          let userInfo: IUserInfo;

          try {
            userInfo = await this.getUserInfo();
          } catch (err) {
            this._userInfo = null;
            this.userInfo$.next(null);

            this._loggerService.error('OAuthService.init: Get user info request is failed', err);

            this._oauthStorageService.addMaxRedirect();

            if (this._oauthStorageService.getMaxRedirect() <= this._maxRetry) {
              setTimeout(() => this.init(), this._waitBeforeRetry);
            }

            return;
          }

          this._userInfo = userInfo;
          this.userInfo$.next(userInfo);

          useUser.getState().setUser(userInfo || void(0));

          this._oauthStorageService.removeMaxRedirect();
        }
      } else {
        this._authorize();
      }
    }
  }
}
