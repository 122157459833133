import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Event } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private _event = new BehaviorSubject<Event | null>(null);

  public event$ = this._event.asObservable();

  emit(event: Event) {
    this._event.next(event);
  }
}
