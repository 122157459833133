import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  inject,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { bufferCount, Subject, takeUntil, filter } from 'rxjs';
import { ISelectOption } from '../../models';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'jit-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    IconSimpleComponent,
  ],
})
export class SelectComponent
  implements ControlValueAccessor, OnDestroy, OnInit, OnChanges
{
  private _destroy = new Subject<void>();
  private _onChange?: (_: any) => void;
  private _onTouched?: any;

  private router = inject(Router);
  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input() label!: string;
  @Input() options!: ISelectOption[];
  @Input() allLabel = 'All';
  @Input() multiple = false;
  @Input() hideDisabled = false;
  @Input() defaultValue?: string;
  @Input() placeholder: string = 'Select';
  @Output() changed = new EventEmitter<void>();

  @ViewChild('select') select?: MatSelect;

  public toppings = new FormControl<string[] | string>([]);

  get displayValue() {
    const value = this.toppings.value;

    if (Array.isArray(value)) {
      return value?.map(
        (el) => this.options.find((item) => item.value === el)?.text
      );
    } else {
      const text = this.options.find((item) => item.value === value)?.text;

      return text ? [text] : [];
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.select?.close();
      });

    if (this.defaultValue) {
      const option = this.options.find(
        (option) => option.text === this.defaultValue
      );

      if (option) {
        setTimeout(() => {
          this.toppings.patchValue(option.value);
        }, 20);
      }
    }

    if (this.multiple) {
      this.options.unshift({
        value: 'all',
        text: this.allLabel,
      });

      this.toppings.valueChanges
        .pipe(bufferCount(2, 1), takeUntil(this._destroy))
        .subscribe((value) => {
          if (value.length < 1) {
            return;
          }

          const result = value[1];

          if (Array.isArray(result)) {
            if (!value[0]?.includes('all') && value[1]?.includes('all')) {
              this._onTouched && this._onTouched();
              this._onChange?.(['all']);
            } else {
              this._onTouched && this._onTouched();
              this._onChange?.(result?.filter((el) => el !== 'all'));
            }
          }
        });
    } else {
      this.toppings.valueChanges
        .pipe(takeUntil(this._destroy))
        .subscribe((value) => {
          this._onTouched && this._onTouched();
          this._onChange?.(value || []);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultValue']) {
      const value = changes['defaultValue'].currentValue;

      if (value) {
        const option = this.options.find((option) => option.text === value);

        if (option) {
          this.toppings.patchValue(option.value);
        }
      }
    } 
    
    if (changes['options']) {
      const options = changes['options'].currentValue;

      if (Array.isArray(options) && options.length === 0) {
        
        setTimeout(() => {
          const select: any = this.select;

          select.ngControl.form.disable();

          this._cdr.markForCheck();
          this._cdr.detectChanges();
        }, 100);
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.toppings.disable();
    } else {
      this.toppings.enable();
    }
  }

  writeValue(obj?: string[] | string): void {
    if (!obj) {
      this.toppings.patchValue(this.multiple ? ['all'] : null);
      return;
    }

    if (Array.isArray(obj)) {
      if (obj.length === 0) {
        this.toppings.patchValue(['all']);
      } else {
        this.toppings.patchValue(obj);
      }
    } else {
      this.toppings.patchValue(this.multiple ? [obj] : obj);
    }
  }

  onSelectionChange() {
    this.changed.emit();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
