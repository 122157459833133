<div class="search-bar">
  <div class="filter-container">
    <div class="filter-row">
      <jit-subject-buttons></jit-subject-buttons>
      <div class="filter-group">
        <jit-select-filters type="grade"></jit-select-filters>
        <jit-select-filters type="domain"></jit-select-filters>
        <!-- <jit-select-filters type="state"></jit-select-filters> -->
      </div>
    </div>

    <jit-autocomplete-input
      class="autocomplete"
      [options]="autocompleteOptions"
      (remove)="onRemove($event)"
      (change)="onSearchChange($event)"
      (changed)="onSearchChanged($event)"
      (keypress)="onSearchChange($event)"
    ></jit-autocomplete-input>

    <!-- <ng-container *ngIf="(hasQuery$ | async) === false">
      <hr />
      <jit-trend-topics [topicsArray]="topicsArray"></jit-trend-topics>
    </ng-container> -->

    <!-- <ng-container *ngIf="hasQuery$ | async">
      <div class="pills">
        <button
          type="button"
          class="button pill primary"
          (click)="openFiltersDialog()"
        >
          <jit-icon-simple size="16" iconId="filters"></jit-icon-simple>

          Filters
        </button>

        <ng-container *ngIf="pills$ | async as pills">
          <div class="pill" *ngFor="let pill of pills">
            {{ pill?.label }}

            <button
              type="button"
              class="button"
              (click)="removeFilterValue(pill)"
            >
              <jit-icon-simple
                size="16"
                iconId="minus-circle"
              ></jit-icon-simple>
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container> -->
  </div>
</div>
