import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IconSimpleComponent } from '../../icon-simple/icon-simple.component';

@Component({
  selector: 'jit-nps-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
  ],
})
class NpsCloseButtonComponent {}

export {
  NpsCloseButtonComponent,
};
