interface IEntityFilters {
  id: string;
  type: string;
  title: string;
  value: string;
  slug?: string;
  active?: string;
  subjectId?: string;
}

export {
  IEntityFilters
};
