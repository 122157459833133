import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { BaseComponent, useAssignment, IResourceEntity, ResourceService } from '@jit/data-layer';
import { IconSimpleComponent, ResourceItemComponent, ResourceItemActions, ModalDialogComponent } from '@jit/shared';

@Component({
  selector: 'jit-assignment-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ResourceItemComponent,
    ModalDialogComponent,
  ],
})
class AssignmentResourcesComponent extends BaseComponent {

  @ViewChild('dialogRemove', { static: false })
  dialogRemove!: ModalDialogComponent;

  @useAssignment('resources')
  resources: IResourceEntity[] = [];

  @useAssignment('related')
  related: IResourceEntity[] = [];
  
  @useAssignment('isSearchMode')
  isSearchMode: boolean = false;

  private _resource: IResourceEntity | null = null;
  private _resourceService: ResourceService = inject(ResourceService);

  public ResourceItemActions: typeof ResourceItemActions = ResourceItemActions;

  onRemove(resource: IResourceEntity): void {
    this._resource = resource;

    this.dialogRemove.open();
  }

  confirmRemove(): void {
    this._resource && useAssignment.getState().removeRelated(this._resource);

    this._resource = null;
    
    this.onRemoveClose();
  }

  onRelated(): void {
    useAssignment.getState().openRelated();
  }

  onSearch(): void {
    useAssignment.getState().openSearch();
  }

  onRemoveClose(): void {
    this.dialogRemove.close();
  }

  onContentClick(item: IResourceEntity): void {
    this._resourceService.openMicrolesson(item);
  }

}

export {
  AssignmentResourcesComponent,
};
