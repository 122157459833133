import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LoggerService } from '../logger';
import { ApiService, IResponse } from '../api';
import { IOAuthConfig } from './oauth.interfaces';

let idSrvDomain: string = environment.idServerUrl;

if (idSrvDomain[idSrvDomain.length - 1] === '/') {
  idSrvDomain = idSrvDomain.substring(0, idSrvDomain.length - 1);
}

@Injectable({
  providedIn: 'root'
})
export class OAuthConfigService {

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);

  private _configUrl: string = idSrvDomain + '/.well-known/openid-configuration';
  private _config!: IOAuthConfig;

  public async getConfig(): Promise<IOAuthConfig> {
    if (this._config) {
      return this._config;
    }

    let response: IResponse<any>;

    try {
      response = await this._apiService.get<any>(this._configUrl);
    } catch (err) {
      this._loggerService.error('OAuthConfigService.getConfig: entrypoint is not available', err);

      throw err;
    }

    this._config = {
      clientId: 'istation.jit',
      redirectUri: `${window.location.origin}/auth/login`,
      logoutRedirectUri: `${window.location.origin}/auth/logout`,
      authEndpoint: `${idSrvDomain}/Account/Login`,
      tokenEndpoint: response.data.token_endpoint,
      userInfo: response.data.userinfo_endpoint,
      scope: 'openid profile istation.jit.api offline_access api.auth api.content.read api.assignment.read api.assignment.write api.core',
      endSession: response.data.end_session_endpoint,
      authorize: response.data.authorization_endpoint,
      revocationEndpoint: response.data.revocation_endpoint,
    };

    return this._config;
  }

}
