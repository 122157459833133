import { Injectable, inject } from '@angular/core';
import { StorageService } from '@jit/core';
import { ISearchInputState } from './search-input.interfaces';

const KEY_SEARCH_LIST = 'search_list';

@Injectable({
  providedIn: 'root',
})
export class SearchInputService {

  private _storageService: StorageService = inject(StorageService);

  public add(state: ISearchInputState): void {
    // let prevOptions: string[] = this._storageService.retrive<string[]>(KEY_SEARCH_LIST) || [];

    // const optionsSet: Set<string> = new Set(prevOptions);
    // const val = state.value().trim();

    // if (val) {
    //   optionsSet.add(val);
    // }

    // const options: string[] = [...optionsSet];

    // this._storageService.store(KEY_SEARCH_LIST, options);

    // state.options.set(options);
  }

  public remove(value: string, state: ISearchInputState): void {
    // if (value) {
    //   const options = [ ...state.options() ];
    //   const at = options.indexOf(value);

    //   if (at >= 0) {
    //     options.splice(at, 1);

    //     state.options.set(options);

    //     this._storageService.store(KEY_SEARCH_LIST, options);
    //   }
    // }
  }

  public filter(state: ISearchInputState) {
    // let options: string[] = [];

    // if (state.value()) {
    //   options = this._storageService.retrive<string[]>(KEY_SEARCH_LIST) || [];

    //   options = options.filter((i) => (i.includes(state.value())));
    // } else {
    //   options = this._storageService.retrive<string[]>(KEY_SEARCH_LIST) || [];
    // }

    // state.options.set(options);
  }
  
}
