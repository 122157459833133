import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ISlugEntity } from '@jit/data-layer';
import { CollectionService, BaseComponent, useCollection } from '@jit/data-layer';

@Component({
  selector: 'jit-collection-button',
  templateUrl: './collection-button.component.html',
  styleUrls: ['./collection-button.component.scss'],
  standalone: true,
  imports: [CommonModule, IconSimpleComponent],
})
export class CollectionButtonComponent extends BaseComponent implements OnChanges {
  
  @Input()
  item!: ISlugEntity;

  @Input()
  size: string = '24';

  @Input()
  color?: string = '#fff';

  private _collectionService: CollectionService = inject(CollectionService);

  public inCollection: boolean = false;
  public count: number = 1;

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes['item'] &&
      changes['item'].currentValue !== changes['item'].previousValue
    ) {
      this._handleChanges(changes['item'].currentValue);
    }
  }

  private _handleChanges(item: ISlugEntity) {
    const favorite = this._collectionService.isFavorite(item);

    if (favorite) {
      this.inCollection = true;
      this.count = favorite.cnt;
    } else {
      this.inCollection = false;
    }
    this.refresh();
  }

  @useCollection()
  onCollectionChanges(): void {
    this._handleChanges(this.item);
  }

  public async onClick(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    if (this.inCollection) {
      this._collectionService.openCreationDialog(this.item);
    } else {
      const collection = this._collectionService.getMainCollection();

      await this._collectionService.addFavorite(this.item, collection.id);
      
      this._collectionService.showSnackbar({
        item: this.item,
        text: `Saved to ${collection.name}`,
        icon: 'check-circle',
        hasAction: true,
      });
      
      this._handleChanges(this.item);
    }
  }
}
