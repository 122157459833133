import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { ApiService } from '../api';
import { LoggerService } from '../logger';
import { OAuthService } from '../oauth/oauth.service';
import { SearchService } from '@jit/shared';
import { CollectionService } from '@jit/data-layer';

const REDIRECT_URL_KEY: string = 'redirect_url';
const restrictedRedirect: string[] = ['auth/login', 'auth/logout'];

@Injectable({
  providedIn: 'root',
})
export class InitService {

  private _storageService: StorageService = inject(StorageService);
  private _oauthService: OAuthService = inject(OAuthService);
  private _collectionService: CollectionService = inject(CollectionService);
  private _searchService: SearchService = inject(SearchService);
  private _apiService: ApiService = inject(ApiService);
  private _loggerService: LoggerService = inject(LoggerService);

  private _initialized: boolean = false;
  private _urlHeartbeat: string = '/v2/api/heartbeat';

  private _ready = new BehaviorSubject<boolean>(false);
  public ready$ = this._ready.asObservable();

  constructor() {
    this.init();
  }

  public async init() {
    if (this._initialized) {
      return;
    }

    await this._oauthService.init();

    await this._collectionService.init();
    this._searchService.init();

    this._storageService.store('redirected', false);

    this._initialized = true;

    this._ready.next(true);

    const redirectUrl = this._storageService.retrive(REDIRECT_URL_KEY);

    if (redirectUrl) {
      this._storageService.remove(REDIRECT_URL_KEY);

      setTimeout(() => ((document as any).location = redirectUrl), 100);
    }

    this.heartbeat();
    this.runChecking();
  }

  initialized(cb: any): void {
    if (this._initialized) {
      setTimeout(cb, 100);
    } else {
      setTimeout(() => this.initialized(cb), 100);
    }
  }

  runChecking(): void {
    const loc: string = document.location.toString();

    if (loc.includes('auth/logout')) {
      setTimeout(() => this.checkAuth(), 500);
    } else {
      setTimeout(() => this.checkAuth(), 3000);
    }
  }

  async checkAuth(): Promise<void> {
    const isValid = await this._oauthService.isTokenValid();
    
    if (isValid) {
      this.runChecking();
    } else {
      const loc: string = document.location.toString();
      const allowRedirect: boolean = !restrictedRedirect.find((url) => (loc.includes(url)));
      
      if (allowRedirect) {
        this._storageService.store(REDIRECT_URL_KEY, document.location.toString());
      }

      document.location.reload();
    }
  }

  async heartbeat(): Promise<void> {
    try {
      await this._apiService.post<any, any>(this._urlHeartbeat, {});
    } catch (err) {
      this._loggerService.error('InitService.heartbeat: Request error', err);
    }

    setTimeout(() => this.heartbeat(), 30 * 1000);
  }

}
