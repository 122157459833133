import { Route } from '@angular/router';
import { LoadingComponent, LogoutComponent, OAuthGuard, AssignmentGuard, SandboxGuard } from '@jit/core';
import { useFeatures } from './data-layer/stores/features.store';

const APP_ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () => import('./features/content-search/content-search.routes').then((m) => m.CONTENT_SEARCH_ROUTES),
    canActivate: [OAuthGuard],
  },
  {
    path: 'auth/login',
    component: LoadingComponent,
  },
  {
    path: 'auth/logout',
    component: LogoutComponent,
  },
  {
    path: 'detail-page/:id/:type',
    loadChildren: () => import('./features/details/details.routes').then((m) => m.DETAILS_ROUTES),
    canActivate: [OAuthGuard],
  },
  {
    path: 'my-collections',
    loadChildren: () => import('./features/my-collections/my-collection.routes').then((m) => m.MY_COLLECTION_ROUTES),
    canActivate: [OAuthGuard],
  },
];

if (useFeatures.getState().assignment) {
  APP_ROUTES.push({
    path: 'assignments',
    loadChildren: () => import('./features/gradebook/gradebook.routes').then((m) => m.GRADEBOOK_ROUTES),
    canActivate: [OAuthGuard, AssignmentGuard],
  });
}

if (useFeatures.getState().sandbox) {
  APP_ROUTES.push({
    path: 'sandbox',
    loadChildren: () => import('./features/sandbox/sandbox.routes').then((m) => m.SANDBOX_ROUTES),
    canActivate: [SandboxGuard],
  });
}

APP_ROUTES.push({
  path: '**',
  pathMatch: 'full',
  redirectTo: '',
});

export {
  APP_ROUTES,
};
