import { UtilsService, StorageService } from '@jit/core';
import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { INotification, NotificationType } from '../interfaces/notification.interface';

interface INotificationState {
  notifications: INotification[];

  init: () => void;
  setNotification: (notification: INotification) => void;
  showError: (notification: INotification) => void;
  showWarn: (notification: INotification) => void;
  showInfo: (notification: INotification) => void;
  showSuccess: (notification: INotification) => void;
}


const useNotifications = createStore<INotificationState>((set, get) => ({
  notifications: [],
  
  init: () => {
    const storageService: StorageService = createStore.inject(StorageService);
    const messages: any[] = storageService.retrive('notifications') || [];

    messages.forEach((item) => get().showWarn(item));

    storageService.store('notifications', []);
  },

  setNotification: (notification: INotification) => {
    const ntf: any = notification;
    const at = get().notifications.findIndex((n) => (n.label === notification.label));

    if (at >= 0) {
      return;
    }
    
    ntf.id = UtilsService.guid();

    if (!ntf.hasOwnProperty('autoClose')) {
      ntf.autoClose = 10000;
    }

    const remove = () => {
      const list = [ ...get().notifications ];
      const at = list.findIndex((i) => (i.id === ntf.id));

      if (at >= 0) {
        list.splice(at, 1);

        set({ notifications: list });
      }
    };

    if (ntf.autoClose > 0) {
      setTimeout(remove, ntf.autoClose);
    }

    const onClose = ntf.onClose || (() => {});

    ntf.onClose = () => {
      onClose();
      remove();
    };

    set({ notifications: [ ...get().notifications, ntf ] });
  },

  showError: (notification: INotification) => {
    notification.type = NotificationType.ERROR;
    
    get().setNotification(notification);
  },
  showWarn: (notification: INotification) => {
    notification.type = NotificationType.WARN;

    get().setNotification(notification);
  },
  showInfo: (notification: INotification) => {
    notification.type = NotificationType.INFO;

    get().setNotification(notification);
  },
  showSuccess: (notification: INotification) => {
    notification.type = NotificationType.SUCCESS;

    get().setNotification(notification);
  },
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useNotifications = useNotifications;
}

export {
  INotificationState,
  useNotifications,
};
