import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { PortalPayload, PortalTemplate } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private _portal = new BehaviorSubject<PortalPayload>(new Map());

  getTemplate(type: PortalTemplate) {
    return this._portal
      .asObservable()
      .pipe(map((payload) => payload.get(type)));
  }

  incert(template: TemplatePortal<any> | null, type: PortalTemplate) {
    const payload = this._portal.value;

    payload.set(type, template);

    this._portal.next(payload);
  }
}
