import { CommonModule } from '@angular/common';
import { inject } from '@angular/core';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GradeService } from '@jit/data-layer';

@Component({
  selector: 'jit-grades',
  templateUrl: './grades.component.html',
  styleUrls: ['./grades.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class GradesComponent implements OnInit, OnChanges {

  @Input()
  grades: number[] = [];

  private _gradeService: GradeService = inject(GradeService);

  public label: string = '';

  ngOnInit(): void {
    this.label = this._gradeService.gradesToStr(this.grades);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['grades'] && changes['grades'].currentValue !== changes['grades'].previousValue) {
      this.label = this._gradeService.gradesToStr(changes['grades'].currentValue);
    }
  }

}

export {
  GradesComponent,
};
