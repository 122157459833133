import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, signal, OnChanges, SimpleChanges } from '@angular/core';
import { ThSorts } from './th-sort.enum';
import { IThSortState } from './th-sort.interfaces';

@Component({
  selector: 'jit-th-sort',
  templateUrl: './th-sort.component.html',
  styleUrls: ['./th-sort.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ThSortComponent implements OnInit, OnChanges {

  @Input()
  sort: ThSorts = ThSorts.NONE;

  @Output()
  changed: EventEmitter<ThSorts> = new EventEmitter();

  public state: IThSortState = {
    sort: signal(ThSorts.NONE),
  };

  public ThSorts: typeof ThSorts = ThSorts;

  ngOnInit(): void {
    this.state.sort.set(this.sort);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const keys: string[] = ['sort'];

    keys.forEach((k: string) => {
      if (changes && changes[k] && changes[k].currentValue !== changes[k].previousValue) {
        const state: any = this.state;
        const self: any = this;

        if (!changes[k].firstChange) {
          state[k].set(self[k]);
        }
      }
    });
  }

  reset(): void {
    this.state.sort.set(ThSorts.NONE);
  }

  onClick(): void {
    let newSort: ThSorts = ThSorts.NONE;

    if (this.state.sort() === ThSorts.NONE) {
      newSort = ThSorts.ASC;
    } else if (this.state.sort() === ThSorts.ASC) {
      newSort = ThSorts.DESC;
    }
    
    this.state.sort.set(newSort);
    this.changed.emit(newSort);
  }

}

export {
  ThSortComponent,
};
