<mat-accordion>
  <mat-expansion-panel
    hideToggle
    *ngFor="let row of view"
    (opened)="row.opened = true"
    (closed)="row.opened = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <jit-icon-simple
          class="arrow"
          [class.arrow--down]="row.opened"
          iconId="chevron"
        ></jit-icon-simple>

        <jit-icon-simple
          [ngStyle]="{ color: row.bundle.color }"
          iconId="bundle-large"
        ></jit-icon-simple>

        <a
          class="link"
          [routerLink]="['/', 'detail-page', row.bundle.id, 'bundle']"
        >
          <strong *ngIf="!hideType" class="type">
            {{ row.bundle.type }} |
          </strong>
          {{ row.bundle.name }} ({{ row.bundle.resources.length }})
        </a>
      </mat-panel-title>

<!--      <jit-collection-button [item]="row.bundle"></jit-collection-button>-->
    </mat-expansion-panel-header>

    <jit-resource-list [resources]="row.bundle.resources"></jit-resource-list>
  </mat-expansion-panel>
</mat-accordion>
