<div class="jit-assignment-content">
  <div class="jit-assignment-content__header">
    <jit-assignment-header></jit-assignment-header>
  </div>
  <div class="jit-assignment-content__content">
    <jit-assignment-resources></jit-assignment-resources>
  </div>
  <div class="jit-assignment-content__footer">
    <jit-assignment-footer></jit-assignment-footer>
  </div>
</div>
