import { IResources, IResourceEntity } from './resource.interface';

interface ISelectItem {
  id: string;
  title: string;
  [key: string]: any;
};

interface ISearchResourcesState {
  subjectId: string | void;
  gradeId: string | void;
  domainId: string | void;
  stateId: string | void;
  searchId: string | number | void;
  sortId: string | void;
  contentType: string;

  subjects: ISelectItem[];
  grades: ISelectItem[];
  domains: ISelectItem[];
  states: ISelectItem[];

  resources: IResources;
  additionalResources: IResourceEntity[];

  loadResources: () => void;
  loadSubjects: () => void;

  setSubjectId: (subjectId: string | void) => Promise<void>;
  setGradeId: (gradeId: string | void) => Promise<void>;
  setDomainId: (domainId: string | void) => Promise<void>;
  setStateId: (stateId: string | void) => void;
  setSearchId: (searchId: string | number | void) => void;
  setSortId: (sortId: string | void) => void;

  reset: () => void;
  init: () => void;
}

export {
  ISelectItem,
  ISearchResourcesState,
};
