export type Page = {
  route: string;
  icon?: boolean;
  title?: string;
  hasSearchBar?: boolean;
};

export const pages: Page[] = [
  {
    route: '/detail-page',
  },
  {
    route: '/my-collections',
    title: 'My Collections',
  },
  {
    route: '/results',
    hasSearchBar: true,
  },
  {
    route: '/',
    icon: true,
    title: 'Find resources as unique as your students.',
    hasSearchBar: true,
  },
  {
    route: '/assignments',
    hasSearchBar: false,
  },
  {
    route: '/sandbox',
    hasSearchBar: false,
  },
];

export const gradeMap: Record<string, string> = {
  '-1': 'Pre-K',
  '0': 'Kindergarten',
  '1': '1st',
  '2': '2nd',
  '3': '3rd',
  '4': '4th',
  '5': '5th',
  '6': '6th',
  '7': '7th',
  '8': '8th',
};

export enum DialogStatus {
  Accept,
  Reject,
}
