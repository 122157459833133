<div class="jit-microlesson-preview">
  <ng-container *ngIf="showThumb && path">
    <a class="jit-microlesson-preview__link" [href]="url | safeUrl" target="_blank">
      <img class="jit-microlesson-preview__thumb" [src]="path | safeUrl" />
    </a>

    <img class="jit-microlesson-preview__thumb mobile" [src]="path | safeUrl" />
  </ng-container>
  
  <ng-container *ngIf="!showThumb">
    <div class="jit-microlesson-preview__content">
      <div class="jit-microlesson-preview__content-overlay"></div>
      <iframe [src]="path | safeUrl" class="jit-microlesson-preview__content-iframe"></iframe>
    </div>
  </ng-container>
</div>
