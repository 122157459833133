import { IAssignment, IStats } from './assignment-view.interface';

interface IResult extends IAssignment {
  studentId: string;
  firstName: string;
  lastName: string;
  skill: string;
}

interface ILessonStats {
  id: string;
  resourceId: string;
  name: string;
  stats: IStats;
  suite: string;
  series: string[];
  episode: number;
  standard: {
    title: string;
    description: string;
  };
  learningTarget: string;
  successCriteria: string[];
  results: IResult[];
  origin: IResult[];
  language: string;
  skill: string;
}

export type { ILessonStats };
