<a *ngIf="link" [href]="link" (click)="onClick($event)">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<a *ngIf="href" [href]="href">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
