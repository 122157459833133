import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { filter, map } from 'rxjs/operators';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { Animations } from '../../animations';
import { 
  IconSimpleComponent, 
  LinkComponent, 
  FeedbackButtonComponent, 
  NewAssignmentButtonComponent,
  SimpleSelectComponent,
} from '@jit/shared';
import { Event, PortalTemplate } from '../../models';
import {
  EventService,
  PortalService,
  StorageService,
  OAuthService,
  IUserInfo,
} from '../../services';
import { PortalModule } from '@angular/cdk/portal';
import { environment } from '../../../../environments/environment';
import { useStates, IEntityFilters, useUser, useFeatures, IFeaturesState } from '@jit/data-layer';

type BackButtonOptions = {
  title: string;
  link?: string;
  href?: string;
};

@Component({
  selector: 'jit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  animations: [Animations.arrowAnim()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    RouterLink,
    RouterLinkActive,
    IconSimpleComponent,
    LinkComponent,
    PortalModule,
    FeedbackButtonComponent,
    NewAssignmentButtonComponent,
    SimpleSelectComponent,
  ],
})
export class HeaderComponent implements OnInit {

  @useStates('state')
  state: IEntityFilters | void = void(0);

  @useUser('allowCreateAssignment')
  allowCreateAssignment: boolean = false;

  @useFeatures()
  features: IFeaturesState = {};

  private _lastSearchUrl?: string;
  private _storage = inject(StorageService);
  private _oauthService: OAuthService = inject(OAuthService);
  private _eventService = inject(EventService);
  private _router = inject(Router);
  private _backButton = new BehaviorSubject<BackButtonOptions>({
    title: 'Back to Home',
    href: environment.parentHomePage,
  });
  private _resourcesActive = new BehaviorSubject<boolean>(true);
  private _portalService = inject(PortalService);

  public user!: ReplaySubject<IUserInfo | null>;
  public showHideFooter: boolean = true;

  public backButton$ = this._backButton.asObservable();
  public resourcesActive$ = this._resourcesActive.asObservable();
  public collectionButton$ = this._portalService.getTemplate(PortalTemplate.HeaderPortal);

  public isResource: boolean = false;
  public isCollection: boolean = false;
  public isSandbox: boolean = false;
  public isGradebook: boolean = false;

  get userTitle$() {
    return this.user.pipe(
      map((user) => {
        if (user) {
          return user.role.filter((el: any) => el === 'student' || el === 'teacher').join(', ');
        } else {
          return '';
        }
      })
    );
  }

  get userName$() {
    return this.user.pipe(
      map((user) => {
        if (user) {
          return `${user.given_name} ${user.family_name[0] || ''}.`
        } else {
          return 'Guest';
        }
      })
    );
  }

  public ngOnInit(): void {
    this.user = this._oauthService.userInfo$;

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this._portalService.incert(null, PortalTemplate.HeaderPortal);

          this._checkTabsByURL(event.urlAfterRedirects);

          if (
            event.urlAfterRedirects === '/' ||
            event.urlAfterRedirects.includes('/results')
          ) {
            this._lastSearchUrl = event.urlAfterRedirects;
            this._storage.store('searchUrl', this._lastSearchUrl);
            this._resourcesActive.next(true);
            this._backButton.next({
              title: 'Back to Home',
              href: environment.parentHomePage,
            });
          } else {
            const url = this._storage.retrive<string>('searchUrl') || '/';

            this._resourcesActive.next(false);
            this._backButton.next({
              title: 'Back to Search',
              link: url,
            });
          }
        }
      });
    
    this._checkTabsByURL(document.location.pathname);
  }

  private _checkTabsByURL(url: string): void {
    this.isResource = false;
    this.isCollection = false;
    this.isSandbox = false;
    this.isGradebook = false;

    if (url === '/' || url.includes('/results') || url.includes('/detail-page/')) {
      this.isResource = true;
    } else if (url.includes('/my-collection')) {
      this.isCollection = true;
    } else if (url.includes('/sandbox')) {
      this.isSandbox = true;
    } else if (url.includes('/assignments')) {
      this.isGradebook = true;
    }
  }

  public async onLogout(): Promise<void> {
    await this._oauthService.logout();
  }

  public onShowMenu() {
    this._eventService.emit(Event.OpenNavigation);
  }

  public onShowSelectState(): void {
    useStates.getState().open();
  }

}
