<footer class="container-fluid">
  <nav class="navbar sticky-bottom d-flex justify-content-center">
    <ul class="nav justify-content-center">
      <li class="nav-item">
        <a href="https://www.istation.com/Legal"
           title="Legal - opens in a new tab"
           target="_blank"
           class="nav-link">{{'footer.legal' | translate}}</a>
      </li>
      <li class="nav-item">
        <a target="_blank"
           href="https://www.istation.com/Legal/PrivacyStatement"
           title="Privacy - opens in a new tab"
           class="nav-link">{{'footer.privacy' | translate}}</a>
      </li>
      <li class="nav-item">
        <a target="_blank"
           href="https://www.istation.com/Legal/TermsOfUse"
           title="Terms & Conditions - opens in a new tab"
           class="nav-link">{{'footer.terms' | translate}}</a>
      </li>
      <li class="nav-item">
        <a target="_blank"
           href="https://www.istation.com/Contact"
           title="Contact - opens in a new tab"
           class="nav-link">{{'footer.contact' | translate}}</a>
      </li>
      <!-- <li class="nav-item">
        <jit-feedback-button [asLink]="true"></jit-feedback-button>
      </li> -->
    </ul>
  </nav>
</footer>
