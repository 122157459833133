import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'jit-trend-topics',
  templateUrl: './trend-topics.component.html',
  styleUrls: ['./trend-topics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf],
})
export class TrendTopicsComponent {
  @Input() public topicsArray: string[] | undefined;
}
