import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  ChangeDetectorRef, 
  inject
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { Bundle } from '../../models';
import { CollectionButtonComponent } from '../collection-button/collection-button.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ResourceListComponent } from '../resource-list/resource-list.component';

@Component({
  selector: 'jit-bundle-list',
  templateUrl: './bundle-list.component.html',
  styleUrls: ['./bundle-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    IconSimpleComponent,
    ResourceListComponent,
    RouterLink,
    CollectionButtonComponent,
  ],
})
export class BundleListComponent implements OnInit {

  @Input() bundles!: Bundle[];
  @Input() hideType = false;
  @Input() id: string = '';

  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  
  public view: { opened: boolean; bundle: Bundle }[] = [];

  ngOnInit(): void {
    this.updateBundles(true);
  }

  public updateBundles(isInit?: boolean) {
    this.view = this.bundles.map((el) => ({ opened: false, bundle: el }));

    if (!isInit) {
      setTimeout(() => {
        this._cdr.markForCheck();
        this._cdr.detectChanges();
      });
    }
  }
}
