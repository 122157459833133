import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponent, IAssignedStudentsItemResponse } from '@jit/data-layer';
import { ResourceSmallItemComponent } from '../resource-small-item/resource-small-item.component';

interface ITooltipPosition {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

@Component({
  selector: 'jit-resource-small-item-list',
  templateUrl: './resource-small-item-list.component.html',
  styleUrls: ['./resource-small-item-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ResourceSmallItemComponent,
  ],
})
class ResourceSmallItemListComponent extends BaseComponent {

  @Input() 
  resources: IAssignedStudentsItemResponse[] = [];

  @Input()
  position: ITooltipPosition = { top: 0, right: 0, bottom: 0, left: 0, };

}

export {
  ResourceSmallItemListComponent,
};
