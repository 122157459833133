import { environment } from '../../../environments/environment';
import { createStore } from '../store';
import { ICollection } from '../interfaces/collection.interface';

interface ICollectionState {
  collections: ICollection[];
  collection: ICollection | null;

  setCollections: (collections: ICollection[]) => void;
  setCollection: (collection: ICollection | null) => void;
}

const useCollection = createStore<ICollectionState>((set, get) => ({
  collections: [],
  collection: null,

  setCollections: (collections: ICollection[]) => set({ collections }),
  setCollection: (collection: ICollection | null) => set({ collection }),
}));

if (environment.features.sandbox) {
  if (!(window as any).jitSandbox) {
    (window as any).jitSandbox = {};
  }

  (window as any).jitSandbox.useCollection = useCollection;
}

export {
  ICollectionState,
  useCollection,
};
