import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'jit-report-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
class ReportIconsComponent {

  @Input()
  name: string = '';

  @Input()
  width: number = 0;

  @Input()
  height: number = 0;

  @Input()
  bg: string = '';

}

export {
  ReportIconsComponent,
};
