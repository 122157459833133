interface ISlugEntity {
  type: string;
  slug: string;
}

interface ISuiteEntity extends ISlugEntity {
  id: string;
  title: string;
  description: string;
  series: ISeriesEntity[];
  parentSubject?: IParentEntity;
}

interface ISeriesEntity extends ISlugEntity {
  id: string;
  title: string;
  description: string;
  resources: IResourceEntity[];
  parentSubject?: IParentEntity;
  parentSuite?: IParentEntity;
  selected?: number;
}

interface IPropertyEntity {
  id: string;
  type: string;
  title: string;
  value: string;
}

interface IFileEntity {
  id: string;
  type: string;
  fileType: string;
  path: string;
}

interface ICodeEntity {
  stateId: string;
  codeId: string;
  stateCode: string;
}

interface IParentEntity {
  id: string;
  title: string;
  slug: string;
}

interface IResourceEntity extends ISlugEntity {
  id: string;
  title: string;
  description?: string;
  status: string;
  episode: string | null;
  duration: string | null;
  grade: IPropertyEntity[] | null;
  file: IFileEntity | null;
  thumbnail: IFileEntity | null;
  codes: ICodeEntity[];
  contentType: string;
  originalContentType: string;
  standard_id: string;
  parentSubject?: IParentEntity;
  parentSuite?: IParentEntity;
  parentSeries?: IParentEntity;
  activityId?: string;
  language: string;
  allowToAssign: boolean;
  oid: number;
  learningTarget: {
    idx: string;
    description: string;
  };
}

interface IResources {
  suites: ISuiteEntity[];
  count: number;
  contentTypes: IContentTypeItem[];
}

interface IContentTypeItem {
  key: string;
  label: string; 
  count: number;
}

interface IState {
  id: string;
  title: string;
}

interface IDetail {
  id: string;
  description: string;
}

interface IDetails {
  learningTarget: IDetail | null;
  successCriterion: IDetail[];
}

export {
  ISlugEntity,
  ISuiteEntity,
  ISeriesEntity,
  IPropertyEntity,
  IFileEntity,
  ICodeEntity,
  IResourceEntity,
  IResources,
  IParentEntity,
  IState,
  IDetail,
  IDetails,
  IContentTypeItem,
};
