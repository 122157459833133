import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { BaseComponent, useAssignment, IResourceEntity } from '@jit/data-layer';
import { IconSimpleComponent, ModalDialogComponent } from '@jit/shared';

@Component({
  selector: 'jit-assignment-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconSimpleComponent,
    ModalDialogComponent,
  ],
})
class AssignmentFooterComponent extends BaseComponent {

  @ViewChild('dialogCancel', { static: false })
  dialogCancel!: ModalDialogComponent;

  @useAssignment('resources')
  resources: IResourceEntity[] = [];

  onApply(): void {
    useAssignment.getState().apply();
  }

  onCancel(): void {
    this.dialogCancel.open();
  }
  
  confirmCancel(): void {
    useAssignment.getState().reset();

    this.onCloseDialog();
  }

  onCloseDialog(): void {
    this.dialogCancel.close();
  }

}

export {
  AssignmentFooterComponent,
};
