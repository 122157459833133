<header>
  <h2 class="header-name">Create new collection</h2>
</header>

<ng-container *ngIf="creationFormView$ | async">
  <div class="control-wrapper">
    <label class="control-label">Collection title</label>

    <input type="text" class="control" [formControl]="name" />
  </div>
  <div class="buttons-wrapper">
    <button
      class="button button-action jit-primary-btn-outline"
      (click)="action('cancel')"
      type="button"
    >
      Cancel
    </button>
    <button
      class="button button-action btn jit-primary-btn"
      (click)="action('save')"
      type="button"
    >
      Save
    </button>
  </div>
</ng-container>

<ng-container *ngIf="(creationFormView$ | async) === false">
  <button type="button" class="button button-create" (click)="changeView()">
    <span class="button-image">
      <img
        ngSrc="/assets/icons/pepicons-pop_plus.svg"
        alt="button-search"
        height="20"
        width="20"
      />
    </span>

    <span class="button-description">Create new collection</span>
  </button>

  <div
    *ngIf="(collections$ | async) as collections"
    class="modal-collections-wrapper"
  >
    <div
      *ngFor="let collection of collections"
      [formGroup]="form"
      class="collection-container"
    >
      <img
        *ngIf="collection.main"
        class="collection-image"
        ngSrc="../../../../assets/icons/ant-design_heart-filled.png"
        alt="create-button"
        height="32"
        width="32"
      />
      <div class="collection-wrapper layout-column">
        <h3 class="collection-name">
          {{ collection.name }}
        </h3>
        <span *ngIf="collection?.favorites?.length" class="collection-count">
          {{ collection.favorites.length }} items
        </span>
      </div>
      <input
        *ngIf="data"
        class="form-check-input collection-checkbox"
        type="checkbox"
        [formControlName]="collection.id"
        (change)="updateToCollection(collection.id)"
      />
    </div>
  </div>

  <div class="buttons-wrapper">
    <button
      class="button button-action btn jit-primary-btn"
      (click)="action('done')"
      type="submit"
    >
      Done
    </button>
  </div>
</ng-container>
