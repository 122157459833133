export interface IUser {
  domainId: string;
  family_name: string;
  given_name: string;
  multi_acct: string;
  name: string;
  oid: string;
  role: string[];
  sub: string;
}
