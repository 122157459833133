import { Injectable } from '@angular/core';
import {DateTime} from "luxon";

const FILE_NAME: string = 'IstationReporting-UserName-Date.csv';

@Injectable({
  providedIn: 'root',
})
class CsvService {

  private _getContent(csvList: any[][] = []): string {
    let content: string = '';

    for (let row of csvList) {
      for (let cell of row) {
        content = content + `"${String(cell).replace(/"/g, '""')}",`;
      }

      content = content + '\r\n';
    }

    return content;
  }

  download(csvList: any[][] = [], username: string, fileName: string = FILE_NAME): void {
    if (csvList.length > 1) {
      const blob = new Blob([this._getContent(csvList)], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download', fileName.replace('UserName', username).replace('Date', DateTime.now().toFormat('yyyy-MM-dd')));

      link.style.visibility = 'hidden';

      document.body.appendChild(link);

      link.click();

      setTimeout(() => document.body.removeChild(link), 300);
    }
  }

}

export {
  CsvService,
};
