import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackBarRef?: MatSnackBarRef<unknown>;

  constructor(private _snackBar: MatSnackBar) {}

  openFromComponent(component: ComponentType<unknown>, data?: unknown) {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.data = data;

    this.snackBarRef = this._snackBar.openFromComponent(component, config);
  }
}
