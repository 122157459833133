import { 
  ChangeDetectionStrategy, Component, inject, OnInit, OnDestroy, 
  EventEmitter, Output, Input, ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SelectComponent } from '../select/select.component';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { ParamsService, FiltersService, IEntityFilters } from '@jit/data-layer';
import { LogError, UtilsService } from '@jit/core';

type TKey = 'grade' | 'domain' | 'state';

const ALLOWED_KEYS = [ 'subject', 'grade', 'domain', 'state' ];

const LABELS: any = {
  grade: {
    title: 'Filter by Grade',
    placeholder: 'Select',
    clear: 'Clear',
  },
  domain: {
    title: 'Filter by Domain',
    placeholder: 'Select',
    clear: 'Clear',
  },
  state: {
    title: 'Filter by State',
    placeholder: 'Select',
    clear: 'Clear',
  },
};

@Component({
  selector: 'jit-select-filters',
  templateUrl: './select-filters.component.html',
  styleUrls: ['./select-filters.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    CommonModule,
    SelectComponent,
    IconSimpleComponent,
    MatInputModule,
    MatSelectModule,
  ],
})
class SelectFiltersComponent implements OnInit, OnDestroy {

  @Input()
  type!: TKey;

  @Output()
  changed: EventEmitter<[string, (IEntityFilters | null)]> = new EventEmitter();
  
  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _paramsService: ParamsService = inject(ParamsService);
  private _filtersService: FiltersService = inject(FiltersService);
  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private _subscription: any;

  public ids: any = {
    subject: '',
    grade: '',
    gomain: '',
    state: '',
  };

  public prevIds: any = {
    subject: '',
    grade: '',
    gomain: '',
    state: '',
  };

  public labels: any = { title: '', placeholder: '', clear: '' };
  public current: IEntityFilters | null = null;
  public options: IEntityFilters[] = [];
  public disabled: boolean = false;

  ngOnInit(): void {
    if (!this.type) {
      throw new LogError('SelectFiltersComponent: type is not defined');
    }

    this.labels = LABELS[this.type];

    this._subscription = this._route.queryParams.subscribe(() => this._handleQueryParams());

    this._handleQueryParams();
  }

  ngOnDestroy(): void {
    this._subscription && this._subscription.unsubscribe();
  }

  async fetch(): Promise<void> {
    const prev = this.prevIds;
    const ids = this.ids;

    let options: IEntityFilters[] = [];

    if (this.type === 'grade') {
      if (ids.subject) {
        if (ids.subject === prev.subject) {
          options = this.options;
        } else {
          options = await this._filtersService.getGrades({ subjectId: ids.subject });
        }
      }
    } else if (this.type === 'domain') {
      if (ids.subject && ids.grade) {
        if (ids.subject === prev.subject && ids.grade === prev.grade) {
          options = this.options;
        } else {
          options = await this._filtersService.getDomains({ subjectId: ids.subject, gradeId: ids.grade });
        }
      }
    } else if (this.type === 'state') {
      if (ids.subject && ids.grade && ids.domain) {
        if (ids.subject === prev.subject && ids.grade === prev.grade && ids.domain === prev.domain) {
          options = this.options;
        } else {
          options = await this._filtersService.getStates({ subjectId: ids.subject, gradeId: ids.grade, domainId: ids.domain });
        }
      }
    }

    if (options !== this.options) {
      if (!!options.length) {
        this.options = options;
        this.disabled = false;

        this.current = options.find((i) => (i.value === this.ids[this.type])) || null;
      } else {
        this.options = [];
        this.disabled = true;
        this.current = null;
      }

      this._refresh();
    }
  }

  private _refresh(): void {
    setTimeout(() => {
      this._cdr.markForCheck();
      this._cdr.detectChanges();
    }, 100);
  }

  private _handleQueryParams(): void {
    this.prevIds = this.ids;
    this.ids = UtilsService.pick(this._route.snapshot.queryParams, ALLOWED_KEYS);

    this.fetch();
  }

  onChange(entity: IEntityFilters): void {
    if (entity) {
      if (!this.current || (this.current && entity.value !== this.current.value)) {
        this.current = entity;

        this._paramsService.applySelectFilters(this.type, entity);

        this.changed.emit([this.type, this.current]);
      }
    } else {
      this.current = null;
  
      this._paramsService.applySelectFilters(this.type, null);

      this.changed.emit([this.type, null]);
    }
  }

}

export {
  SelectFiltersComponent,
};
