import { Pipe, PipeTransform } from '@angular/core';

const LANGUAGE_KEYS: Record<string, any> = {
  en: {
    learningTarget: 'The student will learn',
    successCriteria: 'The student will know they learned it when they can',
  },
  es: {
    learningTarget: 'El estudiante aprenderá',
    successCriteria: 'El estudiante sabrá que lo ha aprendido cuando pueda',
  }
}

@Pipe({
  name: 'resTranslate',
  standalone: true,
})
class ResourceTranslatePipe implements PipeTransform {
  
  transform(key: string, lang: string = 'en'): string {
    return LANGUAGE_KEYS[lang][key] || 'unknown language learning target';
  }

}

export {
  ResourceTranslatePipe,
};
