export enum Color {
  Pink = 'pink',
  Blue = 'blue',
  Orange = 'orange',
  White  = 'white'
}

export const colorMap: Record<string, Color> = {
  reading: Color.Pink,
  lectura: Color.Orange,
  math: Color.Blue,
  undefined: Color.White
};
