import { INpsQuestion, INpsSurvey, INpsAnswer } from '../interfaces';

interface INpsState {
  buttons: number[];
  rating: number;
  descr: string;
  question: string;
  isOpened: boolean;
  isQuestionsOpened: boolean;
  questions: INpsQuestion[];
  survey: INpsSurvey | null;
  questionOnPositive: string;
  questionOnNegative: string;

  dismiss: () => void;
  done: (answers: INpsAnswer[]) => void;
  setRating: (rating: number) => void;
  init: () => void;
  start: () => void;
}

export {
  INpsState,
};
