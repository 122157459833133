<div class="filter-range">
  <div class="filter-range-wrapper">
    <label>
      <select class="form-select" [formControl]="fromControl">
        <option [value]="''">Select</option>
        <option *ngFor="let option of filter.options" [value]="option.key">
          {{ option.label }}
        </option>
      </select>
    </label>

    <ng-container *ngIf="withinControl.value">
      <span>to</span>

      <label>
        <select class="form-select" [formControl]="toControl">
          <option [value]="''">Select</option>
          <option *ngFor="let option of filter.options" [value]="option.key">
            {{ option.label }}
          </option>
        </select>
      </label>
    </ng-container>
  </div>

  <div class="form-check">
    <label class="filter-checkbox form-check-label">
      <input
        class="form-check-input"
        [formControl]="withinControl"
        type="checkbox"
      />

      <span>Show within a range</span>
    </label>
  </div>
</div>
