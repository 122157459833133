import { inject, Injectable } from '@angular/core';
import { UtilsService, ApiService, IResponse, LoggerService } from '@jit/core';
import { IQueryFilters } from '../interfaces/query-filters.interface';
import {
  ISuiteEntity,
  ISeriesEntity,
  IResourceEntity,
  IResources,
  IState,
  IDetails,
  IContentTypeItem,
} from '../interfaces/resource.interface';
import { FiltersService } from './filters.service';
import { IEnvironment, ENV } from '@jit/shared';

@Injectable({
  providedIn: 'root',
})
class ResourceService {

  public static readonly resourceColorMap: Record<string, string> = {
    math: '#354add',
    lectura: '#ff5e03',
    reading: '#aa3c9a',
    undefined: '#fff',
  };

  private _urlSuite: string = '/v2/api/suits/{{ suiteId }}';
  private _urlSeries: string = '/v2/api/series/{{ seriesId }}';
  private _urlResources: string = '/v2/api/resources';
  private _urlAdditionalResources: string = '/v2/api/resources/additional';
  private _urlResource: string = '/v2/api/resources/{{ resourceId }}';
  private _urlStates: string = '/v2/api/resources/states/{{ resourceId }}';
  private _urlDetails: string = '/v2/api/resources/details/{{ resourceId }}';
  private _urlContentTypes: string = '/v2/api/resources/content-types';

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);
  private _filtersService: FiltersService = inject(FiltersService);
  private _env: IEnvironment = inject(ENV);

  private _getQueryStringFromQuery(query: IQueryFilters = {}): string {
    const { subjectId, gradeId, domainId, stateId } = query;

    let queryParams: string = '';

    if (subjectId && gradeId && domainId && stateId) {
      queryParams = new URLSearchParams({
        subjectId,
        gradeId,
        domainId,
        stateId,
      }).toString();
    } else if (subjectId && gradeId && domainId) {
      queryParams = new URLSearchParams({
        subjectId,
        gradeId,
        domainId,
      }).toString();
    } else if (subjectId && gradeId) {
      queryParams = new URLSearchParams({ subjectId, gradeId }).toString();
    } else if (subjectId) {
      queryParams = new URLSearchParams({ subjectId }).toString();
    }

    if (query.searchId) {
      queryParams = queryParams + ((queryParams ? '&' : '') + new URLSearchParams({ search: query.searchId }).toString());
    }

    if (query.sortId) {
      queryParams = queryParams + ((queryParams ? '&' : '') + new URLSearchParams({ sort: query.sortId }).toString());
    }

    if (query.contentType) {
      queryParams = queryParams + ((queryParams ? '&' : '') + new URLSearchParams({ contentType: query.contentType }).toString());
    }

    if (query.assignable) {
      queryParams = queryParams + ((queryParams ? '&' : '') + new URLSearchParams({assignable: 'true'}).toString());
    }

    return queryParams;
  }

  public async getResources(query: IQueryFilters = {}): Promise<IResources> {
    let queryParams: string = this._getQueryStringFromQuery(query);

    if (query.stateId) {
      await this._filtersService.getStateBySlug(query.stateId);
    }

    const url = this._urlResources + (queryParams ? '?' + queryParams : '');

    let response: IResponse<IResources>;

    try {
      response = await this._apiService.get<IResources>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getResources: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getSuite(suiteId: string): Promise<ISuiteEntity> {
    const url = UtilsService.format(this._urlSuite, { suiteId });

    let response: IResponse<ISuiteEntity>;

    try {
      response = await this._apiService.get<ISuiteEntity>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getSuite: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getSeries(seriesId: string, query: IQueryFilters = {}): Promise<ISeriesEntity> {
    let url = UtilsService.format(this._urlSeries, { seriesId });

    if (query.contentType) {
      url = url + '?contentType=' + query.contentType;
    }

    let response: IResponse<ISeriesEntity>;

    try {
      response = await this._apiService.get<ISeriesEntity>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getSeries: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getRelated(seriesId: string, entityId: string | void, query: IQueryFilters = {}): Promise<IResourceEntity[]> {
    const series = await this.getSeries(seriesId, query);

    if (entityId) {
      return series.resources.filter(resource => resource.id !== entityId);
    } else {
      return series.resources;
    }
  }

  public async getResource(resourceId: string): Promise<IResourceEntity> {
    const url = UtilsService.format(this._urlResource, { resourceId });

    let response: IResponse<IResourceEntity>;

    try {
      response = await this._apiService.get<IResourceEntity>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getResource: Request error', err);

      throw err;
    }

    if (!response.data.language) {
      response.data.language = 'en';
    }

    return response.data;
  }

  public async getStates(resourceId: string): Promise<IState[]> {
    const url = UtilsService.format(this._urlStates, { resourceId });

    let response: IResponse<IState[]>;

    try {
      response = await this._apiService.get<IState[]>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getStates: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getDetails(resourceId: string): Promise<IDetails> {
    const url = UtilsService.format(this._urlDetails, { resourceId });

    let response: IResponse<IDetails>;

    try {
      response = await this._apiService.get<IDetails>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getDetails: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getAdditionalResources(query: IQueryFilters = {}): Promise<IResourceEntity[]> {
    const { subjectId, gradeId, domainId, stateId, searchId, contentType, assignable } = query;

    const params: object = Object.fromEntries(
      Object.entries({
        subjectId,
        gradeId,
        domainId,
        stateId,
        contentType: contentType,
        search: searchId,
        assignable: assignable,
      }).filter(([key, value]) => (value !== undefined))
    );

    const queryParams = new URLSearchParams({ ...params }).toString();
    const url: string = this._urlAdditionalResources + (queryParams ? `?${queryParams}` : '');

    let response: IResponse<IResourceEntity[]>;

    try {
      response = await this._apiService.get<IResourceEntity[]>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getAdditionalResources: Request error', err);

      throw err;
    }

    return response.data;
  }

  public async getContentTypes(query: IQueryFilters = {}): Promise<IContentTypeItem[]> {
    let queryParams: string = this._getQueryStringFromQuery(query);

    if (query.stateId) {
      await this._filtersService.getStateBySlug(query.stateId);
    }

    const url = this._urlContentTypes + (queryParams ? '?' + queryParams : '');

    let response: IResponse<IContentTypeItem[]>;

    try {
      response = await this._apiService.get<IContentTypeItem[]>(url);
    } catch (err) {
      this._loggerService.error('ResourceService.getContentTypes: Request error', err);

      throw err;
    }

    return response.data;
  }

  public openMicrolesson(resource: IResourceEntity): void {
    if (resource.contentType === 'Microlesson') {
      const url: string = UtilsService.format(this._env.microlessonDomain, resource);

      (window as any).open(url, '_blank').focus();
    }
  }

}

export { ResourceService };
