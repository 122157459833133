<div class="jit-nps-banner">
  <jit-nps-close-button (click)="onClose()"></jit-nps-close-button>
  <div class="jit-nps-banner__header">
    <div class="jit-nps-banner__wrap">
      <jit-nps-logo></jit-nps-logo>
      <p>{{ descr }}</p>
    </div>
  </div>
  <div class="jit-nps-banner__content">
    <div class="jit-nps-banner__wrap">
      <h2>{{ question }}</h2>
      <div class="jit-nps-banner__buttons">
        @for (at of buttons; track $index) {
          <jit-nps-banner-button [value]="at" (click)="onButton($event)"></jit-nps-banner-button>
        }
      </div>
      <p class="jit-nps-banner__agenda">
        <span><span class="on-mobile">0 - </span>Not likely</span>
        <span class="middle">Somewhat likely</span>
        <span><span class="on-mobile">10 - </span>Very likely</span>
      </p>
    </div>
  </div>
</div>