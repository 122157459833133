<mat-form-field floatLabel="always">
  <div (click)="onClick($event)">
    <mat-select
      #select
      [formControl]="currentValue"
      (selectionChange)="onSelectionChange()"
      [placeholder]="placeholder"
      [hideSingleSelectionIndicator]="true"
    >
      <mat-select-trigger *ngIf="displayValue">
        {{ displayValue }}
      </mat-select-trigger>

      <mat-option>Clear</mat-option>

      <mat-option *ngFor="let option of options" [value]="option.key">
        <span class="check">
          <jit-icon-simple size="16" iconId="check"></jit-icon-simple>
        </span>
        <span>{{ option.label }}</span>
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
