const allIconNames = [
  'filters',
  'x-outline',
  'x-filled',
  'triangle-alert-filled',
  'time-outline',
  'search-outline',
  'revert-outline',
  'remove-filled',
  'profile-filled',
  'print-filled',
  'pinned-outline',
  'pin-outline',
  'new-window-outline',
  'menu',
  'logout-filled',
  'list-view-outline',
  'info-filled',
  'history-outline',
  'help-outline',
  'grid-view-outline',
  'forward-arrow-circle-outline',
  'feedback-filled',
  'eye-slash-outline',
  'eye-outline',
  'edit-outline',
  'checkmark-outline',
  'checkmark-filled',
  'download',
  'caret-up-filled',
  'caret-down-outline',
  'caret-down-filled',
  'back-arrow-outline',
  'add-doc-outline',
  'add-collection',
  'add-collection-filled',
  'slide',
  'backward-design',
  'video',
  'clock',
  'asc',
  'desc',
  'clock-filled',
  'close',
  'document',
  'open',
  'bundle',
  'bundle-large',
  'chevron',
  'chevron-thin',
  'pdf',
  'clock-min',
  'clock-15',
  'suite',
  'check-circle',
  'suite-mono',
  'bundle-mono',
  'heart',
  'check',
  'minus-circle',
  'search',
  'book',
  'sort-asc',
  'sort-desc',
  'microlesson',
  'double-lt',
  'lt',
  'calendar',
  'add',
  'trash',
  'small-check',
  'small-clock',
  'info',
  'warning',
  'doc-2',
  'share',
];

export { allIconNames };

export type IconId =
  | 'filters'
  | 'x-outline'
  | 'x-filled'
  | 'triangle-alert-filled'
  | 'time-outline'
  | 'search-outline'
  | 'revert-outline'
  | 'remove-filled'
  | 'profile-filled'
  | 'print-filled'
  | 'pinned-outline'
  | 'pin-outline'
  | 'new-window-outline'
  | 'menu'
  | 'logout-filled'
  | 'list-view-outline'
  | 'info-filled'
  | 'history-outline'
  | 'help-outline'
  | 'grid-view-outline'
  | 'forward-arrow-circle-outline'
  | 'feedback-filled'
  | 'eye-slash-outline'
  | 'eye-outline'
  | 'edit-outline'
  | 'checkmark-outline'
  | 'checkmark-filled'
  | 'download'
  | 'caret-up-filled'
  | 'caret-down-outline'
  | 'caret-down-filled'
  | 'back-arrow-outline'
  | 'add-doc-outline'
  | 'add-collection'
  | 'add-collection-filled'
  | 'slide'
  | 'backward-design'
  | 'video'
  | 'clock'
  | 'asc'
  | 'desc'
  | 'clock-filled'
  | 'close'
  | 'document'
  | 'open'
  | 'bundle'
  | 'bundle-large'
  | 'chevron'
  | 'chevron-thin'
  | 'pdf'
  | 'clock-min'
  | 'clock-15'
  | 'suite'
  | 'check-circle'
  | 'suite-mono'
  | 'bundle-mono'
  | 'heart'
  | 'check'
  | 'minus-circle'
  | 'search'
  | 'book'
  | 'sort-asc'
  | 'sort-desc'
  | 'microlesson'
  | 'double-lt'
  | 'lt'
  | 'calendar'
  | 'add'
  | 'trash'
  | 'small-check'
  | 'small-clock'
  | 'info'
  | 'warning'
  | 'doc-2'
  | 'share';
