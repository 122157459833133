import { inject, Injectable } from '@angular/core';
import { ApiService, IResponse, LoggerService } from '@jit/core';
import { INpsAvailable, INpsSurvey, INpsCreate } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
class NpsService {
  
  private _urlNpsSurvey: string = '/v2/api/nps-surveys';
  private _urlNpsSurveyDismiss: string = '/v2/api/nps-surveys/dismiss';

  private _loggerService: LoggerService = inject(LoggerService);
  private _apiService: ApiService = inject(ApiService);

  public async getAvailable(): Promise<INpsAvailable> {
    let response: IResponse<INpsAvailable>;

    try {
      response = await this._apiService.get<INpsAvailable>(this._urlNpsSurvey);
    } catch (err) {
      this._loggerService.error('NpsService.getAvailable: Request error', err);

      return { available: false, survey: null };
    }

    return response.data;
  }

  public async dismiss(surveyId: string): Promise<void> {
    try {
      await this._apiService.post(this._urlNpsSurveyDismiss, { surveyId });
    } catch (err) {
      this._loggerService.error('NpsService.dismiss: Request error', err);
    }
  }

  public async create(survey: INpsCreate): Promise<INpsSurvey | null> {
    let response: IResponse<INpsSurvey>;

    try {
      response = await this._apiService.post<INpsSurvey, INpsCreate>(this._urlNpsSurvey, survey);
    } catch (err) {
      this._loggerService.error('NpsService.create: Request error', err);

      return null;
    }

    return response.data;
  }

  public async clean(): Promise<void> {
    try {
      await this._apiService.delete(this._urlNpsSurvey);
    } catch (err) {
      this._loggerService.error('NpsService.clean: Request error', err);
    }
  }

}

export {
  NpsService,
};
