import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ILogItem } from './logger.interfaces';
import { Loglevels, MapLoglevelsSort, MapLoglevels } from './logger.enums';
import { CorrelationService } from '../correlation/correlation.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public readonly api: string = environment.apiUrl.replace('/api', '') + '/logger/post';

  private _correlationService: CorrelationService = inject(CorrelationService);
  private _debugLevel: MapLoglevels = (environment.debugLevel || MapLoglevels.INFO) as MapLoglevels;
  private _toServer: boolean = environment.logToServer;
  private _stash: ILogItem[] = [];
  private _repeatAfter: number = 5000;
  private _httpOptions = { withCredentials: true };
  private _failedRequests = 0;
  private _maxFailedRequests = 20;

  private _mapOrderLevels: Record<MapLoglevels, number> = MapLoglevelsSort;

  constructor(private httpClient: HttpClient) {
    setTimeout(() => this.sendToServer(), 100);

    if (environment.features.sandbox) {
      (window as any).jitSandbox.logger = this;
    }
  }

  private run(): void {
    setTimeout(() => this.sendToServer(), this._repeatAfter);
  }

  private sendToConsole(item: ILogItem): void {
    const numLevel: number = this._mapOrderLevels[item.level];

    if (numLevel >= this._mapOrderLevels[MapLoglevels.ERROR] && numLevel < this._mapOrderLevels[MapLoglevels.NONE]) {
      console.error(item);
    } else if (item.level === MapLoglevels.WARN) {
      console.warn(item);
    } else if (numLevel <= this._mapOrderLevels[MapLoglevels.INFO]) {
      console.log(item);
    }
  }

  private sendToServer(): void {
    if (this._failedRequests <= this._maxFailedRequests) {
      const logs: ILogItem[] = this._stash.splice(0, 10);

      if (logs.length > 0) {
        this.httpClient.post(this.api, { logs }, this._httpOptions).subscribe({
          next: () => {
            this._failedRequests = 0;

            this.run();
          },
          error: err => {
            console.log('[Error] LoggerService: sending request ->', err);

            this._stash = [...logs, ...this._stash];
            this._failedRequests++;

            this.run();
          },
        });
      } else {
        this.run();
      }
    }
  }

  private mapErrorToLoggerRequest(logLevel: MapLoglevels, err: string | Error, extra: any = null): ILogItem {
    const message: string = typeof err === 'string' ? err : err.message;

    let item: ILogItem = {
      level: logLevel,
      data: {
        ...extra.data,
        message,
        '@front-timestamp': new Date().toISOString(),
        'service.environment': environment.envName,
        'service.name': 'frontend',
        'transaction.id': this._correlationService.get(),
      }
    };

    return item;
  }

  private canSend(logLevel: MapLoglevels): boolean {
    return (
      this._mapOrderLevels[logLevel] >= this._mapOrderLevels[this._debugLevel] &&
      this._mapOrderLevels[logLevel] < this._mapOrderLevels[MapLoglevels.NONE]
    );
  }

  private send(item: ILogItem): void {
    if (this._toServer) {
      // TODO should be removed when Kibana support will be implemented
      // this._stash.push(item);

      this.sendToConsole(item);
    } else {
      this.sendToConsole(item);
    }
  }

  public error(err: string | Error, extra: any = null): void {
    if (err && this.canSend(MapLoglevels.ERROR)) {
      const item: ILogItem = this.mapErrorToLoggerRequest(MapLoglevels.ERROR, err, { data: extra });

      this.send(item);
    }
  }

  public warn(message: string, extra: any = null): void {
    if (message && this.canSend(MapLoglevels.WARN)) {
      const item: ILogItem = this.mapErrorToLoggerRequest(MapLoglevels.WARN, message, { data: extra });

      this.send(item);
    }
  }

  public log(message: string, extra: any = null): void {
    if (message && this.canSend(MapLoglevels.INFO)) {
      const item: ILogItem = this.mapErrorToLoggerRequest(MapLoglevels.INFO, message, { data: extra });

      this.send(item);
    }
  }

  public debug(message: string, extra: any = null): void {
    if (message && this.canSend(MapLoglevels.DEBUG)) {
      const item: ILogItem = this.mapErrorToLoggerRequest(MapLoglevels.DEBUG, message, { data: extra });

      this.send(item);
    }
  }

}
