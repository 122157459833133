import { IClassRoom } from './class-room.interface';

interface IAssignedStudentsItemResponse {
  resource_slug: string;
  title: string;
  thumbnail_path: string;
}

interface IAssignedStudentsResponse {
  [key: string]: IAssignedStudentsItemResponse[];
}

interface IStudendsAssignedRequest {
  resources: string[];
}

interface IClassRoomResponse {
  classRooms: IClassRoom[];
  totalStudents: number;
  selectedStudents: number;
}

type TAssignmentStatus = 'new' | 'assigned' | 'in_progress' | 'completed' | 'error';

export {
  IAssignedStudentsItemResponse,
  IAssignedStudentsResponse,
  IStudendsAssignedRequest,
  IClassRoomResponse,
  TAssignmentStatus,
};
