import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
class DateService {

}

export {
  DateService,
};
