import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IconSimpleComponent } from '../icon-simple/icon-simple.component';
import { FilterPipe, SafeHtmlPipe, SearchMatchPipe } from '../../pipes';
import { useResourse } from '@jit/data-layer';

@Component({
  selector: 'jit-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AutocompleteInputComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    NgScrollbarModule,
    NgFor,
    MatOptionModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    IconSimpleComponent,
    SearchMatchPipe,
    SafeHtmlPipe,
    FilterPipe,
  ],
  host: {
    '(blur)': 'onTouched()',
  },
})
export class AutocompleteInputComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  
  @Input()
  placeholder: string | undefined;
  
  @Input()
  options!: string[];
  
  @Output()
  remove = new EventEmitter<string>();
  
  @Output()
  changed = new EventEmitter<string>();

  public onTouched?: any;
  public control = new FormControl('');
  public _filterOptions = new BehaviorSubject<string[]>([]);
  public filterOptions$ = this._filterOptions.asObservable();
  private _destroy = new Subject<void>();
  private _onChange?: (_: any) => void;
  private innerValue: string = '';

  public ngOnInit(): void {
    this.control.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        if (this._onChange) {
          this._onChange(value);
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('options' in changes) {
      this._filterOptions.next(changes['options'].currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  @useResourse('isClear')
  onClearAllQueryParams(): void {
    this.control.setValue(null);
  }

  public get filteredOptions(): string[] {
    return this.options.filter((option: string) =>
      option.toLowerCase().includes(this.innerValue.toLowerCase())
    );
  }

  writeValue(value: string): void {
    this.control.patchValue(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  onRemove(value: string, event: Event) {
    event.stopPropagation();

    this.remove.emit(value);
  }

  onSubmit(): void {
    this.changed.emit(this.control.value || '');
  }

}
