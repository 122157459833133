<div class="jit-treeview-item">
  <div 
    class="jit-treeview-item__header" 
    [class.collapsible]="isCollapsible"
    [class.not-collapsible]="!isCollapsible"
  >
    @if (isCollapsible) {
      <div class="jit-treeview-item__expand" [class.expanded]="expanded" (click)="onToggle()">
        <jit-icon-simple size="24" iconId="caret-down-outline"></jit-icon-simple>
      </div>
    }
    <jit-checkbox [value]="value" [disabled]="disabled" (changed)="onChange()"></jit-checkbox>
    <div class="jit-treeview-item__title">
      {{ title }}
    </div>
    @if (isCollapsible && count > 0) {
      <div class="jit-treeview-item__count">
        {{ count }}
      </div>
    }
    @if (showWarn) {
      <div class="jit-treeview-item__warn">
        <!-- <jit-icon-simple size="24" iconId="triangle-alert-filled" [jitTooltip]="warnLabel"></jit-icon-simple> -->
        <jit-icon-simple size="24" iconId="triangle-alert-filled" [jitResourcesTooltip]="warnResources"></jit-icon-simple>
        <div class="jit-treeview-item__warn-bg"></div>
      </div>
    }
  </div>
  @if (isCollapsible) {
    <div class="jit-treeview-item__content" [class.expanded]="expanded">
      <ng-content></ng-content>
    </div>
  }
</div>