interface INpsQuestion {
  id: string;
  title: string;
  expanded: boolean;
  placeholder: string;
  sort: number;
}

interface INpsAnswer {
  id: string;
  text: string;
  selected: boolean;
}

interface INpsSurvey {
  id: string;
  title: string;
  description: string;
  questionOnPositive: string;
  questionOnNegative: string;
  questions: INpsQuestion[];
}

interface INpsAvailable {
  available: boolean;
  survey: INpsSurvey | null;
}

interface INpsCreate {
  surveyId: string;
  rating: number;
  answers: INpsAnswer[];
}

export {
  INpsAvailable,
  INpsSurvey,
  INpsQuestion,
  INpsCreate,
  INpsAnswer,
};
