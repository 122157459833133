enum NotificationType {
  ERROR = 'error',
  WARN = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

interface INotification {
  label: string;
  id?: string,
  type?: NotificationType,
  autoClose?: number;
  onClose?: (...args: any[]) => any;
}

export {
  NotificationType,
  INotification,
};
