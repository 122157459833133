import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  Bundle,
  Collection,
  Collections,
  ICollection,
  IconId,
  Resource,
  Suite,
} from '../../models';
import { DialogStatus, SnackbarService } from '@jit/core';
import {
  CollectionModalComponent,
  EditCollectionModalComponent,
  NotificationCollectionComponent,
} from '../../components';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  private _collection = new BehaviorSubject<Collections | null>(null);
  private http: HttpClient = inject(HttpClient);

  public collections$ = this._collection.asObservable();

  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {}

  public has(id: string) {
    return this._collection.value?.has(id);
  }

  public init() {
    this.updateCollections().subscribe();
  }

  public getCollectionByName(name: string) {
    return this._collection.value?.getCollectionByName(name);
  }

  public getCollections() {
    return this.http
      .get<ICollection[]>('/collections')
      .pipe(map((res) => new Collections(res)));
  }

  public getCollection(id: string) {
    if (this._collection.value) {
      return this.collections$.pipe(
        map((collections) => collections?.getCollectionById(id))
      );
    } else {
      return this.http
        .get<ICollection>(`/collections/${id}`)
        .pipe(map((res) => new Collection(res)));
    }
  }

  public editCollection(collection: Collection, name: string) {
    return this.http.put<unknown>(`/collections/${collection.id}`, { name });
  }

  public createCollection(body: {
    lang: string;
    name: string | null;
  }): Observable<Collection | null> {
    return this.http.post<{ id: string }>('/collections', body).pipe(
      switchMap(({ id }) =>
        combineLatest({
          id: of(id),
          collections: this.updateCollections(),
        })
      ),
      map(({ id, collections }) => collections.getCollectionById(id)),
      tap(() => {
        this.showSnackbar({
          text: `Collection saved successfully`,
          icon: 'check-circle',
          hasAction: false,
        });
      })
    );
  }

  public deleteCollection(collection: Collection) {
    const dialogRef = this.dialogService.openConfirmDialog({
      title: 'Delete collection',
      description:
        'This action will delete the collection and cannot be undone. Any resources in this collection will remain in favorites or other collections unless removed.',
      action: {
        accept: 'Yes, delete',
        reject: 'Cancel',
      },
    });

    return dialogRef.afterClosed$.pipe(
      switchMap((status) => {
        if (status === DialogStatus.Accept) {
          return this.http.delete<unknown>(`/collections/${collection.id}`);
        } else {
          return throwError(() => status);
        }
      }),
      switchMap(() => this.updateCollections()),
      tap(() =>
        this.showSnackbar({
          text: `Collection Deleted`,
          hasAction: false,
        })
      )
    );
  }

  public addToCollection(
    item: Bundle | Suite | Resource,
    collection: Collection,
    config: { hasAction: boolean } = { hasAction: false }
  ) {
    const body: { materials?: string[]; groups?: string[] } = {};

    if (item instanceof Resource) {
      body.materials = [item.id];
      body.groups = [];
    } else {
      body.groups = [item.id];
      body.materials = [];
    }

    return this.http
      .post<unknown>(`/collections/${collection.id}/items`, body)
      .pipe(
        switchMap(() => {
          const collections = this._collection.value;

          if (collections) {
            this._collection.next(collections.addMaterial(item, collection));

            return of(collections);
          } else {
            return this.updateCollections();
          }
        }),
        tap(() =>
          this.showSnackbar({
            item,
            text: `Saved to ${collection.name}`,
            icon: 'check-circle',
            ...config,
          })
        )
      );
  }

  public deleteFromCollection(
    item: Bundle | Suite | Resource,
    collection: Collection
  ) {
    const body: { materials?: string[]; groups?: string[] } = {};

    if (item instanceof Resource) {
      body.materials = [item.id];
      body.groups = [];
    } else {
      body.groups = [item.id];
      body.materials = [];
    }

    return this.http
      .delete<unknown>(`/collections/${collection.id}/items`, {
        body,
      })
      .pipe(
        switchMap(() => {
          const collections = this._collection.value;

          if (collections) {
            this._collection.next(collections.deleteMaterial(item, collection));

            return of(collections);
          } else {
            return this.updateCollections();
          }
        }),
        tap(() =>
          this.showSnackbar({
            text: `Removed from ${collection.name}`,
            hasAction: false,
          })
        )
      );
  }

  public openCreationDialog(item?: Suite | Bundle | Resource) {
    const dialogRef = this.dialogService.open(CollectionModalComponent, {
      windowClass: 'item-dialog',
      width: '500px',
    });
    dialogRef.data = { item };
  }

  public openEditDialog(collection: Collection) {
    const dialogRef = this.dialogService.open(EditCollectionModalComponent, {
      windowClass: 'item-dialog',
      width: '500px',
      data: collection,
    });

    return dialogRef.afterClosed$;
  }

  public openConfirmDialog() {
    const dialogRef = this.dialogService.openConfirmDialog({
      title: `Remove favorite?`,
      description:
        'This action will remove the resource from your collections. Are you sure you want to do this?',
      action: {
        reject: 'Cancel',
        accept: 'Yes, remove',
      },
    });

    return dialogRef.afterClosed$;
  }

  private updateCollections() {
    return this.getCollections().pipe(
      tap((result) => this._collection.next(result))
    );
  }

  private showSnackbar(payload: {
    text: string;
    hasAction: boolean;
    item?: Bundle | Suite | Resource;
    icon?: IconId;
  }) {
    this.snackbarService.openFromComponent(
      NotificationCollectionComponent,
      payload
    );
  }
}
