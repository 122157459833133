import { sort, reduce } from 'ramda';
import { Bundle } from './bundle';
import { GroupResponse } from './group';
import { IResourceResponse, Resource } from './resource';
import { Suite } from './suites';

export interface ICollection {
  _id: string;
  lang: string;
  name: string;
  type: string;
  groups: GroupResponse[];
  materials: IResourceResponse[];
  ownerId: string;
  isActive?: boolean;
  isSearchable?: boolean;
  isFilterable?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface IModalCollection {
  item?: Resource | Suite | Bundle;
}

export class Collection {
  id: string;
  name: string;
  lang: string;
  type: string;
  ownerId: string;
  groups: GroupResponse[];
  materials: (Bundle | Suite | Resource)[];
  materialsId: string[];

  constructor(data: ICollection) {
    this.id = data._id;
    this.name = data.name;
    this.lang = data.lang;
    this.type = data.type;
    this.materials = [...data.groups, ...data.materials].map((el) =>
      this.parse(el, data.lang)
    );
    this.ownerId = data.ownerId;
    this.groups = data.groups;
    this.materialsId = this.materials.map((item) => item.id);
  }

  private parse(
    item: IResourceResponse | GroupResponse,
    lang: string
  ): Resource | Bundle | Suite {
    if ('bundleId' in item) {
      return new Resource(item, lang);
    }

    if (Bundle.isBundleType(item)) {
      return new Bundle(item);
    } else {
      return new Suite(item);
    }
  }
}

export class Collections {
  private _payload: ICollection[];

  collections: Collection[] = [];
  private itemIds: Set<string> = new Set();

  public has(id: string): number {
    if (this.itemIds.has(id)) {
      return reduce(
        (acc, next) => (next.materialsId.includes(id) ? acc + 1 : acc),
        0,
        this.collections
      );
    } else {
      return 0;
    }
  }

  constructor(payload: ICollection[]) {
    this._payload = payload;
    this.collections = sort(
      (_, next) => (next.name === 'Favorites' ? 1 : -1),
      payload.map((el) => new Collection(el))
    );
    this.updateItemIds();
  }

  public getCollectionById(id: string) {
    return this.collections.find((collection) => collection.id === id) || null;
  }

  public getCollectionByName(name: string) {
    return this.collections.find((collection) => collection.name === name);
  }

  public addMaterial(item: Bundle | Suite | Resource, collection: Collection) {
    const target = this._payload.find((el) => el._id === collection.id);

    if (!target) {
      return this;
    }

    if (item instanceof Resource) {
      target.materials.push(item.clone());
    } else {
      target.groups.push(item.clone());
    }

    return new Collections(this._payload);
  }

  public deleteMaterial(
    item: Bundle | Suite | Resource,
    collection: Collection
  ) {
    const target = this._payload.find((el) => el._id === collection.id);

    if (!target) {
      return this;
    }

    if (item instanceof Resource) {
      const index = target.materials.findIndex((el) => el.id === item.id);

      target.materials.splice(index, 1);
    } else {
      const index = target.groups.findIndex((el) => el.id === item.id);

      target.groups.splice(index, 1);
    }

    return new Collections(this._payload);
  }

  private updateItemIds() {
    this.collections.forEach((collection) =>
      collection.materials.forEach(
        (material) => material.id && this.itemIds.add(material.id)
      )
    );
  }
}
