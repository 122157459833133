<div class="jit-select-state">
  <div *ngIf="!isFirstLoaded" class="jit-select-state__header">
    Welcome!
  </div>
  <div *ngIf="isFirstLoaded"class="jit-select-state__header">
    Time for a change?
  </div>
  <div class="jit-select-state__content">
    <p *ngIf="!isFirstLoaded">
      Select your state to display state specific standard codes as
      you discover new resources. You can change your state 
      selection at any time within your profile settings.
    </p>
    <p *ngIf="isFirstLoaded">
      Choose a different state and hit continue to display that state's
      specific stand codes as you discover new resources. To remove
      standard codes altogether, choose clear in the dropdown and 
      hit continue.
    </p>
    <div class="jit-select-state__select">
      <jit-simple-select
        #dropdown
        placeholder="Select my state"
        [options]="states"
        [value]="state"
        (changed)="onChanged($event)"
      ></jit-simple-select>
    </div>
  </div>
  <div class="jit-select-state__footer">
    <span *ngIf="!isFirstLoaded" class="button-default" (click)="onSkip()">
      Skip
    </span>
    <span *ngIf="isFirstLoaded" class="button-default" (click)="onSkip()">
      Cancel
    </span>
    <span 
      [class.button-active-disabled]="!isChanged"
      [class.button-active]="isChanged"
      (click)="onApply()"
    >
      Continue
    </span>
  </div>
</div>